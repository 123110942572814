import { library } from '@fortawesome/fontawesome-svg-core'
import * as faSolid from '@fortawesome/free-solid-svg-icons'
import * as faRegular from '@fortawesome/free-regular-svg-icons'

library.add(
  faRegular.faLightbulb,
  faSolid.faAlignCenter,
  faSolid.faAlignLeft,
  faSolid.faAlignRight,
  faSolid.faAngleDown,
  faSolid.faAngleRight,
  faSolid.faArrowLeft,
  faSolid.faArrowRight,
  faSolid.faArrowsUpDownLeftRight,
  faSolid.faBan,
  faSolid.faBars,
  faSolid.faBold,
  faSolid.faBullhorn,
  faSolid.faBullseye,
  faSolid.faCalendar,
  faSolid.faCaretDown,
  faSolid.faCaretRight,
  faSolid.faCaretUp,
  faSolid.faCheck,
  faSolid.faChevronDown,
  faSolid.faChevronLeft,
  faSolid.faChevronRight,
  faSolid.faChevronUp,
  faSolid.faCircleCheck,
  faSolid.faCircleInfo,
  faSolid.faCircleMinus,
  faSolid.faClipboard,
  faSolid.faCopy,
  faSolid.faDollar,
  faSolid.faEllipsisH,
  faSolid.faEllipsisV,
  faSolid.faExternalLink,
  faSolid.faEye,
  faSolid.faEyeSlash,
  faSolid.faFilePdf,
  faSolid.faFilter,
  faSolid.faGear,
  faSolid.faGrip,
  faSolid.faGripVertical,
  faSolid.faHourglassStart,
  faSolid.faImage,
  faSolid.faIndent,
  faSolid.faItalic,
  faSolid.faLink,
  faSolid.faList,
  faSolid.faListOl,
  faSolid.faListUl,
  faSolid.faMagnifyingGlass,
  faSolid.faOutdent,
  faSolid.faPause,
  faSolid.faPencil,
  faSolid.faPlay,
  faSolid.faPlus,
  faSolid.faQuestionCircle,
  faSolid.faQuestion,
  faSolid.faSearch,
  faSolid.faSortDown,
  faSolid.faSortUp,
  faSolid.faSquareRootVariable,
  faSolid.faSubscript,
  faSolid.faSuperscript,
  faSolid.faTable,
  faSolid.faTableCellsLarge,
  faSolid.faTimes,
  faSolid.faTrash,
  faSolid.faTriangleExclamation,
  faSolid.faUnderline,
  faSolid.faUnlink,
  faSolid.faVolumeHigh,
  faSolid.faUserPlus,
  faSolid.faXmark,
  faSolid.faRightToBracket,
  faSolid.faRotateLeft,
  faSolid.faArrowRightToBracket,
  faSolid.faVectorSquare,
  faSolid.faShuffle
)
