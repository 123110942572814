var Ou = Object.defineProperty;
var Pu = (e, t, a) => t in e ? Ou(e, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : e[t] = a;
var fe = (e, t, a) => (Pu(e, typeof t != "symbol" ? t + "" : t, a), a);
import { defineComponent as xe, openBlock as P, createElementBlock as j, renderSlot as ie, computed as E, watch as it, h as qt, toRef as Ke, useAttrs as wr, mergeProps as Ne, withDirectives as ma, createElementVNode as ce, isRef as Gt, unref as p, vModelDynamic as Su, createBlock as Ae, createCommentVNode as Q, vModelText as Ai, normalizeClass as we, toDisplayString as Ye, vModelRadio as Cu, ref as U, inject as zt, watchEffect as ya, onMounted as Le, cloneVNode as Du, Fragment as Me, provide as Jt, onUnmounted as ra, nextTick as at, toRaw as $t, withCtx as ye, createVNode as Fe, withModifiers as Ra, watchPostEffect as $u, createTextVNode as st, Teleport as Ti, Transition as Va, renderList as Re, reactive as un, useSlots as Ba, createSlots as dt, normalizeProps as ot, guardReactiveProps as gt, resolveDynamicComponent as kr, normalizeStyle as Pt, render as wo, onBeforeUpdate as Lu, vShow as rr, withKeys as lr, getCurrentScope as Eu, onScopeDispose as Nu, resolveComponent as Oi } from "vue";
import { useIsSubmitting as Ru, useField as Ya, ErrorMessage as Iu, useForm as Hu } from "vee-validate";
const zu = { class: "tw-reset font-bold" }, w3 = /* @__PURE__ */ xe({
  name: "UILabel",
  __name: "UILabel",
  setup(e) {
    return (t, a) => (P(), j("label", zu, [
      ie(t.$slots, "default")
    ]));
  }
}), k3 = /* @__PURE__ */ xe({
  __name: "UIForm",
  setup(e) {
    const t = Ru(), a = (n) => {
      t.value && (n.preventDefault(), n.stopImmediatePropagation());
    };
    return (n, r) => (P(), j("form", {
      class: "tw-reset",
      novalidate: "",
      onSubmit: a
    }, [
      ie(n.$slots, "default")
    ], 32));
  }
});
function ko(e, t) {
  var a = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    t && (n = n.filter(function(r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), a.push.apply(a, n);
  }
  return a;
}
function ve(e) {
  for (var t = 1; t < arguments.length; t++) {
    var a = arguments[t] != null ? arguments[t] : {};
    t % 2 ? ko(Object(a), !0).forEach(function(n) {
      rt(e, n, a[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(a)) : ko(Object(a)).forEach(function(n) {
      Object.defineProperty(e, n, Object.getOwnPropertyDescriptor(a, n));
    });
  }
  return e;
}
function sr(e) {
  "@babel/helpers - typeof";
  return sr = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(t) {
    return typeof t;
  } : function(t) {
    return t && typeof Symbol == "function" && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
  }, sr(e);
}
function Vu(e, t) {
  if (!(e instanceof t))
    throw new TypeError("Cannot call a class as a function");
}
function xo(e, t) {
  for (var a = 0; a < t.length; a++) {
    var n = t[a];
    n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
  }
}
function Bu(e, t, a) {
  return t && xo(e.prototype, t), a && xo(e, a), Object.defineProperty(e, "prototype", {
    writable: !1
  }), e;
}
function rt(e, t, a) {
  return t in e ? Object.defineProperty(e, t, {
    value: a,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = a, e;
}
function Cl(e, t) {
  return Fu(e) || Uu(e, t) || Pi(e, t) || qu();
}
function En(e) {
  return Yu(e) || ju(e) || Pi(e) || Wu();
}
function Yu(e) {
  if (Array.isArray(e))
    return sl(e);
}
function Fu(e) {
  if (Array.isArray(e))
    return e;
}
function ju(e) {
  if (typeof Symbol < "u" && e[Symbol.iterator] != null || e["@@iterator"] != null)
    return Array.from(e);
}
function Uu(e, t) {
  var a = e == null ? null : typeof Symbol < "u" && e[Symbol.iterator] || e["@@iterator"];
  if (a != null) {
    var n = [], r = !0, l = !1, o, i;
    try {
      for (a = a.call(e); !(r = (o = a.next()).done) && (n.push(o.value), !(t && n.length === t)); r = !0)
        ;
    } catch (s) {
      l = !0, i = s;
    } finally {
      try {
        !r && a.return != null && a.return();
      } finally {
        if (l)
          throw i;
      }
    }
    return n;
  }
}
function Pi(e, t) {
  if (e) {
    if (typeof e == "string")
      return sl(e, t);
    var a = Object.prototype.toString.call(e).slice(8, -1);
    if (a === "Object" && e.constructor && (a = e.constructor.name), a === "Map" || a === "Set")
      return Array.from(e);
    if (a === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(a))
      return sl(e, t);
  }
}
function sl(e, t) {
  (t == null || t > e.length) && (t = e.length);
  for (var a = 0, n = new Array(t); a < t; a++)
    n[a] = e[a];
  return n;
}
function Wu() {
  throw new TypeError(`Invalid attempt to spread non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function qu() {
  throw new TypeError(`Invalid attempt to destructure non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
var _o = function() {
}, Dl = {}, Si = {}, Ci = null, Di = {
  mark: _o,
  measure: _o
};
try {
  typeof window < "u" && (Dl = window), typeof document < "u" && (Si = document), typeof MutationObserver < "u" && (Ci = MutationObserver), typeof performance < "u" && (Di = performance);
} catch {
}
var Ku = Dl.navigator || {}, Mo = Ku.userAgent, Ao = Mo === void 0 ? "" : Mo, ga = Dl, Ve = Si, To = Ci, jn = Di;
ga.document;
var la = !!Ve.documentElement && !!Ve.head && typeof Ve.addEventListener == "function" && typeof Ve.createElement == "function", $i = ~Ao.indexOf("MSIE") || ~Ao.indexOf("Trident/"), Un, Wn, qn, Kn, Xn, Zt = "___FONT_AWESOME___", ul = 16, Li = "fa", Ei = "svg-inline--fa", Ia = "data-fa-i2svg", cl = "data-fa-pseudo-element", Xu = "data-fa-pseudo-element-pending", $l = "data-prefix", Ll = "data-icon", Oo = "fontawesome-i2svg", Qu = "async", Gu = ["HTML", "HEAD", "STYLE", "SCRIPT"], Ni = function() {
  try {
    return process.env.NODE_ENV === "production";
  } catch {
    return !1;
  }
}(), ze = "classic", qe = "sharp", El = [ze, qe];
function Nn(e) {
  return new Proxy(e, {
    get: function(a, n) {
      return n in a ? a[n] : a[ze];
    }
  });
}
var Mn = Nn((Un = {}, rt(Un, ze, {
  fa: "solid",
  fas: "solid",
  "fa-solid": "solid",
  far: "regular",
  "fa-regular": "regular",
  fal: "light",
  "fa-light": "light",
  fat: "thin",
  "fa-thin": "thin",
  fad: "duotone",
  "fa-duotone": "duotone",
  fab: "brands",
  "fa-brands": "brands",
  fak: "kit",
  fakd: "kit",
  "fa-kit": "kit",
  "fa-kit-duotone": "kit"
}), rt(Un, qe, {
  fa: "solid",
  fass: "solid",
  "fa-solid": "solid",
  fasr: "regular",
  "fa-regular": "regular",
  fasl: "light",
  "fa-light": "light",
  fast: "thin",
  "fa-thin": "thin"
}), Un)), An = Nn((Wn = {}, rt(Wn, ze, {
  solid: "fas",
  regular: "far",
  light: "fal",
  thin: "fat",
  duotone: "fad",
  brands: "fab",
  kit: "fak"
}), rt(Wn, qe, {
  solid: "fass",
  regular: "fasr",
  light: "fasl",
  thin: "fast"
}), Wn)), Tn = Nn((qn = {}, rt(qn, ze, {
  fab: "fa-brands",
  fad: "fa-duotone",
  fak: "fa-kit",
  fal: "fa-light",
  far: "fa-regular",
  fas: "fa-solid",
  fat: "fa-thin"
}), rt(qn, qe, {
  fass: "fa-solid",
  fasr: "fa-regular",
  fasl: "fa-light",
  fast: "fa-thin"
}), qn)), Ju = Nn((Kn = {}, rt(Kn, ze, {
  "fa-brands": "fab",
  "fa-duotone": "fad",
  "fa-kit": "fak",
  "fa-light": "fal",
  "fa-regular": "far",
  "fa-solid": "fas",
  "fa-thin": "fat"
}), rt(Kn, qe, {
  "fa-solid": "fass",
  "fa-regular": "fasr",
  "fa-light": "fasl",
  "fa-thin": "fast"
}), Kn)), Zu = /fa(s|r|l|t|d|b|k|ss|sr|sl|st)?[\-\ ]/, Ri = "fa-layers-text", ec = /Font ?Awesome ?([56 ]*)(Solid|Regular|Light|Thin|Duotone|Brands|Free|Pro|Sharp|Kit)?.*/i, tc = Nn((Xn = {}, rt(Xn, ze, {
  900: "fas",
  400: "far",
  normal: "far",
  300: "fal",
  100: "fat"
}), rt(Xn, qe, {
  900: "fass",
  400: "fasr",
  300: "fasl",
  100: "fast"
}), Xn)), Ii = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10], ac = Ii.concat([11, 12, 13, 14, 15, 16, 17, 18, 19, 20]), nc = ["class", "data-prefix", "data-icon", "data-fa-transform", "data-fa-mask"], Da = {
  GROUP: "duotone-group",
  SWAP_OPACITY: "swap-opacity",
  PRIMARY: "primary",
  SECONDARY: "secondary"
}, On = /* @__PURE__ */ new Set();
Object.keys(An[ze]).map(On.add.bind(On));
Object.keys(An[qe]).map(On.add.bind(On));
var rc = [].concat(El, En(On), ["2xs", "xs", "sm", "lg", "xl", "2xl", "beat", "border", "fade", "beat-fade", "bounce", "flip-both", "flip-horizontal", "flip-vertical", "flip", "fw", "inverse", "layers-counter", "layers-text", "layers", "li", "pull-left", "pull-right", "pulse", "rotate-180", "rotate-270", "rotate-90", "rotate-by", "shake", "spin-pulse", "spin-reverse", "spin", "stack-1x", "stack-2x", "stack", "ul", Da.GROUP, Da.SWAP_OPACITY, Da.PRIMARY, Da.SECONDARY]).concat(Ii.map(function(e) {
  return "".concat(e, "x");
})).concat(ac.map(function(e) {
  return "w-".concat(e);
})), bn = ga.FontAwesomeConfig || {};
function lc(e) {
  var t = Ve.querySelector("script[" + e + "]");
  if (t)
    return t.getAttribute(e);
}
function oc(e) {
  return e === "" ? !0 : e === "false" ? !1 : e === "true" ? !0 : e;
}
if (Ve && typeof Ve.querySelector == "function") {
  var ic = [["data-family-prefix", "familyPrefix"], ["data-css-prefix", "cssPrefix"], ["data-family-default", "familyDefault"], ["data-style-default", "styleDefault"], ["data-replacement-class", "replacementClass"], ["data-auto-replace-svg", "autoReplaceSvg"], ["data-auto-add-css", "autoAddCss"], ["data-auto-a11y", "autoA11y"], ["data-search-pseudo-elements", "searchPseudoElements"], ["data-observe-mutations", "observeMutations"], ["data-mutate-approach", "mutateApproach"], ["data-keep-original-source", "keepOriginalSource"], ["data-measure-performance", "measurePerformance"], ["data-show-missing-icons", "showMissingIcons"]];
  ic.forEach(function(e) {
    var t = Cl(e, 2), a = t[0], n = t[1], r = oc(lc(a));
    r != null && (bn[n] = r);
  });
}
var Hi = {
  styleDefault: "solid",
  familyDefault: "classic",
  cssPrefix: Li,
  replacementClass: Ei,
  autoReplaceSvg: !0,
  autoAddCss: !0,
  autoA11y: !0,
  searchPseudoElements: !1,
  observeMutations: !0,
  mutateApproach: "async",
  keepOriginalSource: !0,
  measurePerformance: !1,
  showMissingIcons: !0
};
bn.familyPrefix && (bn.cssPrefix = bn.familyPrefix);
var en = ve(ve({}, Hi), bn);
en.autoReplaceSvg || (en.observeMutations = !1);
var pe = {};
Object.keys(Hi).forEach(function(e) {
  Object.defineProperty(pe, e, {
    enumerable: !0,
    set: function(a) {
      en[e] = a, wn.forEach(function(n) {
        return n(pe);
      });
    },
    get: function() {
      return en[e];
    }
  });
});
Object.defineProperty(pe, "familyPrefix", {
  enumerable: !0,
  set: function(t) {
    en.cssPrefix = t, wn.forEach(function(a) {
      return a(pe);
    });
  },
  get: function() {
    return en.cssPrefix;
  }
});
ga.FontAwesomeConfig = pe;
var wn = [];
function sc(e) {
  return wn.push(e), function() {
    wn.splice(wn.indexOf(e), 1);
  };
}
var ua = ul, jt = {
  size: 16,
  x: 0,
  y: 0,
  rotate: 0,
  flipX: !1,
  flipY: !1
};
function uc(e) {
  if (!(!e || !la)) {
    var t = Ve.createElement("style");
    t.setAttribute("type", "text/css"), t.innerHTML = e;
    for (var a = Ve.head.childNodes, n = null, r = a.length - 1; r > -1; r--) {
      var l = a[r], o = (l.tagName || "").toUpperCase();
      ["STYLE", "LINK"].indexOf(o) > -1 && (n = l);
    }
    return Ve.head.insertBefore(t, n), e;
  }
}
var cc = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
function Pn() {
  for (var e = 12, t = ""; e-- > 0; )
    t += cc[Math.random() * 62 | 0];
  return t;
}
function cn(e) {
  for (var t = [], a = (e || []).length >>> 0; a--; )
    t[a] = e[a];
  return t;
}
function Nl(e) {
  return e.classList ? cn(e.classList) : (e.getAttribute("class") || "").split(" ").filter(function(t) {
    return t;
  });
}
function zi(e) {
  return "".concat(e).replace(/&/g, "&amp;").replace(/"/g, "&quot;").replace(/'/g, "&#39;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
}
function dc(e) {
  return Object.keys(e || {}).reduce(function(t, a) {
    return t + "".concat(a, '="').concat(zi(e[a]), '" ');
  }, "").trim();
}
function xr(e) {
  return Object.keys(e || {}).reduce(function(t, a) {
    return t + "".concat(a, ": ").concat(e[a].trim(), ";");
  }, "");
}
function Rl(e) {
  return e.size !== jt.size || e.x !== jt.x || e.y !== jt.y || e.rotate !== jt.rotate || e.flipX || e.flipY;
}
function fc(e) {
  var t = e.transform, a = e.containerWidth, n = e.iconWidth, r = {
    transform: "translate(".concat(a / 2, " 256)")
  }, l = "translate(".concat(t.x * 32, ", ").concat(t.y * 32, ") "), o = "scale(".concat(t.size / 16 * (t.flipX ? -1 : 1), ", ").concat(t.size / 16 * (t.flipY ? -1 : 1), ") "), i = "rotate(".concat(t.rotate, " 0 0)"), s = {
    transform: "".concat(l, " ").concat(o, " ").concat(i)
  }, c = {
    transform: "translate(".concat(n / 2 * -1, " -256)")
  };
  return {
    outer: r,
    inner: s,
    path: c
  };
}
function vc(e) {
  var t = e.transform, a = e.width, n = a === void 0 ? ul : a, r = e.height, l = r === void 0 ? ul : r, o = e.startCentered, i = o === void 0 ? !1 : o, s = "";
  return i && $i ? s += "translate(".concat(t.x / ua - n / 2, "em, ").concat(t.y / ua - l / 2, "em) ") : i ? s += "translate(calc(-50% + ".concat(t.x / ua, "em), calc(-50% + ").concat(t.y / ua, "em)) ") : s += "translate(".concat(t.x / ua, "em, ").concat(t.y / ua, "em) "), s += "scale(".concat(t.size / ua * (t.flipX ? -1 : 1), ", ").concat(t.size / ua * (t.flipY ? -1 : 1), ") "), s += "rotate(".concat(t.rotate, "deg) "), s;
}
var mc = `:root, :host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
  --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
  --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
  --fa-font-sharp-regular: normal 400 1em/1 "Font Awesome 6 Sharp";
  --fa-font-sharp-light: normal 300 1em/1 "Font Awesome 6 Sharp";
  --fa-font-sharp-thin: normal 100 1em/1 "Font Awesome 6 Sharp";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-2xs {
  vertical-align: 0.1em;
}
.svg-inline--fa.fa-xs {
  vertical-align: 0em;
}
.svg-inline--fa.fa-sm {
  vertical-align: -0.0714285705em;
}
.svg-inline--fa.fa-lg {
  vertical-align: -0.2em;
}
.svg-inline--fa.fa-xl {
  vertical-align: -0.25em;
}
.svg-inline--fa.fa-2xl {
  vertical-align: -0.3125em;
}
.svg-inline--fa.fa-pull-left {
  margin-right: var(--fa-pull-margin, 0.3em);
  width: auto;
}
.svg-inline--fa.fa-pull-right {
  margin-left: var(--fa-pull-margin, 0.3em);
  width: auto;
}
.svg-inline--fa.fa-li {
  width: var(--fa-li-width, 2em);
  top: 0.25em;
}
.svg-inline--fa.fa-fw {
  width: var(--fa-fw-width, 1.25em);
}

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.fa-layers-counter, .fa-layers-text {
  display: inline-block;
  position: absolute;
  text-align: center;
}

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -0.125em;
  width: 1em;
}
.fa-layers svg.svg-inline--fa {
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.fa-layers-text {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.fa-layers-counter {
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: var(--fa-inverse, #fff);
  line-height: var(--fa-counter-line-height, 1);
  max-width: var(--fa-counter-max-width, 5em);
  min-width: var(--fa-counter-min-width, 1.5em);
  overflow: hidden;
  padding: var(--fa-counter-padding, 0.25em 0.5em);
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-counter-scale, 0.25));
          transform: scale(var(--fa-counter-scale, 0.25));
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

.fa-layers-bottom-right {
  bottom: var(--fa-bottom, 0);
  right: var(--fa-right, 0);
  top: auto;
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
}

.fa-layers-bottom-left {
  bottom: var(--fa-bottom, 0);
  left: var(--fa-left, 0);
  right: auto;
  top: auto;
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

.fa-layers-top-right {
  top: var(--fa-top, 0);
  right: var(--fa-right, 0);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

.fa-layers-top-left {
  left: var(--fa-left, 0);
  right: auto;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-layers-scale, 0.25));
          transform: scale(var(--fa-layers-scale, 0.25));
  -webkit-transform-origin: top left;
          transform-origin: top left;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em;
}

.fa-xs {
  font-size: 0.75em;
  line-height: 0.0833333337em;
  vertical-align: 0.125em;
}

.fa-sm {
  font-size: 0.875em;
  line-height: 0.0714285718em;
  vertical-align: 0.0535714295em;
}

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em;
}

.fa-xl {
  font-size: 1.5em;
  line-height: 0.0416666682em;
  vertical-align: -0.125em;
}

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em);
}

.fa-beat {
  -webkit-animation-name: fa-beat;
          animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  -webkit-animation-name: fa-bounce;
          animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1));
}

.fa-fade {
  -webkit-animation-name: fa-fade;
          animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-beat-fade {
  -webkit-animation-name: fa-beat-fade;
          animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
}

.fa-flip {
  -webkit-animation-name: fa-flip;
          animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  -webkit-animation-name: fa-shake;
          animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
          animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
          animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
          animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
.fa-bounce,
.fa-fade,
.fa-beat-fade,
.fa-flip,
.fa-pulse,
.fa-shake,
.fa-spin,
.fa-spin-pulse {
    -webkit-animation-delay: -1ms;
            animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
            animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
  }
}
@-webkit-keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25));
  }
}
@-webkit-keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
}
@keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
            transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0);
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
            transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em));
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
            transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0);
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
            transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em));
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
            transform: scale(1, 1) translateY(0);
  }
}
@-webkit-keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4);
  }
}
@-webkit-keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
            transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}
@-webkit-keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}
@-webkit-keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
  }
  4% {
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
  }
  8%, 24% {
    -webkit-transform: rotate(-18deg);
            transform: rotate(-18deg);
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-22deg);
            transform: rotate(-22deg);
  }
  20% {
    -webkit-transform: rotate(22deg);
            transform: rotate(22deg);
  }
  32% {
    -webkit-transform: rotate(-12deg);
            transform: rotate(-12deg);
  }
  36% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
  }
  40%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}

.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
          transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  display: inline-block;
  vertical-align: middle;
  height: 2em;
  position: relative;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--fa-stack-z-index, auto);
}

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em;
}
.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: var(--fa-secondary-opacity, 0.4);
}

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: var(--fa-secondary-opacity, 0.4);
}

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: var(--fa-primary-opacity, 1);
}

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
  fill: black;
}

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff);
}`;
function Vi() {
  var e = Li, t = Ei, a = pe.cssPrefix, n = pe.replacementClass, r = mc;
  if (a !== e || n !== t) {
    var l = new RegExp("\\.".concat(e, "\\-"), "g"), o = new RegExp("\\--".concat(e, "\\-"), "g"), i = new RegExp("\\.".concat(t), "g");
    r = r.replace(l, ".".concat(a, "-")).replace(o, "--".concat(a, "-")).replace(i, ".".concat(n));
  }
  return r;
}
var Po = !1;
function jr() {
  pe.autoAddCss && !Po && (uc(Vi()), Po = !0);
}
var pc = {
  mixout: function() {
    return {
      dom: {
        css: Vi,
        insertCss: jr
      }
    };
  },
  hooks: function() {
    return {
      beforeDOMElementCreation: function() {
        jr();
      },
      beforeI2svg: function() {
        jr();
      }
    };
  }
}, ea = ga || {};
ea[Zt] || (ea[Zt] = {});
ea[Zt].styles || (ea[Zt].styles = {});
ea[Zt].hooks || (ea[Zt].hooks = {});
ea[Zt].shims || (ea[Zt].shims = []);
var Rt = ea[Zt], Bi = [], hc = function e() {
  Ve.removeEventListener("DOMContentLoaded", e), ur = 1, Bi.map(function(t) {
    return t();
  });
}, ur = !1;
la && (ur = (Ve.documentElement.doScroll ? /^loaded|^c/ : /^loaded|^i|^c/).test(Ve.readyState), ur || Ve.addEventListener("DOMContentLoaded", hc));
function yc(e) {
  la && (ur ? setTimeout(e, 0) : Bi.push(e));
}
function Rn(e) {
  var t = e.tag, a = e.attributes, n = a === void 0 ? {} : a, r = e.children, l = r === void 0 ? [] : r;
  return typeof e == "string" ? zi(e) : "<".concat(t, " ").concat(dc(n), ">").concat(l.map(Rn).join(""), "</").concat(t, ">");
}
function So(e, t, a) {
  if (e && e[t] && e[t][a])
    return {
      prefix: t,
      iconName: a,
      icon: e[t][a]
    };
}
var gc = function(t, a) {
  return function(n, r, l, o) {
    return t.call(a, n, r, l, o);
  };
}, Ur = function(t, a, n, r) {
  var l = Object.keys(t), o = l.length, i = r !== void 0 ? gc(a, r) : a, s, c, u;
  for (n === void 0 ? (s = 1, u = t[l[0]]) : (s = 0, u = n); s < o; s++)
    c = l[s], u = i(u, t[c], c, t);
  return u;
};
function bc(e) {
  for (var t = [], a = 0, n = e.length; a < n; ) {
    var r = e.charCodeAt(a++);
    if (r >= 55296 && r <= 56319 && a < n) {
      var l = e.charCodeAt(a++);
      (l & 64512) == 56320 ? t.push(((r & 1023) << 10) + (l & 1023) + 65536) : (t.push(r), a--);
    } else
      t.push(r);
  }
  return t;
}
function dl(e) {
  var t = bc(e);
  return t.length === 1 ? t[0].toString(16) : null;
}
function wc(e, t) {
  var a = e.length, n = e.charCodeAt(t), r;
  return n >= 55296 && n <= 56319 && a > t + 1 && (r = e.charCodeAt(t + 1), r >= 56320 && r <= 57343) ? (n - 55296) * 1024 + r - 56320 + 65536 : n;
}
function Co(e) {
  return Object.keys(e).reduce(function(t, a) {
    var n = e[a], r = !!n.icon;
    return r ? t[n.iconName] = n.icon : t[a] = n, t;
  }, {});
}
function fl(e, t) {
  var a = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {}, n = a.skipHooks, r = n === void 0 ? !1 : n, l = Co(t);
  typeof Rt.hooks.addPack == "function" && !r ? Rt.hooks.addPack(e, Co(t)) : Rt.styles[e] = ve(ve({}, Rt.styles[e] || {}), l), e === "fas" && fl("fa", t);
}
var Qn, Gn, Jn, Ka = Rt.styles, kc = Rt.shims, xc = (Qn = {}, rt(Qn, ze, Object.values(Tn[ze])), rt(Qn, qe, Object.values(Tn[qe])), Qn), Il = null, Yi = {}, Fi = {}, ji = {}, Ui = {}, Wi = {}, _c = (Gn = {}, rt(Gn, ze, Object.keys(Mn[ze])), rt(Gn, qe, Object.keys(Mn[qe])), Gn);
function Mc(e) {
  return ~rc.indexOf(e);
}
function Ac(e, t) {
  var a = t.split("-"), n = a[0], r = a.slice(1).join("-");
  return n === e && r !== "" && !Mc(r) ? r : null;
}
var qi = function() {
  var t = function(l) {
    return Ur(Ka, function(o, i, s) {
      return o[s] = Ur(i, l, {}), o;
    }, {});
  };
  Yi = t(function(r, l, o) {
    if (l[3] && (r[l[3]] = o), l[2]) {
      var i = l[2].filter(function(s) {
        return typeof s == "number";
      });
      i.forEach(function(s) {
        r[s.toString(16)] = o;
      });
    }
    return r;
  }), Fi = t(function(r, l, o) {
    if (r[o] = o, l[2]) {
      var i = l[2].filter(function(s) {
        return typeof s == "string";
      });
      i.forEach(function(s) {
        r[s] = o;
      });
    }
    return r;
  }), Wi = t(function(r, l, o) {
    var i = l[2];
    return r[o] = o, i.forEach(function(s) {
      r[s] = o;
    }), r;
  });
  var a = "far" in Ka || pe.autoFetchSvg, n = Ur(kc, function(r, l) {
    var o = l[0], i = l[1], s = l[2];
    return i === "far" && !a && (i = "fas"), typeof o == "string" && (r.names[o] = {
      prefix: i,
      iconName: s
    }), typeof o == "number" && (r.unicodes[o.toString(16)] = {
      prefix: i,
      iconName: s
    }), r;
  }, {
    names: {},
    unicodes: {}
  });
  ji = n.names, Ui = n.unicodes, Il = _r(pe.styleDefault, {
    family: pe.familyDefault
  });
};
sc(function(e) {
  Il = _r(e.styleDefault, {
    family: pe.familyDefault
  });
});
qi();
function Hl(e, t) {
  return (Yi[e] || {})[t];
}
function Tc(e, t) {
  return (Fi[e] || {})[t];
}
function $a(e, t) {
  return (Wi[e] || {})[t];
}
function Ki(e) {
  return ji[e] || {
    prefix: null,
    iconName: null
  };
}
function Oc(e) {
  var t = Ui[e], a = Hl("fas", e);
  return t || (a ? {
    prefix: "fas",
    iconName: a
  } : null) || {
    prefix: null,
    iconName: null
  };
}
function ba() {
  return Il;
}
var zl = function() {
  return {
    prefix: null,
    iconName: null,
    rest: []
  };
};
function _r(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, a = t.family, n = a === void 0 ? ze : a, r = Mn[n][e], l = An[n][e] || An[n][r], o = e in Rt.styles ? e : null;
  return l || o || null;
}
var Do = (Jn = {}, rt(Jn, ze, Object.keys(Tn[ze])), rt(Jn, qe, Object.keys(Tn[qe])), Jn);
function Mr(e) {
  var t, a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = a.skipLookups, r = n === void 0 ? !1 : n, l = (t = {}, rt(t, ze, "".concat(pe.cssPrefix, "-").concat(ze)), rt(t, qe, "".concat(pe.cssPrefix, "-").concat(qe)), t), o = null, i = ze;
  (e.includes(l[ze]) || e.some(function(c) {
    return Do[ze].includes(c);
  })) && (i = ze), (e.includes(l[qe]) || e.some(function(c) {
    return Do[qe].includes(c);
  })) && (i = qe);
  var s = e.reduce(function(c, u) {
    var d = Ac(pe.cssPrefix, u);
    if (Ka[u] ? (u = xc[i].includes(u) ? Ju[i][u] : u, o = u, c.prefix = u) : _c[i].indexOf(u) > -1 ? (o = u, c.prefix = _r(u, {
      family: i
    })) : d ? c.iconName = d : u !== pe.replacementClass && u !== l[ze] && u !== l[qe] && c.rest.push(u), !r && c.prefix && c.iconName) {
      var f = o === "fa" ? Ki(c.iconName) : {}, h = $a(c.prefix, c.iconName);
      f.prefix && (o = null), c.iconName = f.iconName || h || c.iconName, c.prefix = f.prefix || c.prefix, c.prefix === "far" && !Ka.far && Ka.fas && !pe.autoFetchSvg && (c.prefix = "fas");
    }
    return c;
  }, zl());
  return (e.includes("fa-brands") || e.includes("fab")) && (s.prefix = "fab"), (e.includes("fa-duotone") || e.includes("fad")) && (s.prefix = "fad"), !s.prefix && i === qe && (Ka.fass || pe.autoFetchSvg) && (s.prefix = "fass", s.iconName = $a(s.prefix, s.iconName) || s.iconName), (s.prefix === "fa" || o === "fa") && (s.prefix = ba() || "fas"), s;
}
var Pc = /* @__PURE__ */ function() {
  function e() {
    Vu(this, e), this.definitions = {};
  }
  return Bu(e, [{
    key: "add",
    value: function() {
      for (var a = this, n = arguments.length, r = new Array(n), l = 0; l < n; l++)
        r[l] = arguments[l];
      var o = r.reduce(this._pullDefinitions, {});
      Object.keys(o).forEach(function(i) {
        a.definitions[i] = ve(ve({}, a.definitions[i] || {}), o[i]), fl(i, o[i]);
        var s = Tn[ze][i];
        s && fl(s, o[i]), qi();
      });
    }
  }, {
    key: "reset",
    value: function() {
      this.definitions = {};
    }
  }, {
    key: "_pullDefinitions",
    value: function(a, n) {
      var r = n.prefix && n.iconName && n.icon ? {
        0: n
      } : n;
      return Object.keys(r).map(function(l) {
        var o = r[l], i = o.prefix, s = o.iconName, c = o.icon, u = c[2];
        a[i] || (a[i] = {}), u.length > 0 && u.forEach(function(d) {
          typeof d == "string" && (a[i][d] = c);
        }), a[i][s] = c;
      }), a;
    }
  }]), e;
}(), $o = [], Xa = {}, Ga = {}, Sc = Object.keys(Ga);
function Cc(e, t) {
  var a = t.mixoutsTo;
  return $o = e, Xa = {}, Object.keys(Ga).forEach(function(n) {
    Sc.indexOf(n) === -1 && delete Ga[n];
  }), $o.forEach(function(n) {
    var r = n.mixout ? n.mixout() : {};
    if (Object.keys(r).forEach(function(o) {
      typeof r[o] == "function" && (a[o] = r[o]), sr(r[o]) === "object" && Object.keys(r[o]).forEach(function(i) {
        a[o] || (a[o] = {}), a[o][i] = r[o][i];
      });
    }), n.hooks) {
      var l = n.hooks();
      Object.keys(l).forEach(function(o) {
        Xa[o] || (Xa[o] = []), Xa[o].push(l[o]);
      });
    }
    n.provides && n.provides(Ga);
  }), a;
}
function vl(e, t) {
  for (var a = arguments.length, n = new Array(a > 2 ? a - 2 : 0), r = 2; r < a; r++)
    n[r - 2] = arguments[r];
  var l = Xa[e] || [];
  return l.forEach(function(o) {
    t = o.apply(null, [t].concat(n));
  }), t;
}
function Ha(e) {
  for (var t = arguments.length, a = new Array(t > 1 ? t - 1 : 0), n = 1; n < t; n++)
    a[n - 1] = arguments[n];
  var r = Xa[e] || [];
  r.forEach(function(l) {
    l.apply(null, a);
  });
}
function ta() {
  var e = arguments[0], t = Array.prototype.slice.call(arguments, 1);
  return Ga[e] ? Ga[e].apply(null, t) : void 0;
}
function ml(e) {
  e.prefix === "fa" && (e.prefix = "fas");
  var t = e.iconName, a = e.prefix || ba();
  if (t)
    return t = $a(a, t) || t, So(Xi.definitions, a, t) || So(Rt.styles, a, t);
}
var Xi = new Pc(), Dc = function() {
  pe.autoReplaceSvg = !1, pe.observeMutations = !1, Ha("noAuto");
}, $c = {
  i2svg: function() {
    var t = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return la ? (Ha("beforeI2svg", t), ta("pseudoElements2svg", t), ta("i2svg", t)) : Promise.reject("Operation requires a DOM of some kind.");
  },
  watch: function() {
    var t = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, a = t.autoReplaceSvgRoot;
    pe.autoReplaceSvg === !1 && (pe.autoReplaceSvg = !0), pe.observeMutations = !0, yc(function() {
      Ec({
        autoReplaceSvgRoot: a
      }), Ha("watch", t);
    });
  }
}, Lc = {
  icon: function(t) {
    if (t === null)
      return null;
    if (sr(t) === "object" && t.prefix && t.iconName)
      return {
        prefix: t.prefix,
        iconName: $a(t.prefix, t.iconName) || t.iconName
      };
    if (Array.isArray(t) && t.length === 2) {
      var a = t[1].indexOf("fa-") === 0 ? t[1].slice(3) : t[1], n = _r(t[0]);
      return {
        prefix: n,
        iconName: $a(n, a) || a
      };
    }
    if (typeof t == "string" && (t.indexOf("".concat(pe.cssPrefix, "-")) > -1 || t.match(Zu))) {
      var r = Mr(t.split(" "), {
        skipLookups: !0
      });
      return {
        prefix: r.prefix || ba(),
        iconName: $a(r.prefix, r.iconName) || r.iconName
      };
    }
    if (typeof t == "string") {
      var l = ba();
      return {
        prefix: l,
        iconName: $a(l, t) || t
      };
    }
  }
}, At = {
  noAuto: Dc,
  config: pe,
  dom: $c,
  parse: Lc,
  library: Xi,
  findIconDefinition: ml,
  toHtml: Rn
}, Ec = function() {
  var t = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, a = t.autoReplaceSvgRoot, n = a === void 0 ? Ve : a;
  (Object.keys(Rt.styles).length > 0 || pe.autoFetchSvg) && la && pe.autoReplaceSvg && At.dom.i2svg({
    node: n
  });
};
function Ar(e, t) {
  return Object.defineProperty(e, "abstract", {
    get: t
  }), Object.defineProperty(e, "html", {
    get: function() {
      return e.abstract.map(function(n) {
        return Rn(n);
      });
    }
  }), Object.defineProperty(e, "node", {
    get: function() {
      if (la) {
        var n = Ve.createElement("div");
        return n.innerHTML = e.html, n.children;
      }
    }
  }), e;
}
function Nc(e) {
  var t = e.children, a = e.main, n = e.mask, r = e.attributes, l = e.styles, o = e.transform;
  if (Rl(o) && a.found && !n.found) {
    var i = a.width, s = a.height, c = {
      x: i / s / 2,
      y: 0.5
    };
    r.style = xr(ve(ve({}, l), {}, {
      "transform-origin": "".concat(c.x + o.x / 16, "em ").concat(c.y + o.y / 16, "em")
    }));
  }
  return [{
    tag: "svg",
    attributes: r,
    children: t
  }];
}
function Rc(e) {
  var t = e.prefix, a = e.iconName, n = e.children, r = e.attributes, l = e.symbol, o = l === !0 ? "".concat(t, "-").concat(pe.cssPrefix, "-").concat(a) : l;
  return [{
    tag: "svg",
    attributes: {
      style: "display: none;"
    },
    children: [{
      tag: "symbol",
      attributes: ve(ve({}, r), {}, {
        id: o
      }),
      children: n
    }]
  }];
}
function Vl(e) {
  var t = e.icons, a = t.main, n = t.mask, r = e.prefix, l = e.iconName, o = e.transform, i = e.symbol, s = e.title, c = e.maskId, u = e.titleId, d = e.extra, f = e.watchable, h = f === void 0 ? !1 : f, x = n.found ? n : a, g = x.width, D = x.height, k = r === "fak", b = [pe.replacementClass, l ? "".concat(pe.cssPrefix, "-").concat(l) : ""].filter(function(F) {
    return d.classes.indexOf(F) === -1;
  }).filter(function(F) {
    return F !== "" || !!F;
  }).concat(d.classes).join(" "), m = {
    children: [],
    attributes: ve(ve({}, d.attributes), {}, {
      "data-prefix": r,
      "data-icon": l,
      class: b,
      role: d.attributes.role || "img",
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 ".concat(g, " ").concat(D)
    })
  }, M = k && !~d.classes.indexOf("fa-fw") ? {
    width: "".concat(g / D * 16 * 0.0625, "em")
  } : {};
  h && (m.attributes[Ia] = ""), s && (m.children.push({
    tag: "title",
    attributes: {
      id: m.attributes["aria-labelledby"] || "title-".concat(u || Pn())
    },
    children: [s]
  }), delete m.attributes.title);
  var $ = ve(ve({}, m), {}, {
    prefix: r,
    iconName: l,
    main: a,
    mask: n,
    maskId: c,
    transform: o,
    symbol: i,
    styles: ve(ve({}, M), d.styles)
  }), C = n.found && a.found ? ta("generateAbstractMask", $) || {
    children: [],
    attributes: {}
  } : ta("generateAbstractIcon", $) || {
    children: [],
    attributes: {}
  }, _ = C.children, W = C.attributes;
  return $.children = _, $.attributes = W, i ? Rc($) : Nc($);
}
function Lo(e) {
  var t = e.content, a = e.width, n = e.height, r = e.transform, l = e.title, o = e.extra, i = e.watchable, s = i === void 0 ? !1 : i, c = ve(ve(ve({}, o.attributes), l ? {
    title: l
  } : {}), {}, {
    class: o.classes.join(" ")
  });
  s && (c[Ia] = "");
  var u = ve({}, o.styles);
  Rl(r) && (u.transform = vc({
    transform: r,
    startCentered: !0,
    width: a,
    height: n
  }), u["-webkit-transform"] = u.transform);
  var d = xr(u);
  d.length > 0 && (c.style = d);
  var f = [];
  return f.push({
    tag: "span",
    attributes: c,
    children: [t]
  }), l && f.push({
    tag: "span",
    attributes: {
      class: "sr-only"
    },
    children: [l]
  }), f;
}
function Ic(e) {
  var t = e.content, a = e.title, n = e.extra, r = ve(ve(ve({}, n.attributes), a ? {
    title: a
  } : {}), {}, {
    class: n.classes.join(" ")
  }), l = xr(n.styles);
  l.length > 0 && (r.style = l);
  var o = [];
  return o.push({
    tag: "span",
    attributes: r,
    children: [t]
  }), a && o.push({
    tag: "span",
    attributes: {
      class: "sr-only"
    },
    children: [a]
  }), o;
}
var Wr = Rt.styles;
function pl(e) {
  var t = e[0], a = e[1], n = e.slice(4), r = Cl(n, 1), l = r[0], o = null;
  return Array.isArray(l) ? o = {
    tag: "g",
    attributes: {
      class: "".concat(pe.cssPrefix, "-").concat(Da.GROUP)
    },
    children: [{
      tag: "path",
      attributes: {
        class: "".concat(pe.cssPrefix, "-").concat(Da.SECONDARY),
        fill: "currentColor",
        d: l[0]
      }
    }, {
      tag: "path",
      attributes: {
        class: "".concat(pe.cssPrefix, "-").concat(Da.PRIMARY),
        fill: "currentColor",
        d: l[1]
      }
    }]
  } : o = {
    tag: "path",
    attributes: {
      fill: "currentColor",
      d: l
    }
  }, {
    found: !0,
    width: t,
    height: a,
    icon: o
  };
}
var Hc = {
  found: !1,
  width: 512,
  height: 512
};
function zc(e, t) {
  !Ni && !pe.showMissingIcons && e && console.error('Icon with name "'.concat(e, '" and prefix "').concat(t, '" is missing.'));
}
function hl(e, t) {
  var a = t;
  return t === "fa" && pe.styleDefault !== null && (t = ba()), new Promise(function(n, r) {
    if (ta("missingIconAbstract"), a === "fa") {
      var l = Ki(e) || {};
      e = l.iconName || e, t = l.prefix || t;
    }
    if (e && t && Wr[t] && Wr[t][e]) {
      var o = Wr[t][e];
      return n(pl(o));
    }
    zc(e, t), n(ve(ve({}, Hc), {}, {
      icon: pe.showMissingIcons && e ? ta("missingIconAbstract") || {} : {}
    }));
  });
}
var Eo = function() {
}, yl = pe.measurePerformance && jn && jn.mark && jn.measure ? jn : {
  mark: Eo,
  measure: Eo
}, gn = 'FA "6.5.1"', Vc = function(t) {
  return yl.mark("".concat(gn, " ").concat(t, " begins")), function() {
    return Qi(t);
  };
}, Qi = function(t) {
  yl.mark("".concat(gn, " ").concat(t, " ends")), yl.measure("".concat(gn, " ").concat(t), "".concat(gn, " ").concat(t, " begins"), "".concat(gn, " ").concat(t, " ends"));
}, Bl = {
  begin: Vc,
  end: Qi
}, or = function() {
};
function No(e) {
  var t = e.getAttribute ? e.getAttribute(Ia) : null;
  return typeof t == "string";
}
function Bc(e) {
  var t = e.getAttribute ? e.getAttribute($l) : null, a = e.getAttribute ? e.getAttribute(Ll) : null;
  return t && a;
}
function Yc(e) {
  return e && e.classList && e.classList.contains && e.classList.contains(pe.replacementClass);
}
function Fc() {
  if (pe.autoReplaceSvg === !0)
    return ir.replace;
  var e = ir[pe.autoReplaceSvg];
  return e || ir.replace;
}
function jc(e) {
  return Ve.createElementNS("http://www.w3.org/2000/svg", e);
}
function Uc(e) {
  return Ve.createElement(e);
}
function Gi(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, a = t.ceFn, n = a === void 0 ? e.tag === "svg" ? jc : Uc : a;
  if (typeof e == "string")
    return Ve.createTextNode(e);
  var r = n(e.tag);
  Object.keys(e.attributes || []).forEach(function(o) {
    r.setAttribute(o, e.attributes[o]);
  });
  var l = e.children || [];
  return l.forEach(function(o) {
    r.appendChild(Gi(o, {
      ceFn: n
    }));
  }), r;
}
function Wc(e) {
  var t = " ".concat(e.outerHTML, " ");
  return t = "".concat(t, "Font Awesome fontawesome.com "), t;
}
var ir = {
  replace: function(t) {
    var a = t[0];
    if (a.parentNode)
      if (t[1].forEach(function(r) {
        a.parentNode.insertBefore(Gi(r), a);
      }), a.getAttribute(Ia) === null && pe.keepOriginalSource) {
        var n = Ve.createComment(Wc(a));
        a.parentNode.replaceChild(n, a);
      } else
        a.remove();
  },
  nest: function(t) {
    var a = t[0], n = t[1];
    if (~Nl(a).indexOf(pe.replacementClass))
      return ir.replace(t);
    var r = new RegExp("".concat(pe.cssPrefix, "-.*"));
    if (delete n[0].attributes.id, n[0].attributes.class) {
      var l = n[0].attributes.class.split(" ").reduce(function(i, s) {
        return s === pe.replacementClass || s.match(r) ? i.toSvg.push(s) : i.toNode.push(s), i;
      }, {
        toNode: [],
        toSvg: []
      });
      n[0].attributes.class = l.toSvg.join(" "), l.toNode.length === 0 ? a.removeAttribute("class") : a.setAttribute("class", l.toNode.join(" "));
    }
    var o = n.map(function(i) {
      return Rn(i);
    }).join(`
`);
    a.setAttribute(Ia, ""), a.innerHTML = o;
  }
};
function Ro(e) {
  e();
}
function Ji(e, t) {
  var a = typeof t == "function" ? t : or;
  if (e.length === 0)
    a();
  else {
    var n = Ro;
    pe.mutateApproach === Qu && (n = ga.requestAnimationFrame || Ro), n(function() {
      var r = Fc(), l = Bl.begin("mutate");
      e.map(r), l(), a();
    });
  }
}
var Yl = !1;
function Zi() {
  Yl = !0;
}
function gl() {
  Yl = !1;
}
var cr = null;
function Io(e) {
  if (To && pe.observeMutations) {
    var t = e.treeCallback, a = t === void 0 ? or : t, n = e.nodeCallback, r = n === void 0 ? or : n, l = e.pseudoElementsCallback, o = l === void 0 ? or : l, i = e.observeMutationsRoot, s = i === void 0 ? Ve : i;
    cr = new To(function(c) {
      if (!Yl) {
        var u = ba();
        cn(c).forEach(function(d) {
          if (d.type === "childList" && d.addedNodes.length > 0 && !No(d.addedNodes[0]) && (pe.searchPseudoElements && o(d.target), a(d.target)), d.type === "attributes" && d.target.parentNode && pe.searchPseudoElements && o(d.target.parentNode), d.type === "attributes" && No(d.target) && ~nc.indexOf(d.attributeName))
            if (d.attributeName === "class" && Bc(d.target)) {
              var f = Mr(Nl(d.target)), h = f.prefix, x = f.iconName;
              d.target.setAttribute($l, h || u), x && d.target.setAttribute(Ll, x);
            } else
              Yc(d.target) && r(d.target);
        });
      }
    }), la && cr.observe(s, {
      childList: !0,
      attributes: !0,
      characterData: !0,
      subtree: !0
    });
  }
}
function qc() {
  cr && cr.disconnect();
}
function Kc(e) {
  var t = e.getAttribute("style"), a = [];
  return t && (a = t.split(";").reduce(function(n, r) {
    var l = r.split(":"), o = l[0], i = l.slice(1);
    return o && i.length > 0 && (n[o] = i.join(":").trim()), n;
  }, {})), a;
}
function Xc(e) {
  var t = e.getAttribute("data-prefix"), a = e.getAttribute("data-icon"), n = e.innerText !== void 0 ? e.innerText.trim() : "", r = Mr(Nl(e));
  return r.prefix || (r.prefix = ba()), t && a && (r.prefix = t, r.iconName = a), r.iconName && r.prefix || (r.prefix && n.length > 0 && (r.iconName = Tc(r.prefix, e.innerText) || Hl(r.prefix, dl(e.innerText))), !r.iconName && pe.autoFetchSvg && e.firstChild && e.firstChild.nodeType === Node.TEXT_NODE && (r.iconName = e.firstChild.data)), r;
}
function Qc(e) {
  var t = cn(e.attributes).reduce(function(r, l) {
    return r.name !== "class" && r.name !== "style" && (r[l.name] = l.value), r;
  }, {}), a = e.getAttribute("title"), n = e.getAttribute("data-fa-title-id");
  return pe.autoA11y && (a ? t["aria-labelledby"] = "".concat(pe.replacementClass, "-title-").concat(n || Pn()) : (t["aria-hidden"] = "true", t.focusable = "false")), t;
}
function Gc() {
  return {
    iconName: null,
    title: null,
    titleId: null,
    prefix: null,
    transform: jt,
    symbol: !1,
    mask: {
      iconName: null,
      prefix: null,
      rest: []
    },
    maskId: null,
    extra: {
      classes: [],
      styles: {},
      attributes: {}
    }
  };
}
function Ho(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
    styleParser: !0
  }, a = Xc(e), n = a.iconName, r = a.prefix, l = a.rest, o = Qc(e), i = vl("parseNodeAttributes", {}, e), s = t.styleParser ? Kc(e) : [];
  return ve({
    iconName: n,
    title: e.getAttribute("title"),
    titleId: e.getAttribute("data-fa-title-id"),
    prefix: r,
    transform: jt,
    mask: {
      iconName: null,
      prefix: null,
      rest: []
    },
    maskId: null,
    symbol: !1,
    extra: {
      classes: l,
      styles: s,
      attributes: o
    }
  }, i);
}
var Jc = Rt.styles;
function es(e) {
  var t = pe.autoReplaceSvg === "nest" ? Ho(e, {
    styleParser: !1
  }) : Ho(e);
  return ~t.extra.classes.indexOf(Ri) ? ta("generateLayersText", e, t) : ta("generateSvgReplacementMutation", e, t);
}
var wa = /* @__PURE__ */ new Set();
El.map(function(e) {
  wa.add("fa-".concat(e));
});
Object.keys(Mn[ze]).map(wa.add.bind(wa));
Object.keys(Mn[qe]).map(wa.add.bind(wa));
wa = En(wa);
function zo(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : null;
  if (!la)
    return Promise.resolve();
  var a = Ve.documentElement.classList, n = function(d) {
    return a.add("".concat(Oo, "-").concat(d));
  }, r = function(d) {
    return a.remove("".concat(Oo, "-").concat(d));
  }, l = pe.autoFetchSvg ? wa : El.map(function(u) {
    return "fa-".concat(u);
  }).concat(Object.keys(Jc));
  l.includes("fa") || l.push("fa");
  var o = [".".concat(Ri, ":not([").concat(Ia, "])")].concat(l.map(function(u) {
    return ".".concat(u, ":not([").concat(Ia, "])");
  })).join(", ");
  if (o.length === 0)
    return Promise.resolve();
  var i = [];
  try {
    i = cn(e.querySelectorAll(o));
  } catch {
  }
  if (i.length > 0)
    n("pending"), r("complete");
  else
    return Promise.resolve();
  var s = Bl.begin("onTree"), c = i.reduce(function(u, d) {
    try {
      var f = es(d);
      f && u.push(f);
    } catch (h) {
      Ni || h.name === "MissingIcon" && console.error(h);
    }
    return u;
  }, []);
  return new Promise(function(u, d) {
    Promise.all(c).then(function(f) {
      Ji(f, function() {
        n("active"), n("complete"), r("pending"), typeof t == "function" && t(), s(), u();
      });
    }).catch(function(f) {
      s(), d(f);
    });
  });
}
function Zc(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : null;
  es(e).then(function(a) {
    a && Ji([a], t);
  });
}
function ed(e) {
  return function(t) {
    var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = (t || {}).icon ? t : ml(t || {}), r = a.mask;
    return r && (r = (r || {}).icon ? r : ml(r || {})), e(n, ve(ve({}, a), {}, {
      mask: r
    }));
  };
}
var td = function(t) {
  var a = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, n = a.transform, r = n === void 0 ? jt : n, l = a.symbol, o = l === void 0 ? !1 : l, i = a.mask, s = i === void 0 ? null : i, c = a.maskId, u = c === void 0 ? null : c, d = a.title, f = d === void 0 ? null : d, h = a.titleId, x = h === void 0 ? null : h, g = a.classes, D = g === void 0 ? [] : g, k = a.attributes, b = k === void 0 ? {} : k, m = a.styles, M = m === void 0 ? {} : m;
  if (t) {
    var $ = t.prefix, C = t.iconName, _ = t.icon;
    return Ar(ve({
      type: "icon"
    }, t), function() {
      return Ha("beforeDOMElementCreation", {
        iconDefinition: t,
        params: a
      }), pe.autoA11y && (f ? b["aria-labelledby"] = "".concat(pe.replacementClass, "-title-").concat(x || Pn()) : (b["aria-hidden"] = "true", b.focusable = "false")), Vl({
        icons: {
          main: pl(_),
          mask: s ? pl(s.icon) : {
            found: !1,
            width: null,
            height: null,
            icon: {}
          }
        },
        prefix: $,
        iconName: C,
        transform: ve(ve({}, jt), r),
        symbol: o,
        title: f,
        maskId: u,
        titleId: x,
        extra: {
          attributes: b,
          styles: M,
          classes: D
        }
      });
    });
  }
}, ad = {
  mixout: function() {
    return {
      icon: ed(td)
    };
  },
  hooks: function() {
    return {
      mutationObserverCallbacks: function(a) {
        return a.treeCallback = zo, a.nodeCallback = Zc, a;
      }
    };
  },
  provides: function(t) {
    t.i2svg = function(a) {
      var n = a.node, r = n === void 0 ? Ve : n, l = a.callback, o = l === void 0 ? function() {
      } : l;
      return zo(r, o);
    }, t.generateSvgReplacementMutation = function(a, n) {
      var r = n.iconName, l = n.title, o = n.titleId, i = n.prefix, s = n.transform, c = n.symbol, u = n.mask, d = n.maskId, f = n.extra;
      return new Promise(function(h, x) {
        Promise.all([hl(r, i), u.iconName ? hl(u.iconName, u.prefix) : Promise.resolve({
          found: !1,
          width: 512,
          height: 512,
          icon: {}
        })]).then(function(g) {
          var D = Cl(g, 2), k = D[0], b = D[1];
          h([a, Vl({
            icons: {
              main: k,
              mask: b
            },
            prefix: i,
            iconName: r,
            transform: s,
            symbol: c,
            maskId: d,
            title: l,
            titleId: o,
            extra: f,
            watchable: !0
          })]);
        }).catch(x);
      });
    }, t.generateAbstractIcon = function(a) {
      var n = a.children, r = a.attributes, l = a.main, o = a.transform, i = a.styles, s = xr(i);
      s.length > 0 && (r.style = s);
      var c;
      return Rl(o) && (c = ta("generateAbstractTransformGrouping", {
        main: l,
        transform: o,
        containerWidth: l.width,
        iconWidth: l.width
      })), n.push(c || l.icon), {
        children: n,
        attributes: r
      };
    };
  }
}, nd = {
  mixout: function() {
    return {
      layer: function(a) {
        var n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = n.classes, l = r === void 0 ? [] : r;
        return Ar({
          type: "layer"
        }, function() {
          Ha("beforeDOMElementCreation", {
            assembler: a,
            params: n
          });
          var o = [];
          return a(function(i) {
            Array.isArray(i) ? i.map(function(s) {
              o = o.concat(s.abstract);
            }) : o = o.concat(i.abstract);
          }), [{
            tag: "span",
            attributes: {
              class: ["".concat(pe.cssPrefix, "-layers")].concat(En(l)).join(" ")
            },
            children: o
          }];
        });
      }
    };
  }
}, rd = {
  mixout: function() {
    return {
      counter: function(a) {
        var n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = n.title, l = r === void 0 ? null : r, o = n.classes, i = o === void 0 ? [] : o, s = n.attributes, c = s === void 0 ? {} : s, u = n.styles, d = u === void 0 ? {} : u;
        return Ar({
          type: "counter",
          content: a
        }, function() {
          return Ha("beforeDOMElementCreation", {
            content: a,
            params: n
          }), Ic({
            content: a.toString(),
            title: l,
            extra: {
              attributes: c,
              styles: d,
              classes: ["".concat(pe.cssPrefix, "-layers-counter")].concat(En(i))
            }
          });
        });
      }
    };
  }
}, ld = {
  mixout: function() {
    return {
      text: function(a) {
        var n = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, r = n.transform, l = r === void 0 ? jt : r, o = n.title, i = o === void 0 ? null : o, s = n.classes, c = s === void 0 ? [] : s, u = n.attributes, d = u === void 0 ? {} : u, f = n.styles, h = f === void 0 ? {} : f;
        return Ar({
          type: "text",
          content: a
        }, function() {
          return Ha("beforeDOMElementCreation", {
            content: a,
            params: n
          }), Lo({
            content: a,
            transform: ve(ve({}, jt), l),
            title: i,
            extra: {
              attributes: d,
              styles: h,
              classes: ["".concat(pe.cssPrefix, "-layers-text")].concat(En(c))
            }
          });
        });
      }
    };
  },
  provides: function(t) {
    t.generateLayersText = function(a, n) {
      var r = n.title, l = n.transform, o = n.extra, i = null, s = null;
      if ($i) {
        var c = parseInt(getComputedStyle(a).fontSize, 10), u = a.getBoundingClientRect();
        i = u.width / c, s = u.height / c;
      }
      return pe.autoA11y && !r && (o.attributes["aria-hidden"] = "true"), Promise.resolve([a, Lo({
        content: a.innerHTML,
        width: i,
        height: s,
        transform: l,
        title: r,
        extra: o,
        watchable: !0
      })]);
    };
  }
}, od = new RegExp('"', "ug"), Vo = [1105920, 1112319];
function id(e) {
  var t = e.replace(od, ""), a = wc(t, 0), n = a >= Vo[0] && a <= Vo[1], r = t.length === 2 ? t[0] === t[1] : !1;
  return {
    value: dl(r ? t[0] : t),
    isSecondary: n || r
  };
}
function Bo(e, t) {
  var a = "".concat(Xu).concat(t.replace(":", "-"));
  return new Promise(function(n, r) {
    if (e.getAttribute(a) !== null)
      return n();
    var l = cn(e.children), o = l.filter(function(_) {
      return _.getAttribute(cl) === t;
    })[0], i = ga.getComputedStyle(e, t), s = i.getPropertyValue("font-family").match(ec), c = i.getPropertyValue("font-weight"), u = i.getPropertyValue("content");
    if (o && !s)
      return e.removeChild(o), n();
    if (s && u !== "none" && u !== "") {
      var d = i.getPropertyValue("content"), f = ~["Sharp"].indexOf(s[2]) ? qe : ze, h = ~["Solid", "Regular", "Light", "Thin", "Duotone", "Brands", "Kit"].indexOf(s[2]) ? An[f][s[2].toLowerCase()] : tc[f][c], x = id(d), g = x.value, D = x.isSecondary, k = s[0].startsWith("FontAwesome"), b = Hl(h, g), m = b;
      if (k) {
        var M = Oc(g);
        M.iconName && M.prefix && (b = M.iconName, h = M.prefix);
      }
      if (b && !D && (!o || o.getAttribute($l) !== h || o.getAttribute(Ll) !== m)) {
        e.setAttribute(a, m), o && e.removeChild(o);
        var $ = Gc(), C = $.extra;
        C.attributes[cl] = t, hl(b, h).then(function(_) {
          var W = Vl(ve(ve({}, $), {}, {
            icons: {
              main: _,
              mask: zl()
            },
            prefix: h,
            iconName: m,
            extra: C,
            watchable: !0
          })), F = Ve.createElementNS("http://www.w3.org/2000/svg", "svg");
          t === "::before" ? e.insertBefore(F, e.firstChild) : e.appendChild(F), F.outerHTML = W.map(function(H) {
            return Rn(H);
          }).join(`
`), e.removeAttribute(a), n();
        }).catch(r);
      } else
        n();
    } else
      n();
  });
}
function sd(e) {
  return Promise.all([Bo(e, "::before"), Bo(e, "::after")]);
}
function ud(e) {
  return e.parentNode !== document.head && !~Gu.indexOf(e.tagName.toUpperCase()) && !e.getAttribute(cl) && (!e.parentNode || e.parentNode.tagName !== "svg");
}
function Yo(e) {
  if (la)
    return new Promise(function(t, a) {
      var n = cn(e.querySelectorAll("*")).filter(ud).map(sd), r = Bl.begin("searchPseudoElements");
      Zi(), Promise.all(n).then(function() {
        r(), gl(), t();
      }).catch(function() {
        r(), gl(), a();
      });
    });
}
var cd = {
  hooks: function() {
    return {
      mutationObserverCallbacks: function(a) {
        return a.pseudoElementsCallback = Yo, a;
      }
    };
  },
  provides: function(t) {
    t.pseudoElements2svg = function(a) {
      var n = a.node, r = n === void 0 ? Ve : n;
      pe.searchPseudoElements && Yo(r);
    };
  }
}, Fo = !1, dd = {
  mixout: function() {
    return {
      dom: {
        unwatch: function() {
          Zi(), Fo = !0;
        }
      }
    };
  },
  hooks: function() {
    return {
      bootstrap: function() {
        Io(vl("mutationObserverCallbacks", {}));
      },
      noAuto: function() {
        qc();
      },
      watch: function(a) {
        var n = a.observeMutationsRoot;
        Fo ? gl() : Io(vl("mutationObserverCallbacks", {
          observeMutationsRoot: n
        }));
      }
    };
  }
}, jo = function(t) {
  var a = {
    size: 16,
    x: 0,
    y: 0,
    flipX: !1,
    flipY: !1,
    rotate: 0
  };
  return t.toLowerCase().split(" ").reduce(function(n, r) {
    var l = r.toLowerCase().split("-"), o = l[0], i = l.slice(1).join("-");
    if (o && i === "h")
      return n.flipX = !0, n;
    if (o && i === "v")
      return n.flipY = !0, n;
    if (i = parseFloat(i), isNaN(i))
      return n;
    switch (o) {
      case "grow":
        n.size = n.size + i;
        break;
      case "shrink":
        n.size = n.size - i;
        break;
      case "left":
        n.x = n.x - i;
        break;
      case "right":
        n.x = n.x + i;
        break;
      case "up":
        n.y = n.y - i;
        break;
      case "down":
        n.y = n.y + i;
        break;
      case "rotate":
        n.rotate = n.rotate + i;
        break;
    }
    return n;
  }, a);
}, fd = {
  mixout: function() {
    return {
      parse: {
        transform: function(a) {
          return jo(a);
        }
      }
    };
  },
  hooks: function() {
    return {
      parseNodeAttributes: function(a, n) {
        var r = n.getAttribute("data-fa-transform");
        return r && (a.transform = jo(r)), a;
      }
    };
  },
  provides: function(t) {
    t.generateAbstractTransformGrouping = function(a) {
      var n = a.main, r = a.transform, l = a.containerWidth, o = a.iconWidth, i = {
        transform: "translate(".concat(l / 2, " 256)")
      }, s = "translate(".concat(r.x * 32, ", ").concat(r.y * 32, ") "), c = "scale(".concat(r.size / 16 * (r.flipX ? -1 : 1), ", ").concat(r.size / 16 * (r.flipY ? -1 : 1), ") "), u = "rotate(".concat(r.rotate, " 0 0)"), d = {
        transform: "".concat(s, " ").concat(c, " ").concat(u)
      }, f = {
        transform: "translate(".concat(o / 2 * -1, " -256)")
      }, h = {
        outer: i,
        inner: d,
        path: f
      };
      return {
        tag: "g",
        attributes: ve({}, h.outer),
        children: [{
          tag: "g",
          attributes: ve({}, h.inner),
          children: [{
            tag: n.icon.tag,
            children: n.icon.children,
            attributes: ve(ve({}, n.icon.attributes), h.path)
          }]
        }]
      };
    };
  }
}, qr = {
  x: 0,
  y: 0,
  width: "100%",
  height: "100%"
};
function Uo(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : !0;
  return e.attributes && (e.attributes.fill || t) && (e.attributes.fill = "black"), e;
}
function vd(e) {
  return e.tag === "g" ? e.children : [e];
}
var md = {
  hooks: function() {
    return {
      parseNodeAttributes: function(a, n) {
        var r = n.getAttribute("data-fa-mask"), l = r ? Mr(r.split(" ").map(function(o) {
          return o.trim();
        })) : zl();
        return l.prefix || (l.prefix = ba()), a.mask = l, a.maskId = n.getAttribute("data-fa-mask-id"), a;
      }
    };
  },
  provides: function(t) {
    t.generateAbstractMask = function(a) {
      var n = a.children, r = a.attributes, l = a.main, o = a.mask, i = a.maskId, s = a.transform, c = l.width, u = l.icon, d = o.width, f = o.icon, h = fc({
        transform: s,
        containerWidth: d,
        iconWidth: c
      }), x = {
        tag: "rect",
        attributes: ve(ve({}, qr), {}, {
          fill: "white"
        })
      }, g = u.children ? {
        children: u.children.map(Uo)
      } : {}, D = {
        tag: "g",
        attributes: ve({}, h.inner),
        children: [Uo(ve({
          tag: u.tag,
          attributes: ve(ve({}, u.attributes), h.path)
        }, g))]
      }, k = {
        tag: "g",
        attributes: ve({}, h.outer),
        children: [D]
      }, b = "mask-".concat(i || Pn()), m = "clip-".concat(i || Pn()), M = {
        tag: "mask",
        attributes: ve(ve({}, qr), {}, {
          id: b,
          maskUnits: "userSpaceOnUse",
          maskContentUnits: "userSpaceOnUse"
        }),
        children: [x, k]
      }, $ = {
        tag: "defs",
        children: [{
          tag: "clipPath",
          attributes: {
            id: m
          },
          children: vd(f)
        }, M]
      };
      return n.push($, {
        tag: "rect",
        attributes: ve({
          fill: "currentColor",
          "clip-path": "url(#".concat(m, ")"),
          mask: "url(#".concat(b, ")")
        }, qr)
      }), {
        children: n,
        attributes: r
      };
    };
  }
}, pd = {
  provides: function(t) {
    var a = !1;
    ga.matchMedia && (a = ga.matchMedia("(prefers-reduced-motion: reduce)").matches), t.missingIconAbstract = function() {
      var n = [], r = {
        fill: "currentColor"
      }, l = {
        attributeType: "XML",
        repeatCount: "indefinite",
        dur: "2s"
      };
      n.push({
        tag: "path",
        attributes: ve(ve({}, r), {}, {
          d: "M156.5,447.7l-12.6,29.5c-18.7-9.5-35.9-21.2-51.5-34.9l22.7-22.7C127.6,430.5,141.5,440,156.5,447.7z M40.6,272H8.5 c1.4,21.2,5.4,41.7,11.7,61.1L50,321.2C45.1,305.5,41.8,289,40.6,272z M40.6,240c1.4-18.8,5.2-37,11.1-54.1l-29.5-12.6 C14.7,194.3,10,216.7,8.5,240H40.6z M64.3,156.5c7.8-14.9,17.2-28.8,28.1-41.5L69.7,92.3c-13.7,15.6-25.5,32.8-34.9,51.5 L64.3,156.5z M397,419.6c-13.9,12-29.4,22.3-46.1,30.4l11.9,29.8c20.7-9.9,39.8-22.6,56.9-37.6L397,419.6z M115,92.4 c13.9-12,29.4-22.3,46.1-30.4l-11.9-29.8c-20.7,9.9-39.8,22.6-56.8,37.6L115,92.4z M447.7,355.5c-7.8,14.9-17.2,28.8-28.1,41.5 l22.7,22.7c13.7-15.6,25.5-32.9,34.9-51.5L447.7,355.5z M471.4,272c-1.4,18.8-5.2,37-11.1,54.1l29.5,12.6 c7.5-21.1,12.2-43.5,13.6-66.8H471.4z M321.2,462c-15.7,5-32.2,8.2-49.2,9.4v32.1c21.2-1.4,41.7-5.4,61.1-11.7L321.2,462z M240,471.4c-18.8-1.4-37-5.2-54.1-11.1l-12.6,29.5c21.1,7.5,43.5,12.2,66.8,13.6V471.4z M462,190.8c5,15.7,8.2,32.2,9.4,49.2h32.1 c-1.4-21.2-5.4-41.7-11.7-61.1L462,190.8z M92.4,397c-12-13.9-22.3-29.4-30.4-46.1l-29.8,11.9c9.9,20.7,22.6,39.8,37.6,56.9 L92.4,397z M272,40.6c18.8,1.4,36.9,5.2,54.1,11.1l12.6-29.5C317.7,14.7,295.3,10,272,8.5V40.6z M190.8,50 c15.7-5,32.2-8.2,49.2-9.4V8.5c-21.2,1.4-41.7,5.4-61.1,11.7L190.8,50z M442.3,92.3L419.6,115c12,13.9,22.3,29.4,30.5,46.1 l29.8-11.9C470,128.5,457.3,109.4,442.3,92.3z M397,92.4l22.7-22.7c-15.6-13.7-32.8-25.5-51.5-34.9l-12.6,29.5 C370.4,72.1,384.4,81.5,397,92.4z"
        })
      });
      var o = ve(ve({}, l), {}, {
        attributeName: "opacity"
      }), i = {
        tag: "circle",
        attributes: ve(ve({}, r), {}, {
          cx: "256",
          cy: "364",
          r: "28"
        }),
        children: []
      };
      return a || i.children.push({
        tag: "animate",
        attributes: ve(ve({}, l), {}, {
          attributeName: "r",
          values: "28;14;28;28;14;28;"
        })
      }, {
        tag: "animate",
        attributes: ve(ve({}, o), {}, {
          values: "1;0;1;1;0;1;"
        })
      }), n.push(i), n.push({
        tag: "path",
        attributes: ve(ve({}, r), {}, {
          opacity: "1",
          d: "M263.7,312h-16c-6.6,0-12-5.4-12-12c0-71,77.4-63.9,77.4-107.8c0-20-17.8-40.2-57.4-40.2c-29.1,0-44.3,9.6-59.2,28.7 c-3.9,5-11.1,6-16.2,2.4l-13.1-9.2c-5.6-3.9-6.9-11.8-2.6-17.2c21.2-27.2,46.4-44.7,91.2-44.7c52.3,0,97.4,29.8,97.4,80.2 c0,67.6-77.4,63.5-77.4,107.8C275.7,306.6,270.3,312,263.7,312z"
        }),
        children: a ? [] : [{
          tag: "animate",
          attributes: ve(ve({}, o), {}, {
            values: "1;0;0;0;0;1;"
          })
        }]
      }), a || n.push({
        tag: "path",
        attributes: ve(ve({}, r), {}, {
          opacity: "0",
          d: "M232.5,134.5l7,168c0.3,6.4,5.6,11.5,12,11.5h9c6.4,0,11.7-5.1,12-11.5l7-168c0.3-6.8-5.2-12.5-12-12.5h-23 C237.7,122,232.2,127.7,232.5,134.5z"
        }),
        children: [{
          tag: "animate",
          attributes: ve(ve({}, o), {}, {
            values: "0;0;1;1;0;0;"
          })
        }]
      }), {
        tag: "g",
        attributes: {
          class: "missing"
        },
        children: n
      };
    };
  }
}, hd = {
  hooks: function() {
    return {
      parseNodeAttributes: function(a, n) {
        var r = n.getAttribute("data-fa-symbol"), l = r === null ? !1 : r === "" ? !0 : r;
        return a.symbol = l, a;
      }
    };
  }
}, yd = [pc, ad, nd, rd, ld, cd, dd, fd, md, pd, hd];
Cc(yd, {
  mixoutsTo: At
});
At.noAuto;
var ts = At.config, gd = At.library;
At.dom;
var dr = At.parse;
At.findIconDefinition;
At.toHtml;
var bd = At.icon;
At.layer;
var wd = At.text;
At.counter;
var kd = {
  prefix: "fas",
  iconName: "sort-down",
  icon: [320, 512, ["sort-desc"], "f0dd", "M182.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"]
}, xd = {
  prefix: "fas",
  iconName: "pencil",
  icon: [512, 512, [9999, 61504, "pencil-alt"], "f303", "M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"]
}, _d = {
  prefix: "fas",
  iconName: "caret-right",
  icon: [256, 512, [], "f0da", "M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"]
}, Md = {
  prefix: "fas",
  iconName: "table",
  icon: [512, 512, [], "f0ce", "M64 256V160H224v96H64zm0 64H224v96H64V320zm224 96V320H448v96H288zM448 256H288V160H448v96zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"]
}, Ad = {
  prefix: "fas",
  iconName: "bars",
  icon: [448, 512, ["navicon"], "f0c9", "M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"]
}, Td = {
  prefix: "fas",
  iconName: "list",
  icon: [512, 512, ["list-squares"], "f03a", "M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"]
}, Od = {
  prefix: "fas",
  iconName: "eye-slash",
  icon: [640, 512, [], "f070", "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c8.4-19.3 10.6-41.4 4.8-63.3c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zM373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5L373 389.9z"]
}, Pd = {
  prefix: "fas",
  iconName: "chevron-up",
  icon: [512, 512, [], "f077", "M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"]
}, Sd = {
  prefix: "fas",
  iconName: "angle-right",
  icon: [320, 512, [8250], "f105", "M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"]
}, Cd = {
  prefix: "fas",
  iconName: "bullseye",
  icon: [512, 512, [], "f140", "M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
}, Dd = {
  prefix: "fas",
  iconName: "bullhorn",
  icon: [512, 512, [128226, 128363], "f0a1", "M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192 160 64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V352l8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V300.4c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4V32zm-64 76.7V240 371.3C357.2 317.8 280.5 288 200.7 288H192V192h8.7c79.8 0 156.5-29.8 215.3-83.3z"]
}, $d = {
  prefix: "fas",
  iconName: "ban",
  icon: [512, 512, [128683, "cancel"], "f05e", "M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"]
}, Ld = {
  prefix: "fas",
  iconName: "shuffle",
  icon: [512, 512, [128256, "random"], "f074", "M403.8 34.4c12-5 25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V160H352c-10.1 0-19.6 4.7-25.6 12.8L284 229.3 244 176l31.2-41.6C293.3 110.2 321.8 96 352 96h32V64c0-12.9 7.8-24.6 19.8-29.6zM164 282.7L204 336l-31.2 41.6C154.7 401.8 126.2 416 96 416H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96c10.1 0 19.6-4.7 25.6-12.8L164 282.7zm274.6 188c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V416H352c-30.2 0-58.7-14.2-76.8-38.4L121.6 172.8c-6-8.1-15.5-12.8-25.6-12.8H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96c30.2 0 58.7 14.2 76.8 38.4L326.4 339.2c6 8.1 15.5 12.8 25.6 12.8h32V320c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64z"]
}, Ed = {
  prefix: "fas",
  iconName: "arrow-right-to-bracket",
  icon: [512, 512, ["sign-in"], "f090", "M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"]
}, Nd = {
  prefix: "fas",
  iconName: "hourglass-start",
  icon: [384, 512, ["hourglass-1"], "f251", "M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM288 437v11H96V437c0-25.5 10.1-49.9 28.1-67.9L192 301.3l67.9 67.9c18 18 28.1 42.4 28.1 67.9z"]
}, Rd = {
  prefix: "fas",
  iconName: "right-to-bracket",
  icon: [512, 512, ["sign-in-alt"], "f2f6", "M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"]
}, Id = {
  prefix: "fas",
  iconName: "clipboard",
  icon: [384, 512, [128203], "f328", "M192 0c-41.8 0-77.4 26.7-90.5 64H64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H282.5C269.4 26.7 233.8 0 192 0zm0 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zM112 192H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"]
}, Hd = {
  prefix: "fas",
  iconName: "underline",
  icon: [448, 512, [], "f0cd", "M16 64c0-17.7 14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H128V224c0 53 43 96 96 96s96-43 96-96V96H304c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H384V224c0 88.4-71.6 160-160 160s-160-71.6-160-160V96H48C30.3 96 16 81.7 16 64zM0 448c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32z"]
}, zd = {
  prefix: "fas",
  iconName: "bold",
  icon: [384, 512, [], "f032", "M0 64C0 46.3 14.3 32 32 32H80 96 224c70.7 0 128 57.3 128 128c0 31.3-11.3 60.1-30 82.3c37.1 22.4 62 63.1 62 109.7c0 70.7-57.3 128-128 128H96 80 32c-17.7 0-32-14.3-32-32s14.3-32 32-32H48V256 96H32C14.3 96 0 81.7 0 64zM224 224c35.3 0 64-28.7 64-64s-28.7-64-64-64H112V224H224zM112 288V416H256c35.3 0 64-28.7 64-64s-28.7-64-64-64H224 112z"]
}, Vd = {
  prefix: "fas",
  iconName: "image",
  icon: [512, 512, [], "f03e", "M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"]
}, Bd = {
  prefix: "fas",
  iconName: "caret-up",
  icon: [320, 512, [], "f0d8", "M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"]
}, Yd = {
  prefix: "fas",
  iconName: "rotate-left",
  icon: [512, 512, ["rotate-back", "rotate-backward", "undo-alt"], "f2ea", "M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"]
}, Fd = {
  prefix: "fas",
  iconName: "circle-check",
  icon: [512, 512, [61533, "check-circle"], "f058", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"]
}, jd = {
  prefix: "fas",
  iconName: "pause",
  icon: [320, 512, [9208], "f04c", "M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"]
}, Ud = {
  prefix: "fas",
  iconName: "subscript",
  icon: [512, 512, [], "f12c", "M32 64C14.3 64 0 78.3 0 96s14.3 32 32 32H47.3l89.6 128L47.3 384H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H64c10.4 0 20.2-5.1 26.2-13.6L176 311.8l85.8 122.6c6 8.6 15.8 13.6 26.2 13.6h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H304.7L215.1 256l89.6-128H320c17.7 0 32-14.3 32-32s-14.3-32-32-32H288c-10.4 0-20.2 5.1-26.2 13.6L176 200.2 90.2 77.6C84.2 69.1 74.4 64 64 64H32zM480 320c0-11.1-5.7-21.4-15.2-27.2s-21.2-6.4-31.1-1.4l-32 16c-15.8 7.9-22.2 27.1-14.3 42.9C393 361.5 404.3 368 416 368v80c-17.7 0-32 14.3-32 32s14.3 32 32 32h32 32c17.7 0 32-14.3 32-32s-14.3-32-32-32V320z"]
}, Wd = {
  prefix: "fas",
  iconName: "italic",
  icon: [384, 512, [], "f033", "M128 64c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32s-14.3 32-32 32H293.3L160 416h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H90.7L224 96H160c-17.7 0-32-14.3-32-32z"]
}, qd = {
  prefix: "fas",
  iconName: "list-ol",
  icon: [512, 512, ["list-1-2", "list-numeric"], "f0cb", "M24 56c0-13.3 10.7-24 24-24H80c13.3 0 24 10.7 24 24V176h16c13.3 0 24 10.7 24 24s-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H56V80H48C34.7 80 24 69.3 24 56zM86.7 341.2c-6.5-7.4-18.3-6.9-24 1.2L51.5 357.9c-7.7 10.8-22.7 13.3-33.5 5.6s-13.3-22.7-5.6-33.5l11.1-15.6c23.7-33.2 72.3-35.6 99.2-4.9c21.3 24.4 20.8 60.9-1.1 84.7L86.8 432H120c13.3 0 24 10.7 24 24s-10.7 24-24 24H32c-9.5 0-18.2-5.6-22-14.4s-2.1-18.9 4.3-25.9l72-78c5.3-5.8 5.4-14.6 .3-20.5zM224 64H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 160H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32z"]
}, Kd = {
  prefix: "fas",
  iconName: "vector-square",
  icon: [448, 512, [], "f5cb", "M368 80h32v32H368V80zM352 32c-17.7 0-32 14.3-32 32H128c0-17.7-14.3-32-32-32H32C14.3 32 0 46.3 0 64v64c0 17.7 14.3 32 32 32V352c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32H320c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V384c0-17.7-14.3-32-32-32V160c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H352zM96 160c17.7 0 32-14.3 32-32H320c0 17.7 14.3 32 32 32V352c-17.7 0-32 14.3-32 32H128c0-17.7-14.3-32-32-32V160zM48 400H80v32H48V400zm320 32V400h32v32H368zM48 112V80H80v32H48z"]
}, Xd = {
  prefix: "fas",
  iconName: "filter",
  icon: [512, 512, [], "f0b0", "M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"]
}, Qd = {
  prefix: "fas",
  iconName: "question",
  icon: [320, 512, [10067, 10068, 61736], "3f", "M80 160c0-35.3 28.7-64 64-64h32c35.3 0 64 28.7 64 64v3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74V320c0 17.7 14.3 32 32 32s32-14.3 32-32v-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7V160c0-70.7-57.3-128-128-128H144C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"]
}, Gd = {
  prefix: "fas",
  iconName: "arrow-up-right-from-square",
  icon: [512, 512, ["external-link"], "f08e", "M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"]
}, Jd = Gd, Zd = {
  prefix: "fas",
  iconName: "arrow-right",
  icon: [448, 512, [8594], "f061", "M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"]
}, ef = {
  prefix: "fas",
  iconName: "circle",
  icon: [512, 512, [128308, 128309, 128992, 128993, 128994, 128995, 128996, 9679, 9898, 9899, 11044, 61708, 61915], "f111", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"]
}, tf = {
  prefix: "fas",
  iconName: "volume-high",
  icon: [640, 512, [128266, "volume-up"], "f028", "M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"]
}, af = {
  prefix: "fas",
  iconName: "circle-question",
  icon: [512, 512, [62108, "question-circle"], "f059", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
}, nf = af, rf = {
  prefix: "fas",
  iconName: "eye",
  icon: [576, 512, [128065], "f06e", "M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"]
}, lf = {
  prefix: "fas",
  iconName: "sort-up",
  icon: [320, 512, ["sort-asc"], "f0de", "M182.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"]
}, of = {
  prefix: "fas",
  iconName: "trash",
  icon: [448, 512, [], "f1f8", "M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"]
}, sf = {
  prefix: "fas",
  iconName: "arrow-left",
  icon: [448, 512, [8592], "f060", "M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"]
}, uf = {
  prefix: "fas",
  iconName: "align-left",
  icon: [448, 512, [], "f036", "M288 64c0 17.7-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32H256c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H256c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"]
}, cf = {
  prefix: "fas",
  iconName: "file-pdf",
  icon: [512, 512, [], "f1c1", "M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"]
}, df = {
  prefix: "fas",
  iconName: "table-cells-large",
  icon: [512, 512, ["th-large"], "f009", "M448 96V224H288V96H448zm0 192V416H288V288H448zM224 224H64V96H224V224zM64 288H224V416H64V288zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"]
}, ff = {
  prefix: "fas",
  iconName: "indent",
  icon: [448, 512, [], "f03c", "M0 64C0 46.3 14.3 32 32 32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64zM192 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32zm32 96H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zM0 448c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM127.8 268.6L25.8 347.9C15.3 356.1 0 348.6 0 335.3V176.7c0-13.3 15.3-20.8 25.8-12.6l101.9 79.3c8.2 6.4 8.2 18.9 0 25.3z"]
}, vf = {
  prefix: "fas",
  iconName: "circle-info",
  icon: [512, 512, ["info-circle"], "f05a", "M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"]
}, mf = {
  prefix: "fas",
  iconName: "gear",
  icon: [512, 512, [9881, "cog"], "f013", "M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"]
}, pf = {
  prefix: "fas",
  iconName: "grip-vertical",
  icon: [320, 512, [], "f58e", "M40 352l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zm192 0l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 320c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 192l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40zM40 160c-22.1 0-40-17.9-40-40L0 72C0 49.9 17.9 32 40 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0zM232 32l48 0c22.1 0 40 17.9 40 40l0 48c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-48c0-22.1 17.9-40 40-40z"]
}, hf = {
  prefix: "fas",
  iconName: "square-root-variable",
  icon: [576, 512, ["square-root-alt"], "f698", "M282.6 78.1c8-27.3 33-46.1 61.4-46.1H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H344L238.7 457c-3.6 12.3-14.1 21.2-26.8 22.8s-25.1-4.6-31.5-15.6L77.6 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H77.6c22.8 0 43.8 12.1 55.3 31.8l65.2 111.8L282.6 78.1zM393.4 233.4c12.5-12.5 32.8-12.5 45.3 0L480 274.7l41.4-41.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3L525.3 320l41.4 41.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L480 365.3l-41.4 41.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L434.7 320l-41.4-41.4c-12.5-12.5-12.5-32.8 0-45.3z"]
}, yf = {
  prefix: "fas",
  iconName: "caret-down",
  icon: [320, 512, [], "f0d7", "M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"]
}, gf = {
  prefix: "fas",
  iconName: "ellipsis-vertical",
  icon: [128, 512, ["ellipsis-v"], "f142", "M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"]
}, bf = gf, wf = {
  prefix: "fas",
  iconName: "align-center",
  icon: [448, 512, [], "f037", "M352 64c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32zm96 128c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32zM0 448c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32zM352 320c0-17.7-14.3-32-32-32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32z"]
}, kf = {
  prefix: "fas",
  iconName: "outdent",
  icon: [448, 512, ["dedent"], "f03b", "M0 64C0 46.3 14.3 32 32 32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64zM192 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32zm32 96H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H224c-17.7 0-32-14.3-32-32s14.3-32 32-32zM0 448c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM.2 268.6c-8.2-6.4-8.2-18.9 0-25.3l101.9-79.3c10.5-8.2 25.8-.7 25.8 12.6V335.3c0 13.3-15.3 20.8-25.8 12.6L.2 268.6z"]
}, xf = {
  prefix: "fas",
  iconName: "angle-down",
  icon: [448, 512, [8964], "f107", "M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"]
}, _f = {
  prefix: "fas",
  iconName: "ellipsis",
  icon: [448, 512, ["ellipsis-h"], "f141", "M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"]
}, Mf = _f, Af = {
  prefix: "fas",
  iconName: "superscript",
  icon: [512, 512, [], "f12b", "M480 32c0-11.1-5.7-21.4-15.2-27.2s-21.2-6.4-31.1-1.4l-32 16c-15.8 7.9-22.2 27.1-14.3 42.9C393 73.5 404.3 80 416 80v80c-17.7 0-32 14.3-32 32s14.3 32 32 32h32 32c17.7 0 32-14.3 32-32s-14.3-32-32-32V32zM32 64C14.3 64 0 78.3 0 96s14.3 32 32 32H47.3l89.6 128L47.3 384H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H64c10.4 0 20.2-5.1 26.2-13.6L176 311.8l85.8 122.6c6 8.6 15.8 13.6 26.2 13.6h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H304.7L215.1 256l89.6-128H320c17.7 0 32-14.3 32-32s-14.3-32-32-32H288c-10.4 0-20.2 5.1-26.2 13.6L176 200.2 90.2 77.6C84.2 69.1 74.4 64 64 64H32z"]
}, Tf = {
  prefix: "fas",
  iconName: "link",
  icon: [640, 512, [128279, "chain"], "f0c1", "M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"]
}, Of = {
  prefix: "fas",
  iconName: "play",
  icon: [384, 512, [9654], "f04b", "M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"]
}, as = {
  prefix: "fas",
  iconName: "magnifying-glass",
  icon: [512, 512, [128269, "search"], "f002", "M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"]
}, Pf = as, Sf = {
  prefix: "fas",
  iconName: "chevron-down",
  icon: [512, 512, [], "f078", "M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"]
}, Cf = {
  prefix: "fas",
  iconName: "list-ul",
  icon: [512, 512, ["list-dots"], "f0ca", "M64 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM64 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm48-208a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"]
}, Df = {
  prefix: "fas",
  iconName: "copy",
  icon: [448, 512, [], "f0c5", "M208 0H332.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128h80v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z"]
}, $f = {
  prefix: "fas",
  iconName: "grip",
  icon: [448, 512, ["grip-horizontal"], "f58d", "M128 136c0-22.1-17.9-40-40-40L40 96C17.9 96 0 113.9 0 136l0 48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40l0-48zm0 192c0-22.1-17.9-40-40-40H40c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40V328zm32-192v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V136c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM288 328c0-22.1-17.9-40-40-40H200c-22.1 0-40 17.9-40 40l0 48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V328zm32-192v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V136c0-22.1-17.9-40-40-40l-48 0c-22.1 0-40 17.9-40 40zM448 328c0-22.1-17.9-40-40-40H360c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V328z"]
}, Lf = {
  prefix: "fas",
  iconName: "plus",
  icon: [448, 512, [10133, 61543, "add"], "2b", "M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"]
}, ns = {
  prefix: "fas",
  iconName: "xmark",
  icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"]
}, Ef = ns, Nf = {
  prefix: "fas",
  iconName: "arrows-up-down-left-right",
  icon: [512, 512, ["arrows"], "f047", "M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l9.4-9.4V224H109.3l9.4-9.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4H224V402.7l-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-9.4 9.4V288H402.7l-9.4 9.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l9.4 9.4H288V109.3l9.4 9.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-64-64z"]
}, Rf = {
  prefix: "fas",
  iconName: "chevron-left",
  icon: [320, 512, [9001], "f053", "M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"]
}, If = {
  prefix: "fas",
  iconName: "chevron-right",
  icon: [320, 512, [9002], "f054", "M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"]
}, Hf = {
  prefix: "fas",
  iconName: "calendar",
  icon: [448, 512, [128197, 128198], "f133", "M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z"]
}, zf = {
  prefix: "fas",
  iconName: "link-slash",
  icon: [640, 512, ["chain-broken", "chain-slash", "unlink"], "f127", "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L489.3 358.2l90.5-90.5c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114l-96 96-31.9-25C430.9 239.6 420.1 175.1 377 132c-52.2-52.3-134.5-56.2-191.3-11.7L38.8 5.1zM239 162c30.1-14.9 67.7-9.9 92.8 15.3c20 20 27.5 48.3 21.7 74.5L239 162zM116.6 187.9L60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5l61.8-61.8-50.6-39.9zM220.9 270c-2.1 39.8 12.2 80.1 42.2 110c38.9 38.9 94.4 51 143.6 36.3L220.9 270z"]
}, Vf = zf, Bf = {
  prefix: "fas",
  iconName: "user-plus",
  icon: [640, 512, [], "f234", "M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"]
}, rs = {
  prefix: "fas",
  iconName: "check",
  icon: [448, 512, [10003, 10004], "f00c", "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"]
}, Yf = {
  prefix: "fas",
  iconName: "triangle-exclamation",
  icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"]
}, Ff = {
  prefix: "fas",
  iconName: "align-right",
  icon: [448, 512, [], "f038", "M448 64c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32zm0 256c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32zM0 192c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"]
}, jf = {
  prefix: "fas",
  iconName: "exclamation",
  icon: [64, 512, [10069, 10071, 61738], "21", "M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V320c0 17.7 14.3 32 32 32s32-14.3 32-32V64zM32 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"]
}, Uf = {
  prefix: "fas",
  iconName: "dollar-sign",
  icon: [320, 512, [128178, 61781, "dollar", "usd"], "24", "M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c.4 .1 .7 .1 1.1 .2l48 8.8c17.4 3.2 28.9 19.9 25.7 37.2s-19.9 28.9-37.2 25.7l-47.5-8.7c-31.3-4.6-58.9-1.5-78.3 6.2s-27.2 18.3-29 28.1c-2 10.7-.5 16.7 1.2 20.4c1.8 3.9 5.5 8.3 12.8 13.2c16.3 10.7 41.3 17.7 73.7 26.3l2.9 .8c28.6 7.6 63.6 16.8 89.6 33.8c14.2 9.3 27.6 21.9 35.9 39.5c8.5 17.9 10.3 37.9 6.4 59.2c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.1-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.5 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.6 .4-16.7-1.3-20.4c-1.9-4-5.6-8.4-13-13.3c-16.4-10.7-41.5-17.7-74-26.3l-2.8-.7 0 0C119.4 279.3 84.4 270 58.4 253c-14.2-9.3-27.5-22-35.8-39.6c-8.4-17.9-10.1-37.9-6.1-59.2C23.7 116 52.3 91.2 84.8 78.3c13.3-5.3 27.9-8.9 43.2-11V32c0-17.7 14.3-32 32-32z"]
}, Wf = Uf, qf = {
  prefix: "far",
  iconName: "lightbulb",
  icon: [384, 512, [128161], "f0eb", "M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z"]
};
function Wo(e, t) {
  var a = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    t && (n = n.filter(function(r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), a.push.apply(a, n);
  }
  return a;
}
function Lt(e) {
  for (var t = 1; t < arguments.length; t++) {
    var a = arguments[t] != null ? arguments[t] : {};
    t % 2 ? Wo(Object(a), !0).forEach(function(n) {
      yt(e, n, a[n]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(a)) : Wo(Object(a)).forEach(function(n) {
      Object.defineProperty(e, n, Object.getOwnPropertyDescriptor(a, n));
    });
  }
  return e;
}
function fr(e) {
  "@babel/helpers - typeof";
  return fr = typeof Symbol == "function" && typeof Symbol.iterator == "symbol" ? function(t) {
    return typeof t;
  } : function(t) {
    return t && typeof Symbol == "function" && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
  }, fr(e);
}
function yt(e, t, a) {
  return t = t1(t), t in e ? Object.defineProperty(e, t, {
    value: a,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = a, e;
}
function Kf(e, t) {
  if (e == null)
    return {};
  var a = {}, n = Object.keys(e), r, l;
  for (l = 0; l < n.length; l++)
    r = n[l], !(t.indexOf(r) >= 0) && (a[r] = e[r]);
  return a;
}
function Xf(e, t) {
  if (e == null)
    return {};
  var a = Kf(e, t), n, r;
  if (Object.getOwnPropertySymbols) {
    var l = Object.getOwnPropertySymbols(e);
    for (r = 0; r < l.length; r++)
      n = l[r], !(t.indexOf(n) >= 0) && Object.prototype.propertyIsEnumerable.call(e, n) && (a[n] = e[n]);
  }
  return a;
}
function bl(e) {
  return Qf(e) || Gf(e) || Jf(e) || Zf();
}
function Qf(e) {
  if (Array.isArray(e))
    return wl(e);
}
function Gf(e) {
  if (typeof Symbol < "u" && e[Symbol.iterator] != null || e["@@iterator"] != null)
    return Array.from(e);
}
function Jf(e, t) {
  if (e) {
    if (typeof e == "string")
      return wl(e, t);
    var a = Object.prototype.toString.call(e).slice(8, -1);
    if (a === "Object" && e.constructor && (a = e.constructor.name), a === "Map" || a === "Set")
      return Array.from(e);
    if (a === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(a))
      return wl(e, t);
  }
}
function wl(e, t) {
  (t == null || t > e.length) && (t = e.length);
  for (var a = 0, n = new Array(t); a < t; a++)
    n[a] = e[a];
  return n;
}
function Zf() {
  throw new TypeError(`Invalid attempt to spread non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
}
function e1(e, t) {
  if (typeof e != "object" || e === null)
    return e;
  var a = e[Symbol.toPrimitive];
  if (a !== void 0) {
    var n = a.call(e, t || "default");
    if (typeof n != "object")
      return n;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (t === "string" ? String : Number)(e);
}
function t1(e) {
  var t = e1(e, "string");
  return typeof t == "symbol" ? t : String(t);
}
var a1 = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {}, ls = { exports: {} };
(function(e) {
  (function(t) {
    var a = function(k, b, m) {
      if (!c(b) || d(b) || f(b) || h(b) || s(b))
        return b;
      var M, $ = 0, C = 0;
      if (u(b))
        for (M = [], C = b.length; $ < C; $++)
          M.push(a(k, b[$], m));
      else {
        M = {};
        for (var _ in b)
          Object.prototype.hasOwnProperty.call(b, _) && (M[k(_, m)] = a(k, b[_], m));
      }
      return M;
    }, n = function(k, b) {
      b = b || {};
      var m = b.separator || "_", M = b.split || /(?=[A-Z])/;
      return k.split(M).join(m);
    }, r = function(k) {
      return x(k) ? k : (k = k.replace(/[\-_\s]+(.)?/g, function(b, m) {
        return m ? m.toUpperCase() : "";
      }), k.substr(0, 1).toLowerCase() + k.substr(1));
    }, l = function(k) {
      var b = r(k);
      return b.substr(0, 1).toUpperCase() + b.substr(1);
    }, o = function(k, b) {
      return n(k, b).toLowerCase();
    }, i = Object.prototype.toString, s = function(k) {
      return typeof k == "function";
    }, c = function(k) {
      return k === Object(k);
    }, u = function(k) {
      return i.call(k) == "[object Array]";
    }, d = function(k) {
      return i.call(k) == "[object Date]";
    }, f = function(k) {
      return i.call(k) == "[object RegExp]";
    }, h = function(k) {
      return i.call(k) == "[object Boolean]";
    }, x = function(k) {
      return k = k - 0, k === k;
    }, g = function(k, b) {
      var m = b && "process" in b ? b.process : b;
      return typeof m != "function" ? k : function(M, $) {
        return m(M, k, $);
      };
    }, D = {
      camelize: r,
      decamelize: o,
      pascalize: l,
      depascalize: o,
      camelizeKeys: function(k, b) {
        return a(g(r, b), k);
      },
      decamelizeKeys: function(k, b) {
        return a(g(o, b), k, b);
      },
      pascalizeKeys: function(k, b) {
        return a(g(l, b), k);
      },
      depascalizeKeys: function() {
        return this.decamelizeKeys.apply(this, arguments);
      }
    };
    e.exports ? e.exports = D : t.humps = D;
  })(a1);
})(ls);
var n1 = ls.exports, r1 = ["class", "style"];
function l1(e) {
  return e.split(";").map(function(t) {
    return t.trim();
  }).filter(function(t) {
    return t;
  }).reduce(function(t, a) {
    var n = a.indexOf(":"), r = n1.camelize(a.slice(0, n)), l = a.slice(n + 1).trim();
    return t[r] = l, t;
  }, {});
}
function o1(e) {
  return e.split(/\s+/).reduce(function(t, a) {
    return t[a] = !0, t;
  }, {});
}
function Fl(e) {
  var t = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, a = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
  if (typeof e == "string")
    return e;
  var n = (e.children || []).map(function(s) {
    return Fl(s);
  }), r = Object.keys(e.attributes || {}).reduce(function(s, c) {
    var u = e.attributes[c];
    switch (c) {
      case "class":
        s.class = o1(u);
        break;
      case "style":
        s.style = l1(u);
        break;
      default:
        s.attrs[c] = u;
    }
    return s;
  }, {
    attrs: {},
    class: {},
    style: {}
  });
  a.class;
  var l = a.style, o = l === void 0 ? {} : l, i = Xf(a, r1);
  return qt(e.tag, Lt(Lt(Lt({}, t), {}, {
    class: r.class,
    style: Lt(Lt({}, r.style), o)
  }, r.attrs), i), n);
}
var os = !1;
try {
  os = process.env.NODE_ENV === "production";
} catch {
}
function i1() {
  if (!os && console && typeof console.error == "function") {
    var e;
    (e = console).error.apply(e, arguments);
  }
}
function kn(e, t) {
  return Array.isArray(t) && t.length > 0 || !Array.isArray(t) && t ? yt({}, e, t) : {};
}
function s1(e) {
  var t, a = (t = {
    "fa-spin": e.spin,
    "fa-pulse": e.pulse,
    "fa-fw": e.fixedWidth,
    "fa-border": e.border,
    "fa-li": e.listItem,
    "fa-inverse": e.inverse,
    "fa-flip": e.flip === !0,
    "fa-flip-horizontal": e.flip === "horizontal" || e.flip === "both",
    "fa-flip-vertical": e.flip === "vertical" || e.flip === "both"
  }, yt(t, "fa-".concat(e.size), e.size !== null), yt(t, "fa-rotate-".concat(e.rotation), e.rotation !== null), yt(t, "fa-pull-".concat(e.pull), e.pull !== null), yt(t, "fa-swap-opacity", e.swapOpacity), yt(t, "fa-bounce", e.bounce), yt(t, "fa-shake", e.shake), yt(t, "fa-beat", e.beat), yt(t, "fa-fade", e.fade), yt(t, "fa-beat-fade", e.beatFade), yt(t, "fa-flash", e.flash), yt(t, "fa-spin-pulse", e.spinPulse), yt(t, "fa-spin-reverse", e.spinReverse), t);
  return Object.keys(a).map(function(n) {
    return a[n] ? n : null;
  }).filter(function(n) {
    return n;
  });
}
function qo(e) {
  if (e && fr(e) === "object" && e.prefix && e.iconName && e.icon)
    return e;
  if (dr.icon)
    return dr.icon(e);
  if (e === null)
    return null;
  if (fr(e) === "object" && e.prefix && e.iconName)
    return e;
  if (Array.isArray(e) && e.length === 2)
    return {
      prefix: e[0],
      iconName: e[1]
    };
  if (typeof e == "string")
    return {
      prefix: "fas",
      iconName: e
    };
}
var Fa = xe({
  name: "FontAwesomeIcon",
  props: {
    border: {
      type: Boolean,
      default: !1
    },
    fixedWidth: {
      type: Boolean,
      default: !1
    },
    flip: {
      type: [Boolean, String],
      default: !1,
      validator: function(t) {
        return [!0, !1, "horizontal", "vertical", "both"].indexOf(t) > -1;
      }
    },
    icon: {
      type: [Object, Array, String],
      required: !0
    },
    mask: {
      type: [Object, Array, String],
      default: null
    },
    maskId: {
      type: String,
      default: null
    },
    listItem: {
      type: Boolean,
      default: !1
    },
    pull: {
      type: String,
      default: null,
      validator: function(t) {
        return ["right", "left"].indexOf(t) > -1;
      }
    },
    pulse: {
      type: Boolean,
      default: !1
    },
    rotation: {
      type: [String, Number],
      default: null,
      validator: function(t) {
        return [90, 180, 270].indexOf(Number.parseInt(t, 10)) > -1;
      }
    },
    swapOpacity: {
      type: Boolean,
      default: !1
    },
    size: {
      type: String,
      default: null,
      validator: function(t) {
        return ["2xs", "xs", "sm", "lg", "xl", "2xl", "1x", "2x", "3x", "4x", "5x", "6x", "7x", "8x", "9x", "10x"].indexOf(t) > -1;
      }
    },
    spin: {
      type: Boolean,
      default: !1
    },
    transform: {
      type: [String, Object],
      default: null
    },
    symbol: {
      type: [Boolean, String],
      default: !1
    },
    title: {
      type: String,
      default: null
    },
    titleId: {
      type: String,
      default: null
    },
    inverse: {
      type: Boolean,
      default: !1
    },
    bounce: {
      type: Boolean,
      default: !1
    },
    shake: {
      type: Boolean,
      default: !1
    },
    beat: {
      type: Boolean,
      default: !1
    },
    fade: {
      type: Boolean,
      default: !1
    },
    beatFade: {
      type: Boolean,
      default: !1
    },
    flash: {
      type: Boolean,
      default: !1
    },
    spinPulse: {
      type: Boolean,
      default: !1
    },
    spinReverse: {
      type: Boolean,
      default: !1
    }
  },
  setup: function(t, a) {
    var n = a.attrs, r = E(function() {
      return qo(t.icon);
    }), l = E(function() {
      return kn("classes", s1(t));
    }), o = E(function() {
      return kn("transform", typeof t.transform == "string" ? dr.transform(t.transform) : t.transform);
    }), i = E(function() {
      return kn("mask", qo(t.mask));
    }), s = E(function() {
      return bd(r.value, Lt(Lt(Lt(Lt({}, l.value), o.value), i.value), {}, {
        symbol: t.symbol,
        title: t.title,
        titleId: t.titleId,
        maskId: t.maskId
      }));
    });
    it(s, function(u) {
      if (!u)
        return i1("Could not find one or more icon(s)", r.value, i.value);
    }, {
      immediate: !0
    });
    var c = E(function() {
      return s.value ? Fl(s.value.abstract[0], {}, n) : null;
    });
    return function() {
      return c.value;
    };
  }
}), x3 = xe({
  name: "FontAwesomeLayers",
  props: {
    fixedWidth: {
      type: Boolean,
      default: !1
    }
  },
  setup: function(t, a) {
    var n = a.slots, r = ts.familyPrefix, l = E(function() {
      return ["".concat(r, "-layers")].concat(bl(t.fixedWidth ? ["".concat(r, "-fw")] : []));
    });
    return function() {
      return qt("div", {
        class: l.value
      }, n.default ? n.default() : []);
    };
  }
});
xe({
  name: "FontAwesomeLayersText",
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    transform: {
      type: [String, Object],
      default: null
    },
    counter: {
      type: Boolean,
      default: !1
    },
    position: {
      type: String,
      default: null,
      validator: function(t) {
        return ["bottom-left", "bottom-right", "top-left", "top-right"].indexOf(t) > -1;
      }
    }
  },
  setup: function(t, a) {
    var n = a.attrs, r = ts.familyPrefix, l = E(function() {
      return kn("classes", [].concat(bl(t.counter ? ["".concat(r, "-layers-counter")] : []), bl(t.position ? ["".concat(r, "-layers-").concat(t.position)] : [])));
    }), o = E(function() {
      return kn("transform", typeof t.transform == "string" ? dr.transform(t.transform) : t.transform);
    }), i = E(function() {
      var c = wd(t.value.toString(), Lt(Lt({}, o.value), l.value)), u = c.abstract;
      return t.counter && (u[0].attributes.class = u[0].attributes.class.replace("fa-layers-text", "")), u[0];
    }), s = E(function() {
      return Fl(i.value, {}, n);
    });
    return function() {
      return s.value;
    };
  }
});
gd.add(
  qf,
  wf,
  uf,
  Ff,
  xf,
  Sd,
  sf,
  Zd,
  Nf,
  $d,
  Ad,
  zd,
  Dd,
  Cd,
  Hf,
  yf,
  _d,
  Bd,
  rs,
  Sf,
  Rf,
  If,
  Pd,
  Fd,
  vf,
  Id,
  Df,
  Wf,
  Mf,
  bf,
  Jd,
  jf,
  rf,
  Od,
  cf,
  Xd,
  mf,
  $f,
  pf,
  Nd,
  Vd,
  ff,
  Wd,
  Tf,
  Td,
  qd,
  Cf,
  as,
  kf,
  jd,
  xd,
  Of,
  Lf,
  nf,
  Qd,
  Pf,
  kd,
  lf,
  hf,
  Ud,
  Af,
  Md,
  df,
  Ef,
  of,
  Yf,
  Hd,
  Vf,
  tf,
  Bf,
  ns,
  Rd,
  Yd,
  Ed,
  Kd,
  Ld
);
const u1 = ["type"], _3 = /* @__PURE__ */ xe({
  inheritAttrs: !1,
  __name: "UITextInput",
  props: {
    type: { default: "text" },
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    icon: {},
    controlled: { type: Boolean, default: !0 }
  },
  setup(e) {
    const t = e, { value: a, errorMessage: n } = Ya(
      Ke(t, "name"),
      Ke(t, "rules"),
      {
        label: Ke(t, "label"),
        syncVModel: !0,
        controlled: t.controlled
      }
    ), r = wr(), l = E(() => {
      const { class: i, style: s } = r;
      return { class: i, style: s };
    }), o = E(() => {
      const { class: i, style: s, ...c } = r;
      return c;
    });
    return (i, s) => i.type !== "multiline" ? (P(), j("div", Ne({
      key: 0,
      class: "relative"
    }, l.value), [
      ma(ce("input", Ne(o.value, {
        "onUpdate:modelValue": s[0] || (s[0] = (c) => Gt(a) ? a.value = c : null),
        type: i.type,
        class: [
          "tw-reset w-full px-2 h-8 border rounded border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
          { "pl-8": i.icon },
          {
            "border-warning-orange-600 text-warning-orange-600 italic": p(n)
          }
        ]
      }), null, 16, u1), [
        [Su, p(a)]
      ]),
      i.icon ? (P(), Ae(p(Fa), {
        key: 0,
        icon: i.icon,
        class: "absolute left-2 top-1/2 transform -translate-y-1/2 text-ui-gray-800 disabled:text-ui-gray-300"
      }, null, 8, ["icon"])) : Q("", !0)
    ], 16)) : ma((P(), j("textarea", Ne({ key: 1 }, p(r), {
      "onUpdate:modelValue": s[1] || (s[1] = (c) => Gt(a) ? a.value = c : null),
      class: [
        "tw-reset px-2 border rounded border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
        {
          "border-warning-orange-600 text-warning-orange-600 italic": p(n)
        }
      ]
    }), null, 16)), [
      [Ai, p(a)]
    ]);
  }
}), M3 = /* @__PURE__ */ xe({
  __name: "UIFieldError",
  props: {
    name: {}
  },
  setup(e) {
    return (t, a) => (P(), Ae(p(Iu), {
      name: t.name,
      class: "tw-reset text-sm text-warning-orange-600"
    }, null, 8, ["name"]));
  }
}), c1 = { class: "flex h-6 items-center" }, d1 = ["id", "name", "aria-describedby"], f1 = {
  key: 1,
  class: "w-3.5 h-0.5 bg-pivot-teal-700 rounded-xl"
}, v1 = { className: "ml-3 leading-6" }, m1 = ["htmlFor"], A3 = /* @__PURE__ */ xe({
  __name: "UICheckbox",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    value: { default: "true" },
    indeterminate: { type: Boolean }
  },
  setup(e) {
    const t = e, a = wr(), { checked: n, handleChange: r } = Ya(() => t.name, t.rules, {
      // 👇 These are important
      type: "checkbox",
      checkedValue: t.value,
      label: t.label,
      syncVModel: !0
    }), l = E(() => "disabled" in a), o = E(() => {
      const { style: s, class: c, onClick: u } = a;
      return { style: s, class: c, onClick: u };
    }), i = E(() => {
      const { style: s, class: c, onClick: u, ...d } = a;
      return d;
    });
    return (s, c) => (P(), j("div", Ne({ class: "relative flex items-start" }, o.value), [
      ce("div", c1, [
        ce("input", Ne({
          id: s.name,
          name: s.name,
          type: "checkbox",
          "aria-describedby": s.label
        }, i.value, {
          class: "absolute opacity-0 w-6 h-6",
          onClick: c[0] || (c[0] = (u) => p(r)(s.value))
        }), null, 16, d1),
        ce("span", {
          class: we(["w-6 h-6 rounded border border-ui-gray-400 bg-white flex items-center justify-center hidden-print", {
            "bg-ui-gray-100": l.value
          }])
        }, [
          p(n) && !s.indeterminate ? (P(), Ae(p(Fa), {
            key: 0,
            icon: p(rs),
            class: "text-pivot-teal-700 block",
            size: "lg"
          }, null, 8, ["icon"])) : Q("", !0),
          p(n) && s.indeterminate ? (P(), j("div", f1)) : Q("", !0)
        ], 2)
      ]),
      ce("div", v1, [
        ce("label", {
          htmlFor: s.name,
          class: we(["text-base", {
            "text-ui-gray-800": !p(n),
            "text-pivot-teal-700 font-bold": p(n),
            "text-ui-gray-300": l.value
          }])
        }, Ye(s.label), 11, m1)
      ])
    ], 16));
  }
}), p1 = ["htmlFor"], h1 = { class: "flex h-6 items-center" }, y1 = ["id", "name", "aria-describedby", "value"], g1 = { className: "ml-3" }, T3 = /* @__PURE__ */ xe({
  __name: "UIRadioButton",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    value: { default: "true" }
  },
  emits: ["update:modelValue"],
  setup(e, { emit: t }) {
    const a = e, n = wr(), r = t, {
      checked: l,
      handleChange: o,
      value: i
    } = Ya(() => a.name, a.rules, {
      // 👇 These are important
      type: "radio",
      checkedValue: a.value,
      uncheckedValue: "",
      label: a.label,
      syncVModel: !0
    }), s = () => {
      let f = a.value;
      i.value === f && (f = ""), o(f), r("update:modelValue", f);
    }, c = E(() => "disabled" in n), u = E(() => {
      const { style: f, class: h, onClick: x } = n;
      return { style: f, class: h, onClick: x };
    }), d = E(() => {
      const { style: f, class: h, onClick: x, ...g } = n;
      return g;
    });
    return (f, h) => (P(), j("div", Ne({ class: "relative flex items-start" }, u.value), [
      ce("label", {
        htmlFor: f.value,
        class: "flex"
      }, [
        ce("div", h1, [
          ma(ce("input", Ne({
            id: f.value,
            name: f.name,
            type: "radio",
            "aria-describedby": f.label,
            value: f.value,
            "onUpdate:modelValue": h[0] || (h[0] = (x) => Gt(i) ? i.value = x : null)
          }, d.value, {
            class: "absolute opacity-0",
            onClick: s
          }), null, 16, y1), [
            [Cu, p(i)]
          ]),
          ce("span", {
            class: we(["w-6 h-6 rounded-full border border-ui-gray-400 flex items-center justify-center hidden-print", {
              "bg-ui-gray-100": c.value,
              "bg-white": !c.value
            }])
          }, [
            p(l) ? (P(), Ae(p(Fa), {
              key: 0,
              icon: p(ef),
              class: "text-pivot-teal-700 block",
              size: "xs"
            }, null, 8, ["icon"])) : Q("", !0)
          ], 2)
        ]),
        ce("div", g1, [
          ce("span", {
            class: we(["text-base leading-6", {
              "text-ui-gray-800": !p(l) && !c.value,
              "text-pivot-teal-700 font-bold": p(l),
              "text-ui-gray-300": c.value
            }])
          }, Ye(f.label), 3)
        ])
      ], 8, p1)
    ], 16));
  }
});
function is(e, t, a) {
  let n = U(a == null ? void 0 : a.value), r = E(() => e.value !== void 0);
  return [E(() => r.value ? e.value : n.value), function(l) {
    return r.value || (n.value = l), t == null ? void 0 : t(l);
  }];
}
let b1 = Symbol("headlessui.useid"), w1 = 0;
function Ma() {
  return zt(b1, () => `${++w1}`)();
}
function Be(e) {
  var t;
  if (e == null || e.value == null)
    return null;
  let a = (t = e.value.$el) != null ? t : e.value;
  return a instanceof Node ? a : null;
}
function bt(e, t, ...a) {
  if (e in t) {
    let r = t[e];
    return typeof r == "function" ? r(...a) : r;
  }
  let n = new Error(`Tried to handle "${e}" but there is no handler defined. Only defined handlers are: ${Object.keys(t).map((r) => `"${r}"`).join(", ")}.`);
  throw Error.captureStackTrace && Error.captureStackTrace(n, bt), n;
}
var k1 = Object.defineProperty, x1 = (e, t, a) => t in e ? k1(e, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : e[t] = a, Ko = (e, t, a) => (x1(e, typeof t != "symbol" ? t + "" : t, a), a);
let _1 = class {
  constructor() {
    Ko(this, "current", this.detect()), Ko(this, "currentId", 0);
  }
  set(t) {
    this.current !== t && (this.currentId = 0, this.current = t);
  }
  reset() {
    this.set(this.detect());
  }
  nextId() {
    return ++this.currentId;
  }
  get isServer() {
    return this.current === "server";
  }
  get isClient() {
    return this.current === "client";
  }
  detect() {
    return typeof window > "u" || typeof document > "u" ? "server" : "client";
  }
}, Tr = new _1();
function M1(e) {
  if (Tr.isServer)
    return null;
  if (e instanceof Node)
    return e.ownerDocument;
  if (e != null && e.hasOwnProperty("value")) {
    let t = Be(e);
    if (t)
      return t.ownerDocument;
  }
  return document;
}
let Xo = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e) => `${e}:not([tabindex='-1'])`).join(",");
var A1 = ((e) => (e[e.First = 1] = "First", e[e.Previous = 2] = "Previous", e[e.Next = 4] = "Next", e[e.Last = 8] = "Last", e[e.WrapAround = 16] = "WrapAround", e[e.NoScroll = 32] = "NoScroll", e))(A1 || {}), T1 = ((e) => (e[e.Error = 0] = "Error", e[e.Overflow = 1] = "Overflow", e[e.Success = 2] = "Success", e[e.Underflow = 3] = "Underflow", e))(T1 || {}), O1 = ((e) => (e[e.Previous = -1] = "Previous", e[e.Next = 1] = "Next", e))(O1 || {}), jl = ((e) => (e[e.Strict = 0] = "Strict", e[e.Loose = 1] = "Loose", e))(jl || {});
function ss(e, t = 0) {
  var a;
  return e === ((a = M1(e)) == null ? void 0 : a.body) ? !1 : bt(t, { 0() {
    return e.matches(Xo);
  }, 1() {
    let n = e;
    for (; n !== null; ) {
      if (n.matches(Xo))
        return !0;
      n = n.parentElement;
    }
    return !1;
  } });
}
var P1 = ((e) => (e[e.Keyboard = 0] = "Keyboard", e[e.Mouse = 1] = "Mouse", e))(P1 || {});
typeof window < "u" && typeof document < "u" && (document.addEventListener("keydown", (e) => {
  e.metaKey || e.altKey || e.ctrlKey || (document.documentElement.dataset.headlessuiFocusVisible = "");
}, !0), document.addEventListener("click", (e) => {
  e.detail === 1 ? delete document.documentElement.dataset.headlessuiFocusVisible : e.detail === 0 && (document.documentElement.dataset.headlessuiFocusVisible = "");
}, !0));
function S1(e, t = (a) => a) {
  return e.slice().sort((a, n) => {
    let r = t(a), l = t(n);
    if (r === null || l === null)
      return 0;
    let o = r.compareDocumentPosition(l);
    return o & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : o & Node.DOCUMENT_POSITION_PRECEDING ? 1 : 0;
  });
}
function C1() {
  return /iPhone/gi.test(window.navigator.platform) || /Mac/gi.test(window.navigator.platform) && window.navigator.maxTouchPoints > 0;
}
function D1() {
  return /Android/gi.test(window.navigator.userAgent);
}
function $1() {
  return C1() || D1();
}
function Zn(e, t, a) {
  Tr.isServer || ya((n) => {
    document.addEventListener(e, t, a), n(() => document.removeEventListener(e, t, a));
  });
}
function L1(e, t, a) {
  Tr.isServer || ya((n) => {
    window.addEventListener(e, t, a), n(() => window.removeEventListener(e, t, a));
  });
}
function E1(e, t, a = E(() => !0)) {
  function n(l, o) {
    if (!a.value || l.defaultPrevented)
      return;
    let i = o(l);
    if (i === null || !i.getRootNode().contains(i))
      return;
    let s = function c(u) {
      return typeof u == "function" ? c(u()) : Array.isArray(u) || u instanceof Set ? u : [u];
    }(e);
    for (let c of s) {
      if (c === null)
        continue;
      let u = c instanceof HTMLElement ? c : Be(c);
      if (u != null && u.contains(i) || l.composed && l.composedPath().includes(u))
        return;
    }
    return !ss(i, jl.Loose) && i.tabIndex !== -1 && l.preventDefault(), t(l, i);
  }
  let r = U(null);
  Zn("pointerdown", (l) => {
    var o, i;
    a.value && (r.value = ((i = (o = l.composedPath) == null ? void 0 : o.call(l)) == null ? void 0 : i[0]) || l.target);
  }, !0), Zn("mousedown", (l) => {
    var o, i;
    a.value && (r.value = ((i = (o = l.composedPath) == null ? void 0 : o.call(l)) == null ? void 0 : i[0]) || l.target);
  }, !0), Zn("click", (l) => {
    $1() || r.value && (n(l, () => r.value), r.value = null);
  }, !0), Zn("touchend", (l) => n(l, () => l.target instanceof HTMLElement ? l.target : null), !0), L1("blur", (l) => n(l, () => window.document.activeElement instanceof HTMLIFrameElement ? window.document.activeElement : null), !0);
}
function Qo(e, t) {
  if (e)
    return e;
  let a = t ?? "button";
  if (typeof a == "string" && a.toLowerCase() === "button")
    return "button";
}
function us(e, t) {
  let a = U(Qo(e.value.type, e.value.as));
  return Le(() => {
    a.value = Qo(e.value.type, e.value.as);
  }), ya(() => {
    var n;
    a.value || Be(t) && Be(t) instanceof HTMLButtonElement && !((n = Be(t)) != null && n.hasAttribute("type")) && (a.value = "button");
  }), a;
}
function Go(e) {
  return [e.screenX, e.screenY];
}
function N1() {
  let e = U([-1, -1]);
  return { wasMoved(t) {
    let a = Go(t);
    return e.value[0] === a[0] && e.value[1] === a[1] ? !1 : (e.value = a, !0);
  }, update(t) {
    e.value = Go(t);
  } };
}
var vr = ((e) => (e[e.None = 0] = "None", e[e.RenderStrategy = 1] = "RenderStrategy", e[e.Static = 2] = "Static", e))(vr || {}), fa = ((e) => (e[e.Unmount = 0] = "Unmount", e[e.Hidden = 1] = "Hidden", e))(fa || {});
function Ct({ visible: e = !0, features: t = 0, ourProps: a, theirProps: n, ...r }) {
  var l;
  let o = ds(n, a), i = Object.assign(r, { props: o });
  if (e || t & 2 && o.static)
    return Kr(i);
  if (t & 1) {
    let s = (l = o.unmount) == null || l ? 0 : 1;
    return bt(s, { 0() {
      return null;
    }, 1() {
      return Kr({ ...r, props: { ...o, hidden: !0, style: { display: "none" } } });
    } });
  }
  return Kr(i);
}
function Kr({ props: e, attrs: t, slots: a, slot: n, name: r }) {
  var l, o;
  let { as: i, ...s } = Or(e, ["unmount", "static"]), c = (l = a.default) == null ? void 0 : l.call(a, n), u = {};
  if (n) {
    let d = !1, f = [];
    for (let [h, x] of Object.entries(n))
      typeof x == "boolean" && (d = !0), x === !0 && f.push(h);
    d && (u["data-headlessui-state"] = f.join(" "));
  }
  if (i === "template") {
    if (c = cs(c ?? []), Object.keys(s).length > 0 || Object.keys(t).length > 0) {
      let [d, ...f] = c ?? [];
      if (!R1(d) || f.length > 0)
        throw new Error(['Passing props on "template"!', "", `The current component <${r} /> is rendering a "template".`, "However we need to passthrough the following props:", Object.keys(s).concat(Object.keys(t)).map((g) => g.trim()).filter((g, D, k) => k.indexOf(g) === D).sort((g, D) => g.localeCompare(D)).map((g) => `  - ${g}`).join(`
`), "", "You can apply a few solutions:", ['Add an `as="..."` prop, to ensure that we render an actual element instead of a "template".', "Render a single element as the child so that we can forward the props onto that element."].map((g) => `  - ${g}`).join(`
`)].join(`
`));
      let h = ds((o = d.props) != null ? o : {}, s, u), x = Du(d, h, !0);
      for (let g in h)
        g.startsWith("on") && (x.props || (x.props = {}), x.props[g] = h[g]);
      return x;
    }
    return Array.isArray(c) && c.length === 1 ? c[0] : c;
  }
  return qt(i, Object.assign({}, s, u), { default: () => c });
}
function cs(e) {
  return e.flatMap((t) => t.type === Me ? cs(t.children) : [t]);
}
function ds(...e) {
  if (e.length === 0)
    return {};
  if (e.length === 1)
    return e[0];
  let t = {}, a = {};
  for (let n of e)
    for (let r in n)
      r.startsWith("on") && typeof n[r] == "function" ? (a[r] != null || (a[r] = []), a[r].push(n[r])) : t[r] = n[r];
  if (t.disabled || t["aria-disabled"])
    return Object.assign(t, Object.fromEntries(Object.keys(a).map((n) => [n, void 0])));
  for (let n in a)
    Object.assign(t, { [n](r, ...l) {
      let o = a[n];
      for (let i of o) {
        if (r instanceof Event && r.defaultPrevented)
          return;
        i(r, ...l);
      }
    } });
  return t;
}
function fs(e) {
  let t = Object.assign({}, e);
  for (let a in t)
    t[a] === void 0 && delete t[a];
  return t;
}
function Or(e, t = []) {
  let a = Object.assign({}, e);
  for (let n of t)
    n in a && delete a[n];
  return a;
}
function R1(e) {
  return e == null ? !1 : typeof e.type == "string" || typeof e.type == "object" || typeof e.type == "function";
}
var Ul = ((e) => (e[e.None = 1] = "None", e[e.Focusable = 2] = "Focusable", e[e.Hidden = 4] = "Hidden", e))(Ul || {});
let vs = xe({ name: "Hidden", props: { as: { type: [Object, String], default: "div" }, features: { type: Number, default: 1 } }, setup(e, { slots: t, attrs: a }) {
  return () => {
    var n;
    let { features: r, ...l } = e, o = { "aria-hidden": (r & 2) === 2 ? !0 : (n = l["aria-hidden"]) != null ? n : void 0, style: { position: "fixed", top: 1, left: 1, width: 1, height: 0, padding: 0, margin: -1, overflow: "hidden", clip: "rect(0, 0, 0, 0)", whiteSpace: "nowrap", borderWidth: "0", ...(r & 4) === 4 && (r & 2) !== 2 && { display: "none" } } };
    return Ct({ ourProps: o, theirProps: l, slot: {}, attrs: a, slots: t, name: "Hidden" });
  };
} }), ms = Symbol("Context");
var xt = ((e) => (e[e.Open = 1] = "Open", e[e.Closed = 2] = "Closed", e[e.Closing = 4] = "Closing", e[e.Opening = 8] = "Opening", e))(xt || {});
function I1() {
  return Wl() !== null;
}
function Wl() {
  return zt(ms, null);
}
function ps(e) {
  Jt(ms, e);
}
var tt = ((e) => (e.Space = " ", e.Enter = "Enter", e.Escape = "Escape", e.Backspace = "Backspace", e.Delete = "Delete", e.ArrowLeft = "ArrowLeft", e.ArrowUp = "ArrowUp", e.ArrowRight = "ArrowRight", e.ArrowDown = "ArrowDown", e.Home = "Home", e.End = "End", e.PageUp = "PageUp", e.PageDown = "PageDown", e.Tab = "Tab", e))(tt || {});
function H1(e) {
  throw new Error("Unexpected object: " + e);
}
var mt = ((e) => (e[e.First = 0] = "First", e[e.Previous = 1] = "Previous", e[e.Next = 2] = "Next", e[e.Last = 3] = "Last", e[e.Specific = 4] = "Specific", e[e.Nothing = 5] = "Nothing", e))(mt || {});
function z1(e, t) {
  let a = t.resolveItems();
  if (a.length <= 0)
    return null;
  let n = t.resolveActiveIndex(), r = n ?? -1;
  switch (e.focus) {
    case 0: {
      for (let l = 0; l < a.length; ++l)
        if (!t.resolveDisabled(a[l], l, a))
          return l;
      return n;
    }
    case 1: {
      r === -1 && (r = a.length);
      for (let l = r - 1; l >= 0; --l)
        if (!t.resolveDisabled(a[l], l, a))
          return l;
      return n;
    }
    case 2: {
      for (let l = r + 1; l < a.length; ++l)
        if (!t.resolveDisabled(a[l], l, a))
          return l;
      return n;
    }
    case 3: {
      for (let l = a.length - 1; l >= 0; --l)
        if (!t.resolveDisabled(a[l], l, a))
          return l;
      return n;
    }
    case 4: {
      for (let l = 0; l < a.length; ++l)
        if (t.resolveId(a[l], l, a) === e.id)
          return l;
      return n;
    }
    case 5:
      return null;
    default:
      H1(e);
  }
}
function V1(e) {
  typeof queueMicrotask == "function" ? queueMicrotask(e) : Promise.resolve().then(e).catch((t) => setTimeout(() => {
    throw t;
  }));
}
function ql() {
  let e = [], t = { addEventListener(a, n, r, l) {
    return a.addEventListener(n, r, l), t.add(() => a.removeEventListener(n, r, l));
  }, requestAnimationFrame(...a) {
    let n = requestAnimationFrame(...a);
    t.add(() => cancelAnimationFrame(n));
  }, nextFrame(...a) {
    t.requestAnimationFrame(() => {
      t.requestAnimationFrame(...a);
    });
  }, setTimeout(...a) {
    let n = setTimeout(...a);
    t.add(() => clearTimeout(n));
  }, microTask(...a) {
    let n = { current: !0 };
    return V1(() => {
      n.current && a[0]();
    }), t.add(() => {
      n.current = !1;
    });
  }, style(a, n, r) {
    let l = a.style.getPropertyValue(n);
    return Object.assign(a.style, { [n]: r }), this.add(() => {
      Object.assign(a.style, { [n]: l });
    });
  }, group(a) {
    let n = ql();
    return a(n), this.add(() => n.dispose());
  }, add(a) {
    return e.push(a), () => {
      let n = e.indexOf(a);
      if (n >= 0)
        for (let r of e.splice(n, 1))
          r();
    };
  }, dispose() {
    for (let a of e.splice(0))
      a();
  } };
  return t;
}
function hs(e = {}, t = null, a = []) {
  for (let [n, r] of Object.entries(e))
    gs(a, ys(t, n), r);
  return a;
}
function ys(e, t) {
  return e ? e + "[" + t + "]" : t;
}
function gs(e, t, a) {
  if (Array.isArray(a))
    for (let [n, r] of a.entries())
      gs(e, ys(t, n.toString()), r);
  else
    a instanceof Date ? e.push([t, a.toISOString()]) : typeof a == "boolean" ? e.push([t, a ? "1" : "0"]) : typeof a == "string" ? e.push([t, a]) : typeof a == "number" ? e.push([t, `${a}`]) : a == null ? e.push([t, ""]) : hs(a, t, e);
}
function B1(e) {
  var t, a;
  let n = (t = e == null ? void 0 : e.form) != null ? t : e.closest("form");
  if (n) {
    for (let r of n.elements)
      if (r !== e && (r.tagName === "INPUT" && r.type === "submit" || r.tagName === "BUTTON" && r.type === "submit" || r.nodeName === "INPUT" && r.type === "image")) {
        r.click();
        return;
      }
    (a = n.requestSubmit) == null || a.call(n);
  }
}
let bs = Symbol("DescriptionContext");
function Y1() {
  let e = zt(bs, null);
  if (e === null)
    throw new Error("Missing parent");
  return e;
}
function F1({ slot: e = U({}), name: t = "Description", props: a = {} } = {}) {
  let n = U([]);
  function r(l) {
    return n.value.push(l), () => {
      let o = n.value.indexOf(l);
      o !== -1 && n.value.splice(o, 1);
    };
  }
  return Jt(bs, { register: r, slot: e, name: t, props: a }), E(() => n.value.length > 0 ? n.value.join(" ") : void 0);
}
xe({ name: "Description", props: { as: { type: [Object, String], default: "p" }, id: { type: String, default: null } }, setup(e, { attrs: t, slots: a }) {
  var n;
  let r = (n = e.id) != null ? n : `headlessui-description-${Ma()}`, l = Y1();
  return Le(() => ra(l.register(r))), () => {
    let { name: o = "Description", slot: i = U({}), props: s = {} } = l, { ...c } = e, u = { ...Object.entries(s).reduce((d, [f, h]) => Object.assign(d, { [f]: p(h) }), {}), id: r };
    return Ct({ ourProps: u, theirProps: c, slot: i.value, attrs: t, slots: a, name: o });
  };
} });
let Jo = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
function Zo(e) {
  var t, a;
  let n = (t = e.innerText) != null ? t : "", r = e.cloneNode(!0);
  if (!(r instanceof HTMLElement))
    return n;
  let l = !1;
  for (let i of r.querySelectorAll('[hidden],[aria-hidden],[role="img"]'))
    i.remove(), l = !0;
  let o = l ? (a = r.innerText) != null ? a : "" : n;
  return Jo.test(o) && (o = o.replace(Jo, "")), o;
}
function j1(e) {
  let t = e.getAttribute("aria-label");
  if (typeof t == "string")
    return t.trim();
  let a = e.getAttribute("aria-labelledby");
  if (a) {
    let n = a.split(" ").map((r) => {
      let l = document.getElementById(r);
      if (l) {
        let o = l.getAttribute("aria-label");
        return typeof o == "string" ? o.trim() : Zo(l).trim();
      }
      return null;
    }).filter(Boolean);
    if (n.length > 0)
      return n.join(", ");
  }
  return Zo(e).trim();
}
function U1(e) {
  let t = U(""), a = U("");
  return () => {
    let n = Be(e);
    if (!n)
      return "";
    let r = n.innerText;
    if (t.value === r)
      return a.value;
    let l = j1(n).trim().toLowerCase();
    return t.value = r, a.value = l, l;
  };
}
function W1(e, t) {
  return e === t;
}
var q1 = ((e) => (e[e.Open = 0] = "Open", e[e.Closed = 1] = "Closed", e))(q1 || {}), K1 = ((e) => (e[e.Single = 0] = "Single", e[e.Multi = 1] = "Multi", e))(K1 || {}), X1 = ((e) => (e[e.Pointer = 0] = "Pointer", e[e.Other = 1] = "Other", e))(X1 || {});
function Q1(e) {
  requestAnimationFrame(() => requestAnimationFrame(e));
}
let ws = Symbol("ListboxContext");
function In(e) {
  let t = zt(ws, null);
  if (t === null) {
    let a = new Error(`<${e} /> is missing a parent <Listbox /> component.`);
    throw Error.captureStackTrace && Error.captureStackTrace(a, In), a;
  }
  return t;
}
let G1 = xe({ name: "Listbox", emits: { "update:modelValue": (e) => !0 }, props: { as: { type: [Object, String], default: "template" }, disabled: { type: [Boolean], default: !1 }, by: { type: [String, Function], default: () => W1 }, horizontal: { type: [Boolean], default: !1 }, modelValue: { type: [Object, String, Number, Boolean], default: void 0 }, defaultValue: { type: [Object, String, Number, Boolean], default: void 0 }, form: { type: String, optional: !0 }, name: { type: String, optional: !0 }, multiple: { type: [Boolean], default: !1 } }, inheritAttrs: !1, setup(e, { slots: t, attrs: a, emit: n }) {
  let r = U(1), l = U(null), o = U(null), i = U(null), s = U([]), c = U(""), u = U(null), d = U(1);
  function f(m = (M) => M) {
    let M = u.value !== null ? s.value[u.value] : null, $ = S1(m(s.value.slice()), (_) => Be(_.dataRef.domRef)), C = M ? $.indexOf(M) : null;
    return C === -1 && (C = null), { options: $, activeOptionIndex: C };
  }
  let h = E(() => e.multiple ? 1 : 0), [x, g] = is(E(() => e.modelValue), (m) => n("update:modelValue", m), E(() => e.defaultValue)), D = E(() => x.value === void 0 ? bt(h.value, { 1: [], 0: void 0 }) : x.value), k = { listboxState: r, value: D, mode: h, compare(m, M) {
    if (typeof e.by == "string") {
      let $ = e.by;
      return (m == null ? void 0 : m[$]) === (M == null ? void 0 : M[$]);
    }
    return e.by(m, M);
  }, orientation: E(() => e.horizontal ? "horizontal" : "vertical"), labelRef: l, buttonRef: o, optionsRef: i, disabled: E(() => e.disabled), options: s, searchQuery: c, activeOptionIndex: u, activationTrigger: d, closeListbox() {
    e.disabled || r.value !== 1 && (r.value = 1, u.value = null);
  }, openListbox() {
    e.disabled || r.value !== 0 && (r.value = 0);
  }, goToOption(m, M, $) {
    if (e.disabled || r.value === 1)
      return;
    let C = f(), _ = z1(m === mt.Specific ? { focus: mt.Specific, id: M } : { focus: m }, { resolveItems: () => C.options, resolveActiveIndex: () => C.activeOptionIndex, resolveId: (W) => W.id, resolveDisabled: (W) => W.dataRef.disabled });
    c.value = "", u.value = _, d.value = $ ?? 1, s.value = C.options;
  }, search(m) {
    if (e.disabled || r.value === 1)
      return;
    let M = c.value !== "" ? 0 : 1;
    c.value += m.toLowerCase();
    let $ = (u.value !== null ? s.value.slice(u.value + M).concat(s.value.slice(0, u.value + M)) : s.value).find((_) => _.dataRef.textValue.startsWith(c.value) && !_.dataRef.disabled), C = $ ? s.value.indexOf($) : -1;
    C === -1 || C === u.value || (u.value = C, d.value = 1);
  }, clearSearch() {
    e.disabled || r.value !== 1 && c.value !== "" && (c.value = "");
  }, registerOption(m, M) {
    let $ = f((C) => [...C, { id: m, dataRef: M }]);
    s.value = $.options, u.value = $.activeOptionIndex;
  }, unregisterOption(m) {
    let M = f(($) => {
      let C = $.findIndex((_) => _.id === m);
      return C !== -1 && $.splice(C, 1), $;
    });
    s.value = M.options, u.value = M.activeOptionIndex, d.value = 1;
  }, theirOnChange(m) {
    e.disabled || g(m);
  }, select(m) {
    e.disabled || g(bt(h.value, { 0: () => m, 1: () => {
      let M = $t(k.value.value).slice(), $ = $t(m), C = M.findIndex((_) => k.compare($, $t(_)));
      return C === -1 ? M.push($) : M.splice(C, 1), M;
    } }));
  } };
  E1([o, i], (m, M) => {
    var $;
    k.closeListbox(), ss(M, jl.Loose) || (m.preventDefault(), ($ = Be(o)) == null || $.focus());
  }, E(() => r.value === 0)), Jt(ws, k), ps(E(() => bt(r.value, { 0: xt.Open, 1: xt.Closed })));
  let b = E(() => {
    var m;
    return (m = Be(o)) == null ? void 0 : m.closest("form");
  });
  return Le(() => {
    it([b], () => {
      if (!b.value || e.defaultValue === void 0)
        return;
      function m() {
        k.theirOnChange(e.defaultValue);
      }
      return b.value.addEventListener("reset", m), () => {
        var M;
        (M = b.value) == null || M.removeEventListener("reset", m);
      };
    }, { immediate: !0 });
  }), () => {
    let { name: m, modelValue: M, disabled: $, form: C, ..._ } = e, W = { open: r.value === 0, disabled: $, value: D.value };
    return qt(Me, [...m != null && D.value != null ? hs({ [m]: D.value }).map(([F, H]) => qt(vs, fs({ features: Ul.Hidden, key: F, as: "input", type: "hidden", hidden: !0, readOnly: !0, form: C, name: F, value: H }))) : [], Ct({ ourProps: {}, theirProps: { ...a, ...Or(_, ["defaultValue", "onUpdate:modelValue", "horizontal", "multiple", "by"]) }, slot: W, slots: t, attrs: a, name: "Listbox" })]);
  };
} });
xe({ name: "ListboxLabel", props: { as: { type: [Object, String], default: "label" }, id: { type: String, default: null } }, setup(e, { attrs: t, slots: a }) {
  var n;
  let r = (n = e.id) != null ? n : `headlessui-listbox-label-${Ma()}`, l = In("ListboxLabel");
  function o() {
    var i;
    (i = Be(l.buttonRef)) == null || i.focus({ preventScroll: !0 });
  }
  return () => {
    let i = { open: l.listboxState.value === 0, disabled: l.disabled.value }, { ...s } = e, c = { id: r, ref: l.labelRef, onClick: o };
    return Ct({ ourProps: c, theirProps: s, slot: i, attrs: t, slots: a, name: "ListboxLabel" });
  };
} });
let J1 = xe({ name: "ListboxButton", props: { as: { type: [Object, String], default: "button" }, id: { type: String, default: null } }, setup(e, { attrs: t, slots: a, expose: n }) {
  var r;
  let l = (r = e.id) != null ? r : `headlessui-listbox-button-${Ma()}`, o = In("ListboxButton");
  n({ el: o.buttonRef, $el: o.buttonRef });
  function i(d) {
    switch (d.key) {
      case tt.Space:
      case tt.Enter:
      case tt.ArrowDown:
        d.preventDefault(), o.openListbox(), at(() => {
          var f;
          (f = Be(o.optionsRef)) == null || f.focus({ preventScroll: !0 }), o.value.value || o.goToOption(mt.First);
        });
        break;
      case tt.ArrowUp:
        d.preventDefault(), o.openListbox(), at(() => {
          var f;
          (f = Be(o.optionsRef)) == null || f.focus({ preventScroll: !0 }), o.value.value || o.goToOption(mt.Last);
        });
        break;
    }
  }
  function s(d) {
    switch (d.key) {
      case tt.Space:
        d.preventDefault();
        break;
    }
  }
  function c(d) {
    o.disabled.value || (o.listboxState.value === 0 ? (o.closeListbox(), at(() => {
      var f;
      return (f = Be(o.buttonRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
    })) : (d.preventDefault(), o.openListbox(), Q1(() => {
      var f;
      return (f = Be(o.optionsRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
    })));
  }
  let u = us(E(() => ({ as: e.as, type: t.type })), o.buttonRef);
  return () => {
    var d, f;
    let h = { open: o.listboxState.value === 0, disabled: o.disabled.value, value: o.value.value }, { ...x } = e, g = { ref: o.buttonRef, id: l, type: u.value, "aria-haspopup": "listbox", "aria-controls": (d = Be(o.optionsRef)) == null ? void 0 : d.id, "aria-expanded": o.listboxState.value === 0, "aria-labelledby": o.labelRef.value ? [(f = Be(o.labelRef)) == null ? void 0 : f.id, l].join(" ") : void 0, disabled: o.disabled.value === !0 ? !0 : void 0, onKeydown: i, onKeyup: s, onClick: c };
    return Ct({ ourProps: g, theirProps: x, slot: h, attrs: t, slots: a, name: "ListboxButton" });
  };
} }), Z1 = xe({ name: "ListboxOptions", props: { as: { type: [Object, String], default: "ul" }, static: { type: Boolean, default: !1 }, unmount: { type: Boolean, default: !0 }, id: { type: String, default: null } }, setup(e, { attrs: t, slots: a, expose: n }) {
  var r;
  let l = (r = e.id) != null ? r : `headlessui-listbox-options-${Ma()}`, o = In("ListboxOptions"), i = U(null);
  n({ el: o.optionsRef, $el: o.optionsRef });
  function s(d) {
    switch (i.value && clearTimeout(i.value), d.key) {
      case tt.Space:
        if (o.searchQuery.value !== "")
          return d.preventDefault(), d.stopPropagation(), o.search(d.key);
      case tt.Enter:
        if (d.preventDefault(), d.stopPropagation(), o.activeOptionIndex.value !== null) {
          let f = o.options.value[o.activeOptionIndex.value];
          o.select(f.dataRef.value);
        }
        o.mode.value === 0 && (o.closeListbox(), at(() => {
          var f;
          return (f = Be(o.buttonRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
        }));
        break;
      case bt(o.orientation.value, { vertical: tt.ArrowDown, horizontal: tt.ArrowRight }):
        return d.preventDefault(), d.stopPropagation(), o.goToOption(mt.Next);
      case bt(o.orientation.value, { vertical: tt.ArrowUp, horizontal: tt.ArrowLeft }):
        return d.preventDefault(), d.stopPropagation(), o.goToOption(mt.Previous);
      case tt.Home:
      case tt.PageUp:
        return d.preventDefault(), d.stopPropagation(), o.goToOption(mt.First);
      case tt.End:
      case tt.PageDown:
        return d.preventDefault(), d.stopPropagation(), o.goToOption(mt.Last);
      case tt.Escape:
        d.preventDefault(), d.stopPropagation(), o.closeListbox(), at(() => {
          var f;
          return (f = Be(o.buttonRef)) == null ? void 0 : f.focus({ preventScroll: !0 });
        });
        break;
      case tt.Tab:
        d.preventDefault(), d.stopPropagation();
        break;
      default:
        d.key.length === 1 && (o.search(d.key), i.value = setTimeout(() => o.clearSearch(), 350));
        break;
    }
  }
  let c = Wl(), u = E(() => c !== null ? (c.value & xt.Open) === xt.Open : o.listboxState.value === 0);
  return () => {
    var d, f;
    let h = { open: o.listboxState.value === 0 }, { ...x } = e, g = { "aria-activedescendant": o.activeOptionIndex.value === null || (d = o.options.value[o.activeOptionIndex.value]) == null ? void 0 : d.id, "aria-multiselectable": o.mode.value === 1 ? !0 : void 0, "aria-labelledby": (f = Be(o.buttonRef)) == null ? void 0 : f.id, "aria-orientation": o.orientation.value, id: l, onKeydown: s, role: "listbox", tabIndex: 0, ref: o.optionsRef };
    return Ct({ ourProps: g, theirProps: x, slot: h, attrs: t, slots: a, features: vr.RenderStrategy | vr.Static, visible: u.value, name: "ListboxOptions" });
  };
} }), e0 = xe({ name: "ListboxOption", props: { as: { type: [Object, String], default: "li" }, value: { type: [Object, String, Number, Boolean] }, disabled: { type: Boolean, default: !1 }, id: { type: String, default: null } }, setup(e, { slots: t, attrs: a, expose: n }) {
  var r;
  let l = (r = e.id) != null ? r : `headlessui-listbox-option-${Ma()}`, o = In("ListboxOption"), i = U(null);
  n({ el: i, $el: i });
  let s = E(() => o.activeOptionIndex.value !== null ? o.options.value[o.activeOptionIndex.value].id === l : !1), c = E(() => bt(o.mode.value, { 0: () => o.compare($t(o.value.value), $t(e.value)), 1: () => $t(o.value.value).some((m) => o.compare($t(m), $t(e.value))) })), u = E(() => bt(o.mode.value, { 1: () => {
    var m;
    let M = $t(o.value.value);
    return ((m = o.options.value.find(($) => M.some((C) => o.compare($t(C), $t($.dataRef.value))))) == null ? void 0 : m.id) === l;
  }, 0: () => c.value })), d = U1(i), f = E(() => ({ disabled: e.disabled, value: e.value, get textValue() {
    return d();
  }, domRef: i }));
  Le(() => o.registerOption(l, f)), ra(() => o.unregisterOption(l)), Le(() => {
    it([o.listboxState, c], () => {
      o.listboxState.value === 0 && c.value && bt(o.mode.value, { 1: () => {
        u.value && o.goToOption(mt.Specific, l);
      }, 0: () => {
        o.goToOption(mt.Specific, l);
      } });
    }, { immediate: !0 });
  }), ya(() => {
    o.listboxState.value === 0 && s.value && o.activationTrigger.value !== 0 && at(() => {
      var m, M;
      return (M = (m = Be(i)) == null ? void 0 : m.scrollIntoView) == null ? void 0 : M.call(m, { block: "nearest" });
    });
  });
  function h(m) {
    if (e.disabled)
      return m.preventDefault();
    o.select(e.value), o.mode.value === 0 && (o.closeListbox(), at(() => {
      var M;
      return (M = Be(o.buttonRef)) == null ? void 0 : M.focus({ preventScroll: !0 });
    }));
  }
  function x() {
    if (e.disabled)
      return o.goToOption(mt.Nothing);
    o.goToOption(mt.Specific, l);
  }
  let g = N1();
  function D(m) {
    g.update(m);
  }
  function k(m) {
    g.wasMoved(m) && (e.disabled || s.value || o.goToOption(mt.Specific, l, 0));
  }
  function b(m) {
    g.wasMoved(m) && (e.disabled || s.value && o.goToOption(mt.Nothing));
  }
  return () => {
    let { disabled: m } = e, M = { active: s.value, selected: c.value, disabled: m }, { value: $, disabled: C, ..._ } = e, W = { id: l, ref: i, role: "option", tabIndex: m === !0 ? void 0 : -1, "aria-disabled": m === !0 ? !0 : void 0, "aria-selected": c.value, disabled: void 0, onClick: h, onFocus: x, onPointerenter: D, onMouseenter: D, onPointermove: k, onMousemove: k, onPointerleave: b, onMouseleave: b };
    return Ct({ ourProps: W, theirProps: _, slot: M, attrs: a, slots: t, name: "ListboxOption" });
  };
} }), ks = Symbol("LabelContext");
function xs() {
  let e = zt(ks, null);
  if (e === null) {
    let t = new Error("You used a <Label /> component, but it is not inside a parent.");
    throw Error.captureStackTrace && Error.captureStackTrace(t, xs), t;
  }
  return e;
}
function t0({ slot: e = {}, name: t = "Label", props: a = {} } = {}) {
  let n = U([]);
  function r(l) {
    return n.value.push(l), () => {
      let o = n.value.indexOf(l);
      o !== -1 && n.value.splice(o, 1);
    };
  }
  return Jt(ks, { register: r, slot: e, name: t, props: a }), E(() => n.value.length > 0 ? n.value.join(" ") : void 0);
}
let a0 = xe({ name: "Label", props: { as: { type: [Object, String], default: "label" }, passive: { type: [Boolean], default: !1 }, id: { type: String, default: null } }, setup(e, { slots: t, attrs: a }) {
  var n;
  let r = (n = e.id) != null ? n : `headlessui-label-${Ma()}`, l = xs();
  return Le(() => ra(l.register(r))), () => {
    let { name: o = "Label", slot: i = {}, props: s = {} } = l, { passive: c, ...u } = e, d = { ...Object.entries(s).reduce((f, [h, x]) => Object.assign(f, { [h]: p(x) }), {}), id: r };
    return c && (delete d.onClick, delete d.htmlFor, delete u.onClick), Ct({ ourProps: d, theirProps: u, slot: i, attrs: a, slots: t, name: o });
  };
} }), _s = Symbol("GroupContext"), n0 = xe({ name: "SwitchGroup", props: { as: { type: [Object, String], default: "template" } }, setup(e, { slots: t, attrs: a }) {
  let n = U(null), r = t0({ name: "SwitchLabel", props: { htmlFor: E(() => {
    var o;
    return (o = n.value) == null ? void 0 : o.id;
  }), onClick(o) {
    n.value && (o.currentTarget.tagName === "LABEL" && o.preventDefault(), n.value.click(), n.value.focus({ preventScroll: !0 }));
  } } }), l = F1({ name: "SwitchDescription" });
  return Jt(_s, { switchRef: n, labelledby: r, describedby: l }), () => Ct({ theirProps: e, ourProps: {}, slot: {}, slots: t, attrs: a, name: "SwitchGroup" });
} }), r0 = xe({ name: "Switch", emits: { "update:modelValue": (e) => !0 }, props: { as: { type: [Object, String], default: "button" }, modelValue: { type: Boolean, default: void 0 }, defaultChecked: { type: Boolean, optional: !0 }, form: { type: String, optional: !0 }, name: { type: String, optional: !0 }, value: { type: String, optional: !0 }, id: { type: String, default: null } }, inheritAttrs: !1, setup(e, { emit: t, attrs: a, slots: n, expose: r }) {
  var l;
  let o = (l = e.id) != null ? l : `headlessui-switch-${Ma()}`, i = zt(_s, null), [s, c] = is(E(() => e.modelValue), (b) => t("update:modelValue", b), E(() => e.defaultChecked));
  function u() {
    c(!s.value);
  }
  let d = U(null), f = i === null ? d : i.switchRef, h = us(E(() => ({ as: e.as, type: a.type })), f);
  r({ el: f, $el: f });
  function x(b) {
    b.preventDefault(), u();
  }
  function g(b) {
    b.key === tt.Space ? (b.preventDefault(), u()) : b.key === tt.Enter && B1(b.currentTarget);
  }
  function D(b) {
    b.preventDefault();
  }
  let k = E(() => {
    var b, m;
    return (m = (b = Be(f)) == null ? void 0 : b.closest) == null ? void 0 : m.call(b, "form");
  });
  return Le(() => {
    it([k], () => {
      if (!k.value || e.defaultChecked === void 0)
        return;
      function b() {
        c(e.defaultChecked);
      }
      return k.value.addEventListener("reset", b), () => {
        var m;
        (m = k.value) == null || m.removeEventListener("reset", b);
      };
    }, { immediate: !0 });
  }), () => {
    let { name: b, value: m, form: M, ...$ } = e, C = { checked: s.value }, _ = { id: o, ref: f, role: "switch", type: h.value, tabIndex: 0, "aria-checked": s.value, "aria-labelledby": i == null ? void 0 : i.labelledby.value, "aria-describedby": i == null ? void 0 : i.describedby.value, onClick: x, onKeyup: g, onKeypress: D };
    return qt(Me, [b != null && s.value != null ? qt(vs, fs({ features: Ul.Hidden, as: "input", type: "checkbox", hidden: !0, readOnly: !0, checked: s.value, form: M, name: b, value: m })) : null, Ct({ ourProps: _, theirProps: { ...a, ...Or($, ["modelValue", "defaultChecked"]) }, slot: C, attrs: a, slots: n, name: "Switch" })]);
  };
} }), l0 = a0;
function o0(e) {
  let t = { called: !1 };
  return (...a) => {
    if (!t.called)
      return t.called = !0, e(...a);
  };
}
function Xr(e, ...t) {
  e && t.length > 0 && e.classList.add(...t);
}
function er(e, ...t) {
  e && t.length > 0 && e.classList.remove(...t);
}
var kl = ((e) => (e.Finished = "finished", e.Cancelled = "cancelled", e))(kl || {});
function i0(e, t) {
  let a = ql();
  if (!e)
    return a.dispose;
  let { transitionDuration: n, transitionDelay: r } = getComputedStyle(e), [l, o] = [n, r].map((i) => {
    let [s = 0] = i.split(",").filter(Boolean).map((c) => c.includes("ms") ? parseFloat(c) : parseFloat(c) * 1e3).sort((c, u) => u - c);
    return s;
  });
  return l !== 0 ? a.setTimeout(() => t("finished"), l + o) : t("finished"), a.add(() => t("cancelled")), a.dispose;
}
function ei(e, t, a, n, r, l) {
  let o = ql(), i = l !== void 0 ? o0(l) : () => {
  };
  return er(e, ...r), Xr(e, ...t, ...a), o.nextFrame(() => {
    er(e, ...a), Xr(e, ...n), o.add(i0(e, (s) => (er(e, ...n, ...t), Xr(e, ...r), i(s))));
  }), o.add(() => er(e, ...t, ...a, ...n, ...r)), o.add(() => i("cancelled")), o.dispose;
}
function Pa(e = "") {
  return e.split(/\s+/).filter((t) => t.length > 1);
}
let Kl = Symbol("TransitionContext");
var s0 = ((e) => (e.Visible = "visible", e.Hidden = "hidden", e))(s0 || {});
function u0() {
  return zt(Kl, null) !== null;
}
function c0() {
  let e = zt(Kl, null);
  if (e === null)
    throw new Error("A <TransitionChild /> is used but it is missing a parent <TransitionRoot />.");
  return e;
}
function d0() {
  let e = zt(Xl, null);
  if (e === null)
    throw new Error("A <TransitionChild /> is used but it is missing a parent <TransitionRoot />.");
  return e;
}
let Xl = Symbol("NestingContext");
function Pr(e) {
  return "children" in e ? Pr(e.children) : e.value.filter(({ state: t }) => t === "visible").length > 0;
}
function Ms(e) {
  let t = U([]), a = U(!1);
  Le(() => a.value = !0), ra(() => a.value = !1);
  function n(l, o = fa.Hidden) {
    let i = t.value.findIndex(({ id: s }) => s === l);
    i !== -1 && (bt(o, { [fa.Unmount]() {
      t.value.splice(i, 1);
    }, [fa.Hidden]() {
      t.value[i].state = "hidden";
    } }), !Pr(t) && a.value && (e == null || e()));
  }
  function r(l) {
    let o = t.value.find(({ id: i }) => i === l);
    return o ? o.state !== "visible" && (o.state = "visible") : t.value.push({ id: l, state: "visible" }), () => n(l, fa.Unmount);
  }
  return { children: t, register: r, unregister: n };
}
let As = vr.RenderStrategy, f0 = xe({ props: { as: { type: [Object, String], default: "div" }, show: { type: [Boolean], default: null }, unmount: { type: [Boolean], default: !0 }, appear: { type: [Boolean], default: !1 }, enter: { type: [String], default: "" }, enterFrom: { type: [String], default: "" }, enterTo: { type: [String], default: "" }, entered: { type: [String], default: "" }, leave: { type: [String], default: "" }, leaveFrom: { type: [String], default: "" }, leaveTo: { type: [String], default: "" } }, emits: { beforeEnter: () => !0, afterEnter: () => !0, beforeLeave: () => !0, afterLeave: () => !0 }, setup(e, { emit: t, attrs: a, slots: n, expose: r }) {
  let l = U(0);
  function o() {
    l.value |= xt.Opening, t("beforeEnter");
  }
  function i() {
    l.value &= ~xt.Opening, t("afterEnter");
  }
  function s() {
    l.value |= xt.Closing, t("beforeLeave");
  }
  function c() {
    l.value &= ~xt.Closing, t("afterLeave");
  }
  if (!u0() && I1())
    return () => qt(Ts, { ...e, onBeforeEnter: o, onAfterEnter: i, onBeforeLeave: s, onAfterLeave: c }, n);
  let u = U(null), d = E(() => e.unmount ? fa.Unmount : fa.Hidden);
  r({ el: u, $el: u });
  let { show: f, appear: h } = c0(), { register: x, unregister: g } = d0(), D = U(f.value ? "visible" : "hidden"), k = { value: !0 }, b = Ma(), m = { value: !1 }, M = Ms(() => {
    !m.value && D.value !== "hidden" && (D.value = "hidden", g(b), c());
  });
  Le(() => {
    let de = x(b);
    ra(de);
  }), ya(() => {
    if (d.value === fa.Hidden && b) {
      if (f.value && D.value !== "visible") {
        D.value = "visible";
        return;
      }
      bt(D.value, { hidden: () => g(b), visible: () => x(b) });
    }
  });
  let $ = Pa(e.enter), C = Pa(e.enterFrom), _ = Pa(e.enterTo), W = Pa(e.entered), F = Pa(e.leave), H = Pa(e.leaveFrom), ne = Pa(e.leaveTo);
  Le(() => {
    ya(() => {
      if (D.value === "visible") {
        let de = Be(u);
        if (de instanceof Comment && de.data === "")
          throw new Error("Did you forget to passthrough the `ref` to the actual DOM node?");
      }
    });
  });
  function se(de) {
    let I = k.value && !h.value, O = Be(u);
    !O || !(O instanceof HTMLElement) || I || (m.value = !0, f.value && o(), f.value || s(), de(f.value ? ei(O, $, C, _, W, (T) => {
      m.value = !1, T === kl.Finished && i();
    }) : ei(O, F, H, ne, W, (T) => {
      m.value = !1, T === kl.Finished && (Pr(M) || (D.value = "hidden", g(b), c()));
    })));
  }
  return Le(() => {
    it([f], (de, I, O) => {
      se(O), k.value = !1;
    }, { immediate: !0 });
  }), Jt(Xl, M), ps(E(() => bt(D.value, { visible: xt.Open, hidden: xt.Closed }) | l.value)), () => {
    let { appear: de, show: I, enter: O, enterFrom: T, enterTo: V, entered: re, leave: q, leaveFrom: le, leaveTo: ge, ...A } = e, S = { ref: u }, v = { ...A, ...h.value && f.value && Tr.isServer ? { class: we([a.class, A.class, ...$, ...C]) } : {} };
    return Ct({ theirProps: v, ourProps: S, slot: {}, slots: n, attrs: a, features: As, visible: D.value === "visible", name: "TransitionChild" });
  };
} }), v0 = f0, Ts = xe({ inheritAttrs: !1, props: { as: { type: [Object, String], default: "div" }, show: { type: [Boolean], default: null }, unmount: { type: [Boolean], default: !0 }, appear: { type: [Boolean], default: !1 }, enter: { type: [String], default: "" }, enterFrom: { type: [String], default: "" }, enterTo: { type: [String], default: "" }, entered: { type: [String], default: "" }, leave: { type: [String], default: "" }, leaveFrom: { type: [String], default: "" }, leaveTo: { type: [String], default: "" } }, emits: { beforeEnter: () => !0, afterEnter: () => !0, beforeLeave: () => !0, afterLeave: () => !0 }, setup(e, { emit: t, attrs: a, slots: n }) {
  let r = Wl(), l = E(() => e.show === null && r !== null ? (r.value & xt.Open) === xt.Open : e.show);
  ya(() => {
    if (![!0, !1].includes(l.value))
      throw new Error('A <Transition /> is used but it is missing a `:show="true | false"` prop.');
  });
  let o = U(l.value ? "visible" : "hidden"), i = Ms(() => {
    o.value = "hidden";
  }), s = U(!0), c = { show: l, appear: E(() => e.appear || !s.value) };
  return Le(() => {
    ya(() => {
      s.value = !1, l.value ? o.value = "visible" : Pr(i) || (o.value = "hidden");
    });
  }), Jt(Xl, i), Jt(Kl, c), () => {
    let u = Or(e, ["show", "appear", "unmount", "onBeforeEnter", "onBeforeLeave", "onAfterEnter", "onAfterLeave"]), d = { unmount: e.unmount };
    return Ct({ ourProps: { ...d, as: "template" }, theirProps: {}, slot: {}, slots: { ...n, default: () => [qt(v0, { onBeforeEnter: () => t("beforeEnter"), onAfterEnter: () => t("afterEnter"), onBeforeLeave: () => t("beforeLeave"), onAfterLeave: () => t("afterLeave"), ...a, ...d, ...u }, n.default)] }, attrs: {}, features: As, visible: o.value === "visible", name: "Transition" });
  };
} });
const m0 = /* @__PURE__ */ ce("div", {
  "aria-hidden": "true",
  class: "relative w-8 px-1 pointer-events-none h-5 rounded-full border-2 border-solid border-ui-gray-800 bg-white transition-colors duration-200 ease-in-out group-focus/toggle:shadow-glow ui-checked:bg-pivot-teal-600 ui-checked:border-pivot-teal-600 group-disabled/toggle:border-ui-gray-300 group-disabled/toggle:bg-ui-gray-300"
}, [
  /* @__PURE__ */ ce("div", {
    "aria-hidden": "true",
    class: "absolute top-[3px] pointer-events-none h-[10px] w-[10px] rounded-full shadow bg-ui-gray-800 transition duration-200 ease-in-out group-disabled/toggle:bg-white left-1 ui-checked:translate-x-full ui-checked:bg-white"
  })
], -1), p0 = { class: "font-medium" }, P3 = /* @__PURE__ */ xe({
  __name: "UISwitch",
  props: {
    name: {},
    disabled: { type: Boolean },
    modelValue: { type: Boolean }
  },
  setup(e) {
    const t = e, { value: a } = Ya(Ke(t, "name"), void 0, {
      syncVModel: !0
    });
    return (n, r) => (P(), Ae(p(n0), {
      as: "div",
      class: "tw-reset flex items-center"
    }, {
      default: ye(() => [
        Fe(p(r0), Ne(n.$attrs, {
          modelValue: p(a),
          "onUpdate:modelValue": r[0] || (r[0] = (l) => Gt(a) ? a.value = l : null),
          disabled: n.disabled,
          class: "group/toggle p-0 py-1 border-none flex-shrink-0 rounded-full focus:outline-none"
        }), {
          default: ye(() => [
            m0
          ]),
          _: 1
        }, 16, ["modelValue", "disabled"]),
        Fe(p(l0), {
          as: "span",
          class: "ml-2 cursor-pointer"
        }, {
          default: ye(() => [
            ce("span", p0, [
              ie(n.$slots, "default")
            ])
          ]),
          _: 3
        })
      ]),
      _: 3
    }));
  }
}), S3 = /* @__PURE__ */ xe({
  __name: "UINumberInput",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {}
  },
  setup(e) {
    const t = e, { value: a, errorMessage: n, handleBlur: r } = Ya(
      Ke(t, "name"),
      Ke(t, "rules"),
      {
        label: Ke(t, "label"),
        syncVModel: !0
      }
    );
    return (l, o) => ma((P(), j("input", {
      ref: "input",
      "onUpdate:modelValue": o[0] || (o[0] = (i) => Gt(a) ? a.value = i : null),
      type: "number",
      onWheel: o[1] || (o[1] = Ra(() => {
      }, ["prevent"])),
      onBlur: o[2] || (o[2] = //@ts-ignore
      (...i) => p(r) && p(r)(...i)),
      class: we(["hide-stepper", [
        "tw-reset w-full px-2 h-8 border rounded border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
        {
          "border-warning-orange-600 text-warning-orange-600 italic": p(n)
        }
      ]])
    }, null, 34)), [
      [Ai, p(a)]
    ]);
  }
}), h0 = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, y0 = {
  start: "end",
  end: "start"
};
function Os(e, t) {
  return typeof e == "function" ? e(t) : e;
}
function Sn(e) {
  return e.split("-")[0];
}
function Ql(e) {
  return e.split("-")[1];
}
function g0(e) {
  return e === "x" ? "y" : "x";
}
function Ps(e) {
  return e === "y" ? "height" : "width";
}
function Ss(e) {
  return ["top", "bottom"].includes(Sn(e)) ? "y" : "x";
}
function Cs(e) {
  return g0(Ss(e));
}
function b0(e, t, a) {
  a === void 0 && (a = !1);
  const n = Ql(e), r = Cs(e), l = Ps(r);
  let o = r === "x" ? n === (a ? "end" : "start") ? "right" : "left" : n === "start" ? "bottom" : "top";
  return t.reference[l] > t.floating[l] && (o = mr(o)), [o, mr(o)];
}
function w0(e) {
  const t = mr(e);
  return [xl(e), t, xl(t)];
}
function xl(e) {
  return e.replace(/start|end/g, (t) => y0[t]);
}
function k0(e, t, a) {
  const n = ["left", "right"], r = ["right", "left"], l = ["top", "bottom"], o = ["bottom", "top"];
  switch (e) {
    case "top":
    case "bottom":
      return a ? t ? r : n : t ? n : r;
    case "left":
    case "right":
      return t ? l : o;
    default:
      return [];
  }
}
function x0(e, t, a, n) {
  const r = Ql(e);
  let l = k0(Sn(e), a === "start", n);
  return r && (l = l.map((o) => o + "-" + r), t && (l = l.concat(l.map(xl)))), l;
}
function mr(e) {
  return e.replace(/left|right|bottom|top/g, (t) => h0[t]);
}
function _0(e) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...e
  };
}
function M0(e) {
  return typeof e != "number" ? _0(e) : {
    top: e,
    right: e,
    bottom: e,
    left: e
  };
}
function pr(e) {
  return {
    ...e,
    top: e.y,
    left: e.x,
    right: e.x + e.width,
    bottom: e.y + e.height
  };
}
function ti(e, t, a) {
  let {
    reference: n,
    floating: r
  } = e;
  const l = Ss(t), o = Cs(t), i = Ps(o), s = Sn(t), c = l === "y", u = n.x + n.width / 2 - r.width / 2, d = n.y + n.height / 2 - r.height / 2, f = n[i] / 2 - r[i] / 2;
  let h;
  switch (s) {
    case "top":
      h = {
        x: u,
        y: n.y - r.height
      };
      break;
    case "bottom":
      h = {
        x: u,
        y: n.y + n.height
      };
      break;
    case "right":
      h = {
        x: n.x + n.width,
        y: d
      };
      break;
    case "left":
      h = {
        x: n.x - r.width,
        y: d
      };
      break;
    default:
      h = {
        x: n.x,
        y: n.y
      };
  }
  switch (Ql(t)) {
    case "start":
      h[o] -= f * (a && c ? -1 : 1);
      break;
    case "end":
      h[o] += f * (a && c ? -1 : 1);
      break;
  }
  return h;
}
const A0 = async (e, t, a) => {
  const {
    placement: n = "bottom",
    strategy: r = "absolute",
    middleware: l = [],
    platform: o
  } = a, i = l.filter(Boolean), s = await (o.isRTL == null ? void 0 : o.isRTL(t));
  let c = await o.getElementRects({
    reference: e,
    floating: t,
    strategy: r
  }), {
    x: u,
    y: d
  } = ti(c, n, s), f = n, h = {}, x = 0;
  for (let g = 0; g < i.length; g++) {
    const {
      name: D,
      fn: k
    } = i[g], {
      x: b,
      y: m,
      data: M,
      reset: $
    } = await k({
      x: u,
      y: d,
      initialPlacement: n,
      placement: f,
      strategy: r,
      middlewareData: h,
      rects: c,
      platform: o,
      elements: {
        reference: e,
        floating: t
      }
    });
    if (u = b ?? u, d = m ?? d, h = {
      ...h,
      [D]: {
        ...h[D],
        ...M
      }
    }, $ && x <= 50) {
      x++, typeof $ == "object" && ($.placement && (f = $.placement), $.rects && (c = $.rects === !0 ? await o.getElementRects({
        reference: e,
        floating: t,
        strategy: r
      }) : $.rects), {
        x: u,
        y: d
      } = ti(c, f, s)), g = -1;
      continue;
    }
  }
  return {
    x: u,
    y: d,
    placement: f,
    strategy: r,
    middlewareData: h
  };
};
async function T0(e, t) {
  var a;
  t === void 0 && (t = {});
  const {
    x: n,
    y: r,
    platform: l,
    rects: o,
    elements: i,
    strategy: s
  } = e, {
    boundary: c = "clippingAncestors",
    rootBoundary: u = "viewport",
    elementContext: d = "floating",
    altBoundary: f = !1,
    padding: h = 0
  } = Os(t, e), x = M0(h), D = i[f ? d === "floating" ? "reference" : "floating" : d], k = pr(await l.getClippingRect({
    element: (a = await (l.isElement == null ? void 0 : l.isElement(D))) == null || a ? D : D.contextElement || await (l.getDocumentElement == null ? void 0 : l.getDocumentElement(i.floating)),
    boundary: c,
    rootBoundary: u,
    strategy: s
  })), b = d === "floating" ? {
    ...o.floating,
    x: n,
    y: r
  } : o.reference, m = await (l.getOffsetParent == null ? void 0 : l.getOffsetParent(i.floating)), M = await (l.isElement == null ? void 0 : l.isElement(m)) ? await (l.getScale == null ? void 0 : l.getScale(m)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, $ = pr(l.convertOffsetParentRelativeRectToViewportRelativeRect ? await l.convertOffsetParentRelativeRectToViewportRelativeRect({
    rect: b,
    offsetParent: m,
    strategy: s
  }) : b);
  return {
    top: (k.top - $.top + x.top) / M.y,
    bottom: ($.bottom - k.bottom + x.bottom) / M.y,
    left: (k.left - $.left + x.left) / M.x,
    right: ($.right - k.right + x.right) / M.x
  };
}
const O0 = function(e) {
  return e === void 0 && (e = {}), {
    name: "flip",
    options: e,
    async fn(t) {
      var a, n;
      const {
        placement: r,
        middlewareData: l,
        rects: o,
        initialPlacement: i,
        platform: s,
        elements: c
      } = t, {
        mainAxis: u = !0,
        crossAxis: d = !0,
        fallbackPlacements: f,
        fallbackStrategy: h = "bestFit",
        fallbackAxisSideDirection: x = "none",
        flipAlignment: g = !0,
        ...D
      } = Os(e, t);
      if ((a = l.arrow) != null && a.alignmentOffset)
        return {};
      const k = Sn(r), b = Sn(i) === i, m = await (s.isRTL == null ? void 0 : s.isRTL(c.floating)), M = f || (b || !g ? [mr(i)] : w0(i));
      !f && x !== "none" && M.push(...x0(i, g, x, m));
      const $ = [i, ...M], C = await T0(t, D), _ = [];
      let W = ((n = l.flip) == null ? void 0 : n.overflows) || [];
      if (u && _.push(C[k]), d) {
        const se = b0(r, o, m);
        _.push(C[se[0]], C[se[1]]);
      }
      if (W = [...W, {
        placement: r,
        overflows: _
      }], !_.every((se) => se <= 0)) {
        var F, H;
        const se = (((F = l.flip) == null ? void 0 : F.index) || 0) + 1, de = $[se];
        if (de)
          return {
            data: {
              index: se,
              overflows: W
            },
            reset: {
              placement: de
            }
          };
        let I = (H = W.filter((O) => O.overflows[0] <= 0).sort((O, T) => O.overflows[1] - T.overflows[1])[0]) == null ? void 0 : H.placement;
        if (!I)
          switch (h) {
            case "bestFit": {
              var ne;
              const O = (ne = W.map((T) => [T.placement, T.overflows.filter((V) => V > 0).reduce((V, re) => V + re, 0)]).sort((T, V) => T[1] - V[1])[0]) == null ? void 0 : ne[0];
              O && (I = O);
              break;
            }
            case "initialPlacement":
              I = i;
              break;
          }
        if (r !== I)
          return {
            reset: {
              placement: I
            }
          };
      }
      return {};
    }
  };
}, _l = Math.min, Ja = Math.max, hr = Math.round, tr = Math.floor, ka = (e) => ({
  x: e,
  y: e
});
function xa(e) {
  return Ds(e) ? (e.nodeName || "").toLowerCase() : "#document";
}
function Mt(e) {
  var t;
  return (e == null || (t = e.ownerDocument) == null ? void 0 : t.defaultView) || window;
}
function oa(e) {
  var t;
  return (t = (Ds(e) ? e.ownerDocument : e.document) || window.document) == null ? void 0 : t.documentElement;
}
function Ds(e) {
  return e instanceof Node || e instanceof Mt(e).Node;
}
function aa(e) {
  return e instanceof Element || e instanceof Mt(e).Element;
}
function Kt(e) {
  return e instanceof HTMLElement || e instanceof Mt(e).HTMLElement;
}
function ai(e) {
  return typeof ShadowRoot > "u" ? !1 : e instanceof ShadowRoot || e instanceof Mt(e).ShadowRoot;
}
function Hn(e) {
  const {
    overflow: t,
    overflowX: a,
    overflowY: n,
    display: r
  } = St(e);
  return /auto|scroll|overlay|hidden|clip/.test(t + n + a) && !["inline", "contents"].includes(r);
}
function P0(e) {
  return ["table", "td", "th"].includes(xa(e));
}
function Gl(e) {
  const t = Jl(), a = St(e);
  return a.transform !== "none" || a.perspective !== "none" || (a.containerType ? a.containerType !== "normal" : !1) || !t && (a.backdropFilter ? a.backdropFilter !== "none" : !1) || !t && (a.filter ? a.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((n) => (a.willChange || "").includes(n)) || ["paint", "layout", "strict", "content"].some((n) => (a.contain || "").includes(n));
}
function S0(e) {
  let t = tn(e);
  for (; Kt(t) && !Sr(t); ) {
    if (Gl(t))
      return t;
    t = tn(t);
  }
  return null;
}
function Jl() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function Sr(e) {
  return ["html", "body", "#document"].includes(xa(e));
}
function St(e) {
  return Mt(e).getComputedStyle(e);
}
function Cr(e) {
  return aa(e) ? {
    scrollLeft: e.scrollLeft,
    scrollTop: e.scrollTop
  } : {
    scrollLeft: e.pageXOffset,
    scrollTop: e.pageYOffset
  };
}
function tn(e) {
  if (xa(e) === "html")
    return e;
  const t = (
    // Step into the shadow DOM of the parent of a slotted node.
    e.assignedSlot || // DOM Element detected.
    e.parentNode || // ShadowRoot detected.
    ai(e) && e.host || // Fallback.
    oa(e)
  );
  return ai(t) ? t.host : t;
}
function $s(e) {
  const t = tn(e);
  return Sr(t) ? e.ownerDocument ? e.ownerDocument.body : e.body : Kt(t) && Hn(t) ? t : $s(t);
}
function Cn(e, t, a) {
  var n;
  t === void 0 && (t = []), a === void 0 && (a = !0);
  const r = $s(e), l = r === ((n = e.ownerDocument) == null ? void 0 : n.body), o = Mt(r);
  return l ? t.concat(o, o.visualViewport || [], Hn(r) ? r : [], o.frameElement && a ? Cn(o.frameElement) : []) : t.concat(r, Cn(r, [], a));
}
function Ls(e) {
  const t = St(e);
  let a = parseFloat(t.width) || 0, n = parseFloat(t.height) || 0;
  const r = Kt(e), l = r ? e.offsetWidth : a, o = r ? e.offsetHeight : n, i = hr(a) !== l || hr(n) !== o;
  return i && (a = l, n = o), {
    width: a,
    height: n,
    $: i
  };
}
function Zl(e) {
  return aa(e) ? e : e.contextElement;
}
function Za(e) {
  const t = Zl(e);
  if (!Kt(t))
    return ka(1);
  const a = t.getBoundingClientRect(), {
    width: n,
    height: r,
    $: l
  } = Ls(t);
  let o = (l ? hr(a.width) : a.width) / n, i = (l ? hr(a.height) : a.height) / r;
  return (!o || !Number.isFinite(o)) && (o = 1), (!i || !Number.isFinite(i)) && (i = 1), {
    x: o,
    y: i
  };
}
const C0 = /* @__PURE__ */ ka(0);
function Es(e) {
  const t = Mt(e);
  return !Jl() || !t.visualViewport ? C0 : {
    x: t.visualViewport.offsetLeft,
    y: t.visualViewport.offsetTop
  };
}
function D0(e, t, a) {
  return t === void 0 && (t = !1), !a || t && a !== Mt(e) ? !1 : t;
}
function za(e, t, a, n) {
  t === void 0 && (t = !1), a === void 0 && (a = !1);
  const r = e.getBoundingClientRect(), l = Zl(e);
  let o = ka(1);
  t && (n ? aa(n) && (o = Za(n)) : o = Za(e));
  const i = D0(l, a, n) ? Es(l) : ka(0);
  let s = (r.left + i.x) / o.x, c = (r.top + i.y) / o.y, u = r.width / o.x, d = r.height / o.y;
  if (l) {
    const f = Mt(l), h = n && aa(n) ? Mt(n) : n;
    let x = f, g = x.frameElement;
    for (; g && n && h !== x; ) {
      const D = Za(g), k = g.getBoundingClientRect(), b = St(g), m = k.left + (g.clientLeft + parseFloat(b.paddingLeft)) * D.x, M = k.top + (g.clientTop + parseFloat(b.paddingTop)) * D.y;
      s *= D.x, c *= D.y, u *= D.x, d *= D.y, s += m, c += M, x = Mt(g), g = x.frameElement;
    }
  }
  return pr({
    width: u,
    height: d,
    x: s,
    y: c
  });
}
const $0 = [":popover-open", ":modal"];
function Ns(e) {
  return $0.some((t) => {
    try {
      return e.matches(t);
    } catch {
      return !1;
    }
  });
}
function L0(e) {
  let {
    elements: t,
    rect: a,
    offsetParent: n,
    strategy: r
  } = e;
  const l = r === "fixed", o = oa(n), i = t ? Ns(t.floating) : !1;
  if (n === o || i && l)
    return a;
  let s = {
    scrollLeft: 0,
    scrollTop: 0
  }, c = ka(1);
  const u = ka(0), d = Kt(n);
  if ((d || !d && !l) && ((xa(n) !== "body" || Hn(o)) && (s = Cr(n)), Kt(n))) {
    const f = za(n);
    c = Za(n), u.x = f.x + n.clientLeft, u.y = f.y + n.clientTop;
  }
  return {
    width: a.width * c.x,
    height: a.height * c.y,
    x: a.x * c.x - s.scrollLeft * c.x + u.x,
    y: a.y * c.y - s.scrollTop * c.y + u.y
  };
}
function E0(e) {
  return Array.from(e.getClientRects());
}
function Rs(e) {
  return za(oa(e)).left + Cr(e).scrollLeft;
}
function N0(e) {
  const t = oa(e), a = Cr(e), n = e.ownerDocument.body, r = Ja(t.scrollWidth, t.clientWidth, n.scrollWidth, n.clientWidth), l = Ja(t.scrollHeight, t.clientHeight, n.scrollHeight, n.clientHeight);
  let o = -a.scrollLeft + Rs(e);
  const i = -a.scrollTop;
  return St(n).direction === "rtl" && (o += Ja(t.clientWidth, n.clientWidth) - r), {
    width: r,
    height: l,
    x: o,
    y: i
  };
}
function R0(e, t) {
  const a = Mt(e), n = oa(e), r = a.visualViewport;
  let l = n.clientWidth, o = n.clientHeight, i = 0, s = 0;
  if (r) {
    l = r.width, o = r.height;
    const c = Jl();
    (!c || c && t === "fixed") && (i = r.offsetLeft, s = r.offsetTop);
  }
  return {
    width: l,
    height: o,
    x: i,
    y: s
  };
}
function I0(e, t) {
  const a = za(e, !0, t === "fixed"), n = a.top + e.clientTop, r = a.left + e.clientLeft, l = Kt(e) ? Za(e) : ka(1), o = e.clientWidth * l.x, i = e.clientHeight * l.y, s = r * l.x, c = n * l.y;
  return {
    width: o,
    height: i,
    x: s,
    y: c
  };
}
function ni(e, t, a) {
  let n;
  if (t === "viewport")
    n = R0(e, a);
  else if (t === "document")
    n = N0(oa(e));
  else if (aa(t))
    n = I0(t, a);
  else {
    const r = Es(e);
    n = {
      ...t,
      x: t.x - r.x,
      y: t.y - r.y
    };
  }
  return pr(n);
}
function Is(e, t) {
  const a = tn(e);
  return a === t || !aa(a) || Sr(a) ? !1 : St(a).position === "fixed" || Is(a, t);
}
function H0(e, t) {
  const a = t.get(e);
  if (a)
    return a;
  let n = Cn(e, [], !1).filter((i) => aa(i) && xa(i) !== "body"), r = null;
  const l = St(e).position === "fixed";
  let o = l ? tn(e) : e;
  for (; aa(o) && !Sr(o); ) {
    const i = St(o), s = Gl(o);
    !s && i.position === "fixed" && (r = null), (l ? !s && !r : !s && i.position === "static" && !!r && ["absolute", "fixed"].includes(r.position) || Hn(o) && !s && Is(e, o)) ? n = n.filter((u) => u !== o) : r = i, o = tn(o);
  }
  return t.set(e, n), n;
}
function z0(e) {
  let {
    element: t,
    boundary: a,
    rootBoundary: n,
    strategy: r
  } = e;
  const o = [...a === "clippingAncestors" ? H0(t, this._c) : [].concat(a), n], i = o[0], s = o.reduce((c, u) => {
    const d = ni(t, u, r);
    return c.top = Ja(d.top, c.top), c.right = _l(d.right, c.right), c.bottom = _l(d.bottom, c.bottom), c.left = Ja(d.left, c.left), c;
  }, ni(t, i, r));
  return {
    width: s.right - s.left,
    height: s.bottom - s.top,
    x: s.left,
    y: s.top
  };
}
function V0(e) {
  const {
    width: t,
    height: a
  } = Ls(e);
  return {
    width: t,
    height: a
  };
}
function B0(e, t, a) {
  const n = Kt(t), r = oa(t), l = a === "fixed", o = za(e, !0, l, t);
  let i = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const s = ka(0);
  if (n || !n && !l)
    if ((xa(t) !== "body" || Hn(r)) && (i = Cr(t)), n) {
      const d = za(t, !0, l, t);
      s.x = d.x + t.clientLeft, s.y = d.y + t.clientTop;
    } else
      r && (s.x = Rs(r));
  const c = o.left + i.scrollLeft - s.x, u = o.top + i.scrollTop - s.y;
  return {
    x: c,
    y: u,
    width: o.width,
    height: o.height
  };
}
function ri(e, t) {
  return !Kt(e) || St(e).position === "fixed" ? null : t ? t(e) : e.offsetParent;
}
function Hs(e, t) {
  const a = Mt(e);
  if (!Kt(e) || Ns(e))
    return a;
  let n = ri(e, t);
  for (; n && P0(n) && St(n).position === "static"; )
    n = ri(n, t);
  return n && (xa(n) === "html" || xa(n) === "body" && St(n).position === "static" && !Gl(n)) ? a : n || S0(e) || a;
}
const Y0 = async function(e) {
  const t = this.getOffsetParent || Hs, a = this.getDimensions;
  return {
    reference: B0(e.reference, await t(e.floating), e.strategy),
    floating: {
      x: 0,
      y: 0,
      ...await a(e.floating)
    }
  };
};
function F0(e) {
  return St(e).direction === "rtl";
}
const j0 = {
  convertOffsetParentRelativeRectToViewportRelativeRect: L0,
  getDocumentElement: oa,
  getClippingRect: z0,
  getOffsetParent: Hs,
  getElementRects: Y0,
  getClientRects: E0,
  getDimensions: V0,
  getScale: Za,
  isElement: aa,
  isRTL: F0
};
function U0(e, t) {
  let a = null, n;
  const r = oa(e);
  function l() {
    var i;
    clearTimeout(n), (i = a) == null || i.disconnect(), a = null;
  }
  function o(i, s) {
    i === void 0 && (i = !1), s === void 0 && (s = 1), l();
    const {
      left: c,
      top: u,
      width: d,
      height: f
    } = e.getBoundingClientRect();
    if (i || t(), !d || !f)
      return;
    const h = tr(u), x = tr(r.clientWidth - (c + d)), g = tr(r.clientHeight - (u + f)), D = tr(c), b = {
      rootMargin: -h + "px " + -x + "px " + -g + "px " + -D + "px",
      threshold: Ja(0, _l(1, s)) || 1
    };
    let m = !0;
    function M($) {
      const C = $[0].intersectionRatio;
      if (C !== s) {
        if (!m)
          return o();
        C ? o(!1, C) : n = setTimeout(() => {
          o(!1, 1e-7);
        }, 100);
      }
      m = !1;
    }
    try {
      a = new IntersectionObserver(M, {
        ...b,
        // Handle <iframe>s
        root: r.ownerDocument
      });
    } catch {
      a = new IntersectionObserver(M, b);
    }
    a.observe(e);
  }
  return o(!0), l;
}
function W0(e, t, a, n) {
  n === void 0 && (n = {});
  const {
    ancestorScroll: r = !0,
    ancestorResize: l = !0,
    elementResize: o = typeof ResizeObserver == "function",
    layoutShift: i = typeof IntersectionObserver == "function",
    animationFrame: s = !1
  } = n, c = Zl(e), u = r || l ? [...c ? Cn(c) : [], ...Cn(t)] : [];
  u.forEach((k) => {
    r && k.addEventListener("scroll", a, {
      passive: !0
    }), l && k.addEventListener("resize", a);
  });
  const d = c && i ? U0(c, a) : null;
  let f = -1, h = null;
  o && (h = new ResizeObserver((k) => {
    let [b] = k;
    b && b.target === c && h && (h.unobserve(t), cancelAnimationFrame(f), f = requestAnimationFrame(() => {
      var m;
      (m = h) == null || m.observe(t);
    })), a();
  }), c && !s && h.observe(c), h.observe(t));
  let x, g = s ? za(e) : null;
  s && D();
  function D() {
    const k = za(e);
    g && (k.x !== g.x || k.y !== g.y || k.width !== g.width || k.height !== g.height) && a(), g = k, x = requestAnimationFrame(D);
  }
  return a(), () => {
    var k;
    u.forEach((b) => {
      r && b.removeEventListener("scroll", a), l && b.removeEventListener("resize", a);
    }), d == null || d(), (k = h) == null || k.disconnect(), h = null, s && cancelAnimationFrame(x);
  };
}
const q0 = O0, K0 = (e, t, a) => {
  const n = /* @__PURE__ */ new Map(), r = {
    platform: j0,
    ...a
  }, l = {
    ...r.platform,
    _c: n
  };
  return A0(e, t, {
    ...r,
    platform: l
  });
}, X0 = { class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2" }, C3 = /* @__PURE__ */ xe({
  __name: "UISelectInput",
  props: {
    name: {},
    modelValue: {},
    label: {},
    rules: {},
    options: {},
    placeholder: { default: "Choose One" },
    multiple: { type: Boolean, default: !1 }
  },
  setup(e) {
    const t = e, { value: a, errorMessage: n, handleBlur: r } = Ya(
      Ke(t, "name"),
      Ke(t, "rules"),
      {
        label: Ke(t, "label"),
        syncVModel: !0
      }
    ), l = E(() => {
      const s = Array.isArray(a.value) ? a.value : [a.value];
      return t.options.filter((c) => s.includes(c.value));
    }), o = U(), i = U();
    return $u((s) => {
      const c = o.value, u = i.value;
      !c || !u || s(
        W0(c, u, async () => {
          const { x: d, y: f } = await K0(c, u, {
            placement: "bottom-start",
            middleware: [q0()]
          });
          u.style.left = `${d}px`, u.style.top = `${f}px`, u.style.width = `${c.clientWidth}px`, c.closest("[role=dialog]") && (u.style.zIndex = "10000");
        })
      );
    }), (s, c) => (P(), j("div", {
      ref_key: "anchorRef",
      ref: o
    }, [
      Fe(p(G1), {
        as: "div",
        modelValue: p(a),
        "onUpdate:modelValue": c[0] || (c[0] = (u) => Gt(a) ? a.value = u : null),
        multiple: s.multiple
      }, {
        default: ye(() => [
          Fe(p(J1), {
            class: we([
              "tw-reset relative text-left cursor-default w-full px-2 h-8 border rounded-sm border-ui-gray-400 focus:shadow-glow focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100",
              {
                "border-warning-orange-600 text-warning-orange-600 italic": p(n)
              }
            ]),
            onBlur: p(r)
          }, {
            default: ye(({ open: u }) => [
              l.value.length > 0 ? (P(), j(Me, { key: 0 }, [
                st(Ye(l.value.map((d) => d.label).join(", ")), 1)
              ], 64)) : (P(), j(Me, { key: 1 }, [
                st(Ye(s.placeholder), 1)
              ], 64)),
              ce("span", X0, [
                Fe(p(Fa), {
                  class: "h-4 w-4 text-gray-800",
                  "aria-hidden": "true",
                  icon: u ? "caret-up" : "caret-down"
                }, null, 8, ["icon"])
              ])
            ]),
            _: 1
          }, 8, ["class", "onBlur"]),
          (P(), Ae(Ti, { to: "body" }, [
            ce("div", {
              ref_key: "menuRef",
              ref: i,
              class: "absolute z-10 [[role=dialog]_&]:z-[10000]"
            }, [
              Fe(Va, {
                "leave-active-class": "transition ease-in duration-100",
                "leave-from-class": "opacity-100",
                "leave-to-class": "opacity-0"
              }, {
                default: ye(() => [
                  Fe(p(Z1), { class: "max-h-60 overflow-auto rounded-sm bg-white shadow-lg border border-ui-gray-400 border-t-0 focus:outline-none" }, {
                    default: ye(() => [
                      (P(!0), j(Me, null, Re(s.options, (u) => (P(), Ae(p(e0), {
                        key: u.value,
                        value: u.value,
                        as: "li",
                        class: "ui-active:bg-pivot-teal-600 ui-active:text-white text-ui-gray-800 relative cursor-default select-none py-1 pl-2 pr-9 ui-selected:font-semibold font-normal"
                      }, {
                        default: ye(() => [
                          st(Ye(u.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ], 512)
          ]))
        ]),
        _: 1
      }, 8, ["modelValue", "multiple"])
    ], 512));
  }
});
function he(e) {
  const t = Object.prototype.toString.call(e);
  return e instanceof Date || typeof e == "object" && t === "[object Date]" ? new e.constructor(+e) : typeof e == "number" || t === "[object Number]" || typeof e == "string" || t === "[object String]" ? new Date(e) : /* @__PURE__ */ new Date(NaN);
}
function Ce(e, t) {
  return e instanceof Date ? new e.constructor(t) : new Date(t);
}
function Et(e, t) {
  const a = he(e);
  return isNaN(t) ? Ce(e, NaN) : (t && a.setDate(a.getDate() + t), a);
}
function It(e, t) {
  const a = he(e);
  if (isNaN(t))
    return Ce(e, NaN);
  if (!t)
    return a;
  const n = a.getDate(), r = Ce(e, a.getTime());
  r.setMonth(a.getMonth() + t + 1, 0);
  const l = r.getDate();
  return n >= l ? r : (a.setFullYear(
    r.getFullYear(),
    r.getMonth(),
    n
  ), a);
}
function zs(e, t) {
  const {
    years: a = 0,
    months: n = 0,
    weeks: r = 0,
    days: l = 0,
    hours: o = 0,
    minutes: i = 0,
    seconds: s = 0
  } = t, c = he(e), u = n || a ? It(c, n + a * 12) : c, d = l || r ? Et(u, l + r * 7) : u, f = i + o * 60, x = (s + f * 60) * 1e3;
  return Ce(e, d.getTime() + x);
}
function Q0(e, t) {
  const a = +he(e);
  return Ce(e, a + t);
}
const Vs = 6048e5, G0 = 864e5, J0 = 6e4, Bs = 36e5, Z0 = 1e3;
function ev(e, t) {
  return Q0(e, t * Bs);
}
let tv = {};
function ja() {
  return tv;
}
function Ht(e, t) {
  var i, s, c, u;
  const a = ja(), n = (t == null ? void 0 : t.weekStartsOn) ?? ((s = (i = t == null ? void 0 : t.locale) == null ? void 0 : i.options) == null ? void 0 : s.weekStartsOn) ?? a.weekStartsOn ?? ((u = (c = a.locale) == null ? void 0 : c.options) == null ? void 0 : u.weekStartsOn) ?? 0, r = he(e), l = r.getDay(), o = (l < n ? 7 : 0) + l - n;
  return r.setDate(r.getDate() - o), r.setHours(0, 0, 0, 0), r;
}
function an(e) {
  return Ht(e, { weekStartsOn: 1 });
}
function Ys(e) {
  const t = he(e), a = t.getFullYear(), n = Ce(e, 0);
  n.setFullYear(a + 1, 0, 4), n.setHours(0, 0, 0, 0);
  const r = an(n), l = Ce(e, 0);
  l.setFullYear(a, 0, 4), l.setHours(0, 0, 0, 0);
  const o = an(l);
  return t.getTime() >= r.getTime() ? a + 1 : t.getTime() >= o.getTime() ? a : a - 1;
}
function li(e) {
  const t = he(e);
  return t.setHours(0, 0, 0, 0), t;
}
function yr(e) {
  const t = he(e), a = new Date(
    Date.UTC(
      t.getFullYear(),
      t.getMonth(),
      t.getDate(),
      t.getHours(),
      t.getMinutes(),
      t.getSeconds(),
      t.getMilliseconds()
    )
  );
  return a.setUTCFullYear(t.getFullYear()), +e - +a;
}
function Fs(e, t) {
  const a = li(e), n = li(t), r = +a - yr(a), l = +n - yr(n);
  return Math.round((r - l) / G0);
}
function av(e) {
  const t = Ys(e), a = Ce(e, 0);
  return a.setFullYear(t, 0, 4), a.setHours(0, 0, 0, 0), an(a);
}
function nv(e, t) {
  const a = t * 3;
  return It(e, a);
}
function eo(e, t) {
  return It(e, t * 12);
}
function oi(e, t) {
  const a = he(e), n = he(t), r = a.getTime() - n.getTime();
  return r < 0 ? -1 : r > 0 ? 1 : r;
}
function js(e) {
  return e instanceof Date || typeof e == "object" && Object.prototype.toString.call(e) === "[object Date]";
}
function xn(e) {
  if (!js(e) && typeof e != "number")
    return !1;
  const t = he(e);
  return !isNaN(Number(t));
}
function ii(e) {
  const t = he(e);
  return Math.trunc(t.getMonth() / 3) + 1;
}
function rv(e, t) {
  const a = he(e), n = he(t);
  return a.getFullYear() - n.getFullYear();
}
function lv(e, t) {
  const a = he(e), n = he(t), r = oi(a, n), l = Math.abs(rv(a, n));
  a.setFullYear(1584), n.setFullYear(1584);
  const o = oi(a, n) === -r, i = r * (l - +o);
  return i === 0 ? 0 : i;
}
function Us(e, t) {
  const a = he(e.start), n = he(e.end);
  let r = +a > +n;
  const l = r ? +a : +n, o = r ? n : a;
  o.setHours(0, 0, 0, 0);
  let i = (t == null ? void 0 : t.step) ?? 1;
  if (!i)
    return [];
  i < 0 && (i = -i, r = !r);
  const s = [];
  for (; +o <= l; )
    s.push(he(o)), o.setDate(o.getDate() + i), o.setHours(0, 0, 0, 0);
  return r ? s.reverse() : s;
}
function La(e) {
  const t = he(e), a = t.getMonth(), n = a - a % 3;
  return t.setMonth(n, 1), t.setHours(0, 0, 0, 0), t;
}
function ov(e, t) {
  const a = he(e.start), n = he(e.end);
  let r = +a > +n;
  const l = r ? +La(a) : +La(n);
  let o = La(r ? n : a), i = (t == null ? void 0 : t.step) ?? 1;
  if (!i)
    return [];
  i < 0 && (i = -i, r = !r);
  const s = [];
  for (; +o <= l; )
    s.push(he(o)), o = nv(o, i);
  return r ? s.reverse() : s;
}
function iv(e) {
  const t = he(e);
  return t.setDate(1), t.setHours(0, 0, 0, 0), t;
}
function Ws(e) {
  const t = he(e), a = t.getFullYear();
  return t.setFullYear(a + 1, 0, 0), t.setHours(23, 59, 59, 999), t;
}
function Dn(e) {
  const t = he(e), a = Ce(e, 0);
  return a.setFullYear(t.getFullYear(), 0, 1), a.setHours(0, 0, 0, 0), a;
}
function qs(e, t) {
  var i, s, c, u;
  const a = ja(), n = (t == null ? void 0 : t.weekStartsOn) ?? ((s = (i = t == null ? void 0 : t.locale) == null ? void 0 : i.options) == null ? void 0 : s.weekStartsOn) ?? a.weekStartsOn ?? ((u = (c = a.locale) == null ? void 0 : c.options) == null ? void 0 : u.weekStartsOn) ?? 0, r = he(e), l = r.getDay(), o = (l < n ? -7 : 0) + 6 - (l - n);
  return r.setDate(r.getDate() + o), r.setHours(23, 59, 59, 999), r;
}
function si(e) {
  const t = he(e), a = t.getMonth(), n = a - a % 3 + 3;
  return t.setMonth(n, 0), t.setHours(23, 59, 59, 999), t;
}
const sv = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "1 second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about 1 hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "1 hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "1 day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about 1 week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about 1 month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "1 month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about 1 year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "1 year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over 1 year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost 1 year",
    other: "almost {{count}} years"
  }
}, uv = (e, t, a) => {
  let n;
  const r = sv[e];
  return typeof r == "string" ? n = r : t === 1 ? n = r.one : n = r.other.replace("{{count}}", t.toString()), a != null && a.addSuffix ? a.comparison && a.comparison > 0 ? "in " + n : n + " ago" : n;
};
function Qr(e) {
  return (t = {}) => {
    const a = t.width ? String(t.width) : e.defaultWidth;
    return e.formats[a] || e.formats[e.defaultWidth];
  };
}
const cv = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
}, dv = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
}, fv = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
}, vv = {
  date: Qr({
    formats: cv,
    defaultWidth: "full"
  }),
  time: Qr({
    formats: dv,
    defaultWidth: "full"
  }),
  dateTime: Qr({
    formats: fv,
    defaultWidth: "full"
  })
}, mv = {
  lastWeek: "'last' eeee 'at' p",
  yesterday: "'yesterday at' p",
  today: "'today at' p",
  tomorrow: "'tomorrow at' p",
  nextWeek: "eeee 'at' p",
  other: "P"
}, pv = (e, t, a, n) => mv[e];
function pn(e) {
  return (t, a) => {
    const n = a != null && a.context ? String(a.context) : "standalone";
    let r;
    if (n === "formatting" && e.formattingValues) {
      const o = e.defaultFormattingWidth || e.defaultWidth, i = a != null && a.width ? String(a.width) : o;
      r = e.formattingValues[i] || e.formattingValues[o];
    } else {
      const o = e.defaultWidth, i = a != null && a.width ? String(a.width) : e.defaultWidth;
      r = e.values[i] || e.values[o];
    }
    const l = e.argumentCallback ? e.argumentCallback(t) : t;
    return r[l];
  };
}
const hv = {
  narrow: ["B", "A"],
  abbreviated: ["BC", "AD"],
  wide: ["Before Christ", "Anno Domini"]
}, yv = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1st quarter", "2nd quarter", "3rd quarter", "4th quarter"]
}, gv = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ],
  wide: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
}, bv = {
  narrow: ["S", "M", "T", "W", "T", "F", "S"],
  short: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  abbreviated: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  wide: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ]
}, wv = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    night: "night"
  }
}, kv = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mi",
    noon: "n",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnight",
    noon: "noon",
    morning: "in the morning",
    afternoon: "in the afternoon",
    evening: "in the evening",
    night: "at night"
  }
}, xv = (e, t) => {
  const a = Number(e), n = a % 100;
  if (n > 20 || n < 10)
    switch (n % 10) {
      case 1:
        return a + "st";
      case 2:
        return a + "nd";
      case 3:
        return a + "rd";
    }
  return a + "th";
}, _v = {
  ordinalNumber: xv,
  era: pn({
    values: hv,
    defaultWidth: "wide"
  }),
  quarter: pn({
    values: yv,
    defaultWidth: "wide",
    argumentCallback: (e) => e - 1
  }),
  month: pn({
    values: gv,
    defaultWidth: "wide"
  }),
  day: pn({
    values: bv,
    defaultWidth: "wide"
  }),
  dayPeriod: pn({
    values: wv,
    defaultWidth: "wide",
    formattingValues: kv,
    defaultFormattingWidth: "wide"
  })
};
function hn(e) {
  return (t, a = {}) => {
    const n = a.width, r = n && e.matchPatterns[n] || e.matchPatterns[e.defaultMatchWidth], l = t.match(r);
    if (!l)
      return null;
    const o = l[0], i = n && e.parsePatterns[n] || e.parsePatterns[e.defaultParseWidth], s = Array.isArray(i) ? Av(i, (d) => d.test(o)) : (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
      Mv(i, (d) => d.test(o))
    );
    let c;
    c = e.valueCallback ? e.valueCallback(s) : s, c = a.valueCallback ? (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- I challange you to fix the type
      a.valueCallback(c)
    ) : c;
    const u = t.slice(o.length);
    return { value: c, rest: u };
  };
}
function Mv(e, t) {
  for (const a in e)
    if (Object.prototype.hasOwnProperty.call(e, a) && t(e[a]))
      return a;
}
function Av(e, t) {
  for (let a = 0; a < e.length; a++)
    if (t(e[a]))
      return a;
}
function Tv(e) {
  return (t, a = {}) => {
    const n = t.match(e.matchPattern);
    if (!n)
      return null;
    const r = n[0], l = t.match(e.parsePattern);
    if (!l)
      return null;
    let o = e.valueCallback ? e.valueCallback(l[0]) : l[0];
    o = a.valueCallback ? a.valueCallback(o) : o;
    const i = t.slice(r.length);
    return { value: o, rest: i };
  };
}
const Ov = /^(\d+)(th|st|nd|rd)?/i, Pv = /\d+/i, Sv = {
  narrow: /^(b|a)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(before christ|before common era|anno domini|common era)/i
}, Cv = {
  any: [/^b/i, /^(a|c)/i]
}, Dv = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](th|st|nd|rd)? quarter/i
}, $v = {
  any: [/1/i, /2/i, /3/i, /4/i]
}, Lv = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/i,
  wide: /^(january|february|march|april|may|june|july|august|september|october|november|december)/i
}, Ev = {
  narrow: [
    /^j/i,
    /^f/i,
    /^m/i,
    /^a/i,
    /^m/i,
    /^j/i,
    /^j/i,
    /^a/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ],
  any: [
    /^ja/i,
    /^f/i,
    /^mar/i,
    /^ap/i,
    /^may/i,
    /^jun/i,
    /^jul/i,
    /^au/i,
    /^s/i,
    /^o/i,
    /^n/i,
    /^d/i
  ]
}, Nv = {
  narrow: /^[smtwf]/i,
  short: /^(su|mo|tu|we|th|fr|sa)/i,
  abbreviated: /^(sun|mon|tue|wed|thu|fri|sat)/i,
  wide: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)/i
}, Rv = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
}, Iv = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
}, Hv = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
}, zv = {
  ordinalNumber: Tv({
    matchPattern: Ov,
    parsePattern: Pv,
    valueCallback: (e) => parseInt(e, 10)
  }),
  era: hn({
    matchPatterns: Sv,
    defaultMatchWidth: "wide",
    parsePatterns: Cv,
    defaultParseWidth: "any"
  }),
  quarter: hn({
    matchPatterns: Dv,
    defaultMatchWidth: "wide",
    parsePatterns: $v,
    defaultParseWidth: "any",
    valueCallback: (e) => e + 1
  }),
  month: hn({
    matchPatterns: Lv,
    defaultMatchWidth: "wide",
    parsePatterns: Ev,
    defaultParseWidth: "any"
  }),
  day: hn({
    matchPatterns: Nv,
    defaultMatchWidth: "wide",
    parsePatterns: Rv,
    defaultParseWidth: "any"
  }),
  dayPeriod: hn({
    matchPatterns: Iv,
    defaultMatchWidth: "any",
    parsePatterns: Hv,
    defaultParseWidth: "any"
  })
}, Ks = {
  code: "en-US",
  formatDistance: uv,
  formatLong: vv,
  formatRelative: pv,
  localize: _v,
  match: zv,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
function Vv(e) {
  const t = he(e);
  return Fs(t, Dn(t)) + 1;
}
function to(e) {
  const t = he(e), a = +an(t) - +av(t);
  return Math.round(a / Vs) + 1;
}
function ao(e, t) {
  var u, d, f, h;
  const a = he(e), n = a.getFullYear(), r = ja(), l = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((d = (u = t == null ? void 0 : t.locale) == null ? void 0 : u.options) == null ? void 0 : d.firstWeekContainsDate) ?? r.firstWeekContainsDate ?? ((h = (f = r.locale) == null ? void 0 : f.options) == null ? void 0 : h.firstWeekContainsDate) ?? 1, o = Ce(e, 0);
  o.setFullYear(n + 1, 0, l), o.setHours(0, 0, 0, 0);
  const i = Ht(o, t), s = Ce(e, 0);
  s.setFullYear(n, 0, l), s.setHours(0, 0, 0, 0);
  const c = Ht(s, t);
  return a.getTime() >= i.getTime() ? n + 1 : a.getTime() >= c.getTime() ? n : n - 1;
}
function Bv(e, t) {
  var i, s, c, u;
  const a = ja(), n = (t == null ? void 0 : t.firstWeekContainsDate) ?? ((s = (i = t == null ? void 0 : t.locale) == null ? void 0 : i.options) == null ? void 0 : s.firstWeekContainsDate) ?? a.firstWeekContainsDate ?? ((u = (c = a.locale) == null ? void 0 : c.options) == null ? void 0 : u.firstWeekContainsDate) ?? 1, r = ao(e, t), l = Ce(e, 0);
  return l.setFullYear(r, 0, n), l.setHours(0, 0, 0, 0), Ht(l, t);
}
function no(e, t) {
  const a = he(e), n = +Ht(a, t) - +Bv(a, t);
  return Math.round(n / Vs) + 1;
}
function $e(e, t) {
  const a = e < 0 ? "-" : "", n = Math.abs(e).toString().padStart(t, "0");
  return a + n;
}
const ca = {
  // Year
  y(e, t) {
    const a = e.getFullYear(), n = a > 0 ? a : 1 - a;
    return $e(t === "yy" ? n % 100 : n, t.length);
  },
  // Month
  M(e, t) {
    const a = e.getMonth();
    return t === "M" ? String(a + 1) : $e(a + 1, 2);
  },
  // Day of the month
  d(e, t) {
    return $e(e.getDate(), t.length);
  },
  // AM or PM
  a(e, t) {
    const a = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return a.toUpperCase();
      case "aaa":
        return a;
      case "aaaaa":
        return a[0];
      case "aaaa":
      default:
        return a === "am" ? "a.m." : "p.m.";
    }
  },
  // Hour [1-12]
  h(e, t) {
    return $e(e.getHours() % 12 || 12, t.length);
  },
  // Hour [0-23]
  H(e, t) {
    return $e(e.getHours(), t.length);
  },
  // Minute
  m(e, t) {
    return $e(e.getMinutes(), t.length);
  },
  // Second
  s(e, t) {
    return $e(e.getSeconds(), t.length);
  },
  // Fraction of second
  S(e, t) {
    const a = t.length, n = e.getMilliseconds(), r = Math.trunc(
      n * Math.pow(10, a - 3)
    );
    return $e(r, t.length);
  }
}, Ua = {
  am: "am",
  pm: "pm",
  midnight: "midnight",
  noon: "noon",
  morning: "morning",
  afternoon: "afternoon",
  evening: "evening",
  night: "night"
}, ui = {
  // Era
  G: function(e, t, a) {
    const n = e.getFullYear() > 0 ? 1 : 0;
    switch (t) {
      case "G":
      case "GG":
      case "GGG":
        return a.era(n, { width: "abbreviated" });
      case "GGGGG":
        return a.era(n, { width: "narrow" });
      case "GGGG":
      default:
        return a.era(n, { width: "wide" });
    }
  },
  // Year
  y: function(e, t, a) {
    if (t === "yo") {
      const n = e.getFullYear(), r = n > 0 ? n : 1 - n;
      return a.ordinalNumber(r, { unit: "year" });
    }
    return ca.y(e, t);
  },
  // Local week-numbering year
  Y: function(e, t, a, n) {
    const r = ao(e, n), l = r > 0 ? r : 1 - r;
    if (t === "YY") {
      const o = l % 100;
      return $e(o, 2);
    }
    return t === "Yo" ? a.ordinalNumber(l, { unit: "year" }) : $e(l, t.length);
  },
  // ISO week-numbering year
  R: function(e, t) {
    const a = Ys(e);
    return $e(a, t.length);
  },
  // Extended year. This is a single number designating the year of this calendar system.
  // The main difference between `y` and `u` localizers are B.C. years:
  // | Year | `y` | `u` |
  // |------|-----|-----|
  // | AC 1 |   1 |   1 |
  // | BC 1 |   1 |   0 |
  // | BC 2 |   2 |  -1 |
  // Also `yy` always returns the last two digits of a year,
  // while `uu` pads single digit years to 2 characters and returns other years unchanged.
  u: function(e, t) {
    const a = e.getFullYear();
    return $e(a, t.length);
  },
  // Quarter
  Q: function(e, t, a) {
    const n = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "Q":
        return String(n);
      case "QQ":
        return $e(n, 2);
      case "Qo":
        return a.ordinalNumber(n, { unit: "quarter" });
      case "QQQ":
        return a.quarter(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "QQQQQ":
        return a.quarter(n, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return a.quarter(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone quarter
  q: function(e, t, a) {
    const n = Math.ceil((e.getMonth() + 1) / 3);
    switch (t) {
      case "q":
        return String(n);
      case "qq":
        return $e(n, 2);
      case "qo":
        return a.ordinalNumber(n, { unit: "quarter" });
      case "qqq":
        return a.quarter(n, {
          width: "abbreviated",
          context: "standalone"
        });
      case "qqqqq":
        return a.quarter(n, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return a.quarter(n, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // Month
  M: function(e, t, a) {
    const n = e.getMonth();
    switch (t) {
      case "M":
      case "MM":
        return ca.M(e, t);
      case "Mo":
        return a.ordinalNumber(n + 1, { unit: "month" });
      case "MMM":
        return a.month(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "MMMMM":
        return a.month(n, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return a.month(n, { width: "wide", context: "formatting" });
    }
  },
  // Stand-alone month
  L: function(e, t, a) {
    const n = e.getMonth();
    switch (t) {
      case "L":
        return String(n + 1);
      case "LL":
        return $e(n + 1, 2);
      case "Lo":
        return a.ordinalNumber(n + 1, { unit: "month" });
      case "LLL":
        return a.month(n, {
          width: "abbreviated",
          context: "standalone"
        });
      case "LLLLL":
        return a.month(n, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return a.month(n, { width: "wide", context: "standalone" });
    }
  },
  // Local week of year
  w: function(e, t, a, n) {
    const r = no(e, n);
    return t === "wo" ? a.ordinalNumber(r, { unit: "week" }) : $e(r, t.length);
  },
  // ISO week of year
  I: function(e, t, a) {
    const n = to(e);
    return t === "Io" ? a.ordinalNumber(n, { unit: "week" }) : $e(n, t.length);
  },
  // Day of the month
  d: function(e, t, a) {
    return t === "do" ? a.ordinalNumber(e.getDate(), { unit: "date" }) : ca.d(e, t);
  },
  // Day of year
  D: function(e, t, a) {
    const n = Vv(e);
    return t === "Do" ? a.ordinalNumber(n, { unit: "dayOfYear" }) : $e(n, t.length);
  },
  // Day of week
  E: function(e, t, a) {
    const n = e.getDay();
    switch (t) {
      case "E":
      case "EE":
      case "EEE":
        return a.day(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "EEEEE":
        return a.day(n, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return a.day(n, {
          width: "short",
          context: "formatting"
        });
      case "EEEE":
      default:
        return a.day(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Local day of week
  e: function(e, t, a, n) {
    const r = e.getDay(), l = (r - n.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "e":
        return String(l);
      case "ee":
        return $e(l, 2);
      case "eo":
        return a.ordinalNumber(l, { unit: "day" });
      case "eee":
        return a.day(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "eeeee":
        return a.day(r, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return a.day(r, {
          width: "short",
          context: "formatting"
        });
      case "eeee":
      default:
        return a.day(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Stand-alone local day of week
  c: function(e, t, a, n) {
    const r = e.getDay(), l = (r - n.weekStartsOn + 8) % 7 || 7;
    switch (t) {
      case "c":
        return String(l);
      case "cc":
        return $e(l, t.length);
      case "co":
        return a.ordinalNumber(l, { unit: "day" });
      case "ccc":
        return a.day(r, {
          width: "abbreviated",
          context: "standalone"
        });
      case "ccccc":
        return a.day(r, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return a.day(r, {
          width: "short",
          context: "standalone"
        });
      case "cccc":
      default:
        return a.day(r, {
          width: "wide",
          context: "standalone"
        });
    }
  },
  // ISO day of week
  i: function(e, t, a) {
    const n = e.getDay(), r = n === 0 ? 7 : n;
    switch (t) {
      case "i":
        return String(r);
      case "ii":
        return $e(r, t.length);
      case "io":
        return a.ordinalNumber(r, { unit: "day" });
      case "iii":
        return a.day(n, {
          width: "abbreviated",
          context: "formatting"
        });
      case "iiiii":
        return a.day(n, {
          width: "narrow",
          context: "formatting"
        });
      case "iiiiii":
        return a.day(n, {
          width: "short",
          context: "formatting"
        });
      case "iiii":
      default:
        return a.day(n, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM or PM
  a: function(e, t, a) {
    const r = e.getHours() / 12 >= 1 ? "pm" : "am";
    switch (t) {
      case "a":
      case "aa":
        return a.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "aaa":
        return a.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "aaaaa":
        return a.dayPeriod(r, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return a.dayPeriod(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // AM, PM, midnight, noon
  b: function(e, t, a) {
    const n = e.getHours();
    let r;
    switch (n === 12 ? r = Ua.noon : n === 0 ? r = Ua.midnight : r = n / 12 >= 1 ? "pm" : "am", t) {
      case "b":
      case "bb":
        return a.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "bbb":
        return a.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        }).toLowerCase();
      case "bbbbb":
        return a.dayPeriod(r, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return a.dayPeriod(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // in the morning, in the afternoon, in the evening, at night
  B: function(e, t, a) {
    const n = e.getHours();
    let r;
    switch (n >= 17 ? r = Ua.evening : n >= 12 ? r = Ua.afternoon : n >= 4 ? r = Ua.morning : r = Ua.night, t) {
      case "B":
      case "BB":
      case "BBB":
        return a.dayPeriod(r, {
          width: "abbreviated",
          context: "formatting"
        });
      case "BBBBB":
        return a.dayPeriod(r, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return a.dayPeriod(r, {
          width: "wide",
          context: "formatting"
        });
    }
  },
  // Hour [1-12]
  h: function(e, t, a) {
    if (t === "ho") {
      let n = e.getHours() % 12;
      return n === 0 && (n = 12), a.ordinalNumber(n, { unit: "hour" });
    }
    return ca.h(e, t);
  },
  // Hour [0-23]
  H: function(e, t, a) {
    return t === "Ho" ? a.ordinalNumber(e.getHours(), { unit: "hour" }) : ca.H(e, t);
  },
  // Hour [0-11]
  K: function(e, t, a) {
    const n = e.getHours() % 12;
    return t === "Ko" ? a.ordinalNumber(n, { unit: "hour" }) : $e(n, t.length);
  },
  // Hour [1-24]
  k: function(e, t, a) {
    let n = e.getHours();
    return n === 0 && (n = 24), t === "ko" ? a.ordinalNumber(n, { unit: "hour" }) : $e(n, t.length);
  },
  // Minute
  m: function(e, t, a) {
    return t === "mo" ? a.ordinalNumber(e.getMinutes(), { unit: "minute" }) : ca.m(e, t);
  },
  // Second
  s: function(e, t, a) {
    return t === "so" ? a.ordinalNumber(e.getSeconds(), { unit: "second" }) : ca.s(e, t);
  },
  // Fraction of second
  S: function(e, t) {
    return ca.S(e, t);
  },
  // Timezone (ISO-8601. If offset is 0, output is always `'Z'`)
  X: function(e, t, a) {
    const n = e.getTimezoneOffset();
    if (n === 0)
      return "Z";
    switch (t) {
      case "X":
        return di(n);
      case "XXXX":
      case "XX":
        return Sa(n);
      case "XXXXX":
      case "XXX":
      default:
        return Sa(n, ":");
    }
  },
  // Timezone (ISO-8601. If offset is 0, output is `'+00:00'` or equivalent)
  x: function(e, t, a) {
    const n = e.getTimezoneOffset();
    switch (t) {
      case "x":
        return di(n);
      case "xxxx":
      case "xx":
        return Sa(n);
      case "xxxxx":
      case "xxx":
      default:
        return Sa(n, ":");
    }
  },
  // Timezone (GMT)
  O: function(e, t, a) {
    const n = e.getTimezoneOffset();
    switch (t) {
      case "O":
      case "OO":
      case "OOO":
        return "GMT" + ci(n, ":");
      case "OOOO":
      default:
        return "GMT" + Sa(n, ":");
    }
  },
  // Timezone (specific non-location)
  z: function(e, t, a) {
    const n = e.getTimezoneOffset();
    switch (t) {
      case "z":
      case "zz":
      case "zzz":
        return "GMT" + ci(n, ":");
      case "zzzz":
      default:
        return "GMT" + Sa(n, ":");
    }
  },
  // Seconds timestamp
  t: function(e, t, a) {
    const n = Math.trunc(e.getTime() / 1e3);
    return $e(n, t.length);
  },
  // Milliseconds timestamp
  T: function(e, t, a) {
    const n = e.getTime();
    return $e(n, t.length);
  }
};
function ci(e, t = "") {
  const a = e > 0 ? "-" : "+", n = Math.abs(e), r = Math.trunc(n / 60), l = n % 60;
  return l === 0 ? a + String(r) : a + String(r) + t + $e(l, 2);
}
function di(e, t) {
  return e % 60 === 0 ? (e > 0 ? "-" : "+") + $e(Math.abs(e) / 60, 2) : Sa(e, t);
}
function Sa(e, t = "") {
  const a = e > 0 ? "-" : "+", n = Math.abs(e), r = $e(Math.trunc(n / 60), 2), l = $e(n % 60, 2);
  return a + r + t + l;
}
const fi = (e, t) => {
  switch (e) {
    case "P":
      return t.date({ width: "short" });
    case "PP":
      return t.date({ width: "medium" });
    case "PPP":
      return t.date({ width: "long" });
    case "PPPP":
    default:
      return t.date({ width: "full" });
  }
}, Xs = (e, t) => {
  switch (e) {
    case "p":
      return t.time({ width: "short" });
    case "pp":
      return t.time({ width: "medium" });
    case "ppp":
      return t.time({ width: "long" });
    case "pppp":
    default:
      return t.time({ width: "full" });
  }
}, Yv = (e, t) => {
  const a = e.match(/(P+)(p+)?/) || [], n = a[1], r = a[2];
  if (!r)
    return fi(e, t);
  let l;
  switch (n) {
    case "P":
      l = t.dateTime({ width: "short" });
      break;
    case "PP":
      l = t.dateTime({ width: "medium" });
      break;
    case "PPP":
      l = t.dateTime({ width: "long" });
      break;
    case "PPPP":
    default:
      l = t.dateTime({ width: "full" });
      break;
  }
  return l.replace("{{date}}", fi(n, t)).replace("{{time}}", Xs(r, t));
}, Ml = {
  p: Xs,
  P: Yv
}, Fv = /^D+$/, jv = /^Y+$/, Uv = ["D", "DD", "YY", "YYYY"];
function Qs(e) {
  return Fv.test(e);
}
function Gs(e) {
  return jv.test(e);
}
function Al(e, t, a) {
  const n = Wv(e, t, a);
  if (console.warn(n), Uv.includes(e))
    throw new RangeError(n);
}
function Wv(e, t, a) {
  const n = e[0] === "Y" ? "years" : "days of the month";
  return `Use \`${e.toLowerCase()}\` instead of \`${e}\` (in \`${t}\`) for formatting ${n} to the input \`${a}\`; see: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md`;
}
const qv = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, Kv = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, Xv = /^'([^]*?)'?$/, Qv = /''/g, Gv = /[a-zA-Z]/;
function Wt(e, t, a) {
  var u, d, f, h, x, g, D, k;
  const n = ja(), r = (a == null ? void 0 : a.locale) ?? n.locale ?? Ks, l = (a == null ? void 0 : a.firstWeekContainsDate) ?? ((d = (u = a == null ? void 0 : a.locale) == null ? void 0 : u.options) == null ? void 0 : d.firstWeekContainsDate) ?? n.firstWeekContainsDate ?? ((h = (f = n.locale) == null ? void 0 : f.options) == null ? void 0 : h.firstWeekContainsDate) ?? 1, o = (a == null ? void 0 : a.weekStartsOn) ?? ((g = (x = a == null ? void 0 : a.locale) == null ? void 0 : x.options) == null ? void 0 : g.weekStartsOn) ?? n.weekStartsOn ?? ((k = (D = n.locale) == null ? void 0 : D.options) == null ? void 0 : k.weekStartsOn) ?? 0, i = he(e);
  if (!xn(i))
    throw new RangeError("Invalid time value");
  let s = t.match(Kv).map((b) => {
    const m = b[0];
    if (m === "p" || m === "P") {
      const M = Ml[m];
      return M(b, r.formatLong);
    }
    return b;
  }).join("").match(qv).map((b) => {
    if (b === "''")
      return { isToken: !1, value: "'" };
    const m = b[0];
    if (m === "'")
      return { isToken: !1, value: Jv(b) };
    if (ui[m])
      return { isToken: !0, value: b };
    if (m.match(Gv))
      throw new RangeError(
        "Format string contains an unescaped latin alphabet character `" + m + "`"
      );
    return { isToken: !1, value: b };
  });
  r.localize.preprocessor && (s = r.localize.preprocessor(i, s));
  const c = {
    firstWeekContainsDate: l,
    weekStartsOn: o,
    locale: r
  };
  return s.map((b) => {
    if (!b.isToken)
      return b.value;
    const m = b.value;
    (!(a != null && a.useAdditionalWeekYearTokens) && Gs(m) || !(a != null && a.useAdditionalDayOfYearTokens) && Qs(m)) && Al(m, t, String(e));
    const M = ui[m[0]];
    return M(i, m, r.localize, c);
  }).join("");
}
function Jv(e) {
  const t = e.match(Xv);
  return t ? t[1].replace(Qv, "'") : e;
}
function Zv(e) {
  return he(e).getDay();
}
function em(e) {
  const t = he(e), a = t.getFullYear(), n = t.getMonth(), r = Ce(e, 0);
  return r.setFullYear(a, n + 1, 0), r.setHours(0, 0, 0, 0), r.getDate();
}
function tm() {
  return Object.assign({}, ja());
}
function na(e) {
  return he(e).getHours();
}
function am(e) {
  let a = he(e).getDay();
  return a === 0 && (a = 7), a;
}
function _a(e) {
  return he(e).getMinutes();
}
function Pe(e) {
  return he(e).getMonth();
}
function nn(e) {
  return he(e).getSeconds();
}
function _e(e) {
  return he(e).getFullYear();
}
function rn(e, t) {
  const a = he(e), n = he(t);
  return a.getTime() > n.getTime();
}
function $n(e, t) {
  const a = he(e), n = he(t);
  return +a < +n;
}
function Qa(e, t) {
  const a = he(e), n = he(t);
  return +a == +n;
}
function nm(e, t) {
  const a = t instanceof Date ? Ce(t, 0) : new t(0);
  return a.setFullYear(
    e.getFullYear(),
    e.getMonth(),
    e.getDate()
  ), a.setHours(
    e.getHours(),
    e.getMinutes(),
    e.getSeconds(),
    e.getMilliseconds()
  ), a;
}
const rm = 10;
class Js {
  constructor() {
    fe(this, "subPriority", 0);
  }
  validate(t, a) {
    return !0;
  }
}
class lm extends Js {
  constructor(t, a, n, r, l) {
    super(), this.value = t, this.validateValue = a, this.setValue = n, this.priority = r, l && (this.subPriority = l);
  }
  validate(t, a) {
    return this.validateValue(t, this.value, a);
  }
  set(t, a, n) {
    return this.setValue(t, a, this.value, n);
  }
}
class om extends Js {
  constructor() {
    super(...arguments);
    fe(this, "priority", rm);
    fe(this, "subPriority", -1);
  }
  set(a, n) {
    return n.timestampIsSet ? a : Ce(a, nm(a, Date));
  }
}
class De {
  run(t, a, n, r) {
    const l = this.parse(t, a, n, r);
    return l ? {
      setter: new lm(
        l.value,
        this.validate,
        this.set,
        this.priority,
        this.subPriority
      ),
      rest: l.rest
    } : null;
  }
  validate(t, a, n) {
    return !0;
  }
}
class im extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 140);
    fe(this, "incompatibleTokens", ["R", "u", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "G":
      case "GG":
      case "GGG":
        return r.era(a, { width: "abbreviated" }) || r.era(a, { width: "narrow" });
      case "GGGGG":
        return r.era(a, { width: "narrow" });
      case "GGGG":
      default:
        return r.era(a, { width: "wide" }) || r.era(a, { width: "abbreviated" }) || r.era(a, { width: "narrow" });
    }
  }
  set(a, n, r) {
    return n.era = r, a.setFullYear(r, 0, 1), a.setHours(0, 0, 0, 0), a;
  }
}
const Je = {
  month: /^(1[0-2]|0?\d)/,
  // 0 to 12
  date: /^(3[0-1]|[0-2]?\d)/,
  // 0 to 31
  dayOfYear: /^(36[0-6]|3[0-5]\d|[0-2]?\d?\d)/,
  // 0 to 366
  week: /^(5[0-3]|[0-4]?\d)/,
  // 0 to 53
  hour23h: /^(2[0-3]|[0-1]?\d)/,
  // 0 to 23
  hour24h: /^(2[0-4]|[0-1]?\d)/,
  // 0 to 24
  hour11h: /^(1[0-1]|0?\d)/,
  // 0 to 11
  hour12h: /^(1[0-2]|0?\d)/,
  // 0 to 12
  minute: /^[0-5]?\d/,
  // 0 to 59
  second: /^[0-5]?\d/,
  // 0 to 59
  singleDigit: /^\d/,
  // 0 to 9
  twoDigits: /^\d{1,2}/,
  // 0 to 99
  threeDigits: /^\d{1,3}/,
  // 0 to 999
  fourDigits: /^\d{1,4}/,
  // 0 to 9999
  anyDigitsSigned: /^-?\d+/,
  singleDigitSigned: /^-?\d/,
  // 0 to 9, -0 to -9
  twoDigitsSigned: /^-?\d{1,2}/,
  // 0 to 99, -0 to -99
  threeDigitsSigned: /^-?\d{1,3}/,
  // 0 to 999, -0 to -999
  fourDigitsSigned: /^-?\d{1,4}/
  // 0 to 9999, -0 to -9999
}, Yt = {
  basicOptionalMinutes: /^([+-])(\d{2})(\d{2})?|Z/,
  basic: /^([+-])(\d{2})(\d{2})|Z/,
  basicOptionalSeconds: /^([+-])(\d{2})(\d{2})((\d{2}))?|Z/,
  extended: /^([+-])(\d{2}):(\d{2})|Z/,
  extendedOptionalSeconds: /^([+-])(\d{2}):(\d{2})(:(\d{2}))?|Z/
};
function Ze(e, t) {
  return e && {
    value: t(e.value),
    rest: e.rest
  };
}
function Ue(e, t) {
  const a = t.match(e);
  return a ? {
    value: parseInt(a[0], 10),
    rest: t.slice(a[0].length)
  } : null;
}
function Ft(e, t) {
  const a = t.match(e);
  if (!a)
    return null;
  if (a[0] === "Z")
    return {
      value: 0,
      rest: t.slice(1)
    };
  const n = a[1] === "+" ? 1 : -1, r = a[2] ? parseInt(a[2], 10) : 0, l = a[3] ? parseInt(a[3], 10) : 0, o = a[5] ? parseInt(a[5], 10) : 0;
  return {
    value: n * (r * Bs + l * J0 + o * Z0),
    rest: t.slice(a[0].length)
  };
}
function Zs(e) {
  return Ue(Je.anyDigitsSigned, e);
}
function Xe(e, t) {
  switch (e) {
    case 1:
      return Ue(Je.singleDigit, t);
    case 2:
      return Ue(Je.twoDigits, t);
    case 3:
      return Ue(Je.threeDigits, t);
    case 4:
      return Ue(Je.fourDigits, t);
    default:
      return Ue(new RegExp("^\\d{1," + e + "}"), t);
  }
}
function gr(e, t) {
  switch (e) {
    case 1:
      return Ue(Je.singleDigitSigned, t);
    case 2:
      return Ue(Je.twoDigitsSigned, t);
    case 3:
      return Ue(Je.threeDigitsSigned, t);
    case 4:
      return Ue(Je.fourDigitsSigned, t);
    default:
      return Ue(new RegExp("^-?\\d{1," + e + "}"), t);
  }
}
function ro(e) {
  switch (e) {
    case "morning":
      return 4;
    case "evening":
      return 17;
    case "pm":
    case "noon":
    case "afternoon":
      return 12;
    case "am":
    case "midnight":
    case "night":
    default:
      return 0;
  }
}
function eu(e, t) {
  const a = t > 0, n = a ? t : 1 - t;
  let r;
  if (n <= 50)
    r = e || 100;
  else {
    const l = n + 50, o = Math.trunc(l / 100) * 100, i = e >= l % 100;
    r = e + o - (i ? 100 : 0);
  }
  return a ? r : 1 - r;
}
function tu(e) {
  return e % 400 === 0 || e % 4 === 0 && e % 100 !== 0;
}
class sm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 130);
    fe(this, "incompatibleTokens", ["Y", "R", "u", "w", "I", "i", "e", "c", "t", "T"]);
  }
  parse(a, n, r) {
    const l = (o) => ({
      year: o,
      isTwoDigitYear: n === "yy"
    });
    switch (n) {
      case "y":
        return Ze(Xe(4, a), l);
      case "yo":
        return Ze(
          r.ordinalNumber(a, {
            unit: "year"
          }),
          l
        );
      default:
        return Ze(Xe(n.length, a), l);
    }
  }
  validate(a, n) {
    return n.isTwoDigitYear || n.year > 0;
  }
  set(a, n, r) {
    const l = a.getFullYear();
    if (r.isTwoDigitYear) {
      const i = eu(
        r.year,
        l
      );
      return a.setFullYear(i, 0, 1), a.setHours(0, 0, 0, 0), a;
    }
    const o = !("era" in n) || n.era === 1 ? r.year : 1 - r.year;
    return a.setFullYear(o, 0, 1), a.setHours(0, 0, 0, 0), a;
  }
}
class um extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 130);
    fe(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "Q",
      "q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "i",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    const l = (o) => ({
      year: o,
      isTwoDigitYear: n === "YY"
    });
    switch (n) {
      case "Y":
        return Ze(Xe(4, a), l);
      case "Yo":
        return Ze(
          r.ordinalNumber(a, {
            unit: "year"
          }),
          l
        );
      default:
        return Ze(Xe(n.length, a), l);
    }
  }
  validate(a, n) {
    return n.isTwoDigitYear || n.year > 0;
  }
  set(a, n, r, l) {
    const o = ao(a, l);
    if (r.isTwoDigitYear) {
      const s = eu(
        r.year,
        o
      );
      return a.setFullYear(
        s,
        0,
        l.firstWeekContainsDate
      ), a.setHours(0, 0, 0, 0), Ht(a, l);
    }
    const i = !("era" in n) || n.era === 1 ? r.year : 1 - r.year;
    return a.setFullYear(i, 0, l.firstWeekContainsDate), a.setHours(0, 0, 0, 0), Ht(a, l);
  }
}
class cm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 130);
    fe(this, "incompatibleTokens", [
      "G",
      "y",
      "Y",
      "u",
      "Q",
      "q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n) {
    return gr(n === "R" ? 4 : n.length, a);
  }
  set(a, n, r) {
    const l = Ce(a, 0);
    return l.setFullYear(r, 0, 4), l.setHours(0, 0, 0, 0), an(l);
  }
}
class dm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 130);
    fe(this, "incompatibleTokens", ["G", "y", "Y", "R", "w", "I", "i", "e", "c", "t", "T"]);
  }
  parse(a, n) {
    return gr(n === "u" ? 4 : n.length, a);
  }
  set(a, n, r) {
    return a.setFullYear(r, 0, 1), a.setHours(0, 0, 0, 0), a;
  }
}
class fm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 120);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "Q":
      case "QQ":
        return Xe(n.length, a);
      case "Qo":
        return r.ordinalNumber(a, { unit: "quarter" });
      case "QQQ":
        return r.quarter(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.quarter(a, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQQ":
        return r.quarter(a, {
          width: "narrow",
          context: "formatting"
        });
      case "QQQQ":
      default:
        return r.quarter(a, {
          width: "wide",
          context: "formatting"
        }) || r.quarter(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.quarter(a, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 4;
  }
  set(a, n, r) {
    return a.setMonth((r - 1) * 3, 1), a.setHours(0, 0, 0, 0), a;
  }
}
class vm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 120);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "Q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "q":
      case "qq":
        return Xe(n.length, a);
      case "qo":
        return r.ordinalNumber(a, { unit: "quarter" });
      case "qqq":
        return r.quarter(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.quarter(a, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqqq":
        return r.quarter(a, {
          width: "narrow",
          context: "standalone"
        });
      case "qqqq":
      default:
        return r.quarter(a, {
          width: "wide",
          context: "standalone"
        }) || r.quarter(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.quarter(a, {
          width: "narrow",
          context: "standalone"
        });
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 4;
  }
  set(a, n, r) {
    return a.setMonth((r - 1) * 3, 1), a.setHours(0, 0, 0, 0), a;
  }
}
class mm extends De {
  constructor() {
    super(...arguments);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "L",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
    fe(this, "priority", 110);
  }
  parse(a, n, r) {
    const l = (o) => o - 1;
    switch (n) {
      case "M":
        return Ze(
          Ue(Je.month, a),
          l
        );
      case "MM":
        return Ze(Xe(2, a), l);
      case "Mo":
        return Ze(
          r.ordinalNumber(a, {
            unit: "month"
          }),
          l
        );
      case "MMM":
        return r.month(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.month(a, { width: "narrow", context: "formatting" });
      case "MMMMM":
        return r.month(a, {
          width: "narrow",
          context: "formatting"
        });
      case "MMMM":
      default:
        return r.month(a, { width: "wide", context: "formatting" }) || r.month(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.month(a, { width: "narrow", context: "formatting" });
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 11;
  }
  set(a, n, r) {
    return a.setMonth(r, 1), a.setHours(0, 0, 0, 0), a;
  }
}
class pm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 110);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "M",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    const l = (o) => o - 1;
    switch (n) {
      case "L":
        return Ze(
          Ue(Je.month, a),
          l
        );
      case "LL":
        return Ze(Xe(2, a), l);
      case "Lo":
        return Ze(
          r.ordinalNumber(a, {
            unit: "month"
          }),
          l
        );
      case "LLL":
        return r.month(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.month(a, { width: "narrow", context: "standalone" });
      case "LLLLL":
        return r.month(a, {
          width: "narrow",
          context: "standalone"
        });
      case "LLLL":
      default:
        return r.month(a, { width: "wide", context: "standalone" }) || r.month(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.month(a, { width: "narrow", context: "standalone" });
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 11;
  }
  set(a, n, r) {
    return a.setMonth(r, 1), a.setHours(0, 0, 0, 0), a;
  }
}
function hm(e, t, a) {
  const n = he(e), r = no(n, a) - t;
  return n.setDate(n.getDate() - r * 7), n;
}
class ym extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 100);
    fe(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "i",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "w":
        return Ue(Je.week, a);
      case "wo":
        return r.ordinalNumber(a, { unit: "week" });
      default:
        return Xe(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 53;
  }
  set(a, n, r, l) {
    return Ht(hm(a, r, l), l);
  }
}
function gm(e, t) {
  const a = he(e), n = to(a) - t;
  return a.setDate(a.getDate() - n * 7), a;
}
class bm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 100);
    fe(this, "incompatibleTokens", [
      "y",
      "Y",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "I":
        return Ue(Je.week, a);
      case "Io":
        return r.ordinalNumber(a, { unit: "week" });
      default:
        return Xe(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 53;
  }
  set(a, n, r) {
    return an(gm(a, r));
  }
}
const wm = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], km = [
  31,
  29,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31
];
class xm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "subPriority", 1);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "w",
      "I",
      "D",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "d":
        return Ue(Je.date, a);
      case "do":
        return r.ordinalNumber(a, { unit: "date" });
      default:
        return Xe(n.length, a);
    }
  }
  validate(a, n) {
    const r = a.getFullYear(), l = tu(r), o = a.getMonth();
    return l ? n >= 1 && n <= km[o] : n >= 1 && n <= wm[o];
  }
  set(a, n, r) {
    return a.setDate(r), a.setHours(0, 0, 0, 0), a;
  }
}
class _m extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "subpriority", 1);
    fe(this, "incompatibleTokens", [
      "Y",
      "R",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "I",
      "d",
      "E",
      "i",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    switch (n) {
      case "D":
      case "DD":
        return Ue(Je.dayOfYear, a);
      case "Do":
        return r.ordinalNumber(a, { unit: "date" });
      default:
        return Xe(n.length, a);
    }
  }
  validate(a, n) {
    const r = a.getFullYear();
    return tu(r) ? n >= 1 && n <= 366 : n >= 1 && n <= 365;
  }
  set(a, n, r) {
    return a.setMonth(0, r), a.setHours(0, 0, 0, 0), a;
  }
}
function lo(e, t, a) {
  var d, f, h, x;
  const n = ja(), r = (a == null ? void 0 : a.weekStartsOn) ?? ((f = (d = a == null ? void 0 : a.locale) == null ? void 0 : d.options) == null ? void 0 : f.weekStartsOn) ?? n.weekStartsOn ?? ((x = (h = n.locale) == null ? void 0 : h.options) == null ? void 0 : x.weekStartsOn) ?? 0, l = he(e), o = l.getDay(), s = (t % 7 + 7) % 7, c = 7 - r, u = t < 0 || t > 6 ? t - (o + c) % 7 : (s + c) % 7 - (o + c) % 7;
  return Et(l, u);
}
class Mm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "incompatibleTokens", ["D", "i", "e", "c", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "E":
      case "EE":
      case "EEE":
        return r.day(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
      case "EEEEE":
        return r.day(a, {
          width: "narrow",
          context: "formatting"
        });
      case "EEEEEE":
        return r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
      case "EEEE":
      default:
        return r.day(a, { width: "wide", context: "formatting" }) || r.day(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 6;
  }
  set(a, n, r, l) {
    return a = lo(a, r, l), a.setHours(0, 0, 0, 0), a;
  }
}
class Am extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "E",
      "i",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r, l) {
    const o = (i) => {
      const s = Math.floor((i - 1) / 7) * 7;
      return (i + l.weekStartsOn + 6) % 7 + s;
    };
    switch (n) {
      case "e":
      case "ee":
        return Ze(Xe(n.length, a), o);
      case "eo":
        return Ze(
          r.ordinalNumber(a, {
            unit: "day"
          }),
          o
        );
      case "eee":
        return r.day(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
      case "eeeee":
        return r.day(a, {
          width: "narrow",
          context: "formatting"
        });
      case "eeeeee":
        return r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
      case "eeee":
      default:
        return r.day(a, { width: "wide", context: "formatting" }) || r.day(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.day(a, { width: "short", context: "formatting" }) || r.day(a, { width: "narrow", context: "formatting" });
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 6;
  }
  set(a, n, r, l) {
    return a = lo(a, r, l), a.setHours(0, 0, 0, 0), a;
  }
}
class Tm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "incompatibleTokens", [
      "y",
      "R",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "I",
      "d",
      "D",
      "E",
      "i",
      "e",
      "t",
      "T"
    ]);
  }
  parse(a, n, r, l) {
    const o = (i) => {
      const s = Math.floor((i - 1) / 7) * 7;
      return (i + l.weekStartsOn + 6) % 7 + s;
    };
    switch (n) {
      case "c":
      case "cc":
        return Ze(Xe(n.length, a), o);
      case "co":
        return Ze(
          r.ordinalNumber(a, {
            unit: "day"
          }),
          o
        );
      case "ccc":
        return r.day(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.day(a, { width: "short", context: "standalone" }) || r.day(a, { width: "narrow", context: "standalone" });
      case "ccccc":
        return r.day(a, {
          width: "narrow",
          context: "standalone"
        });
      case "cccccc":
        return r.day(a, { width: "short", context: "standalone" }) || r.day(a, { width: "narrow", context: "standalone" });
      case "cccc":
      default:
        return r.day(a, { width: "wide", context: "standalone" }) || r.day(a, {
          width: "abbreviated",
          context: "standalone"
        }) || r.day(a, { width: "short", context: "standalone" }) || r.day(a, { width: "narrow", context: "standalone" });
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 6;
  }
  set(a, n, r, l) {
    return a = lo(a, r, l), a.setHours(0, 0, 0, 0), a;
  }
}
function Om(e, t) {
  const a = he(e), n = am(a), r = t - n;
  return Et(a, r);
}
class Pm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 90);
    fe(this, "incompatibleTokens", [
      "y",
      "Y",
      "u",
      "q",
      "Q",
      "M",
      "L",
      "w",
      "d",
      "D",
      "E",
      "e",
      "c",
      "t",
      "T"
    ]);
  }
  parse(a, n, r) {
    const l = (o) => o === 0 ? 7 : o;
    switch (n) {
      case "i":
      case "ii":
        return Xe(n.length, a);
      case "io":
        return r.ordinalNumber(a, { unit: "day" });
      case "iii":
        return Ze(
          r.day(a, {
            width: "abbreviated",
            context: "formatting"
          }) || r.day(a, {
            width: "short",
            context: "formatting"
          }) || r.day(a, {
            width: "narrow",
            context: "formatting"
          }),
          l
        );
      case "iiiii":
        return Ze(
          r.day(a, {
            width: "narrow",
            context: "formatting"
          }),
          l
        );
      case "iiiiii":
        return Ze(
          r.day(a, {
            width: "short",
            context: "formatting"
          }) || r.day(a, {
            width: "narrow",
            context: "formatting"
          }),
          l
        );
      case "iiii":
      default:
        return Ze(
          r.day(a, {
            width: "wide",
            context: "formatting"
          }) || r.day(a, {
            width: "abbreviated",
            context: "formatting"
          }) || r.day(a, {
            width: "short",
            context: "formatting"
          }) || r.day(a, {
            width: "narrow",
            context: "formatting"
          }),
          l
        );
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 7;
  }
  set(a, n, r) {
    return a = Om(a, r), a.setHours(0, 0, 0, 0), a;
  }
}
class Sm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 80);
    fe(this, "incompatibleTokens", ["b", "B", "H", "k", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "a":
      case "aa":
      case "aaa":
        return r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaaa":
        return r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "aaaa":
      default:
        return r.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(a, n, r) {
    return a.setHours(ro(r), 0, 0, 0), a;
  }
}
class Cm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 80);
    fe(this, "incompatibleTokens", ["a", "B", "H", "k", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "b":
      case "bb":
      case "bbb":
        return r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbbb":
        return r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "bbbb":
      default:
        return r.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(a, n, r) {
    return a.setHours(ro(r), 0, 0, 0), a;
  }
}
class Dm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 80);
    fe(this, "incompatibleTokens", ["a", "b", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "B":
      case "BB":
      case "BBB":
        return r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBBB":
        return r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
      case "BBBB":
      default:
        return r.dayPeriod(a, {
          width: "wide",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "abbreviated",
          context: "formatting"
        }) || r.dayPeriod(a, {
          width: "narrow",
          context: "formatting"
        });
    }
  }
  set(a, n, r) {
    return a.setHours(ro(r), 0, 0, 0), a;
  }
}
class $m extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 70);
    fe(this, "incompatibleTokens", ["H", "K", "k", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "h":
        return Ue(Je.hour12h, a);
      case "ho":
        return r.ordinalNumber(a, { unit: "hour" });
      default:
        return Xe(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 12;
  }
  set(a, n, r) {
    const l = a.getHours() >= 12;
    return l && r < 12 ? a.setHours(r + 12, 0, 0, 0) : !l && r === 12 ? a.setHours(0, 0, 0, 0) : a.setHours(r, 0, 0, 0), a;
  }
}
class Lm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 70);
    fe(this, "incompatibleTokens", ["a", "b", "h", "K", "k", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "H":
        return Ue(Je.hour23h, a);
      case "Ho":
        return r.ordinalNumber(a, { unit: "hour" });
      default:
        return Xe(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 23;
  }
  set(a, n, r) {
    return a.setHours(r, 0, 0, 0), a;
  }
}
class Em extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 70);
    fe(this, "incompatibleTokens", ["h", "H", "k", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "K":
        return Ue(Je.hour11h, a);
      case "Ko":
        return r.ordinalNumber(a, { unit: "hour" });
      default:
        return Xe(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 11;
  }
  set(a, n, r) {
    return a.getHours() >= 12 && r < 12 ? a.setHours(r + 12, 0, 0, 0) : a.setHours(r, 0, 0, 0), a;
  }
}
class Nm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 70);
    fe(this, "incompatibleTokens", ["a", "b", "h", "H", "K", "t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "k":
        return Ue(Je.hour24h, a);
      case "ko":
        return r.ordinalNumber(a, { unit: "hour" });
      default:
        return Xe(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 1 && n <= 24;
  }
  set(a, n, r) {
    const l = r <= 24 ? r % 24 : r;
    return a.setHours(l, 0, 0, 0), a;
  }
}
class Rm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 60);
    fe(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "m":
        return Ue(Je.minute, a);
      case "mo":
        return r.ordinalNumber(a, { unit: "minute" });
      default:
        return Xe(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 59;
  }
  set(a, n, r) {
    return a.setMinutes(r, 0, 0), a;
  }
}
class Im extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 50);
    fe(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(a, n, r) {
    switch (n) {
      case "s":
        return Ue(Je.second, a);
      case "so":
        return r.ordinalNumber(a, { unit: "second" });
      default:
        return Xe(n.length, a);
    }
  }
  validate(a, n) {
    return n >= 0 && n <= 59;
  }
  set(a, n, r) {
    return a.setSeconds(r, 0), a;
  }
}
class Hm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 30);
    fe(this, "incompatibleTokens", ["t", "T"]);
  }
  parse(a, n) {
    const r = (l) => Math.trunc(l * Math.pow(10, -n.length + 3));
    return Ze(Xe(n.length, a), r);
  }
  set(a, n, r) {
    return a.setMilliseconds(r), a;
  }
}
class zm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 10);
    fe(this, "incompatibleTokens", ["t", "T", "x"]);
  }
  parse(a, n) {
    switch (n) {
      case "X":
        return Ft(
          Yt.basicOptionalMinutes,
          a
        );
      case "XX":
        return Ft(Yt.basic, a);
      case "XXXX":
        return Ft(
          Yt.basicOptionalSeconds,
          a
        );
      case "XXXXX":
        return Ft(
          Yt.extendedOptionalSeconds,
          a
        );
      case "XXX":
      default:
        return Ft(Yt.extended, a);
    }
  }
  set(a, n, r) {
    return n.timestampIsSet ? a : Ce(
      a,
      a.getTime() - yr(a) - r
    );
  }
}
class Vm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 10);
    fe(this, "incompatibleTokens", ["t", "T", "X"]);
  }
  parse(a, n) {
    switch (n) {
      case "x":
        return Ft(
          Yt.basicOptionalMinutes,
          a
        );
      case "xx":
        return Ft(Yt.basic, a);
      case "xxxx":
        return Ft(
          Yt.basicOptionalSeconds,
          a
        );
      case "xxxxx":
        return Ft(
          Yt.extendedOptionalSeconds,
          a
        );
      case "xxx":
      default:
        return Ft(Yt.extended, a);
    }
  }
  set(a, n, r) {
    return n.timestampIsSet ? a : Ce(
      a,
      a.getTime() - yr(a) - r
    );
  }
}
class Bm extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 40);
    fe(this, "incompatibleTokens", "*");
  }
  parse(a) {
    return Zs(a);
  }
  set(a, n, r) {
    return [Ce(a, r * 1e3), { timestampIsSet: !0 }];
  }
}
class Ym extends De {
  constructor() {
    super(...arguments);
    fe(this, "priority", 20);
    fe(this, "incompatibleTokens", "*");
  }
  parse(a) {
    return Zs(a);
  }
  set(a, n, r) {
    return [Ce(a, r), { timestampIsSet: !0 }];
  }
}
const Fm = {
  G: new im(),
  y: new sm(),
  Y: new um(),
  R: new cm(),
  u: new dm(),
  Q: new fm(),
  q: new vm(),
  M: new mm(),
  L: new pm(),
  w: new ym(),
  I: new bm(),
  d: new xm(),
  D: new _m(),
  E: new Mm(),
  e: new Am(),
  c: new Tm(),
  i: new Pm(),
  a: new Sm(),
  b: new Cm(),
  B: new Dm(),
  h: new $m(),
  H: new Lm(),
  K: new Em(),
  k: new Nm(),
  m: new Rm(),
  s: new Im(),
  S: new Hm(),
  X: new zm(),
  x: new Vm(),
  t: new Bm(),
  T: new Ym()
}, jm = /[yYQqMLwIdDecihHKkms]o|(\w)\1*|''|'(''|[^'])+('|$)|./g, Um = /P+p+|P+|p+|''|'(''|[^'])+('|$)|./g, Wm = /^'([^]*?)'?$/, qm = /''/g, Km = /\S/, Xm = /[a-zA-Z]/;
function Tl(e, t, a, n) {
  var g, D, k, b, m, M, $, C;
  const r = tm(), l = (n == null ? void 0 : n.locale) ?? r.locale ?? Ks, o = (n == null ? void 0 : n.firstWeekContainsDate) ?? ((D = (g = n == null ? void 0 : n.locale) == null ? void 0 : g.options) == null ? void 0 : D.firstWeekContainsDate) ?? r.firstWeekContainsDate ?? ((b = (k = r.locale) == null ? void 0 : k.options) == null ? void 0 : b.firstWeekContainsDate) ?? 1, i = (n == null ? void 0 : n.weekStartsOn) ?? ((M = (m = n == null ? void 0 : n.locale) == null ? void 0 : m.options) == null ? void 0 : M.weekStartsOn) ?? r.weekStartsOn ?? ((C = ($ = r.locale) == null ? void 0 : $.options) == null ? void 0 : C.weekStartsOn) ?? 0;
  if (t === "")
    return e === "" ? he(a) : Ce(a, NaN);
  const s = {
    firstWeekContainsDate: o,
    weekStartsOn: i,
    locale: l
  }, c = [new om()], u = t.match(Um).map((_) => {
    const W = _[0];
    if (W in Ml) {
      const F = Ml[W];
      return F(_, l.formatLong);
    }
    return _;
  }).join("").match(jm), d = [];
  for (let _ of u) {
    !(n != null && n.useAdditionalWeekYearTokens) && Gs(_) && Al(_, t, e), !(n != null && n.useAdditionalDayOfYearTokens) && Qs(_) && Al(_, t, e);
    const W = _[0], F = Fm[W];
    if (F) {
      const { incompatibleTokens: H } = F;
      if (Array.isArray(H)) {
        const se = d.find(
          (de) => H.includes(de.token) || de.token === W
        );
        if (se)
          throw new RangeError(
            `The format string mustn't contain \`${se.fullToken}\` and \`${_}\` at the same time`
          );
      } else if (F.incompatibleTokens === "*" && d.length > 0)
        throw new RangeError(
          `The format string mustn't contain \`${_}\` and any other token at the same time`
        );
      d.push({ token: W, fullToken: _ });
      const ne = F.run(
        e,
        _,
        l.match,
        s
      );
      if (!ne)
        return Ce(a, NaN);
      c.push(ne.setter), e = ne.rest;
    } else {
      if (W.match(Xm))
        throw new RangeError(
          "Format string contains an unescaped latin alphabet character `" + W + "`"
        );
      if (_ === "''" ? _ = "'" : W === "'" && (_ = Qm(_)), e.indexOf(_) === 0)
        e = e.slice(_.length);
      else
        return Ce(a, NaN);
    }
  }
  if (e.length > 0 && Km.test(e))
    return Ce(a, NaN);
  const f = c.map((_) => _.priority).sort((_, W) => W - _).filter((_, W, F) => F.indexOf(_) === W).map(
    (_) => c.filter((W) => W.priority === _).sort((W, F) => F.subPriority - W.subPriority)
  ).map((_) => _[0]);
  let h = he(a);
  if (isNaN(h.getTime()))
    return Ce(a, NaN);
  const x = {};
  for (const _ of f) {
    if (!_.validate(h, s))
      return Ce(a, NaN);
    const W = _.set(h, x, s);
    Array.isArray(W) ? (h = W[0], Object.assign(x, W[1])) : h = W;
  }
  return Ce(a, h);
}
function Qm(e) {
  return e.match(Wm)[1].replace(qm, "'");
}
function vi(e, t) {
  const a = La(e), n = La(t);
  return +a == +n;
}
function Gm(e, t) {
  return Et(e, -t);
}
function au(e, t) {
  const a = he(e), n = a.getFullYear(), r = a.getDate(), l = Ce(e, 0);
  l.setFullYear(n, t, 15), l.setHours(0, 0, 0, 0);
  const o = em(l);
  return a.setMonth(t, Math.min(r, o)), a;
}
function Ee(e, t) {
  let a = he(e);
  return isNaN(+a) ? Ce(e, NaN) : (t.year != null && a.setFullYear(t.year), t.month != null && (a = au(a, t.month)), t.date != null && a.setDate(t.date), t.hours != null && a.setHours(t.hours), t.minutes != null && a.setMinutes(t.minutes), t.seconds != null && a.setSeconds(t.seconds), t.milliseconds != null && a.setMilliseconds(t.milliseconds), a);
}
function Jm(e, t) {
  const a = he(e);
  return a.setHours(t), a;
}
function nu(e, t) {
  const a = he(e);
  return a.setMilliseconds(t), a;
}
function Zm(e, t) {
  const a = he(e);
  return a.setMinutes(t), a;
}
function ru(e, t) {
  const a = he(e);
  return a.setSeconds(t), a;
}
function Ut(e, t) {
  const a = he(e);
  return isNaN(+a) ? Ce(e, NaN) : (a.setFullYear(t), a);
}
function ln(e, t) {
  return It(e, -t);
}
function e2(e, t) {
  const {
    years: a = 0,
    months: n = 0,
    weeks: r = 0,
    days: l = 0,
    hours: o = 0,
    minutes: i = 0,
    seconds: s = 0
  } = t, c = ln(e, n + a * 12), u = Gm(c, l + r * 7), d = i + o * 60, h = (s + d * 60) * 1e3;
  return Ce(e, u.getTime() - h);
}
function lu(e, t) {
  return eo(e, -t);
}
function dn() {
  const e = wr();
  return P(), j(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img",
      ...e
    },
    [
      ce("path", {
        d: "M29.333 8c0-2.208-1.792-4-4-4h-18.667c-2.208 0-4 1.792-4 4v18.667c0 2.208 1.792 4 4 4h18.667c2.208 0 4-1.792 4-4v-18.667zM26.667 8v18.667c0 0.736-0.597 1.333-1.333 1.333 0 0-18.667 0-18.667 0-0.736 0-1.333-0.597-1.333-1.333 0 0 0-18.667 0-18.667 0-0.736 0.597-1.333 1.333-1.333 0 0 18.667 0 18.667 0 0.736 0 1.333 0.597 1.333 1.333z"
      }),
      ce("path", {
        d: "M20 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
      }),
      ce("path", {
        d: "M9.333 2.667v5.333c0 0.736 0.597 1.333 1.333 1.333s1.333-0.597 1.333-1.333v-5.333c0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
      }),
      ce("path", {
        d: "M4 14.667h24c0.736 0 1.333-0.597 1.333-1.333s-0.597-1.333-1.333-1.333h-24c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333z"
      })
    ]
  );
}
dn.compatConfig = {
  MODE: 3
};
function ou() {
  return P(), j(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ce("path", {
        d: "M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
      }),
      ce("path", {
        d: "M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
      })
    ]
  );
}
ou.compatConfig = {
  MODE: 3
};
function oo() {
  return P(), j(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ce("path", {
        d: "M20.943 23.057l-7.057-7.057c0 0 7.057-7.057 7.057-7.057 0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0l-8 8c-0.521 0.521-0.521 1.365 0 1.885l8 8c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885z"
      })
    ]
  );
}
oo.compatConfig = {
  MODE: 3
};
function io() {
  return P(), j(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ce("path", {
        d: "M12.943 24.943l8-8c0.521-0.521 0.521-1.365 0-1.885l-8-8c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885l7.057 7.057c0 0-7.057 7.057-7.057 7.057-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0z"
      })
    ]
  );
}
io.compatConfig = {
  MODE: 3
};
function so() {
  return P(), j(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ce("path", {
        d: "M16 1.333c-8.095 0-14.667 6.572-14.667 14.667s6.572 14.667 14.667 14.667c8.095 0 14.667-6.572 14.667-14.667s-6.572-14.667-14.667-14.667zM16 4c6.623 0 12 5.377 12 12s-5.377 12-12 12c-6.623 0-12-5.377-12-12s5.377-12 12-12z"
      }),
      ce("path", {
        d: "M14.667 8v8c0 0.505 0.285 0.967 0.737 1.193l5.333 2.667c0.658 0.329 1.46 0.062 1.789-0.596s0.062-1.46-0.596-1.789l-4.596-2.298c0 0 0-7.176 0-7.176 0-0.736-0.597-1.333-1.333-1.333s-1.333 0.597-1.333 1.333z"
      })
    ]
  );
}
so.compatConfig = {
  MODE: 3
};
function uo() {
  return P(), j(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ce("path", {
        d: "M24.943 19.057l-8-8c-0.521-0.521-1.365-0.521-1.885 0l-8 8c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l7.057-7.057c0 0 7.057 7.057 7.057 7.057 0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885z"
      })
    ]
  );
}
uo.compatConfig = {
  MODE: 3
};
function co() {
  return P(), j(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 32 32",
      fill: "currentColor",
      "aria-hidden": "true",
      class: "dp__icon",
      role: "img"
    },
    [
      ce("path", {
        d: "M7.057 12.943l8 8c0.521 0.521 1.365 0.521 1.885 0l8-8c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0l-7.057 7.057c0 0-7.057-7.057-7.057-7.057-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
      })
    ]
  );
}
co.compatConfig = {
  MODE: 3
};
const wt = (e, t) => t ? new Date(e.toLocaleString("en-US", { timeZone: t })) : new Date(e), fo = (e, t, a) => Ol(e, t, a) || G(), t2 = (e, t, a) => {
  const n = t.dateInTz ? wt(new Date(e), t.dateInTz) : G(e);
  return a ? ht(n, !0) : n;
}, Ol = (e, t, a) => {
  if (!e)
    return null;
  const n = a ? ht(G(e), !0) : G(e);
  return t ? t.exactMatch ? t2(e, t, a) : wt(n, t.timezone) : n;
}, a2 = (e) => {
  if (!e)
    return 0;
  const t = /* @__PURE__ */ new Date(), a = new Date(t.toLocaleString("en-US", { timeZone: "UTC" })), n = new Date(t.toLocaleString("en-US", { timeZone: e })), r = n.getTimezoneOffset() / 60;
  return (+a - +n) / (1e3 * 60 * 60) - r;
};
var Dt = /* @__PURE__ */ ((e) => (e.month = "month", e.year = "year", e))(Dt || {}), Ca = /* @__PURE__ */ ((e) => (e.top = "top", e.bottom = "bottom", e))(Ca || {}), Ea = /* @__PURE__ */ ((e) => (e.header = "header", e.calendar = "calendar", e.timePicker = "timePicker", e))(Ea || {}), ct = /* @__PURE__ */ ((e) => (e.month = "month", e.year = "year", e.calendar = "calendar", e.time = "time", e.minutes = "minutes", e.hours = "hours", e.seconds = "seconds", e))(ct || {});
const n2 = ["timestamp", "date", "iso"];
var vt = /* @__PURE__ */ ((e) => (e.up = "up", e.down = "down", e.left = "left", e.right = "right", e))(vt || {}), He = /* @__PURE__ */ ((e) => (e.arrowUp = "ArrowUp", e.arrowDown = "ArrowDown", e.arrowLeft = "ArrowLeft", e.arrowRight = "ArrowRight", e.enter = "Enter", e.space = " ", e.esc = "Escape", e.tab = "Tab", e.home = "Home", e.end = "End", e.pageUp = "PageUp", e.pageDown = "PageDown", e))(He || {});
function mi(e) {
  return (t) => new Intl.DateTimeFormat(e, { weekday: "short", timeZone: "UTC" }).format(/* @__PURE__ */ new Date(`2017-01-0${t}T00:00:00+00:00`)).slice(0, 2);
}
function r2(e) {
  return (t) => Wt(wt(/* @__PURE__ */ new Date(`2017-01-0${t}T00:00:00+00:00`), "UTC"), "EEEEEE", { locale: e });
}
const l2 = (e, t, a) => {
  const n = [1, 2, 3, 4, 5, 6, 7];
  let r;
  if (e !== null)
    try {
      r = n.map(r2(e));
    } catch {
      r = n.map(mi(t));
    }
  else
    r = n.map(mi(t));
  const l = r.slice(0, a), o = r.slice(a + 1, r.length);
  return [r[a]].concat(...o).concat(...l);
}, vo = (e, t, a) => {
  const n = [];
  for (let r = +e[0]; r <= +e[1]; r++)
    n.push({ value: +r, text: cu(r, t) });
  return a ? n.reverse() : n;
}, iu = (e, t, a) => {
  const n = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((l) => {
    const o = l < 10 ? `0${l}` : l;
    return /* @__PURE__ */ new Date(`2017-${o}-01T00:00:00+00:00`);
  });
  if (e !== null)
    try {
      const l = a === "long" ? "LLLL" : "LLL";
      return n.map((o, i) => {
        const s = Wt(wt(o, "UTC"), l, { locale: e });
        return {
          text: s.charAt(0).toUpperCase() + s.substring(1),
          value: i
        };
      });
    } catch {
    }
  const r = new Intl.DateTimeFormat(t, { month: a, timeZone: "UTC" });
  return n.map((l, o) => {
    const i = r.format(l);
    return {
      text: i.charAt(0).toUpperCase() + i.substring(1),
      value: o
    };
  });
}, o2 = (e) => [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11][e], nt = (e) => {
  const t = p(e);
  return t != null && t.$el ? t == null ? void 0 : t.$el : t;
}, i2 = (e) => ({ type: "dot", ...e ?? {} }), su = (e) => Array.isArray(e) ? !!e[0] && !!e[1] : !1, mo = {
  prop: (e) => `"${e}" prop must be enabled!`,
  dateArr: (e) => `You need to use array as "model-value" binding in order to support "${e}"`
}, lt = (e) => e, pi = (e) => e === 0 ? e : !e || isNaN(+e) ? null : +e, hi = (e) => e === null, uu = (e) => {
  if (e)
    return [...e.querySelectorAll("input, button, select, textarea, a[href]")][0];
}, s2 = (e) => {
  const t = [], a = (n) => n.filter((r) => r);
  for (let n = 0; n < e.length; n += 3) {
    const r = [e[n], e[n + 1], e[n + 2]];
    t.push(a(r));
  }
  return t;
}, Ln = (e, t, a) => {
  const n = a != null, r = t != null;
  if (!n && !r)
    return !1;
  const l = +a, o = +t;
  return n && r ? +e > l || +e < o : n ? +e > l : r ? +e < o : !1;
}, on = (e, t) => s2(e).map((a) => a.map((n) => {
  const { active: r, disabled: l, isBetween: o, highlighted: i } = t(n);
  return {
    ...n,
    active: r,
    disabled: l,
    className: {
      dp__overlay_cell_active: r,
      dp__overlay_cell: !r,
      dp__overlay_cell_disabled: l,
      dp__overlay_cell_pad: !0,
      dp__overlay_cell_active_disabled: l && r,
      dp__cell_in_between: o,
      "dp--highlighted": i
    }
  };
})), pa = (e, t, a = !1) => {
  e && t.allowStopPropagation && (a && e.stopImmediatePropagation(), e.stopPropagation());
}, u2 = () => [
  "a[href]",
  "area[href]",
  "input:not([disabled]):not([type='hidden'])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "button:not([disabled])",
  "[tabindex]:not([tabindex='-1'])",
  "[data-datepicker-instance]"
].join(", ");
function c2(e, t) {
  let a = [...document.querySelectorAll(u2())];
  a = a.filter((r) => !e.contains(r) || r.hasAttribute("data-datepicker-instance"));
  const n = a.indexOf(e);
  if (n >= 0 && (t ? n - 1 >= 0 : n + 1 <= a.length))
    return a[n + (t ? -1 : 1)];
}
const Pl = (e, t) => e == null ? void 0 : e.querySelector(`[data-dp-element="${t}"]`), cu = (e, t) => new Intl.NumberFormat(t, { useGrouping: !1, style: "decimal" }).format(e), po = (e) => Wt(e, "dd-MM-yyyy"), Gr = (e) => Array.isArray(e), br = (e, t) => t.get(po(e)), d2 = (e, t) => e ? t ? t instanceof Map ? !!br(e, t) : t(G(e)) : !1 : !0, pt = (e, t, a = !1, n) => {
  if (e.key === He.enter || e.key === He.space)
    return a && e.preventDefault(), t();
  if (n)
    return n(e);
}, f2 = () => ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].some(
  (e) => navigator.userAgent.includes(e)
) || navigator.userAgent.includes("Mac") && "ontouchend" in document, yi = (e, t, a, n, r, l) => {
  const o = Tl(e, t.slice(0, e.length), /* @__PURE__ */ new Date(), { locale: l });
  return xn(o) && js(o) ? n || r ? o : Ee(o, {
    hours: +a.hours,
    minutes: +(a == null ? void 0 : a.minutes),
    seconds: +(a == null ? void 0 : a.seconds),
    milliseconds: 0
  }) : null;
}, v2 = (e, t, a, n, r, l) => {
  const o = Array.isArray(a) ? a[0] : a;
  if (typeof t == "string")
    return yi(e, t, o, n, r, l);
  if (Array.isArray(t)) {
    let i = null;
    for (const s of t)
      if (i = yi(e, s, o, n, r, l), i)
        break;
    return i;
  }
  return typeof t == "function" ? t(e) : null;
}, G = (e) => e ? new Date(e) : /* @__PURE__ */ new Date(), m2 = (e, t, a) => {
  if (t) {
    const r = (e.getMonth() + 1).toString().padStart(2, "0"), l = e.getDate().toString().padStart(2, "0"), o = e.getHours().toString().padStart(2, "0"), i = e.getMinutes().toString().padStart(2, "0"), s = a ? e.getSeconds().toString().padStart(2, "0") : "00";
    return `${e.getFullYear()}-${r}-${l}T${o}:${i}:${s}.000Z`;
  }
  const n = Date.UTC(
    e.getUTCFullYear(),
    e.getUTCMonth(),
    e.getUTCDate(),
    e.getUTCHours(),
    e.getUTCMinutes(),
    e.getUTCSeconds()
  );
  return new Date(n).toISOString();
}, ht = (e, t) => {
  const a = G(JSON.parse(JSON.stringify(e))), n = Ee(a, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  return t ? iv(n) : n;
}, ha = (e, t, a, n) => {
  let r = e ? G(e) : G();
  return (t || t === 0) && (r = Jm(r, +t)), (a || a === 0) && (r = Zm(r, +a)), (n || n === 0) && (r = ru(r, +n)), nu(r, 0);
}, We = (e, t) => !e || !t ? !1 : $n(ht(e), ht(t)), Se = (e, t) => !e || !t ? !1 : Qa(ht(e), ht(t)), Ge = (e, t) => !e || !t ? !1 : rn(ht(e), ht(t)), Dr = (e, t, a) => e != null && e[0] && e != null && e[1] ? Ge(a, e[0]) && We(a, e[1]) : e != null && e[0] && t ? Ge(a, e[0]) && We(a, t) || We(a, e[0]) && Ge(a, t) : !1, Nt = (e) => {
  const t = Ee(new Date(e), { date: 1 });
  return ht(t);
}, Jr = (e, t, a) => t && (a || a === 0) ? Object.fromEntries(
  ["hours", "minutes", "seconds"].map((n) => n === t ? [n, a] : [n, isNaN(+e[n]) ? void 0 : +e[n]])
) : {
  hours: isNaN(+e.hours) ? void 0 : +e.hours,
  minutes: isNaN(+e.minutes) ? void 0 : +e.minutes,
  seconds: isNaN(+e.seconds) ? void 0 : +e.seconds
}, Na = (e) => ({
  hours: na(e),
  minutes: _a(e),
  seconds: nn(e)
}), du = (e, t) => {
  if (t) {
    const a = _e(G(t));
    if (a > e)
      return 12;
    if (a === e)
      return Pe(G(t));
  }
}, fu = (e, t) => {
  if (t) {
    const a = _e(G(t));
    return a < e ? -1 : a === e ? Pe(G(t)) : void 0;
  }
}, sn = (e) => {
  if (e)
    return _e(G(e));
}, vu = (e, t) => {
  const a = Ge(e, t) ? t : e, n = Ge(t, e) ? t : e;
  return Us({ start: a, end: n });
}, p2 = (e) => {
  const t = It(e, 1);
  return { month: Pe(t), year: _e(t) };
}, Xt = (e, t) => {
  const a = Ht(e, { weekStartsOn: +t }), n = qs(e, { weekStartsOn: +t });
  return [a, n];
}, mu = (e, t) => {
  const a = {
    hours: na(G()),
    minutes: _a(G()),
    seconds: t ? nn(G()) : 0
  };
  return Object.assign(a, e);
}, va = (e, t, a) => [Ee(G(e), { date: 1 }), Ee(G(), { month: t, year: a, date: 1 })], Qt = (e, t, a) => {
  let n = e ? G(e) : G();
  return (t || t === 0) && (n = au(n, t)), a && (n = Ut(n, a)), n;
}, pu = (e, t, a, n, r) => {
  if (!n || r && !t || !r && !a)
    return !1;
  const l = r ? It(e, 1) : ln(e, 1), o = [Pe(l), _e(l)];
  return r ? !y2(...o, t) : !h2(...o, a);
}, h2 = (e, t, a) => We(...va(a, e, t)) || Se(...va(a, e, t)), y2 = (e, t, a) => Ge(...va(a, e, t)) || Se(...va(a, e, t)), hu = (e, t, a, n, r, l, o) => {
  if (typeof t == "function" && !o)
    return t(e);
  const i = a ? { locale: a } : void 0;
  return Array.isArray(e) ? `${Wt(e[0], l, i)}${r && !e[1] ? "" : n}${e[1] ? Wt(e[1], l, i) : ""}` : Wt(e, l, i);
}, Wa = (e) => {
  if (e)
    return null;
  throw new Error(mo.prop("partial-range"));
}, ar = (e, t) => {
  if (t)
    return e();
  throw new Error(mo.prop("range"));
}, Sl = (e) => Array.isArray(e) ? xn(e[0]) && (e[1] ? xn(e[1]) : !0) : e ? xn(e) : !1, g2 = (e, t) => Ee(t ?? G(), {
  hours: +e.hours || 0,
  minutes: +e.minutes || 0,
  seconds: +e.seconds || 0
}), Zr = (e, t, a, n) => {
  if (!e)
    return !0;
  if (n) {
    const r = a === "max" ? $n(e, t) : rn(e, t), l = { seconds: 0, milliseconds: 0 };
    return r || Qa(Ee(e, l), Ee(t, l));
  }
  return a === "max" ? e.getTime() <= t.getTime() : e.getTime() >= t.getTime();
}, el = (e, t, a) => e ? g2(e, t) : G(a ?? t), gi = (e, t, a, n, r) => {
  if (Array.isArray(n)) {
    const o = el(e, n[0], t), i = el(e, n[1], t);
    return Zr(n[0], o, a, !!t) && Zr(n[1], i, a, !!t) && r;
  }
  const l = el(e, n, t);
  return Zr(n, l, a, !!t) && r;
}, tl = (e) => Ee(G(), Na(e)), b2 = (e, t) => e instanceof Map ? Array.from(e.values()).filter((a) => _e(G(a)) === t).map((a) => Pe(a)) : [], yu = (e, t, a) => typeof e == "function" ? e({ month: t, year: a }) : !!e.months.find((n) => n.month === t && n.year === a), ho = (e, t) => typeof e == "function" ? e(t) : e.years.includes(t), gu = (e) => Wt(e, "yyyy-MM-dd"), yn = un({
  menuFocused: !1,
  shiftKeyInMenu: !1
}), bu = () => {
  const e = (a) => {
    yn.menuFocused = a;
  }, t = (a) => {
    yn.shiftKeyInMenu !== a && (yn.shiftKeyInMenu = a);
  };
  return {
    control: E(() => ({ shiftKeyInMenu: yn.shiftKeyInMenu, menuFocused: yn.menuFocused })),
    setMenuFocused: e,
    setShiftKey: t
  };
}, Ie = un({
  monthYear: [],
  calendar: [],
  time: [],
  actionRow: [],
  selectionGrid: [],
  timePicker: {
    0: [],
    1: []
  },
  monthPicker: []
}), al = U(null), nr = U(!1), nl = U(!1), rl = U(!1), ll = U(!1), ut = U(0), Qe = U(0), Aa = () => {
  const e = E(() => nr.value ? [...Ie.selectionGrid, Ie.actionRow].filter((d) => d.length) : nl.value ? [
    ...Ie.timePicker[0],
    ...Ie.timePicker[1],
    ll.value ? [] : [al.value],
    Ie.actionRow
  ].filter((d) => d.length) : rl.value ? [...Ie.monthPicker, Ie.actionRow] : [Ie.monthYear, ...Ie.calendar, Ie.time, Ie.actionRow].filter((d) => d.length)), t = (d) => {
    ut.value = d ? ut.value + 1 : ut.value - 1;
    let f = null;
    e.value[Qe.value] && (f = e.value[Qe.value][ut.value]), !f && e.value[Qe.value + (d ? 1 : -1)] ? (Qe.value = Qe.value + (d ? 1 : -1), ut.value = d ? 0 : e.value[Qe.value].length - 1) : f || (ut.value = d ? ut.value - 1 : ut.value + 1);
  }, a = (d) => {
    Qe.value === 0 && !d || Qe.value === e.value.length && d || (Qe.value = d ? Qe.value + 1 : Qe.value - 1, e.value[Qe.value] ? e.value[Qe.value] && !e.value[Qe.value][ut.value] && ut.value !== 0 && (ut.value = e.value[Qe.value].length - 1) : Qe.value = d ? Qe.value - 1 : Qe.value + 1);
  }, n = (d) => {
    let f = null;
    e.value[Qe.value] && (f = e.value[Qe.value][ut.value]), f ? f.focus({ preventScroll: !nr.value }) : ut.value = d ? ut.value - 1 : ut.value + 1;
  }, r = () => {
    t(!0), n(!0);
  }, l = () => {
    t(!1), n(!1);
  }, o = () => {
    a(!1), n(!0);
  }, i = () => {
    a(!0), n(!0);
  }, s = (d, f) => {
    Ie[f] = d;
  }, c = (d, f) => {
    Ie[f] = d;
  }, u = () => {
    ut.value = 0, Qe.value = 0;
  };
  return {
    buildMatrix: s,
    buildMultiLevelMatrix: c,
    setTimePickerBackRef: (d) => {
      al.value = d;
    },
    setSelectionGrid: (d) => {
      nr.value = d, u(), d || (Ie.selectionGrid = []);
    },
    setTimePicker: (d, f = !1) => {
      nl.value = d, ll.value = f, u(), d || (Ie.timePicker[0] = [], Ie.timePicker[1] = []);
    },
    setTimePickerElements: (d, f = 0) => {
      Ie.timePicker[f] = d;
    },
    arrowRight: r,
    arrowLeft: l,
    arrowUp: o,
    arrowDown: i,
    clearArrowNav: () => {
      Ie.monthYear = [], Ie.calendar = [], Ie.time = [], Ie.actionRow = [], Ie.selectionGrid = [], Ie.timePicker[0] = [], Ie.timePicker[1] = [], nr.value = !1, nl.value = !1, ll.value = !1, rl.value = !1, u(), al.value = null;
    },
    setMonthPicker: (d) => {
      rl.value = d, u();
    },
    refSets: Ie
    // exposed for testing
  };
}, bi = (e) => ({
  menuAppearTop: "dp-menu-appear-top",
  menuAppearBottom: "dp-menu-appear-bottom",
  open: "dp-slide-down",
  close: "dp-slide-up",
  next: "calendar-next",
  previous: "calendar-prev",
  vNext: "dp-slide-up",
  vPrevious: "dp-slide-down",
  ...e ?? {}
}), w2 = (e) => ({
  toggleOverlay: "Toggle overlay",
  menu: "Datepicker menu",
  input: "Datepicker input",
  openTimePicker: "Open time picker",
  closeTimePicker: "Close time Picker",
  incrementValue: (t) => `Increment ${t}`,
  decrementValue: (t) => `Decrement ${t}`,
  openTpOverlay: (t) => `Open ${t} overlay`,
  amPmButton: "Switch AM/PM mode",
  openYearsOverlay: "Open years overlay",
  openMonthsOverlay: "Open months overlay",
  nextMonth: "Next month",
  prevMonth: "Previous month",
  nextYear: "Next year",
  prevYear: "Previous year",
  day: void 0,
  weekDay: void 0,
  clearInput: "Clear value",
  calendarIcon: "Calendar icon",
  timePicker: "Time picker",
  monthPicker: (t) => `Month picker${t ? " overlay" : ""}`,
  yearPicker: (t) => `Year picker${t ? " overlay" : ""}`,
  timeOverlay: (t) => `${t} overlay`,
  ...e ?? {}
}), wi = (e) => e ? typeof e == "boolean" ? e ? 2 : 0 : +e >= 2 ? +e : 2 : 0, k2 = (e) => {
  const t = typeof e == "object" && e, a = {
    static: !0,
    solo: !1
  };
  if (!e)
    return { ...a, count: wi(!1) };
  const n = t ? e : {}, r = t ? n.count ?? !0 : e, l = wi(r);
  return Object.assign(a, n, { count: l });
}, x2 = (e, t, a) => e || (typeof a == "string" ? a : t), _2 = (e) => typeof e == "boolean" ? e ? bi({}) : !1 : bi(e), M2 = (e) => {
  const t = {
    enterSubmit: !0,
    tabSubmit: !0,
    openMenu: "open",
    selectOnFocus: !1,
    rangeSeparator: " - "
  };
  return typeof e == "object" ? { ...t, ...e ?? {}, enabled: !0 } : { ...t, enabled: e };
}, A2 = (e) => ({
  months: [],
  years: [],
  times: { hours: [], minutes: [], seconds: [] },
  ...e ?? {}
}), T2 = (e) => ({
  showSelect: !0,
  showCancel: !0,
  showNow: !1,
  showPreview: !0,
  ...e ?? {}
}), O2 = (e) => {
  const t = { input: !1 };
  return typeof e == "object" ? { ...t, ...e ?? {}, enabled: !0 } : {
    enabled: e,
    ...t
  };
}, P2 = (e) => ({ allowStopPropagation: !0, closeOnScroll: !1, modeHeight: 255, allowPreventDefault: !1, closeOnClearValue: !0, closeOnAutoApply: !0, noSwipe: !1, keepActionRow: !1, onClickOutside: void 0, tabOutClosesMenu: !0, arrowLeft: void 0, keepViewOnOffsetClick: !1, timeArrowHoldThreshold: 0, shadowDom: !1, ...e ?? {} }), S2 = (e) => {
  const t = {
    dates: Array.isArray(e) ? e.map((a) => G(a)) : [],
    years: [],
    months: [],
    quarters: [],
    weeks: [],
    weekdays: [],
    options: { highlightDisabled: !1 }
  };
  return typeof e == "function" ? e : { ...t, ...e ?? {} };
}, C2 = (e) => typeof e == "object" ? {
  type: (e == null ? void 0 : e.type) ?? "local",
  hideOnOffsetDates: (e == null ? void 0 : e.hideOnOffsetDates) ?? !1
} : {
  type: e,
  hideOnOffsetDates: !1
}, D2 = (e) => {
  const t = {
    noDisabledRange: !1,
    showLastInRange: !0,
    minMaxRawRange: !1,
    partialRange: !0,
    disableTimeRangeValidation: !1,
    maxRange: void 0,
    minRange: void 0,
    autoRange: void 0,
    fixedStart: !1,
    fixedEnd: !1
  };
  return typeof e == "object" ? { enabled: !0, ...t, ...e } : {
    enabled: e,
    ...t
  };
}, $2 = (e) => e ? typeof e == "string" ? {
  timezone: e,
  exactMatch: !1,
  dateInTz: void 0,
  emitTimezone: void 0,
  convertModel: !0
} : {
  timezone: e.timezone,
  exactMatch: e.exactMatch ?? !1,
  dateInTz: e.dateInTz ?? void 0,
  emitTimezone: e.emitTimezone ?? void 0,
  convertModel: e.convertModel ?? !0
} : { timezone: void 0, exactMatch: !1, emitTimezone: void 0 }, ol = (e, t, a) => new Map(
  e.map((n) => {
    const r = fo(n, t, a);
    return [po(r), r];
  })
), L2 = (e, t) => e.length ? new Map(
  e.map((a) => {
    const n = fo(a.date, t);
    return [po(n), a];
  })
) : null, E2 = (e) => {
  var t;
  return {
    minDate: Ol(e.minDate, e.timezone, e.isSpecific),
    maxDate: Ol(e.maxDate, e.timezone, e.isSpecific),
    disabledDates: Gr(e.disabledDates) ? ol(e.disabledDates, e.timezone, e.isSpecific) : e.disabledDates,
    allowedDates: Gr(e.allowedDates) ? ol(e.allowedDates, e.timezone, e.isSpecific) : null,
    highlight: typeof e.highlight == "object" && Gr((t = e.highlight) == null ? void 0 : t.dates) ? ol(e.highlight.dates, e.timezone) : e.highlight,
    markers: L2(e.markers, e.timezone)
  };
}, N2 = (e) => typeof e == "boolean" ? { enabled: e, dragSelect: !0, limit: null } : {
  enabled: !!e,
  limit: e.limit ? +e.limit : null,
  dragSelect: e.dragSelect ?? !0
}, R2 = (e) => ({
  ...Object.fromEntries(
    Object.keys(e).map((t) => {
      const a = t, n = e[a], r = typeof e[a] == "string" ? { [n]: !0 } : Object.fromEntries(n.map((l) => [l, !0]));
      return [t, r];
    })
  )
}), je = (e) => {
  const t = () => {
    const C = e.enableSeconds ? ":ss" : "", _ = e.enableMinutes ? ":mm" : "";
    return e.is24 ? `HH${_}${C}` : `hh${_}${C} aa`;
  }, a = () => {
    var C;
    return e.format ? e.format : e.monthPicker ? "MM/yyyy" : e.timePicker ? t() : e.weekPicker ? `${((C = D.value) == null ? void 0 : C.type) === "iso" ? "RR" : "ww"}-yyyy` : e.yearPicker ? "yyyy" : e.quarterPicker ? "QQQ/yyyy" : e.enableTimePicker ? `MM/dd/yyyy, ${t()}` : "MM/dd/yyyy";
  }, n = (C) => mu(C, e.enableSeconds), r = () => M.value.enabled ? e.startTime && Array.isArray(e.startTime) ? [n(e.startTime[0]), n(e.startTime[1])] : null : e.startTime && !Array.isArray(e.startTime) ? n(e.startTime) : null, l = E(() => k2(e.multiCalendars)), o = E(() => r()), i = E(() => w2(e.ariaLabels)), s = E(() => A2(e.filters)), c = E(() => _2(e.transitions)), u = E(() => T2(e.actionRow)), d = E(
    () => x2(e.previewFormat, e.format, a())
  ), f = E(() => M2(e.textInput)), h = E(() => O2(e.inline)), x = E(() => P2(e.config)), g = E(() => S2(e.highlight)), D = E(() => C2(e.weekNumbers)), k = E(() => $2(e.timezone)), b = E(() => N2(e.multiDates)), m = E(
    () => E2({
      minDate: e.minDate,
      maxDate: e.maxDate,
      disabledDates: e.disabledDates,
      allowedDates: e.allowedDates,
      highlight: g.value,
      markers: e.markers,
      timezone: k.value,
      isSpecific: e.monthPicker || e.yearPicker || e.quarterPicker
    })
  ), M = E(() => D2(e.range)), $ = E(() => R2(e.ui));
  return {
    defaultedTransitions: c,
    defaultedMultiCalendars: l,
    defaultedStartTime: o,
    defaultedAriaLabels: i,
    defaultedFilters: s,
    defaultedActionRow: u,
    defaultedPreviewFormat: d,
    defaultedTextInput: f,
    defaultedInline: h,
    defaultedConfig: x,
    defaultedHighlight: g,
    defaultedWeekNumbers: D,
    defaultedRange: M,
    propDates: m,
    defaultedTz: k,
    defaultedMultiDates: b,
    defaultedUI: $,
    getDefaultPattern: a,
    getDefaultStartTime: r
  };
}, I2 = (e, t, a) => {
  const n = U(), { defaultedTextInput: r, defaultedRange: l, defaultedTz: o, defaultedMultiDates: i, getDefaultPattern: s } = je(t), c = U(""), u = Ke(t, "format"), d = Ke(t, "formatLocale");
  it(
    n,
    () => {
      typeof t.onInternalModelChange == "function" && e("internal-model-change", n.value, K(!0));
    },
    { deep: !0 }
  ), it(l, (w, oe) => {
    w.enabled !== oe.enabled && (n.value = null);
  }), it(u, () => {
    re();
  });
  const f = (w) => o.value.timezone && o.value.convertModel ? wt(w, o.value.timezone) : w, h = (w) => {
    if (o.value.timezone && o.value.convertModel) {
      const oe = a2(o.value.timezone);
      return ev(w, oe);
    }
    return w;
  }, x = (w, oe, ke = !1) => hu(
    w,
    t.format,
    t.formatLocale,
    r.value.rangeSeparator,
    t.modelAuto,
    oe ?? s(),
    ke
  ), g = (w) => w ? t.modelType ? le(w) : {
    hours: na(w),
    minutes: _a(w),
    seconds: t.enableSeconds ? nn(w) : 0
  } : null, D = (w) => t.modelType ? le(w) : { month: Pe(w), year: _e(w) }, k = (w) => Array.isArray(w) ? i.value.enabled ? w.map((oe) => b(oe, Ut(G(), oe))) : ar(
    () => [
      Ut(G(), w[0]),
      w[1] ? Ut(G(), w[1]) : Wa(l.value.partialRange)
    ],
    l.value.enabled
  ) : Ut(G(), +w), b = (w, oe) => (typeof w == "string" || typeof w == "number") && t.modelType ? q(w) : oe, m = (w) => Array.isArray(w) ? [
    b(
      w[0],
      ha(null, +w[0].hours, +w[0].minutes, w[0].seconds)
    ),
    b(
      w[1],
      ha(null, +w[1].hours, +w[1].minutes, w[1].seconds)
    )
  ] : b(w, ha(null, w.hours, w.minutes, w.seconds)), M = (w) => {
    const oe = Ee(G(), { date: 1 });
    return Array.isArray(w) ? i.value.enabled ? w.map((ke) => b(ke, Qt(oe, +ke.month, +ke.year))) : ar(
      () => [
        b(w[0], Qt(oe, +w[0].month, +w[0].year)),
        b(
          w[1],
          w[1] ? Qt(oe, +w[1].month, +w[1].year) : Wa(l.value.partialRange)
        )
      ],
      l.value.enabled
    ) : b(w, Qt(oe, +w.month, +w.year));
  }, $ = (w) => {
    if (Array.isArray(w))
      return w.map((oe) => q(oe));
    throw new Error(mo.dateArr("multi-dates"));
  }, C = (w) => {
    if (Array.isArray(w) && l.value.enabled) {
      const oe = w[0], ke = w[1];
      return [
        G(Array.isArray(oe) ? oe[0] : null),
        G(Array.isArray(ke) ? ke[0] : null)
      ];
    }
    return G(w[0]);
  }, _ = (w) => t.modelAuto ? Array.isArray(w) ? [q(w[0]), q(w[1])] : t.autoApply ? [q(w)] : [q(w), null] : Array.isArray(w) ? ar(
    () => w[1] ? [
      q(w[0]),
      w[1] ? q(w[1]) : Wa(l.value.partialRange)
    ] : [q(w[0])],
    l.value.enabled
  ) : q(w), W = () => {
    Array.isArray(n.value) && l.value.enabled && n.value.length === 1 && n.value.push(Wa(l.value.partialRange));
  }, F = () => {
    const w = n.value;
    return [
      le(w[0]),
      w[1] ? le(w[1]) : Wa(l.value.partialRange)
    ];
  }, H = () => n.value[1] ? F() : le(lt(n.value[0])), ne = () => (n.value || []).map((w) => le(w)), se = (w = !1) => (w || W(), t.modelAuto ? H() : i.value.enabled ? ne() : Array.isArray(n.value) ? ar(() => F(), l.value.enabled) : le(lt(n.value))), de = (w) => !w || Array.isArray(w) && !w.length ? null : t.timePicker ? m(lt(w)) : t.monthPicker ? M(lt(w)) : t.yearPicker ? k(lt(w)) : i.value.enabled ? $(lt(w)) : t.weekPicker ? C(lt(w)) : _(lt(w)), I = (w) => {
    const oe = de(w);
    Sl(lt(oe)) ? (n.value = lt(oe), re()) : (n.value = null, c.value = "");
  }, O = () => {
    const w = (oe) => Wt(oe, r.value.format);
    return `${w(n.value[0])} ${r.value.rangeSeparator} ${n.value[1] ? w(n.value[1]) : ""}`;
  }, T = () => a.value && n.value ? Array.isArray(n.value) ? O() : Wt(n.value, r.value.format) : x(n.value), V = () => n.value ? i.value.enabled ? n.value.map((w) => x(w)).join("; ") : r.value.enabled && typeof r.value.format == "string" ? T() : x(n.value) : "", re = () => {
    !t.format || typeof t.format == "string" || r.value.enabled && typeof r.value.format == "string" ? c.value = V() : c.value = t.format(n.value);
  }, q = (w) => {
    if (t.utc) {
      const oe = new Date(w);
      return t.utc === "preserve" ? new Date(oe.getTime() + oe.getTimezoneOffset() * 6e4) : oe;
    }
    return t.modelType ? n2.includes(t.modelType) ? f(new Date(w)) : t.modelType === "format" && (typeof t.format == "string" || !t.format) ? f(
      Tl(w, s(), /* @__PURE__ */ new Date(), { locale: d.value })
    ) : f(
      Tl(w, t.modelType, /* @__PURE__ */ new Date(), { locale: d.value })
    ) : f(new Date(w));
  }, le = (w) => w ? t.utc ? m2(w, t.utc === "preserve", t.enableSeconds) : t.modelType ? t.modelType === "timestamp" ? +h(w) : t.modelType === "iso" ? h(w).toISOString() : t.modelType === "format" && (typeof t.format == "string" || !t.format) ? x(h(w)) : x(h(w), t.modelType, !0) : h(w) : "", ge = (w, oe = !1, ke = !1) => {
    if (ke)
      return w;
    if (e("update:model-value", w), o.value.emitTimezone && oe) {
      const et = Array.isArray(w) ? w.map((z) => wt(lt(z), o.value.emitTimezone)) : wt(lt(w), o.value.emitTimezone);
      e("update:model-timezone-value", et);
    }
  }, A = (w) => Array.isArray(n.value) ? i.value.enabled ? n.value.map((oe) => w(oe)) : [
    w(n.value[0]),
    n.value[1] ? w(n.value[1]) : Wa(l.value.partialRange)
  ] : w(lt(n.value)), S = () => {
    if (Array.isArray(n.value)) {
      const w = Xt(n.value[0], t.weekStart), oe = n.value[1] ? Xt(n.value[1], t.weekStart) : [];
      return [w.map((ke) => G(ke)), oe.map((ke) => G(ke))];
    }
    return Xt(n.value, t.weekStart).map((w) => G(w));
  }, v = (w, oe) => ge(lt(A(w)), !1, oe), R = (w) => {
    const oe = S();
    return w ? oe : e("update:model-value", S());
  }, K = (w = !1) => (w || re(), t.monthPicker ? v(D, w) : t.timePicker ? v(g, w) : t.yearPicker ? v(_e, w) : t.weekPicker ? R(w) : ge(se(w), !0, w));
  return {
    inputValue: c,
    internalModelValue: n,
    checkBeforeEmit: () => n.value ? l.value.enabled ? l.value.partialRange ? n.value.length >= 1 : n.value.length === 2 : !!n.value : !1,
    parseExternalModelValue: I,
    formatInputValue: re,
    emitModelValue: K
  };
}, H2 = (e, t) => {
  const { defaultedFilters: a, propDates: n } = je(e), { validateMonthYearInRange: r } = Ta(e), l = (u, d) => {
    let f = u;
    return a.value.months.includes(Pe(f)) ? (f = d ? It(u, 1) : ln(u, 1), l(f, d)) : f;
  }, o = (u, d) => {
    let f = u;
    return a.value.years.includes(_e(f)) ? (f = d ? eo(u, 1) : lu(u, 1), o(f, d)) : f;
  }, i = (u, d = !1) => {
    const f = Ee(G(), { month: e.month, year: e.year });
    let h = u ? It(f, 1) : ln(f, 1);
    e.disableYearSelect && (h = Ut(h, e.year));
    let x = Pe(h), g = _e(h);
    a.value.months.includes(x) && (h = l(h, u), x = Pe(h), g = _e(h)), a.value.years.includes(g) && (h = o(h, u), g = _e(h)), r(x, g, u, e.preventMinMaxNavigation) && s(x, g, d);
  }, s = (u, d, f) => {
    t("update-month-year", { month: u, year: d, fromNav: f });
  }, c = E(() => (u) => pu(
    Ee(G(), { month: e.month, year: e.year }),
    n.value.maxDate,
    n.value.minDate,
    e.preventMinMaxNavigation,
    u
  ));
  return { handleMonthYearChange: i, isDisabled: c, updateMonthYear: s };
}, $r = {
  multiCalendars: { type: [Boolean, Number, String, Object], default: void 0 },
  modelValue: { type: [String, Date, Array, Object, Number], default: null },
  modelType: { type: String, default: null },
  position: { type: String, default: "center" },
  dark: { type: Boolean, default: !1 },
  format: {
    type: [String, Function],
    default: () => null
  },
  autoPosition: { type: Boolean, default: !0 },
  altPosition: { type: Function, default: null },
  transitions: { type: [Boolean, Object], default: !0 },
  formatLocale: { type: Object, default: null },
  utc: { type: [Boolean, String], default: !1 },
  ariaLabels: { type: Object, default: () => ({}) },
  offset: { type: [Number, String], default: 10 },
  hideNavigation: { type: Array, default: () => [] },
  timezone: { type: [String, Object], default: null },
  vertical: { type: Boolean, default: !1 },
  disableMonthYearSelect: { type: Boolean, default: !1 },
  disableYearSelect: { type: Boolean, default: !1 },
  dayClass: {
    type: Function,
    default: null
  },
  yearRange: { type: Array, default: () => [1900, 2100] },
  enableTimePicker: { type: Boolean, default: !0 },
  autoApply: { type: Boolean, default: !1 },
  disabledDates: { type: [Array, Function], default: () => [] },
  monthNameFormat: { type: String, default: "short" },
  startDate: { type: [Date, String], default: null },
  startTime: { type: [Object, Array], default: null },
  hideOffsetDates: { type: Boolean, default: !1 },
  noToday: { type: Boolean, default: !1 },
  disabledWeekDays: { type: Array, default: () => [] },
  allowedDates: { type: Array, default: null },
  nowButtonLabel: { type: String, default: "Now" },
  markers: { type: Array, default: () => [] },
  escClose: { type: Boolean, default: !0 },
  spaceConfirm: { type: Boolean, default: !0 },
  monthChangeOnArrows: { type: Boolean, default: !0 },
  presetDates: { type: Array, default: () => [] },
  flow: { type: Array, default: () => [] },
  partialFlow: { type: Boolean, default: !1 },
  preventMinMaxNavigation: { type: Boolean, default: !1 },
  reverseYears: { type: Boolean, default: !1 },
  weekPicker: { type: Boolean, default: !1 },
  filters: { type: Object, default: () => ({}) },
  arrowNavigation: { type: Boolean, default: !1 },
  highlight: {
    type: [Function, Object],
    default: null
  },
  teleport: { type: [Boolean, String, Object], default: null },
  teleportCenter: { type: Boolean, default: !1 },
  locale: { type: String, default: "en-Us" },
  weekNumName: { type: String, default: "W" },
  weekStart: { type: [Number, String], default: 1 },
  weekNumbers: {
    type: [String, Function, Object],
    default: null
  },
  monthChangeOnScroll: { type: [Boolean, String], default: !0 },
  dayNames: {
    type: [Function, Array],
    default: null
  },
  monthPicker: { type: Boolean, default: !1 },
  customProps: { type: Object, default: null },
  yearPicker: { type: Boolean, default: !1 },
  modelAuto: { type: Boolean, default: !1 },
  selectText: { type: String, default: "Select" },
  cancelText: { type: String, default: "Cancel" },
  previewFormat: {
    type: [String, Function],
    default: () => ""
  },
  multiDates: { type: [Object, Boolean], default: !1 },
  ignoreTimeValidation: { type: Boolean, default: !1 },
  minDate: { type: [Date, String], default: null },
  maxDate: { type: [Date, String], default: null },
  minTime: { type: Object, default: null },
  maxTime: { type: Object, default: null },
  name: { type: String, default: null },
  placeholder: { type: String, default: "" },
  hideInputIcon: { type: Boolean, default: !1 },
  clearable: { type: Boolean, default: !0 },
  state: { type: Boolean, default: null },
  required: { type: Boolean, default: !1 },
  autocomplete: { type: String, default: "off" },
  timePicker: { type: Boolean, default: !1 },
  enableSeconds: { type: Boolean, default: !1 },
  is24: { type: Boolean, default: !0 },
  noHoursOverlay: { type: Boolean, default: !1 },
  noMinutesOverlay: { type: Boolean, default: !1 },
  noSecondsOverlay: { type: Boolean, default: !1 },
  hoursGridIncrement: { type: [String, Number], default: 1 },
  minutesGridIncrement: { type: [String, Number], default: 5 },
  secondsGridIncrement: { type: [String, Number], default: 5 },
  hoursIncrement: { type: [Number, String], default: 1 },
  minutesIncrement: { type: [Number, String], default: 1 },
  secondsIncrement: { type: [Number, String], default: 1 },
  range: { type: [Boolean, Object], default: !1 },
  uid: { type: String, default: null },
  disabled: { type: Boolean, default: !1 },
  readonly: { type: Boolean, default: !1 },
  inline: { type: [Boolean, Object], default: !1 },
  textInput: { type: [Boolean, Object], default: !1 },
  sixWeeks: { type: [Boolean, String], default: !1 },
  actionRow: { type: Object, default: () => ({}) },
  focusStartDate: { type: Boolean, default: !1 },
  disabledTimes: { type: [Function, Array], default: void 0 },
  timePickerInline: { type: Boolean, default: !1 },
  calendar: { type: Function, default: null },
  config: { type: Object, default: void 0 },
  quarterPicker: { type: Boolean, default: !1 },
  yearFirst: { type: Boolean, default: !1 },
  loading: { type: Boolean, default: !1 },
  onInternalModelChange: { type: [Function, Object], default: null },
  enableMinutes: { type: Boolean, default: !0 },
  ui: { type: Object, default: () => ({}) }
}, Vt = {
  ...$r,
  shadow: { type: Boolean, default: !1 },
  flowStep: { type: Number, default: 0 },
  internalModelValue: { type: [Date, Array], default: null },
  noOverlayFocus: { type: Boolean, default: !1 },
  collapse: { type: Boolean, default: !1 },
  menuWrapRef: { type: Object, default: null },
  getInputRect: { type: Function, default: () => ({}) },
  isTextInputDate: { type: Boolean, default: !1 }
}, z2 = ["title"], V2 = ["disabled"], B2 = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "ActionRow",
  props: {
    menuMount: { type: Boolean, default: !1 },
    calendarWidth: { type: Number, default: 0 },
    ...Vt
  },
  emits: ["close-picker", "select-date", "select-now", "invalid-select"],
  setup(e, { emit: t }) {
    const a = t, n = e, {
      defaultedActionRow: r,
      defaultedPreviewFormat: l,
      defaultedMultiCalendars: o,
      defaultedTextInput: i,
      defaultedInline: s,
      defaultedRange: c,
      defaultedMultiDates: u,
      getDefaultPattern: d
    } = je(n), { isTimeValid: f, isMonthValid: h } = Ta(n), { buildMatrix: x } = Aa(), g = U(null), D = U(null), k = U(!1), b = U({}), m = U(null), M = U(null);
    Le(() => {
      n.arrowNavigation && x([nt(g), nt(D)], "actionRow"), $(), window.addEventListener("resize", $);
    }), ra(() => {
      window.removeEventListener("resize", $);
    });
    const $ = () => {
      k.value = !1, setTimeout(() => {
        var O, T;
        const V = (O = m.value) == null ? void 0 : O.getBoundingClientRect(), re = (T = M.value) == null ? void 0 : T.getBoundingClientRect();
        V && re && (b.value.maxWidth = `${re.width - V.width - 20}px`), k.value = !0;
      }, 0);
    }, C = E(() => c.value.enabled && !c.value.partialRange && n.internalModelValue ? n.internalModelValue.length === 2 : !0), _ = E(
      () => !f.value(n.internalModelValue) || !h.value(n.internalModelValue) || !C.value
    ), W = () => {
      const O = l.value;
      return n.timePicker || n.monthPicker, O(lt(n.internalModelValue));
    }, F = () => {
      const O = n.internalModelValue;
      return o.value.count > 0 ? `${H(O[0])} - ${H(O[1])}` : [H(O[0]), H(O[1])];
    }, H = (O) => hu(
      O,
      l.value,
      n.formatLocale,
      i.value.rangeSeparator,
      n.modelAuto,
      d()
    ), ne = E(() => !n.internalModelValue || !n.menuMount ? "" : typeof l.value == "string" ? Array.isArray(n.internalModelValue) ? n.internalModelValue.length === 2 && n.internalModelValue[1] ? F() : u.value.enabled ? n.internalModelValue.map((O) => `${H(O)}`) : n.modelAuto ? `${H(n.internalModelValue[0])}` : `${H(n.internalModelValue[0])} -` : H(n.internalModelValue) : W()), se = () => u.value.enabled ? "; " : " - ", de = E(
      () => Array.isArray(ne.value) ? ne.value.join(se()) : ne.value
    ), I = () => {
      f.value(n.internalModelValue) && h.value(n.internalModelValue) && C.value ? a("select-date") : a("invalid-select");
    };
    return (O, T) => (P(), j("div", {
      ref_key: "actionRowRef",
      ref: M,
      class: "dp__action_row"
    }, [
      O.$slots["action-row"] ? ie(O.$slots, "action-row", ot(Ne({ key: 0 }, {
        internalModelValue: O.internalModelValue,
        disabled: _.value,
        selectDate: () => O.$emit("select-date"),
        closePicker: () => O.$emit("close-picker")
      }))) : (P(), j(Me, { key: 1 }, [
        p(r).showPreview ? (P(), j("div", {
          key: 0,
          class: "dp__selection_preview",
          title: de.value,
          style: Pt(b.value)
        }, [
          O.$slots["action-preview"] && k.value ? ie(O.$slots, "action-preview", {
            key: 0,
            value: O.internalModelValue
          }) : Q("", !0),
          !O.$slots["action-preview"] && k.value ? (P(), j(Me, { key: 1 }, [
            st(Ye(de.value), 1)
          ], 64)) : Q("", !0)
        ], 12, z2)) : Q("", !0),
        ce("div", {
          ref_key: "actionBtnContainer",
          ref: m,
          class: "dp__action_buttons",
          "data-dp-element": "action-row"
        }, [
          O.$slots["action-buttons"] ? ie(O.$slots, "action-buttons", {
            key: 0,
            value: O.internalModelValue
          }) : Q("", !0),
          O.$slots["action-buttons"] ? Q("", !0) : (P(), j(Me, { key: 1 }, [
            !p(s).enabled && p(r).showCancel ? (P(), j("button", {
              key: 0,
              ref_key: "cancelButtonRef",
              ref: g,
              type: "button",
              class: "dp__action_button dp__action_cancel",
              onClick: T[0] || (T[0] = (V) => O.$emit("close-picker")),
              onKeydown: T[1] || (T[1] = (V) => p(pt)(V, () => O.$emit("close-picker")))
            }, Ye(O.cancelText), 545)) : Q("", !0),
            p(r).showNow ? (P(), j("button", {
              key: 1,
              type: "button",
              class: "dp__action_button dp__action_cancel",
              onClick: T[2] || (T[2] = (V) => O.$emit("select-now")),
              onKeydown: T[3] || (T[3] = (V) => p(pt)(V, () => O.$emit("select-now")))
            }, Ye(O.nowButtonLabel), 33)) : Q("", !0),
            p(r).showSelect ? (P(), j("button", {
              key: 2,
              ref_key: "selectButtonRef",
              ref: D,
              type: "button",
              class: "dp__action_button dp__action_select",
              disabled: _.value,
              "data-test": "select-button",
              onKeydown: T[4] || (T[4] = (V) => p(pt)(V, () => I())),
              onClick: I
            }, Ye(O.selectText), 41, V2)) : Q("", !0)
          ], 64))
        ], 512)
      ], 64))
    ], 512));
  }
}), Y2 = ["role", "aria-label", "tabindex"], F2 = { class: "dp__selection_grid_header" }, j2 = ["aria-selected", "aria-disabled", "data-test", "onClick", "onKeydown", "onMouseover"], U2 = ["aria-label"], zn = /* @__PURE__ */ xe({
  __name: "SelectionOverlay",
  props: {
    items: {},
    type: {},
    isLast: { type: Boolean },
    arrowNavigation: { type: Boolean },
    skipButtonRef: { type: Boolean },
    headerRefs: {},
    hideNavigation: {},
    escClose: { type: Boolean },
    useRelative: { type: Boolean },
    height: {},
    textInput: { type: [Boolean, Object] },
    config: {},
    noOverlayFocus: { type: Boolean },
    focusValue: {},
    menuWrapRef: {},
    ariaLabels: {},
    overlayLabel: {}
  },
  emits: ["selected", "toggle", "reset-flow", "hover-value"],
  setup(e, { expose: t, emit: a }) {
    const { setSelectionGrid: n, buildMultiLevelMatrix: r, setMonthPicker: l } = Aa(), o = a, i = e, { defaultedAriaLabels: s, defaultedTextInput: c, defaultedConfig: u } = je(
      i
    ), { hideNavigationButtons: d } = Nr(), f = U(!1), h = U(null), x = U(null), g = U([]), D = U(), k = U(null), b = U(0), m = U(null);
    Lu(() => {
      h.value = null;
    }), Le(() => {
      at().then(() => ne()), i.noOverlayFocus || $(), M(!0);
    }), ra(() => M(!1));
    const M = (A) => {
      var S;
      i.arrowNavigation && ((S = i.headerRefs) != null && S.length ? l(A) : n(A));
    }, $ = () => {
      var A;
      const S = nt(x);
      S && (c.value.enabled || (h.value ? (A = h.value) == null || A.focus({ preventScroll: !0 }) : S.focus({ preventScroll: !0 })), f.value = S.clientHeight < S.scrollHeight);
    }, C = E(
      () => ({
        dp__overlay: !0,
        "dp--overlay-absolute": !i.useRelative,
        "dp--overlay-relative": i.useRelative
      })
    ), _ = E(
      () => i.useRelative ? { height: `${i.height}px`, width: "260px" } : void 0
    ), W = E(() => ({
      dp__overlay_col: !0
    })), F = E(
      () => ({
        dp__btn: !0,
        dp__button: !0,
        dp__overlay_action: !0,
        dp__over_action_scroll: f.value,
        dp__button_bottom: i.isLast
      })
    ), H = E(() => {
      var A, S;
      return {
        dp__overlay_container: !0,
        dp__container_flex: ((A = i.items) == null ? void 0 : A.length) <= 6,
        dp__container_block: ((S = i.items) == null ? void 0 : S.length) > 6
      };
    });
    it(
      () => i.items,
      () => ne(!1),
      { deep: !0 }
    );
    const ne = (A = !0) => {
      at().then(() => {
        const S = nt(h), v = nt(x), R = nt(k), K = nt(m), w = R ? R.getBoundingClientRect().height : 0;
        v && (v.getBoundingClientRect().height ? b.value = v.getBoundingClientRect().height - w : b.value = u.value.modeHeight - w), S && K && A && (K.scrollTop = S.offsetTop - K.offsetTop - (b.value / 2 - S.getBoundingClientRect().height) - w);
      });
    }, se = (A) => {
      A.disabled || o("selected", A.value);
    }, de = () => {
      o("toggle"), o("reset-flow");
    }, I = () => {
      i.escClose && de();
    }, O = (A, S, v, R) => {
      A && ((S.active || S.value === i.focusValue) && (h.value = A), i.arrowNavigation && (Array.isArray(g.value[v]) ? g.value[v][R] = A : g.value[v] = [A], T()));
    }, T = () => {
      var A, S;
      const v = (A = i.headerRefs) != null && A.length ? [i.headerRefs].concat(g.value) : g.value.concat([i.skipButtonRef ? [] : [k.value]]);
      r(lt(v), (S = i.headerRefs) != null && S.length ? "monthPicker" : "selectionGrid");
    }, V = (A) => {
      i.arrowNavigation || pa(A, u.value, !0);
    }, re = (A) => {
      D.value = A, o("hover-value", A);
    }, q = () => {
      if (de(), !i.isLast) {
        const A = Pl(i.menuWrapRef ?? null, "action-row");
        if (A) {
          const S = uu(A);
          S == null || S.focus();
        }
      }
    }, le = (A) => {
      switch (A.key) {
        case He.esc:
          return I();
        case He.arrowLeft:
          return V(A);
        case He.arrowRight:
          return V(A);
        case He.arrowUp:
          return V(A);
        case He.arrowDown:
          return V(A);
        default:
          return;
      }
    }, ge = (A) => {
      if (A.key === He.enter)
        return de();
      if (A.key === He.tab)
        return q();
    };
    return t({ focusGrid: $ }), (A, S) => {
      var v;
      return P(), j("div", {
        ref_key: "gridWrapRef",
        ref: x,
        class: we(C.value),
        style: Pt(_.value),
        role: A.useRelative ? void 0 : "dialog",
        "aria-label": A.overlayLabel,
        tabindex: A.useRelative ? void 0 : "0",
        onKeydown: le,
        onClick: S[0] || (S[0] = Ra(() => {
        }, ["prevent"]))
      }, [
        ce("div", {
          ref_key: "containerRef",
          ref: m,
          class: we(H.value),
          style: Pt({ "--dp-overlay-height": `${b.value}px` }),
          role: "grid"
        }, [
          ce("div", F2, [
            ie(A.$slots, "header")
          ]),
          A.$slots.overlay ? ie(A.$slots, "overlay", { key: 0 }) : (P(!0), j(Me, { key: 1 }, Re(A.items, (R, K) => (P(), j("div", {
            key: K,
            class: we(["dp__overlay_row", { dp__flex_row: A.items.length >= 3 }]),
            role: "row"
          }, [
            (P(!0), j(Me, null, Re(R, (w, oe) => (P(), j("div", {
              key: w.value,
              ref_for: !0,
              ref: (ke) => O(ke, w, K, oe),
              role: "gridcell",
              class: we(W.value),
              "aria-selected": w.active || void 0,
              "aria-disabled": w.disabled || void 0,
              tabindex: "0",
              "data-test": w.text,
              onClick: Ra((ke) => se(w), ["prevent"]),
              onKeydown: (ke) => p(pt)(ke, () => se(w), !0),
              onMouseover: (ke) => re(w.value)
            }, [
              ce("div", {
                class: we(w.className)
              }, [
                A.$slots.item ? ie(A.$slots, "item", {
                  key: 0,
                  item: w
                }) : Q("", !0),
                A.$slots.item ? Q("", !0) : (P(), j(Me, { key: 1 }, [
                  st(Ye(w.text), 1)
                ], 64))
              ], 2)
            ], 42, j2))), 128))
          ], 2))), 128))
        ], 6),
        A.$slots["button-icon"] ? ma((P(), j("button", {
          key: 0,
          ref_key: "toggleButton",
          ref: k,
          type: "button",
          "aria-label": (v = p(s)) == null ? void 0 : v.toggleOverlay,
          class: we(F.value),
          tabindex: "0",
          onClick: de,
          onKeydown: ge
        }, [
          ie(A.$slots, "button-icon")
        ], 42, U2)), [
          [rr, !p(d)(A.hideNavigation, A.type)]
        ]) : Q("", !0)
      ], 46, Y2);
    };
  }
}), Lr = /* @__PURE__ */ xe({
  __name: "InstanceWrap",
  props: {
    multiCalendars: {},
    stretch: { type: Boolean },
    collapse: { type: Boolean }
  },
  setup(e) {
    const t = e, a = E(
      () => t.multiCalendars > 0 ? [...Array(t.multiCalendars).keys()] : [0]
    ), n = E(() => ({
      dp__instance_calendar: t.multiCalendars > 0
    }));
    return (r, l) => (P(), j("div", {
      class: we({
        dp__menu_inner: !r.stretch,
        "dp--menu--inner-stretched": r.stretch,
        dp__flex_display: r.multiCalendars > 0,
        "dp--flex-display-collapsed": r.collapse
      })
    }, [
      (P(!0), j(Me, null, Re(a.value, (o, i) => (P(), j("div", {
        key: o,
        class: we(n.value)
      }, [
        ie(r.$slots, "default", {
          instance: o,
          index: i
        })
      ], 2))), 128))
    ], 2));
  }
}), W2 = ["data-dp-element", "aria-label", "aria-disabled"], _n = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "ArrowBtn",
  props: {
    ariaLabel: {},
    elName: {},
    disabled: { type: Boolean }
  },
  emits: ["activate", "set-ref"],
  setup(e, { emit: t }) {
    const a = t, n = U(null);
    return Le(() => a("set-ref", n)), (r, l) => (P(), j("button", {
      ref_key: "elRef",
      ref: n,
      type: "button",
      "data-dp-element": r.elName,
      class: "dp__btn dp--arrow-btn-nav",
      tabindex: "0",
      "aria-label": r.ariaLabel,
      "aria-disabled": r.disabled || void 0,
      onClick: l[0] || (l[0] = (o) => r.$emit("activate")),
      onKeydown: l[1] || (l[1] = (o) => p(pt)(o, () => r.$emit("activate"), !0))
    }, [
      ce("span", {
        class: we(["dp__inner_nav", { dp__inner_nav_disabled: r.disabled }])
      }, [
        ie(r.$slots, "default")
      ], 2)
    ], 40, W2));
  }
}), q2 = ["aria-label", "data-test"], wu = /* @__PURE__ */ xe({
  __name: "YearModePicker",
  props: {
    ...Vt,
    showYearPicker: { type: Boolean, default: !1 },
    items: { type: Array, default: () => [] },
    instance: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    isDisabled: { type: Function, default: () => !1 }
  },
  emits: ["toggle-year-picker", "year-select", "handle-year"],
  setup(e, { emit: t }) {
    const a = t, n = e, { showRightIcon: r, showLeftIcon: l } = Nr(), { defaultedConfig: o, defaultedMultiCalendars: i, defaultedAriaLabels: s, defaultedTransitions: c, defaultedUI: u } = je(n), { showTransition: d, transitionName: f } = Vn(c), h = U(!1), x = (k = !1, b) => {
      h.value = !h.value, a("toggle-year-picker", { flow: k, show: b });
    }, g = (k) => {
      h.value = !1, a("year-select", k);
    }, D = (k = !1) => {
      a("handle-year", k);
    };
    return (k, b) => {
      var m, M, $, C, _;
      return P(), j(Me, null, [
        ce("div", {
          class: we(["dp--year-mode-picker", { "dp--hidden-el": h.value }])
        }, [
          p(l)(p(i), e.instance) ? (P(), Ae(_n, {
            key: 0,
            ref: "mpPrevIconRef",
            "aria-label": (m = p(s)) == null ? void 0 : m.prevYear,
            disabled: e.isDisabled(!1),
            class: we((M = p(u)) == null ? void 0 : M.navBtnPrev),
            onActivate: b[0] || (b[0] = (W) => D(!1))
          }, {
            default: ye(() => [
              k.$slots["arrow-left"] ? ie(k.$slots, "arrow-left", { key: 0 }) : Q("", !0),
              k.$slots["arrow-left"] ? Q("", !0) : (P(), Ae(p(oo), { key: 1 }))
            ]),
            _: 3
          }, 8, ["aria-label", "disabled", "class"])) : Q("", !0),
          ce("button", {
            ref: "mpYearButtonRef",
            class: "dp__btn dp--year-select",
            type: "button",
            "aria-label": `${e.year}-${($ = p(s)) == null ? void 0 : $.openYearsOverlay}`,
            "data-test": `year-mode-btn-${e.instance}`,
            onClick: b[1] || (b[1] = () => x(!1)),
            onKeydown: b[2] || (b[2] = lr(() => x(!1), ["enter"]))
          }, [
            k.$slots.year ? ie(k.$slots, "year", {
              key: 0,
              year: e.year
            }) : Q("", !0),
            k.$slots.year ? Q("", !0) : (P(), j(Me, { key: 1 }, [
              st(Ye(e.year), 1)
            ], 64))
          ], 40, q2),
          p(r)(p(i), e.instance) ? (P(), Ae(_n, {
            key: 1,
            ref: "mpNextIconRef",
            "aria-label": (C = p(s)) == null ? void 0 : C.nextYear,
            disabled: e.isDisabled(!0),
            class: we((_ = p(u)) == null ? void 0 : _.navBtnNext),
            onActivate: b[3] || (b[3] = (W) => D(!0))
          }, {
            default: ye(() => [
              k.$slots["arrow-right"] ? ie(k.$slots, "arrow-right", { key: 0 }) : Q("", !0),
              k.$slots["arrow-right"] ? Q("", !0) : (P(), Ae(p(io), { key: 1 }))
            ]),
            _: 3
          }, 8, ["aria-label", "disabled", "class"])) : Q("", !0)
        ], 2),
        Fe(Va, {
          name: p(f)(e.showYearPicker),
          css: p(d)
        }, {
          default: ye(() => {
            var W, F;
            return [
              e.showYearPicker ? (P(), Ae(zn, {
                key: 0,
                items: e.items,
                "text-input": k.textInput,
                "esc-close": k.escClose,
                config: k.config,
                "is-last": k.autoApply && !p(o).keepActionRow,
                "hide-navigation": k.hideNavigation,
                "aria-labels": k.ariaLabels,
                "overlay-label": (F = (W = p(s)) == null ? void 0 : W.yearPicker) == null ? void 0 : F.call(W, !0),
                type: "year",
                onToggle: x,
                onSelected: b[4] || (b[4] = (H) => g(H))
              }, dt({
                "button-icon": ye(() => [
                  k.$slots["calendar-icon"] ? ie(k.$slots, "calendar-icon", { key: 0 }) : Q("", !0),
                  k.$slots["calendar-icon"] ? Q("", !0) : (P(), Ae(p(dn), { key: 1 }))
                ]),
                _: 2
              }, [
                k.$slots["year-overlay-value"] ? {
                  name: "item",
                  fn: ye(({ item: H }) => [
                    ie(k.$slots, "year-overlay-value", {
                      text: H.text,
                      value: H.value
                    })
                  ]),
                  key: "0"
                } : void 0
              ]), 1032, ["items", "text-input", "esc-close", "config", "is-last", "hide-navigation", "aria-labels", "overlay-label"])) : Q("", !0)
            ];
          }),
          _: 3
        }, 8, ["name", "css"])
      ], 64);
    };
  }
}), yo = (e, t, a) => {
  if (t.value && Array.isArray(t.value))
    if (t.value.some((n) => Se(e, n))) {
      const n = t.value.filter((r) => !Se(r, e));
      t.value = n.length ? n : null;
    } else
      (a && +a > t.value.length || !a) && t.value.push(e);
  else
    t.value = [e];
}, go = (e, t, a) => {
  let n = e.value ? e.value.slice() : [];
  return n.length === 2 && n[1] !== null && (n = []), n.length ? We(t, n[0]) ? (n.unshift(t), a("range-start", n[0]), a("range-start", n[1])) : (n[1] = t, a("range-end", t)) : (n = [t], a("range-start", t)), n;
}, Er = (e, t, a, n) => {
  e && (e[0] && e[1] && a && t("auto-apply"), e[0] && !e[1] && n && a && t("auto-apply"));
}, ku = (e) => {
  Array.isArray(e.value) && e.value.length <= 2 && e.range ? e.modelValue.value = e.value.map((t) => wt(G(t), e.timezone)) : Array.isArray(e.value) || (e.modelValue.value = wt(G(e.value), e.timezone));
}, xu = (e, t, a, n) => Array.isArray(t.value) && (t.value.length === 2 || t.value.length === 1 && n.value.partialRange) ? n.value.fixedStart && (Ge(e, t.value[0]) || Se(e, t.value[0])) ? [t.value[0], e] : n.value.fixedEnd && (We(e, t.value[1]) || Se(e, t.value[1])) ? [e, t.value[1]] : (a("invalid-fixed-range", e), t.value) : [], _u = ({
  multiCalendars: e,
  range: t,
  highlight: a,
  propDates: n,
  calendars: r,
  modelValue: l,
  props: o,
  filters: i,
  year: s,
  month: c,
  emit: u
}) => {
  const d = E(() => vo(o.yearRange, o.locale, o.reverseYears)), f = U([!1]), h = E(() => (H, ne) => {
    const se = Ee(Nt(/* @__PURE__ */ new Date()), {
      month: c.value(H),
      year: s.value(H)
    }), de = ne ? Ws(se) : Dn(se);
    return pu(
      de,
      n.value.maxDate,
      n.value.minDate,
      o.preventMinMaxNavigation,
      ne
    );
  }), x = () => Array.isArray(l.value) && e.value.solo && l.value[1], g = () => {
    for (let H = 0; H < e.value.count; H++)
      if (H === 0)
        r.value[H] = r.value[0];
      else if (H === e.value.count - 1 && x())
        r.value[H] = {
          month: Pe(l.value[1]),
          year: _e(l.value[1])
        };
      else {
        const ne = Ee(G(), r.value[H - 1]);
        r.value[H] = { month: Pe(ne), year: _e(eo(ne, 1)) };
      }
  }, D = (H) => {
    if (!H)
      return g();
    const ne = Ee(G(), r.value[H]);
    return r.value[0].year = _e(lu(ne, e.value.count - 1)), g();
  }, k = (H, ne) => {
    const se = lv(ne, H);
    return t.value.showLastInRange && se > 1 ? ne : H;
  }, b = (H) => o.focusStartDate || e.value.solo ? H[0] : H[1] ? k(H[0], H[1]) : H[0], m = () => {
    if (l.value) {
      const H = Array.isArray(l.value) ? b(l.value) : l.value;
      r.value[0] = { month: Pe(H), year: _e(H) };
    }
  }, M = () => {
    m(), e.value.count && g();
  };
  it(l, (H, ne) => {
    o.isTextInputDate && JSON.stringify(H ?? {}) !== JSON.stringify(ne ?? {}) && M();
  }), Le(() => {
    M();
  });
  const $ = (H, ne) => {
    r.value[ne].year = H, u("update-month-year", { instance: ne, year: H, month: r.value[ne].month }), e.value.count && !e.value.solo && D(ne);
  }, C = E(() => (H) => on(d.value, (ne) => {
    var se;
    const de = s.value(H) === ne.value, I = Ln(
      ne.value,
      sn(n.value.minDate),
      sn(n.value.maxDate)
    ) || ((se = i.value.years) == null ? void 0 : se.includes(s.value(H))), O = ho(a.value, ne.value);
    return { active: de, disabled: I, highlighted: O };
  })), _ = (H, ne) => {
    $(H, ne), F(ne);
  }, W = (H, ne = !1) => {
    if (!h.value(H, ne)) {
      const se = ne ? s.value(H) + 1 : s.value(H) - 1;
      $(se, H);
    }
  }, F = (H, ne = !1, se) => {
    ne || u("reset-flow"), se !== void 0 ? f.value[H] = se : f.value[H] = !f.value[H], f.value[H] ? u("overlay-toggle", { open: !0, overlay: ct.year }) : (u("overlay-closed"), u("overlay-toggle", { open: !1, overlay: ct.year }));
  };
  return {
    isDisabled: h,
    groupedYears: C,
    showYearPicker: f,
    selectYear: $,
    toggleYearPicker: F,
    handleYearSelect: _,
    handleYear: W
  };
}, K2 = (e, t) => {
  const {
    defaultedMultiCalendars: a,
    defaultedAriaLabels: n,
    defaultedTransitions: r,
    defaultedConfig: l,
    defaultedRange: o,
    defaultedHighlight: i,
    propDates: s,
    defaultedTz: c,
    defaultedFilters: u,
    defaultedMultiDates: d
  } = je(e), f = () => {
    e.isTextInputDate && M(_e(G(e.startDate)), 0);
  }, { modelValue: h, year: x, month: g, calendars: D } = Bn(e, t, f), k = E(() => iu(e.formatLocale, e.locale, e.monthNameFormat)), b = U(null), { checkMinMaxRange: m } = Ta(e), {
    selectYear: M,
    groupedYears: $,
    showYearPicker: C,
    toggleYearPicker: _,
    handleYearSelect: W,
    handleYear: F,
    isDisabled: H
  } = _u({
    modelValue: h,
    multiCalendars: a,
    range: o,
    highlight: i,
    calendars: D,
    year: x,
    propDates: s,
    month: g,
    filters: u,
    props: e,
    emit: t
  });
  Le(() => {
    e.startDate && (h.value && e.focusStartDate || !h.value) && M(_e(G(e.startDate)), 0);
  });
  const ne = (v) => v ? { month: Pe(v), year: _e(v) } : { month: null, year: null }, se = () => h.value ? Array.isArray(h.value) ? h.value.map((v) => ne(v)) : ne(h.value) : ne(), de = (v, R) => {
    const K = D.value[v], w = se();
    return Array.isArray(w) ? w.some((oe) => oe.year === (K == null ? void 0 : K.year) && oe.month === R) : (K == null ? void 0 : K.year) === w.year && R === w.month;
  }, I = (v, R, K) => {
    var w, oe;
    const ke = se();
    return Array.isArray(ke) ? x.value(R) === ((w = ke[K]) == null ? void 0 : w.year) && v === ((oe = ke[K]) == null ? void 0 : oe.month) : !1;
  }, O = (v, R) => {
    if (o.value.enabled) {
      const K = se();
      if (Array.isArray(h.value) && Array.isArray(K)) {
        const w = I(v, R, 0) || I(v, R, 1), oe = Qt(Nt(G()), v, x.value(R));
        return Dr(h.value, b.value, oe) && !w;
      }
      return !1;
    }
    return !1;
  }, T = E(() => (v) => on(k.value, (R) => {
    var K;
    const w = de(v, R.value), oe = Ln(
      R.value,
      du(x.value(v), s.value.minDate),
      fu(x.value(v), s.value.maxDate)
    ) || b2(s.value.disabledDates, x.value(v)).includes(R.value) || ((K = u.value.months) == null ? void 0 : K.includes(R.value)), ke = O(R.value, v), et = yu(i.value, R.value, x.value(v));
    return { active: w, disabled: oe, isBetween: ke, highlighted: et };
  })), V = (v, R) => Qt(Nt(G()), v, x.value(R)), re = (v, R) => {
    const K = h.value ? h.value : Nt(/* @__PURE__ */ new Date());
    h.value = Qt(K, v, x.value(R)), t("auto-apply"), t("update-flow-step");
  }, q = (v, R) => {
    const K = V(v, R);
    o.value.fixedEnd || o.value.fixedStart ? h.value = xu(K, h, t, o) : h.value ? m(K, h.value) && (h.value = go(h, V(v, R), t)) : h.value = [V(v, R)], at().then(() => {
      Er(h.value, t, e.autoApply, e.modelAuto);
    });
  }, le = (v, R) => {
    yo(V(v, R), h, d.value.limit), t("auto-apply", !0);
  }, ge = (v, R) => (D.value[R].month = v, S(R, D.value[R].year, v), d.value.enabled ? le(v, R) : o.value.enabled ? q(v, R) : re(v, R)), A = (v, R) => {
    M(v, R), S(R, v, null);
  }, S = (v, R, K) => {
    let w = K;
    if (!w && w !== 0) {
      const oe = se();
      w = Array.isArray(oe) ? oe[v].month : oe.month;
    }
    t("update-month-year", { instance: v, year: R, month: w });
  };
  return {
    groupedMonths: T,
    groupedYears: $,
    year: x,
    isDisabled: H,
    defaultedMultiCalendars: a,
    defaultedAriaLabels: n,
    defaultedTransitions: r,
    defaultedConfig: l,
    showYearPicker: C,
    modelValue: h,
    presetDate: (v, R) => {
      ku({
        value: v,
        modelValue: h,
        range: o.value.enabled,
        timezone: R ? void 0 : c.value.timezone
      }), t("auto-apply");
    },
    setHoverDate: (v, R) => {
      b.value = V(v, R);
    },
    selectMonth: ge,
    selectYear: A,
    toggleYearPicker: _,
    handleYearSelect: W,
    handleYear: F,
    getModelMonthYear: se
  };
}, X2 = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "MonthPicker",
  props: {
    ...Vt
  },
  emits: [
    "update:internal-model-value",
    "overlay-closed",
    "reset-flow",
    "range-start",
    "range-end",
    "auto-apply",
    "update-month-year",
    "update-flow-step",
    "mount",
    "invalid-fixed-range",
    "overlay-toggle"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = Ba(), l = _t(r, "yearMode"), o = e;
    Le(() => {
      o.shadow || n("mount", null);
    });
    const {
      groupedMonths: i,
      groupedYears: s,
      year: c,
      isDisabled: u,
      defaultedMultiCalendars: d,
      defaultedConfig: f,
      showYearPicker: h,
      modelValue: x,
      presetDate: g,
      setHoverDate: D,
      selectMonth: k,
      selectYear: b,
      toggleYearPicker: m,
      handleYearSelect: M,
      handleYear: $,
      getModelMonthYear: C
    } = K2(o, n);
    return t({ getSidebarProps: () => ({
      modelValue: x,
      year: c,
      getModelMonthYear: C,
      selectMonth: k,
      selectYear: b,
      handleYear: $
    }), presetDate: g, toggleYearPicker: (_) => m(0, _) }), (_, W) => (P(), Ae(Lr, {
      "multi-calendars": p(d).count,
      collapse: _.collapse,
      stretch: ""
    }, {
      default: ye(({ instance: F }) => [
        _.$slots["top-extra"] ? ie(_.$slots, "top-extra", {
          key: 0,
          value: _.internalModelValue
        }) : Q("", !0),
        _.$slots["month-year"] ? ie(_.$slots, "month-year", ot(Ne({ key: 1 }, {
          year: p(c),
          months: p(i)(F),
          years: p(s)(F),
          selectMonth: p(k),
          selectYear: p(b),
          instance: F
        }))) : (P(), Ae(zn, {
          key: 2,
          items: p(i)(F),
          "arrow-navigation": _.arrowNavigation,
          "is-last": _.autoApply && !p(f).keepActionRow,
          "esc-close": _.escClose,
          height: p(f).modeHeight,
          config: _.config,
          "no-overlay-focus": !!(_.noOverlayFocus || _.textInput),
          "use-relative": "",
          type: "month",
          onSelected: (H) => p(k)(H, F),
          onHoverValue: (H) => p(D)(H, F)
        }, dt({
          header: ye(() => [
            Fe(wu, Ne(_.$props, {
              items: p(s)(F),
              instance: F,
              "show-year-picker": p(h)[F],
              year: p(c)(F),
              "is-disabled": (H) => p(u)(F, H),
              onHandleYear: (H) => p($)(F, H),
              onYearSelect: (H) => p(M)(H, F),
              onToggleYearPicker: (H) => p(m)(F, H == null ? void 0 : H.flow, H == null ? void 0 : H.show)
            }), dt({ _: 2 }, [
              Re(p(l), (H, ne) => ({
                name: H,
                fn: ye((se) => [
                  ie(_.$slots, H, ot(gt(se)))
                ])
              }))
            ]), 1040, ["items", "instance", "show-year-picker", "year", "is-disabled", "onHandleYear", "onYearSelect", "onToggleYearPicker"])
          ]),
          _: 2
        }, [
          _.$slots["month-overlay-value"] ? {
            name: "item",
            fn: ye(({ item: H }) => [
              ie(_.$slots, "month-overlay-value", {
                text: H.text,
                value: H.value
              })
            ]),
            key: "0"
          } : void 0
        ]), 1032, ["items", "arrow-navigation", "is-last", "esc-close", "height", "config", "no-overlay-focus", "onSelected", "onHoverValue"]))
      ]),
      _: 3
    }, 8, ["multi-calendars", "collapse"]));
  }
}), Q2 = (e, t) => {
  const a = () => {
    e.isTextInputDate && (u.value = _e(G(e.startDate)));
  }, { modelValue: n } = Bn(e, t, a), r = U(null), { defaultedHighlight: l, defaultedMultiDates: o, defaultedFilters: i, defaultedRange: s, propDates: c } = je(e), u = U();
  Le(() => {
    e.startDate && (n.value && e.focusStartDate || !n.value) && (u.value = _e(G(e.startDate)));
  });
  const d = (g) => Array.isArray(n.value) ? n.value.some((D) => _e(D) === g) : n.value ? _e(n.value) === g : !1, f = (g) => s.value.enabled && Array.isArray(n.value) ? Dr(n.value, r.value, x(g)) : !1, h = E(() => on(vo(e.yearRange, e.locale, e.reverseYears), (g) => {
    const D = d(g.value), k = Ln(
      g.value,
      sn(c.value.minDate),
      sn(c.value.maxDate)
    ) || i.value.years.includes(g.value), b = f(g.value) && !D, m = ho(l.value, g.value);
    return { active: D, disabled: k, isBetween: b, highlighted: m };
  })), x = (g) => Ut(Nt(Dn(/* @__PURE__ */ new Date())), g);
  return {
    groupedYears: h,
    modelValue: n,
    focusYear: u,
    setHoverValue: (g) => {
      r.value = Ut(Nt(/* @__PURE__ */ new Date()), g);
    },
    selectYear: (g) => {
      var D;
      if (t("update-month-year", { instance: 0, year: g }), o.value.enabled)
        return n.value ? Array.isArray(n.value) && (((D = n.value) == null ? void 0 : D.map((k) => _e(k))).includes(g) ? n.value = n.value.filter((k) => _e(k) !== g) : n.value.push(Ut(ht(G()), g))) : n.value = [Ut(ht(Dn(G())), g)], t("auto-apply", !0);
      s.value.enabled ? (n.value = go(n, x(g), t), at().then(() => {
        Er(n.value, t, e.autoApply, e.modelAuto);
      })) : (n.value = x(g), t("auto-apply"));
    }
  };
}, G2 = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "YearPicker",
  props: {
    ...Vt
  },
  emits: [
    "update:internal-model-value",
    "reset-flow",
    "range-start",
    "range-end",
    "auto-apply",
    "update-month-year"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, { groupedYears: l, modelValue: o, focusYear: i, selectYear: s, setHoverValue: c } = Q2(r, n), { defaultedConfig: u } = je(r);
    return t({ getSidebarProps: () => ({
      modelValue: o,
      selectYear: s
    }) }), (d, f) => (P(), j("div", null, [
      d.$slots["top-extra"] ? ie(d.$slots, "top-extra", {
        key: 0,
        value: d.internalModelValue
      }) : Q("", !0),
      d.$slots["month-year"] ? ie(d.$slots, "month-year", ot(Ne({ key: 1 }, {
        years: p(l),
        selectYear: p(s)
      }))) : (P(), Ae(zn, {
        key: 2,
        items: p(l),
        "is-last": d.autoApply && !p(u).keepActionRow,
        height: p(u).modeHeight,
        config: d.config,
        "no-overlay-focus": !!(d.noOverlayFocus || d.textInput),
        "focus-value": p(i),
        type: "year",
        "use-relative": "",
        onSelected: p(s),
        onHoverValue: p(c)
      }, dt({ _: 2 }, [
        d.$slots["year-overlay-value"] ? {
          name: "item",
          fn: ye(({ item: h }) => [
            ie(d.$slots, "year-overlay-value", {
              text: h.text,
              value: h.value
            })
          ]),
          key: "0"
        } : void 0
      ]), 1032, ["items", "is-last", "height", "config", "no-overlay-focus", "focus-value", "onSelected", "onHoverValue"]))
    ]));
  }
}), J2 = {
  key: 0,
  class: "dp__time_input"
}, Z2 = ["data-test", "aria-label", "onKeydown", "onClick", "onMousedown"], ep = /* @__PURE__ */ ce("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_l" }, null, -1), tp = /* @__PURE__ */ ce("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_r" }, null, -1), ap = ["aria-label", "disabled", "data-test", "onKeydown", "onClick"], np = ["data-test", "aria-label", "onKeydown", "onClick", "onMousedown"], rp = /* @__PURE__ */ ce("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_l" }, null, -1), lp = /* @__PURE__ */ ce("span", { class: "dp__tp_inline_btn_bar dp__tp_btn_in_r" }, null, -1), op = { key: 0 }, ip = ["aria-label"], sp = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "TimeInput",
  props: {
    hours: { type: Number, default: 0 },
    minutes: { type: Number, default: 0 },
    seconds: { type: Number, default: 0 },
    closeTimePickerBtn: { type: Object, default: null },
    order: { type: Number, default: 0 },
    disabledTimesConfig: { type: Function, default: null },
    validateTime: { type: Function, default: () => !1 },
    ...Vt
  },
  emits: [
    "set-hours",
    "set-minutes",
    "update:hours",
    "update:minutes",
    "update:seconds",
    "reset-flow",
    "mounted",
    "overlay-closed",
    "overlay-opened",
    "am-pm-change"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, { setTimePickerElements: l, setTimePickerBackRef: o } = Aa(), { defaultedAriaLabels: i, defaultedTransitions: s, defaultedFilters: c, defaultedConfig: u, defaultedRange: d } = je(r), { transitionName: f, showTransition: h } = Vn(s), x = un({
      hours: !1,
      minutes: !1,
      seconds: !1
    }), g = U("AM"), D = U(null), k = U([]), b = U(), m = U(!1);
    Le(() => {
      n("mounted");
    });
    const M = (y) => Ee(/* @__PURE__ */ new Date(), {
      hours: y.hours,
      minutes: y.minutes,
      seconds: r.enableSeconds ? y.seconds : 0,
      milliseconds: 0
    }), $ = E(
      () => (y) => V(y, r[y]) || _(y, r[y])
    ), C = E(() => ({ hours: r.hours, minutes: r.minutes, seconds: r.seconds })), _ = (y, X) => d.value.enabled && !d.value.disableTimeRangeValidation ? !r.validateTime(y, X) : !1, W = (y, X) => {
      if (d.value.enabled && !d.value.disableTimeRangeValidation) {
        const ee = X ? +r[`${y}Increment`] : -+r[`${y}Increment`], Y = r[y] + ee;
        return !r.validateTime(y, Y);
      }
      return !1;
    }, F = E(() => (y) => !A(+r[y] + +r[`${y}Increment`], y) || W(y, !0)), H = E(() => (y) => !A(+r[y] - +r[`${y}Increment`], y) || W(y, !1)), ne = (y, X) => zs(Ee(G(), y), X), se = (y, X) => e2(Ee(G(), y), X), de = E(
      () => ({
        dp__time_col: !0,
        dp__time_col_block: !r.timePickerInline,
        dp__time_col_reg_block: !r.enableSeconds && r.is24 && !r.timePickerInline,
        dp__time_col_reg_inline: !r.enableSeconds && r.is24 && r.timePickerInline,
        dp__time_col_reg_with_button: !r.enableSeconds && !r.is24,
        dp__time_col_sec: r.enableSeconds && r.is24,
        dp__time_col_sec_with_button: r.enableSeconds && !r.is24
      })
    ), I = E(() => {
      const y = [{ type: "hours" }];
      return r.enableMinutes && y.push({ type: "", separator: !0 }, {
        type: "minutes"
      }), r.enableSeconds && y.push({ type: "", separator: !0 }, {
        type: "seconds"
      }), y;
    }), O = E(() => I.value.filter((y) => !y.separator)), T = E(() => (y) => {
      if (y === "hours") {
        const X = oe(+r.hours);
        return { text: X < 10 ? `0${X}` : `${X}`, value: X };
      }
      return { text: r[y] < 10 ? `0${r[y]}` : `${r[y]}`, value: r[y] };
    }), V = (y, X) => {
      var ee;
      if (!r.disabledTimesConfig)
        return !1;
      const Y = r.disabledTimesConfig(r.order, y === "hours" ? X : void 0);
      return Y[y] ? !!((ee = Y[y]) != null && ee.includes(X)) : !0;
    }, re = (y, X) => X !== "hours" || g.value === "AM" ? y : y + 12, q = (y) => {
      const X = r.is24 ? 24 : 12, ee = y === "hours" ? X : 60, Y = +r[`${y}GridIncrement`], Te = y === "hours" && !r.is24 ? Y : 0, N = [];
      for (let J = Te; J < ee; J += Y)
        N.push({ value: r.is24 ? J : re(J, y), text: J < 10 ? `0${J}` : `${J}` });
      return y === "hours" && !r.is24 && N.unshift({ value: g.value === "PM" ? 12 : 0, text: "12" }), on(N, (J) => ({ active: !1, disabled: c.value.times[y].includes(J.value) || !A(J.value, y) || V(y, J.value) || _(y, J.value) }));
    }, le = (y) => y >= 0 ? y : 59, ge = (y) => y >= 0 ? y : 23, A = (y, X) => {
      const ee = r.minTime ? M(Jr(r.minTime)) : null, Y = r.maxTime ? M(Jr(r.maxTime)) : null, Te = M(
        Jr(
          C.value,
          X,
          X === "minutes" || X === "seconds" ? le(y) : ge(y)
        )
      );
      return ee && Y ? ($n(Te, Y) || Qa(Te, Y)) && (rn(Te, ee) || Qa(Te, ee)) : ee ? rn(Te, ee) || Qa(Te, ee) : Y ? $n(Te, Y) || Qa(Te, Y) : !0;
    }, S = (y) => r[`no${y[0].toUpperCase() + y.slice(1)}Overlay`], v = (y) => {
      S(y) || (x[y] = !x[y], x[y] ? (m.value = !0, n("overlay-opened", y)) : (m.value = !1, n("overlay-closed", y)));
    }, R = (y) => y === "hours" ? na : y === "minutes" ? _a : nn, K = () => {
      b.value && clearTimeout(b.value);
    }, w = (y, X = !0, ee) => {
      const Y = X ? ne : se, Te = X ? +r[`${y}Increment`] : -+r[`${y}Increment`];
      A(+r[y] + Te, y) && n(
        `update:${y}`,
        R(y)(Y({ [y]: +r[y] }, { [y]: +r[`${y}Increment`] }))
      ), !(ee != null && ee.keyboard) && u.value.timeArrowHoldThreshold && (b.value = setTimeout(() => {
        w(y, X);
      }, u.value.timeArrowHoldThreshold));
    }, oe = (y) => r.is24 ? y : (y >= 12 ? g.value = "PM" : g.value = "AM", o2(y)), ke = () => {
      g.value === "PM" ? (g.value = "AM", n("update:hours", r.hours - 12)) : (g.value = "PM", n("update:hours", r.hours + 12)), n("am-pm-change", g.value);
    }, et = (y) => {
      x[y] = !0;
    }, z = (y, X, ee) => {
      if (y && r.arrowNavigation) {
        Array.isArray(k.value[X]) ? k.value[X][ee] = y : k.value[X] = [y];
        const Y = k.value.reduce(
          (Te, N) => N.map((J, me) => [...Te[me] || [], N[me]]),
          []
        );
        o(r.closeTimePickerBtn), D.value && (Y[1] = Y[1].concat(D.value)), l(Y, r.order);
      }
    }, Z = (y, X) => (v(y), n(`update:${y}`, X));
    return t({ openChildCmp: et }), (y, X) => {
      var ee;
      return y.disabled ? Q("", !0) : (P(), j("div", J2, [
        (P(!0), j(Me, null, Re(I.value, (Y, Te) => {
          var N, J, me;
          return P(), j("div", {
            key: Te,
            class: we(de.value)
          }, [
            Y.separator ? (P(), j(Me, { key: 0 }, [
              m.value ? Q("", !0) : (P(), j(Me, { key: 0 }, [
                st(":")
              ], 64))
            ], 64)) : (P(), j(Me, { key: 1 }, [
              ce("button", {
                ref_for: !0,
                ref: (Oe) => z(Oe, Te, 0),
                type: "button",
                class: we({
                  dp__btn: !0,
                  dp__inc_dec_button: !y.timePickerInline,
                  dp__inc_dec_button_inline: y.timePickerInline,
                  dp__tp_inline_btn_top: y.timePickerInline,
                  dp__inc_dec_button_disabled: F.value(Y.type),
                  "dp--hidden-el": m.value
                }),
                "data-test": `${Y.type}-time-inc-btn-${r.order}`,
                "aria-label": (N = p(i)) == null ? void 0 : N.incrementValue(Y.type),
                tabindex: "0",
                onKeydown: (Oe) => p(pt)(Oe, () => w(Y.type, !0, { keyboard: !0 }), !0),
                onClick: (Oe) => p(u).timeArrowHoldThreshold ? void 0 : w(Y.type, !0),
                onMousedown: (Oe) => p(u).timeArrowHoldThreshold ? w(Y.type, !0) : void 0,
                onMouseup: K
              }, [
                r.timePickerInline ? (P(), j(Me, { key: 1 }, [
                  y.$slots["tp-inline-arrow-up"] ? ie(y.$slots, "tp-inline-arrow-up", { key: 0 }) : (P(), j(Me, { key: 1 }, [
                    ep,
                    tp
                  ], 64))
                ], 64)) : (P(), j(Me, { key: 0 }, [
                  y.$slots["arrow-up"] ? ie(y.$slots, "arrow-up", { key: 0 }) : Q("", !0),
                  y.$slots["arrow-up"] ? Q("", !0) : (P(), Ae(p(uo), { key: 1 }))
                ], 64))
              ], 42, Z2),
              ce("button", {
                ref_for: !0,
                ref: (Oe) => z(Oe, Te, 1),
                type: "button",
                "aria-label": `${T.value(Y.type).text}-${(J = p(i)) == null ? void 0 : J.openTpOverlay(Y.type)}`,
                class: we({
                  dp__time_display: !0,
                  dp__time_display_block: !y.timePickerInline,
                  dp__time_display_inline: y.timePickerInline,
                  "dp--time-invalid": $.value(Y.type),
                  "dp--time-overlay-btn": !$.value(Y.type),
                  "dp--hidden-el": m.value
                }),
                disabled: S(Y.type),
                tabindex: "0",
                "data-test": `${Y.type}-toggle-overlay-btn-${r.order}`,
                onKeydown: (Oe) => p(pt)(Oe, () => v(Y.type), !0),
                onClick: (Oe) => v(Y.type)
              }, [
                y.$slots[Y.type] ? ie(y.$slots, Y.type, {
                  key: 0,
                  text: T.value(Y.type).text,
                  value: T.value(Y.type).value
                }) : Q("", !0),
                y.$slots[Y.type] ? Q("", !0) : (P(), j(Me, { key: 1 }, [
                  st(Ye(T.value(Y.type).text), 1)
                ], 64))
              ], 42, ap),
              ce("button", {
                ref_for: !0,
                ref: (Oe) => z(Oe, Te, 2),
                type: "button",
                class: we({
                  dp__btn: !0,
                  dp__inc_dec_button: !y.timePickerInline,
                  dp__inc_dec_button_inline: y.timePickerInline,
                  dp__tp_inline_btn_bottom: y.timePickerInline,
                  dp__inc_dec_button_disabled: H.value(Y.type),
                  "dp--hidden-el": m.value
                }),
                "data-test": `${Y.type}-time-dec-btn-${r.order}`,
                "aria-label": (me = p(i)) == null ? void 0 : me.decrementValue(Y.type),
                tabindex: "0",
                onKeydown: (Oe) => p(pt)(Oe, () => w(Y.type, !1, { keyboard: !0 }), !0),
                onClick: (Oe) => p(u).timeArrowHoldThreshold ? void 0 : w(Y.type, !1),
                onMousedown: (Oe) => p(u).timeArrowHoldThreshold ? w(Y.type, !1) : void 0,
                onMouseup: K
              }, [
                r.timePickerInline ? (P(), j(Me, { key: 1 }, [
                  y.$slots["tp-inline-arrow-down"] ? ie(y.$slots, "tp-inline-arrow-down", { key: 0 }) : (P(), j(Me, { key: 1 }, [
                    rp,
                    lp
                  ], 64))
                ], 64)) : (P(), j(Me, { key: 0 }, [
                  y.$slots["arrow-down"] ? ie(y.$slots, "arrow-down", { key: 0 }) : Q("", !0),
                  y.$slots["arrow-down"] ? Q("", !0) : (P(), Ae(p(co), { key: 1 }))
                ], 64))
              ], 42, np)
            ], 64))
          ], 2);
        }), 128)),
        y.is24 ? Q("", !0) : (P(), j("div", op, [
          y.$slots["am-pm-button"] ? ie(y.$slots, "am-pm-button", {
            key: 0,
            toggle: ke,
            value: g.value
          }) : Q("", !0),
          y.$slots["am-pm-button"] ? Q("", !0) : (P(), j("button", {
            key: 1,
            ref_key: "amPmButton",
            ref: D,
            type: "button",
            class: "dp__pm_am_button",
            role: "button",
            "aria-label": (ee = p(i)) == null ? void 0 : ee.amPmButton,
            tabindex: "0",
            onClick: ke,
            onKeydown: X[0] || (X[0] = (Y) => p(pt)(Y, () => ke(), !0))
          }, Ye(g.value), 41, ip))
        ])),
        (P(!0), j(Me, null, Re(O.value, (Y, Te) => (P(), Ae(Va, {
          key: Te,
          name: p(f)(x[Y.type]),
          css: p(h)
        }, {
          default: ye(() => {
            var N, J;
            return [
              x[Y.type] ? (P(), Ae(zn, {
                key: 0,
                items: q(Y.type),
                "is-last": y.autoApply && !p(u).keepActionRow,
                "esc-close": y.escClose,
                type: Y.type,
                "text-input": y.textInput,
                config: y.config,
                "arrow-navigation": y.arrowNavigation,
                "aria-labels": y.ariaLabels,
                "overlay-label": (J = (N = p(i)).timeOverlay) == null ? void 0 : J.call(N, Y.type),
                onSelected: (me) => Z(Y.type, me),
                onToggle: (me) => v(Y.type),
                onResetFlow: X[1] || (X[1] = (me) => y.$emit("reset-flow"))
              }, dt({
                "button-icon": ye(() => [
                  y.$slots["clock-icon"] ? ie(y.$slots, "clock-icon", { key: 0 }) : Q("", !0),
                  y.$slots["clock-icon"] ? Q("", !0) : (P(), Ae(kr(y.timePickerInline ? p(dn) : p(so)), { key: 1 }))
                ]),
                _: 2
              }, [
                y.$slots[`${Y.type}-overlay-value`] ? {
                  name: "item",
                  fn: ye(({ item: me }) => [
                    ie(y.$slots, `${Y.type}-overlay-value`, {
                      text: me.text,
                      value: me.value
                    })
                  ]),
                  key: "0"
                } : void 0,
                y.$slots[`${Y.type}-overlay-header`] ? {
                  name: "header",
                  fn: ye(() => [
                    ie(y.$slots, `${Y.type}-overlay-header`, {
                      toggle: () => v(Y.type)
                    })
                  ]),
                  key: "1"
                } : void 0
              ]), 1032, ["items", "is-last", "esc-close", "type", "text-input", "config", "arrow-navigation", "aria-labels", "overlay-label", "onSelected", "onToggle"])) : Q("", !0)
            ];
          }),
          _: 2
        }, 1032, ["name", "css"]))), 128))
      ]));
    };
  }
}), up = { class: "dp--tp-wrap" }, cp = ["aria-label", "tabindex"], dp = ["role", "aria-label", "tabindex"], fp = ["aria-label"], Mu = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "TimePicker",
  props: {
    hours: { type: [Number, Array], default: 0 },
    minutes: { type: [Number, Array], default: 0 },
    seconds: { type: [Number, Array], default: 0 },
    disabledTimesConfig: { type: Function, default: null },
    validateTime: {
      type: Function,
      default: () => !1
    },
    ...Vt
  },
  emits: [
    "update:hours",
    "update:minutes",
    "update:seconds",
    "mount",
    "reset-flow",
    "overlay-opened",
    "overlay-closed",
    "am-pm-change"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, { buildMatrix: l, setTimePicker: o } = Aa(), i = Ba(), { defaultedTransitions: s, defaultedAriaLabels: c, defaultedTextInput: u, defaultedConfig: d, defaultedRange: f } = je(r), { transitionName: h, showTransition: x } = Vn(s), { hideNavigationButtons: g } = Nr(), D = U(null), k = U(null), b = U([]), m = U(null), M = U(!1);
    Le(() => {
      n("mount"), !r.timePicker && r.arrowNavigation ? l([nt(D.value)], "time") : o(!0, r.timePicker);
    });
    const $ = E(() => f.value.enabled && r.modelAuto ? su(r.internalModelValue) : !0), C = U(!1), _ = (q) => ({
      hours: Array.isArray(r.hours) ? r.hours[q] : r.hours,
      minutes: Array.isArray(r.minutes) ? r.minutes[q] : r.minutes,
      seconds: Array.isArray(r.seconds) ? r.seconds[q] : r.seconds
    }), W = E(() => {
      const q = [];
      if (f.value.enabled)
        for (let le = 0; le < 2; le++)
          q.push(_(le));
      else
        q.push(_(0));
      return q;
    }), F = (q, le = !1, ge = "") => {
      le || n("reset-flow"), C.value = q, n(q ? "overlay-opened" : "overlay-closed", ct.time), r.arrowNavigation && o(q), at(() => {
        ge !== "" && b.value[0] && b.value[0].openChildCmp(ge);
      });
    }, H = E(() => ({
      dp__btn: !0,
      dp__button: !0,
      dp__button_bottom: r.autoApply && !d.value.keepActionRow
    })), ne = _t(i, "timePicker"), se = (q, le, ge) => f.value.enabled ? le === 0 ? [q, W.value[1][ge]] : [W.value[0][ge], q] : q, de = (q) => {
      n("update:hours", q);
    }, I = (q) => {
      n("update:minutes", q);
    }, O = (q) => {
      n("update:seconds", q);
    }, T = () => {
      if (m.value && !u.value.enabled && !r.noOverlayFocus) {
        const q = uu(m.value);
        q && q.focus({ preventScroll: !0 });
      }
    }, V = (q) => {
      M.value = !1, n("overlay-closed", q);
    }, re = (q) => {
      M.value = !0, n("overlay-opened", q);
    };
    return t({ toggleTimePicker: F }), (q, le) => {
      var ge;
      return P(), j("div", up, [
        !q.timePicker && !q.timePickerInline ? ma((P(), j("button", {
          key: 0,
          ref_key: "openTimePickerBtn",
          ref: D,
          type: "button",
          class: we({ ...H.value, "dp--hidden-el": C.value }),
          "aria-label": (ge = p(c)) == null ? void 0 : ge.openTimePicker,
          tabindex: q.noOverlayFocus ? void 0 : 0,
          "data-test": "open-time-picker-btn",
          onKeydown: le[0] || (le[0] = (A) => p(pt)(A, () => F(!0))),
          onClick: le[1] || (le[1] = (A) => F(!0))
        }, [
          q.$slots["clock-icon"] ? ie(q.$slots, "clock-icon", { key: 0 }) : Q("", !0),
          q.$slots["clock-icon"] ? Q("", !0) : (P(), Ae(p(so), { key: 1 }))
        ], 42, cp)), [
          [rr, !p(g)(q.hideNavigation, "time")]
        ]) : Q("", !0),
        Fe(Va, {
          name: p(h)(C.value),
          css: p(x) && !q.timePickerInline
        }, {
          default: ye(() => {
            var A, S;
            return [
              C.value || q.timePicker || q.timePickerInline ? (P(), j("div", {
                key: 0,
                ref_key: "overlayRef",
                ref: m,
                role: q.timePickerInline ? void 0 : "dialog",
                class: we({
                  dp__overlay: !q.timePickerInline,
                  "dp--overlay-absolute": !r.timePicker && !q.timePickerInline,
                  "dp--overlay-relative": r.timePicker
                }),
                style: Pt(q.timePicker ? { height: `${p(d).modeHeight}px` } : void 0),
                "aria-label": (A = p(c)) == null ? void 0 : A.timePicker,
                tabindex: q.timePickerInline ? void 0 : 0
              }, [
                ce("div", {
                  class: we(
                    q.timePickerInline ? "dp__time_picker_inline_container" : "dp__overlay_container dp__container_flex dp__time_picker_overlay_container"
                  ),
                  style: { display: "flex" }
                }, [
                  q.$slots["time-picker-overlay"] ? ie(q.$slots, "time-picker-overlay", {
                    key: 0,
                    hours: e.hours,
                    minutes: e.minutes,
                    seconds: e.seconds,
                    setHours: de,
                    setMinutes: I,
                    setSeconds: O
                  }) : Q("", !0),
                  q.$slots["time-picker-overlay"] ? Q("", !0) : (P(), j("div", {
                    key: 1,
                    class: we(q.timePickerInline ? "dp__flex" : "dp__overlay_row dp__flex_row")
                  }, [
                    (P(!0), j(Me, null, Re(W.value, (v, R) => ma((P(), Ae(sp, Ne({
                      key: R,
                      ref_for: !0
                    }, {
                      ...q.$props,
                      order: R,
                      hours: v.hours,
                      minutes: v.minutes,
                      seconds: v.seconds,
                      closeTimePickerBtn: k.value,
                      disabledTimesConfig: e.disabledTimesConfig,
                      disabled: R === 0 ? p(f).fixedStart : p(f).fixedEnd
                    }, {
                      ref_for: !0,
                      ref_key: "timeInputRefs",
                      ref: b,
                      "validate-time": (K, w) => e.validateTime(K, se(w, R, K)),
                      "onUpdate:hours": (K) => de(se(K, R, "hours")),
                      "onUpdate:minutes": (K) => I(se(K, R, "minutes")),
                      "onUpdate:seconds": (K) => O(se(K, R, "seconds")),
                      onMounted: T,
                      onOverlayClosed: V,
                      onOverlayOpened: re,
                      onAmPmChange: le[2] || (le[2] = (K) => q.$emit("am-pm-change", K))
                    }), dt({ _: 2 }, [
                      Re(p(ne), (K, w) => ({
                        name: K,
                        fn: ye((oe) => [
                          ie(q.$slots, K, Ne({ ref_for: !0 }, oe))
                        ])
                      }))
                    ]), 1040, ["validate-time", "onUpdate:hours", "onUpdate:minutes", "onUpdate:seconds"])), [
                      [rr, R === 0 ? !0 : $.value]
                    ])), 128))
                  ], 2)),
                  !q.timePicker && !q.timePickerInline ? ma((P(), j("button", {
                    key: 2,
                    ref_key: "closeTimePickerBtn",
                    ref: k,
                    type: "button",
                    class: we({ ...H.value, "dp--hidden-el": M.value }),
                    "aria-label": (S = p(c)) == null ? void 0 : S.closeTimePicker,
                    tabindex: "0",
                    onKeydown: le[3] || (le[3] = (v) => p(pt)(v, () => F(!1))),
                    onClick: le[4] || (le[4] = (v) => F(!1))
                  }, [
                    q.$slots["calendar-icon"] ? ie(q.$slots, "calendar-icon", { key: 0 }) : Q("", !0),
                    q.$slots["calendar-icon"] ? Q("", !0) : (P(), Ae(p(dn), { key: 1 }))
                  ], 42, fp)), [
                    [rr, !p(g)(q.hideNavigation, "time")]
                  ]) : Q("", !0)
                ], 2)
              ], 14, dp)) : Q("", !0)
            ];
          }),
          _: 3
        }, 8, ["name", "css"])
      ]);
    };
  }
}), Au = (e, t, a, n) => {
  const { defaultedRange: r } = je(e), l = (m, M) => Array.isArray(t[m]) ? t[m][M] : t[m], o = (m) => e.enableSeconds ? Array.isArray(t.seconds) ? t.seconds[m] : t.seconds : 0, i = (m, M) => m ? M !== void 0 ? ha(m, l("hours", M), l("minutes", M), o(M)) : ha(m, t.hours, t.minutes, o()) : ru(G(), o(M)), s = (m, M) => {
    t[m] = M;
  }, c = E(() => e.modelAuto && r.value.enabled ? Array.isArray(a.value) ? a.value.length > 1 : !1 : r.value.enabled), u = (m, M) => {
    const $ = Object.fromEntries(
      Object.keys(t).map((C) => C === m ? [C, M] : [C, t[C]].slice())
    );
    if (c.value && !r.value.disableTimeRangeValidation) {
      const C = (W) => a.value ? ha(
        a.value[W],
        $.hours[W],
        $.minutes[W],
        $.seconds[W]
      ) : null, _ = (W) => nu(a.value[W], 0);
      return !(Se(C(0), C(1)) && (rn(C(0), _(1)) || $n(C(1), _(0))));
    }
    return !0;
  }, d = (m, M) => {
    u(m, M) && (s(m, M), n && n());
  }, f = (m) => {
    d("hours", m);
  }, h = (m) => {
    d("minutes", m);
  }, x = (m) => {
    d("seconds", m);
  }, g = (m, M, $, C) => {
    M && f(m), !M && !$ && h(m), $ && x(m), a.value && C(a.value);
  }, D = (m) => {
    if (m) {
      const M = Array.isArray(m), $ = M ? [+m[0].hours, +m[1].hours] : +m.hours, C = M ? [+m[0].minutes, +m[1].minutes] : +m.minutes, _ = M ? [+m[0].seconds, +m[1].seconds] : +m.seconds;
      s("hours", $), s("minutes", C), e.enableSeconds && s("seconds", _);
    }
  }, k = (m, M) => {
    const $ = {
      hours: Array.isArray(t.hours) ? t.hours[m] : t.hours,
      disabledArr: []
    };
    return (M || M === 0) && ($.hours = M), Array.isArray(e.disabledTimes) && ($.disabledArr = r.value.enabled && Array.isArray(e.disabledTimes[m]) ? e.disabledTimes[m] : e.disabledTimes), $;
  }, b = E(() => (m, M) => {
    var $;
    if (Array.isArray(e.disabledTimes)) {
      const { disabledArr: C, hours: _ } = k(m, M), W = C.filter((F) => +F.hours === _);
      return (($ = W[0]) == null ? void 0 : $.minutes) === "*" ? { hours: [_], minutes: void 0, seconds: void 0 } : {
        hours: [],
        minutes: (W == null ? void 0 : W.map((F) => +F.minutes)) ?? [],
        seconds: (W == null ? void 0 : W.map((F) => F.seconds ? +F.seconds : void 0)) ?? []
      };
    }
    return { hours: [], minutes: [], seconds: [] };
  });
  return {
    setTime: s,
    updateHours: f,
    updateMinutes: h,
    updateSeconds: x,
    getSetDateTime: i,
    updateTimeValues: g,
    getSecondsValue: o,
    assignStartTime: D,
    validateTime: u,
    disabledTimesConfig: b
  };
}, vp = (e, t) => {
  const a = () => {
    e.isTextInputDate && M();
  }, { modelValue: n, time: r } = Bn(e, t, a), { defaultedStartTime: l, defaultedRange: o, defaultedTz: i } = je(e), { updateTimeValues: s, getSetDateTime: c, setTime: u, assignStartTime: d, disabledTimesConfig: f, validateTime: h } = Au(e, r, n, x);
  function x() {
    t("update-flow-step");
  }
  const g = (C) => {
    const { hours: _, minutes: W, seconds: F } = C;
    return { hours: +_, minutes: +W, seconds: F ? +F : 0 };
  }, D = () => {
    if (e.startTime) {
      if (Array.isArray(e.startTime)) {
        const _ = g(e.startTime[0]), W = g(e.startTime[1]);
        return [Ee(G(), _), Ee(G(), W)];
      }
      const C = g(e.startTime);
      return Ee(G(), C);
    }
    return o.value.enabled ? [null, null] : null;
  }, k = () => {
    if (o.value.enabled) {
      const [C, _] = D();
      n.value = [
        wt(c(C, 0), i.value.timezone),
        wt(c(_, 1), i.value.timezone)
      ];
    } else
      n.value = wt(c(D()), i.value.timezone);
  }, b = (C) => Array.isArray(C) ? [Na(G(C[0])), Na(G(C[1]))] : [Na(C ?? G())], m = (C, _, W) => {
    u("hours", C), u("minutes", _), u("seconds", e.enableSeconds ? W : 0);
  }, M = () => {
    const [C, _] = b(n.value);
    return o.value.enabled ? m(
      [C.hours, _.hours],
      [C.minutes, _.minutes],
      [C.seconds, _.seconds]
    ) : m(C.hours, C.minutes, C.seconds);
  };
  Le(() => {
    if (!e.shadow)
      return d(l.value), n.value ? M() : k();
  });
  const $ = () => {
    Array.isArray(n.value) ? n.value = n.value.map((C, _) => C && c(C, _)) : n.value = c(n.value), t("time-update");
  };
  return {
    modelValue: n,
    time: r,
    disabledTimesConfig: f,
    updateTime: (C, _ = !0, W = !1) => {
      s(C, _, W, $);
    },
    validateTime: h
  };
}, mp = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "TimePickerSolo",
  props: {
    ...Vt
  },
  emits: [
    "update:internal-model-value",
    "time-update",
    "am-pm-change",
    "mount",
    "reset-flow",
    "update-flow-step",
    "overlay-toggle"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, l = Ba(), o = _t(l, "timePicker"), i = U(null), { time: s, modelValue: c, disabledTimesConfig: u, updateTime: d, validateTime: f } = vp(r, n);
    return Le(() => {
      r.shadow || n("mount", null);
    }), t({ getSidebarProps: () => ({
      modelValue: c,
      time: s,
      updateTime: d
    }), toggleTimePicker: (h, x = !1, g = "") => {
      var D;
      (D = i.value) == null || D.toggleTimePicker(h, x, g);
    } }), (h, x) => (P(), Ae(Lr, {
      "multi-calendars": 0,
      stretch: ""
    }, {
      default: ye(() => [
        Fe(Mu, Ne({
          ref_key: "tpRef",
          ref: i
        }, h.$props, {
          hours: p(s).hours,
          minutes: p(s).minutes,
          seconds: p(s).seconds,
          "internal-model-value": h.internalModelValue,
          "disabled-times-config": p(u),
          "validate-time": p(f),
          "onUpdate:hours": x[0] || (x[0] = (g) => p(d)(g)),
          "onUpdate:minutes": x[1] || (x[1] = (g) => p(d)(g, !1)),
          "onUpdate:seconds": x[2] || (x[2] = (g) => p(d)(g, !1, !0)),
          onAmPmChange: x[3] || (x[3] = (g) => h.$emit("am-pm-change", g)),
          onResetFlow: x[4] || (x[4] = (g) => h.$emit("reset-flow")),
          onOverlayClosed: x[5] || (x[5] = (g) => h.$emit("overlay-toggle", { open: !1, overlay: g })),
          onOverlayOpened: x[6] || (x[6] = (g) => h.$emit("overlay-toggle", { open: !0, overlay: g }))
        }), dt({ _: 2 }, [
          Re(p(o), (g, D) => ({
            name: g,
            fn: ye((k) => [
              ie(h.$slots, g, ot(gt(k)))
            ])
          }))
        ]), 1040, ["hours", "minutes", "seconds", "internal-model-value", "disabled-times-config", "validate-time"])
      ]),
      _: 3
    }));
  }
}), pp = { class: "dp--header-wrap" }, hp = {
  key: 0,
  class: "dp__month_year_wrap"
}, yp = { key: 0 }, gp = { class: "dp__month_year_wrap" }, bp = ["data-dp-element", "aria-label", "data-test", "onClick", "onKeydown"], wp = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "DpHeader",
  props: {
    month: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    instance: { type: Number, default: 0 },
    years: { type: Array, default: () => [] },
    months: { type: Array, default: () => [] },
    ...Vt
  },
  emits: ["update-month-year", "mount", "reset-flow", "overlay-closed", "overlay-opened"],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, {
      defaultedTransitions: l,
      defaultedAriaLabels: o,
      defaultedMultiCalendars: i,
      defaultedFilters: s,
      defaultedConfig: c,
      defaultedHighlight: u,
      propDates: d,
      defaultedUI: f
    } = je(r), { transitionName: h, showTransition: x } = Vn(l), { buildMatrix: g } = Aa(), { handleMonthYearChange: D, isDisabled: k, updateMonthYear: b } = H2(r, n), { showLeftIcon: m, showRightIcon: M } = Nr(), $ = U(!1), C = U(!1), _ = U(!1), W = U([null, null, null, null]);
    Le(() => {
      n("mount");
    });
    const F = (S) => ({
      get: () => r[S],
      set: (v) => {
        const R = S === Dt.month ? Dt.year : Dt.month;
        n("update-month-year", { [S]: v, [R]: r[R] }), S === Dt.month ? V(!0) : re(!0);
      }
    }), H = E(F(Dt.month)), ne = E(F(Dt.year)), se = E(() => (S) => ({
      month: r.month,
      year: r.year,
      items: S === Dt.month ? r.months : r.years,
      instance: r.instance,
      updateMonthYear: b,
      toggle: S === Dt.month ? V : re
    })), de = E(() => r.months.find((v) => v.value === r.month) || { text: "", value: 0 }), I = E(() => on(r.months, (S) => {
      const v = r.month === S.value, R = Ln(
        S.value,
        du(r.year, d.value.minDate),
        fu(r.year, d.value.maxDate)
      ) || s.value.months.includes(S.value), K = yu(u.value, S.value, r.year);
      return { active: v, disabled: R, highlighted: K };
    })), O = E(() => on(r.years, (S) => {
      const v = r.year === S.value, R = Ln(
        S.value,
        sn(d.value.minDate),
        sn(d.value.maxDate)
      ) || s.value.years.includes(S.value), K = ho(u.value, S.value);
      return { active: v, disabled: R, highlighted: K };
    })), T = (S, v, R) => {
      R !== void 0 ? S.value = R : S.value = !S.value, S.value ? (_.value = !0, n("overlay-opened", v)) : (_.value = !1, n("overlay-closed", v));
    }, V = (S = !1, v) => {
      q(S), T($, ct.month, v);
    }, re = (S = !1, v) => {
      q(S), T(C, ct.year, v);
    }, q = (S) => {
      S || n("reset-flow");
    }, le = (S, v) => {
      r.arrowNavigation && (W.value[v] = nt(S), g(W.value, "monthYear"));
    }, ge = E(() => {
      var S, v, R, K, w, oe;
      return [
        {
          type: Dt.month,
          index: 1,
          toggle: V,
          modelValue: H.value,
          updateModelValue: (ke) => H.value = ke,
          text: de.value.text,
          showSelectionGrid: $.value,
          items: I.value,
          ariaLabel: (S = o.value) == null ? void 0 : S.openMonthsOverlay,
          overlayLabel: ((R = (v = o.value).monthPicker) == null ? void 0 : R.call(v, !0)) ?? void 0
        },
        {
          type: Dt.year,
          index: 2,
          toggle: re,
          modelValue: ne.value,
          updateModelValue: (ke) => ne.value = ke,
          text: cu(r.year, r.locale),
          showSelectionGrid: C.value,
          items: O.value,
          ariaLabel: (K = o.value) == null ? void 0 : K.openYearsOverlay,
          overlayLabel: ((oe = (w = o.value).yearPicker) == null ? void 0 : oe.call(w, !0)) ?? void 0
        }
      ];
    }), A = E(() => r.disableYearSelect ? [ge.value[0]] : r.yearFirst ? [...ge.value].reverse() : ge.value);
    return t({
      toggleMonthPicker: V,
      toggleYearPicker: re,
      handleMonthYearChange: D
    }), (S, v) => {
      var R, K, w, oe, ke, et;
      return P(), j("div", pp, [
        S.$slots["month-year"] ? (P(), j("div", hp, [
          ie(S.$slots, "month-year", ot(gt({ month: e.month, year: e.year, months: e.months, years: e.years, updateMonthYear: p(b), handleMonthYearChange: p(D), instance: e.instance })))
        ])) : (P(), j(Me, { key: 1 }, [
          S.$slots["top-extra"] ? (P(), j("div", yp, [
            ie(S.$slots, "top-extra", { value: S.internalModelValue })
          ])) : Q("", !0),
          ce("div", gp, [
            p(m)(p(i), e.instance) && !S.vertical ? (P(), Ae(_n, {
              key: 0,
              "aria-label": (R = p(o)) == null ? void 0 : R.prevMonth,
              disabled: p(k)(!1),
              class: we((K = p(f)) == null ? void 0 : K.navBtnPrev),
              "el-name": "action-prev",
              onActivate: v[0] || (v[0] = (z) => p(D)(!1, !0)),
              onSetRef: v[1] || (v[1] = (z) => le(z, 0))
            }, {
              default: ye(() => [
                S.$slots["arrow-left"] ? ie(S.$slots, "arrow-left", { key: 0 }) : Q("", !0),
                S.$slots["arrow-left"] ? Q("", !0) : (P(), Ae(p(oo), { key: 1 }))
              ]),
              _: 3
            }, 8, ["aria-label", "disabled", "class"])) : Q("", !0),
            ce("div", {
              class: we(["dp__month_year_wrap", {
                dp__year_disable_select: S.disableYearSelect
              }])
            }, [
              (P(!0), j(Me, null, Re(A.value, (z, Z) => (P(), j(Me, {
                key: z.type
              }, [
                ce("button", {
                  ref_for: !0,
                  ref: (y) => le(y, Z + 1),
                  type: "button",
                  "data-dp-element": `overlay-${z.type}`,
                  class: we(["dp__btn dp__month_year_select", { "dp--hidden-el": _.value }]),
                  "aria-label": `${z.text}-${z.ariaLabel}`,
                  "data-test": `${z.type}-toggle-overlay-${e.instance}`,
                  onClick: z.toggle,
                  onKeydown: (y) => p(pt)(y, () => z.toggle(), !0)
                }, [
                  S.$slots[z.type] ? ie(S.$slots, z.type, {
                    key: 0,
                    text: z.text,
                    value: r[z.type]
                  }) : Q("", !0),
                  S.$slots[z.type] ? Q("", !0) : (P(), j(Me, { key: 1 }, [
                    st(Ye(z.text), 1)
                  ], 64))
                ], 42, bp),
                Fe(Va, {
                  name: p(h)(z.showSelectionGrid),
                  css: p(x)
                }, {
                  default: ye(() => [
                    z.showSelectionGrid ? (P(), Ae(zn, {
                      key: 0,
                      items: z.items,
                      "arrow-navigation": S.arrowNavigation,
                      "hide-navigation": S.hideNavigation,
                      "is-last": S.autoApply && !p(c).keepActionRow,
                      "skip-button-ref": !1,
                      config: S.config,
                      type: z.type,
                      "header-refs": [],
                      "esc-close": S.escClose,
                      "menu-wrap-ref": S.menuWrapRef,
                      "text-input": S.textInput,
                      "aria-labels": S.ariaLabels,
                      "overlay-label": z.overlayLabel,
                      onSelected: z.updateModelValue,
                      onToggle: z.toggle
                    }, dt({
                      "button-icon": ye(() => [
                        S.$slots["calendar-icon"] ? ie(S.$slots, "calendar-icon", { key: 0 }) : Q("", !0),
                        S.$slots["calendar-icon"] ? Q("", !0) : (P(), Ae(p(dn), { key: 1 }))
                      ]),
                      _: 2
                    }, [
                      S.$slots[`${z.type}-overlay-value`] ? {
                        name: "item",
                        fn: ye(({ item: y }) => [
                          ie(S.$slots, `${z.type}-overlay-value`, {
                            text: y.text,
                            value: y.value
                          })
                        ]),
                        key: "0"
                      } : void 0,
                      S.$slots[`${z.type}-overlay`] ? {
                        name: "overlay",
                        fn: ye(() => [
                          ie(S.$slots, `${z.type}-overlay`, Ne({ ref_for: !0 }, se.value(z.type)))
                        ]),
                        key: "1"
                      } : void 0,
                      S.$slots[`${z.type}-overlay-header`] ? {
                        name: "header",
                        fn: ye(() => [
                          ie(S.$slots, `${z.type}-overlay-header`, {
                            toggle: z.toggle
                          })
                        ]),
                        key: "2"
                      } : void 0
                    ]), 1032, ["items", "arrow-navigation", "hide-navigation", "is-last", "config", "type", "esc-close", "menu-wrap-ref", "text-input", "aria-labels", "overlay-label", "onSelected", "onToggle"])) : Q("", !0)
                  ]),
                  _: 2
                }, 1032, ["name", "css"])
              ], 64))), 128))
            ], 2),
            p(m)(p(i), e.instance) && S.vertical ? (P(), Ae(_n, {
              key: 1,
              "aria-label": (w = p(o)) == null ? void 0 : w.prevMonth,
              "el-name": "action-prev",
              disabled: p(k)(!1),
              class: we((oe = p(f)) == null ? void 0 : oe.navBtnPrev),
              onActivate: v[2] || (v[2] = (z) => p(D)(!1, !0))
            }, {
              default: ye(() => [
                S.$slots["arrow-up"] ? ie(S.$slots, "arrow-up", { key: 0 }) : Q("", !0),
                S.$slots["arrow-up"] ? Q("", !0) : (P(), Ae(p(uo), { key: 1 }))
              ]),
              _: 3
            }, 8, ["aria-label", "disabled", "class"])) : Q("", !0),
            p(M)(p(i), e.instance) ? (P(), Ae(_n, {
              key: 2,
              ref: "rightIcon",
              "el-name": "action-next",
              disabled: p(k)(!0),
              "aria-label": (ke = p(o)) == null ? void 0 : ke.nextMonth,
              class: we((et = p(f)) == null ? void 0 : et.navBtnNext),
              onActivate: v[3] || (v[3] = (z) => p(D)(!0, !0)),
              onSetRef: v[4] || (v[4] = (z) => le(z, S.disableYearSelect ? 2 : 3))
            }, {
              default: ye(() => [
                S.$slots[S.vertical ? "arrow-down" : "arrow-right"] ? ie(S.$slots, S.vertical ? "arrow-down" : "arrow-right", { key: 0 }) : Q("", !0),
                S.$slots[S.vertical ? "arrow-down" : "arrow-right"] ? Q("", !0) : (P(), Ae(kr(S.vertical ? p(co) : p(io)), { key: 1 }))
              ]),
              _: 3
            }, 8, ["disabled", "aria-label", "class"])) : Q("", !0)
          ])
        ], 64))
      ]);
    };
  }
}), kp = {
  class: "dp__calendar_header",
  role: "row"
}, xp = {
  key: 0,
  class: "dp__calendar_header_item",
  role: "gridcell"
}, _p = ["aria-label"], Mp = /* @__PURE__ */ ce("div", { class: "dp__calendar_header_separator" }, null, -1), Ap = {
  key: 0,
  class: "dp__calendar_item dp__week_num",
  role: "gridcell"
}, Tp = { class: "dp__cell_inner" }, Op = ["id", "aria-pressed", "aria-disabled", "aria-label", "data-test", "onClick", "onTouchend", "onKeydown", "onMouseenter", "onMouseleave", "onMousedown"], Pp = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "DpCalendar",
  props: {
    mappedDates: { type: Array, default: () => [] },
    instance: { type: Number, default: 0 },
    month: { type: Number, default: 0 },
    year: { type: Number, default: 0 },
    ...Vt
  },
  emits: [
    "select-date",
    "set-hover-date",
    "handle-scroll",
    "mount",
    "handle-swipe",
    "handle-space",
    "tooltip-open",
    "tooltip-close"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, { buildMultiLevelMatrix: l } = Aa(), {
      defaultedTransitions: o,
      defaultedConfig: i,
      defaultedAriaLabels: s,
      defaultedMultiCalendars: c,
      defaultedWeekNumbers: u,
      defaultedMultiDates: d,
      defaultedUI: f
    } = je(r), h = U(null), x = U({
      bottom: "",
      left: "",
      transform: ""
    }), g = U([]), D = U(null), k = U(!0), b = U(""), m = U({ startX: 0, endX: 0, startY: 0, endY: 0 }), M = U([]), $ = U({ left: "50%" }), C = U(!1), _ = E(() => r.calendar ? r.calendar(r.mappedDates) : r.mappedDates), W = E(() => r.dayNames ? Array.isArray(r.dayNames) ? r.dayNames : r.dayNames(r.locale, +r.weekStart) : l2(r.formatLocale, r.locale, +r.weekStart));
    Le(() => {
      n("mount", { cmp: "calendar", refs: g }), i.value.noSwipe || D.value && (D.value.addEventListener("touchstart", le, { passive: !1 }), D.value.addEventListener("touchend", ge, { passive: !1 }), D.value.addEventListener("touchmove", A, { passive: !1 })), r.monthChangeOnScroll && D.value && D.value.addEventListener("wheel", R, { passive: !1 });
    });
    const F = (z) => z ? r.vertical ? "vNext" : "next" : r.vertical ? "vPrevious" : "previous", H = (z, Z) => {
      if (r.transitions) {
        const y = ht(Qt(G(), r.month, r.year));
        b.value = Ge(ht(Qt(G(), z, Z)), y) ? o.value[F(!0)] : o.value[F(!1)], k.value = !1, at(() => {
          k.value = !0;
        });
      }
    }, ne = E(
      () => ({
        ...f.value.calendar ?? {}
      })
    ), se = E(() => (z) => {
      const Z = i2(z);
      return {
        dp__marker_dot: Z.type === "dot",
        dp__marker_line: Z.type === "line"
      };
    }), de = E(() => (z) => Se(z, h.value)), I = E(() => ({
      dp__calendar: !0,
      dp__calendar_next: c.value.count > 0 && r.instance !== 0
    })), O = E(() => (z) => r.hideOffsetDates ? z.current : !0), T = async (z, Z) => {
      const { width: y, height: X } = z.getBoundingClientRect();
      h.value = Z.value;
      let ee = { left: `${y / 2}px` }, Y = -50;
      if (await at(), M.value[0]) {
        const { left: Te, width: N } = M.value[0].getBoundingClientRect();
        Te < 0 && (ee = { left: "0" }, Y = 0, $.value.left = `${y / 2}px`), window.innerWidth < Te + N && (ee = { right: "0" }, Y = 0, $.value.left = `${N - y / 2}px`);
      }
      x.value = {
        bottom: `${X}px`,
        ...ee,
        transform: `translateX(${Y}%)`
      };
    }, V = async (z, Z, y) => {
      var X, ee, Y;
      const Te = nt(g.value[Z][y]);
      Te && ((X = z.marker) != null && X.customPosition && (Y = (ee = z.marker) == null ? void 0 : ee.tooltip) != null && Y.length ? x.value = z.marker.customPosition(Te) : await T(Te, z), n("tooltip-open", z.marker));
    }, re = async (z, Z, y) => {
      var X, ee;
      if (C.value && d.value.enabled && d.value.dragSelect)
        return n("select-date", z);
      n("set-hover-date", z), (ee = (X = z.marker) == null ? void 0 : X.tooltip) != null && ee.length && await V(z, Z, y);
    }, q = (z) => {
      h.value && (h.value = null, x.value = JSON.parse(JSON.stringify({ bottom: "", left: "", transform: "" })), n("tooltip-close", z.marker));
    }, le = (z) => {
      m.value.startX = z.changedTouches[0].screenX, m.value.startY = z.changedTouches[0].screenY;
    }, ge = (z) => {
      m.value.endX = z.changedTouches[0].screenX, m.value.endY = z.changedTouches[0].screenY, S();
    }, A = (z) => {
      r.vertical && !r.inline && z.preventDefault();
    }, S = () => {
      const z = r.vertical ? "Y" : "X";
      Math.abs(m.value[`start${z}`] - m.value[`end${z}`]) > 10 && n("handle-swipe", m.value[`start${z}`] > m.value[`end${z}`] ? "right" : "left");
    }, v = (z, Z, y) => {
      z && (Array.isArray(g.value[Z]) ? g.value[Z][y] = z : g.value[Z] = [z]), r.arrowNavigation && l(g.value, "calendar");
    }, R = (z) => {
      r.monthChangeOnScroll && (z.preventDefault(), n("handle-scroll", z));
    }, K = (z) => u.value.type === "local" ? no(z.value, { weekStartsOn: +r.weekStart }) : u.value.type === "iso" ? to(z.value) : typeof u.value.type == "function" ? u.value.type(z.value) : "", w = (z) => {
      const Z = z[0];
      return u.value.hideOnOffsetDates ? z.some((y) => y.current) ? K(Z) : "" : K(Z);
    }, oe = (z, Z, y = !0) => {
      y && f2() || d.value.enabled || (pa(z, i.value), n("select-date", Z));
    }, ke = (z) => {
      pa(z, i.value);
    }, et = (z) => {
      d.value.enabled && d.value.dragSelect ? (C.value = !0, n("select-date", z)) : d.value.enabled && n("select-date", z);
    };
    return t({ triggerTransition: H }), (z, Z) => (P(), j("div", {
      class: we(I.value)
    }, [
      ce("div", {
        ref_key: "calendarWrapRef",
        ref: D,
        class: we(ne.value),
        role: "grid"
      }, [
        ce("div", kp, [
          z.weekNumbers ? (P(), j("div", xp, Ye(z.weekNumName), 1)) : Q("", !0),
          (P(!0), j(Me, null, Re(W.value, (y, X) => {
            var ee, Y;
            return P(), j("div", {
              key: X,
              class: "dp__calendar_header_item",
              role: "gridcell",
              "data-test": "calendar-header",
              "aria-label": (Y = (ee = p(s)) == null ? void 0 : ee.weekDay) == null ? void 0 : Y.call(ee, X)
            }, [
              z.$slots["calendar-header"] ? ie(z.$slots, "calendar-header", {
                key: 0,
                day: y,
                index: X
              }) : Q("", !0),
              z.$slots["calendar-header"] ? Q("", !0) : (P(), j(Me, { key: 1 }, [
                st(Ye(y), 1)
              ], 64))
            ], 8, _p);
          }), 128))
        ]),
        Mp,
        Fe(Va, {
          name: b.value,
          css: !!z.transitions
        }, {
          default: ye(() => [
            k.value ? (P(), j("div", {
              key: 0,
              class: "dp__calendar",
              role: "rowgroup",
              onMouseleave: Z[1] || (Z[1] = (y) => C.value = !1)
            }, [
              (P(!0), j(Me, null, Re(_.value, (y, X) => (P(), j("div", {
                key: X,
                class: "dp__calendar_row",
                role: "row"
              }, [
                z.weekNumbers ? (P(), j("div", Ap, [
                  ce("div", Tp, Ye(w(y.days)), 1)
                ])) : Q("", !0),
                (P(!0), j(Me, null, Re(y.days, (ee, Y) => {
                  var Te, N, J;
                  return P(), j("div", {
                    id: p(gu)(ee.value),
                    ref_for: !0,
                    ref: (me) => v(me, X, Y),
                    key: Y + X,
                    role: "gridcell",
                    class: "dp__calendar_item",
                    "aria-pressed": (ee.classData.dp__active_date || ee.classData.dp__range_start || ee.classData.dp__range_start) ?? void 0,
                    "aria-disabled": ee.classData.dp__cell_disabled || void 0,
                    "aria-label": (N = (Te = p(s)) == null ? void 0 : Te.day) == null ? void 0 : N.call(Te, ee),
                    tabindex: "0",
                    "data-test": ee.value,
                    onClick: Ra((me) => oe(me, ee), ["prevent"]),
                    onTouchend: (me) => oe(me, ee, !1),
                    onKeydown: (me) => p(pt)(me, () => z.$emit("select-date", ee)),
                    onMouseenter: (me) => re(ee, X, Y),
                    onMouseleave: (me) => q(ee),
                    onMousedown: (me) => et(ee),
                    onMouseup: Z[0] || (Z[0] = (me) => C.value = !1)
                  }, [
                    ce("div", {
                      class: we(["dp__cell_inner", ee.classData])
                    }, [
                      z.$slots.day && O.value(ee) ? ie(z.$slots, "day", {
                        key: 0,
                        day: +ee.text,
                        date: ee.value
                      }) : Q("", !0),
                      z.$slots.day ? Q("", !0) : (P(), j(Me, { key: 1 }, [
                        st(Ye(ee.text), 1)
                      ], 64)),
                      ee.marker && O.value(ee) ? (P(), j(Me, { key: 2 }, [
                        z.$slots.marker ? ie(z.$slots, "marker", {
                          key: 0,
                          marker: ee.marker,
                          day: +ee.text,
                          date: ee.value
                        }) : (P(), j("div", {
                          key: 1,
                          class: we(se.value(ee.marker)),
                          style: Pt(ee.marker.color ? { backgroundColor: ee.marker.color } : {})
                        }, null, 6))
                      ], 64)) : Q("", !0),
                      de.value(ee.value) ? (P(), j("div", {
                        key: 3,
                        ref_for: !0,
                        ref_key: "activeTooltip",
                        ref: M,
                        class: "dp__marker_tooltip",
                        style: Pt(x.value)
                      }, [
                        (J = ee.marker) != null && J.tooltip ? (P(), j("div", {
                          key: 0,
                          class: "dp__tooltip_content",
                          onClick: ke
                        }, [
                          (P(!0), j(Me, null, Re(ee.marker.tooltip, (me, Oe) => (P(), j("div", {
                            key: Oe,
                            class: "dp__tooltip_text"
                          }, [
                            z.$slots["marker-tooltip"] ? ie(z.$slots, "marker-tooltip", {
                              key: 0,
                              tooltip: me,
                              day: ee.value
                            }) : Q("", !0),
                            z.$slots["marker-tooltip"] ? Q("", !0) : (P(), j(Me, { key: 1 }, [
                              ce("div", {
                                class: "dp__tooltip_mark",
                                style: Pt(me.color ? { backgroundColor: me.color } : {})
                              }, null, 4),
                              ce("div", null, Ye(me.text), 1)
                            ], 64))
                          ]))), 128)),
                          ce("div", {
                            class: "dp__arrow_bottom_tp",
                            style: Pt($.value)
                          }, null, 4)
                        ])) : Q("", !0)
                      ], 4)) : Q("", !0)
                    ], 2)
                  ], 40, Op);
                }), 128))
              ]))), 128))
            ], 32)) : Q("", !0)
          ]),
          _: 3
        }, 8, ["name", "css"])
      ], 2)
    ], 2));
  }
}), ki = (e) => Array.isArray(e), Sp = (e, t, a, n) => {
  const r = U([]), l = U(/* @__PURE__ */ new Date()), o = U(), i = () => le(e.isTextInputDate), { modelValue: s, calendars: c, time: u, today: d } = Bn(e, t, i), {
    defaultedMultiCalendars: f,
    defaultedStartTime: h,
    defaultedRange: x,
    defaultedConfig: g,
    defaultedTz: D,
    propDates: k,
    defaultedMultiDates: b
  } = je(e), { validateMonthYearInRange: m, isDisabled: M, isDateRangeAllowed: $, checkMinMaxRange: C } = Ta(e), { updateTimeValues: _, getSetDateTime: W, setTime: F, assignStartTime: H, validateTime: ne, disabledTimesConfig: se } = Au(e, u, s, n), de = E(
    () => (L) => c.value[L] ? c.value[L].month : 0
  ), I = E(
    () => (L) => c.value[L] ? c.value[L].year : 0
  ), O = (L) => !g.value.keepViewOnOffsetClick || L ? !0 : !o.value, T = (L, ae, B, te = !1) => {
    var ue, ft;
    O(te) && (c.value[L] || (c.value[L] = { month: 0, year: 0 }), c.value[L].month = hi(ae) ? (ue = c.value[L]) == null ? void 0 : ue.month : ae, c.value[L].year = hi(B) ? (ft = c.value[L]) == null ? void 0 : ft.year : B);
  }, V = () => {
    e.autoApply && t("select-date");
  };
  Le(() => {
    e.shadow || (s.value || (z(), h.value && H(h.value)), le(!0), e.focusStartDate && e.startDate && z());
  });
  const re = E(() => {
    var L;
    return (L = e.flow) != null && L.length && !e.partialFlow ? e.flowStep === e.flow.length : !0;
  }), q = () => {
    e.autoApply && re.value && t("auto-apply", e.partialFlow ? e.flowStep !== e.flow.length : !1);
  }, le = (L = !1) => {
    if (s.value)
      return Array.isArray(s.value) ? (r.value = s.value, w(L)) : S(s.value, L);
    if (f.value.count && L && !e.startDate)
      return A(G(), L);
  }, ge = () => Array.isArray(s.value) && x.value.enabled ? Pe(s.value[0]) === Pe(s.value[1] ?? s.value[0]) : !1, A = (L = /* @__PURE__ */ new Date(), ae = !1) => {
    if ((!f.value.count || !f.value.static || ae) && T(0, Pe(L), _e(L)), f.value.count && (!f.value.solo || !s.value || ge()))
      for (let B = 1; B < f.value.count; B++) {
        const te = Ee(G(), { month: de.value(B - 1), year: I.value(B - 1) }), ue = zs(te, { months: 1 });
        c.value[B] = { month: Pe(ue), year: _e(ue) };
      }
  }, S = (L, ae) => {
    A(L), F("hours", na(L)), F("minutes", _a(L)), F("seconds", nn(L)), f.value.count && ae && et();
  }, v = (L) => {
    if (f.value.count) {
      if (f.value.solo)
        return 0;
      const ae = Pe(L[0]), B = Pe(L[1]);
      return Math.abs(B - ae) < f.value.count ? 0 : 1;
    }
    return 1;
  }, R = (L, ae) => {
    L[1] && x.value.showLastInRange ? A(L[v(L)], ae) : A(L[0], ae);
    const B = (te, ue) => [
      te(L[0]),
      L[1] ? te(L[1]) : u[ue][1]
    ];
    F("hours", B(na, "hours")), F("minutes", B(_a, "minutes")), F("seconds", B(nn, "seconds"));
  }, K = (L, ae) => {
    if ((x.value.enabled || e.weekPicker) && !b.value.enabled)
      return R(L, ae);
    if (b.value.enabled && ae) {
      const B = L[L.length - 1];
      return S(B, ae);
    }
  }, w = (L) => {
    const ae = s.value;
    K(ae, L), f.value.count && f.value.solo && et();
  }, oe = (L, ae) => {
    const B = Ee(G(), { month: de.value(ae), year: I.value(ae) }), te = L < 0 ? It(B, 1) : ln(B, 1);
    m(Pe(te), _e(te), L < 0, e.preventMinMaxNavigation) && (T(ae, Pe(te), _e(te)), t("update-month-year", { instance: ae, month: Pe(te), year: _e(te) }), f.value.count && !f.value.solo && ke(ae), a());
  }, ke = (L) => {
    for (let ae = L - 1; ae >= 0; ae--) {
      const B = ln(Ee(G(), { month: de.value(ae + 1), year: I.value(ae + 1) }), 1);
      T(ae, Pe(B), _e(B));
    }
    for (let ae = L + 1; ae <= f.value.count - 1; ae++) {
      const B = It(Ee(G(), { month: de.value(ae - 1), year: I.value(ae - 1) }), 1);
      T(ae, Pe(B), _e(B));
    }
  }, et = () => {
    if (Array.isArray(s.value) && s.value.length === 2) {
      const L = G(
        G(s.value[1] ? s.value[1] : It(s.value[0], 1))
      ), [ae, B] = [Pe(s.value[0]), _e(s.value[0])], [te, ue] = [Pe(s.value[1]), _e(s.value[1])];
      (ae !== te || ae === te && B !== ue) && f.value.solo && T(1, Pe(L), _e(L));
    } else
      s.value && !Array.isArray(s.value) && (T(0, Pe(s.value), _e(s.value)), A(G()));
  }, z = () => {
    e.startDate && (T(0, Pe(G(e.startDate)), _e(G(e.startDate))), f.value.count && ke(0));
  }, Z = (L, ae) => {
    if (e.monthChangeOnScroll) {
      const B = (/* @__PURE__ */ new Date()).getTime() - l.value.getTime(), te = Math.abs(L.deltaY);
      let ue = 500;
      te > 1 && (ue = 100), te > 100 && (ue = 0), B > ue && (l.value = /* @__PURE__ */ new Date(), oe(e.monthChangeOnScroll !== "inverse" ? -L.deltaY : L.deltaY, ae));
    }
  }, y = (L, ae, B = !1) => {
    e.monthChangeOnArrows && e.vertical === B && X(L, ae);
  }, X = (L, ae) => {
    oe(L === "right" ? -1 : 1, ae);
  }, ee = (L) => {
    if (k.value.markers)
      return br(L.value, k.value.markers);
  }, Y = (L, ae) => {
    switch (e.sixWeeks === !0 ? "append" : e.sixWeeks) {
      case "prepend":
        return [!0, !1];
      case "center":
        return [L == 0, !0];
      case "fair":
        return [L == 0 || ae > L, !0];
      case "append":
        return [!1, !1];
      default:
        return [!1, !1];
    }
  }, Te = (L, ae, B, te) => {
    if (e.sixWeeks && L.length < 6) {
      const ue = 6 - L.length, ft = (ae.getDay() + 7 - te) % 7, Ot = 6 - (B.getDay() + 7 - te) % 7, [Oa, Fn] = Y(ft, Ot);
      for (let mn = 1; mn <= ue; mn++)
        if (Fn ? !!(mn % 2) == Oa : Oa) {
          const sa = L[0].days[0], Fr = N(Et(sa.value, -7), Pe(ae));
          L.unshift({ days: Fr });
        } else {
          const sa = L[L.length - 1], Fr = sa.days[sa.days.length - 1], Tu = N(Et(Fr.value, 1), Pe(ae));
          L.push({ days: Tu });
        }
    }
    return L;
  }, N = (L, ae) => {
    const B = G(L), te = [];
    for (let ue = 0; ue < 7; ue++) {
      const ft = Et(B, ue), Ot = Pe(ft) !== ae;
      te.push({
        text: e.hideOffsetDates && Ot ? "" : ft.getDate(),
        value: ft,
        current: !Ot,
        classData: {}
      });
    }
    return te;
  }, J = (L, ae) => {
    const B = [], te = new Date(ae, L), ue = new Date(ae, L + 1, 0), ft = e.weekStart, Ot = Ht(te, { weekStartsOn: ft }), Oa = (Fn) => {
      const mn = N(Fn, L);
      if (B.push({ days: mn }), !B[B.length - 1].days.some(
        (sa) => Se(ht(sa.value), ht(ue))
      )) {
        const sa = Et(Fn, 7);
        Oa(sa);
      }
    };
    return Oa(Ot), Te(B, te, ue, ft);
  }, me = (L) => {
    const ae = ha(G(L.value), u.hours, u.minutes, kt());
    t("date-update", ae), b.value.enabled ? yo(ae, s, b.value.limit) : s.value = ae, n(), at().then(() => {
      q();
    });
  }, Oe = (L) => x.value.noDisabledRange ? vu(r.value[0], L).some((ae) => M(ae)) : !1, Tt = () => {
    r.value = s.value ? s.value.slice() : [], r.value.length === 2 && !(x.value.fixedStart || x.value.fixedEnd) && (r.value = []);
  }, be = (L, ae) => {
    const B = [
      G(L.value),
      Et(G(L.value), +x.value.autoRange)
    ];
    $(B) ? (ae && ia(L.value), r.value = B) : t("invalid-date", L.value);
  }, ia = (L) => {
    const ae = Pe(G(L)), B = _e(G(L));
    if (T(0, ae, B), f.value.count > 0)
      for (let te = 1; te < f.value.count; te++) {
        const ue = p2(
          Ee(G(L), { year: I.value(te - 1), month: de.value(te - 1) })
        );
        T(te, ue.month, ue.year);
      }
  }, Bt = (L) => {
    if (Oe(L.value) || !C(L.value, s.value, x.value.fixedStart ? 0 : 1))
      return t("invalid-date", L.value);
    r.value = xu(G(L.value), s, t, x);
  }, fn = (L, ae) => {
    if (Tt(), x.value.autoRange)
      return be(L, ae);
    if (x.value.fixedStart || x.value.fixedEnd)
      return Bt(L);
    r.value[0] ? C(G(L.value), s.value) && !Oe(L.value) ? We(G(L.value), G(r.value[0])) ? (r.value.unshift(G(L.value)), t("range-end", r.value[0])) : (r.value[1] = G(L.value), t("range-end", r.value[1])) : (e.autoApply && t("auto-apply-invalid", L.value), t("invalid-date", L.value)) : (r.value[0] = G(L.value), t("range-start", r.value[0]));
  }, kt = (L = !0) => e.enableSeconds ? Array.isArray(u.seconds) ? L ? u.seconds[0] : u.seconds[1] : u.seconds : 0, vn = (L) => {
    r.value[L] = ha(
      r.value[L],
      u.hours[L],
      u.minutes[L],
      kt(L !== 1)
    );
  }, Rr = () => {
    var L, ae;
    r.value[0] && r.value[1] && +((L = r.value) == null ? void 0 : L[0]) > +((ae = r.value) == null ? void 0 : ae[1]) && (r.value.reverse(), t("range-start", r.value[0]), t("range-end", r.value[1]));
  }, Yn = () => {
    r.value.length && (r.value[0] && !r.value[1] ? vn(0) : (vn(0), vn(1), n()), Rr(), s.value = r.value.slice(), Er(r.value, t, e.autoApply, e.modelAuto));
  }, Ir = (L, ae = !1) => {
    if (M(L.value) || !L.current && e.hideOffsetDates)
      return t("invalid-date", L.value);
    if (o.value = JSON.parse(JSON.stringify(L)), !x.value.enabled)
      return me(L);
    ki(u.hours) && ki(u.minutes) && !b.value.enabled && (fn(L, ae), Yn());
  }, Hr = (L, ae) => {
    var B;
    T(L, ae.month, ae.year, !0), f.value.count && !f.value.solo && ke(L), t("update-month-year", { instance: L, month: ae.month, year: ae.year }), a(f.value.solo ? L : void 0);
    const te = (B = e.flow) != null && B.length ? e.flow[e.flowStep] : void 0;
    !ae.fromNav && (te === ct.month || te === ct.year) && n();
  }, zr = (L, ae) => {
    ku({
      value: L,
      modelValue: s,
      range: x.value.enabled,
      timezone: ae ? void 0 : D.value.timezone
    }), V(), e.multiCalendars && at().then(() => le(!0));
  }, Vr = () => {
    const L = fo(G(), D.value);
    x.value.enabled ? s.value && Array.isArray(s.value) && s.value[0] ? s.value = We(L, s.value[0]) ? [L, s.value[0]] : [s.value[0], L] : s.value = [L] : s.value = L, V();
  }, Br = () => {
    if (Array.isArray(s.value))
      if (b.value.enabled) {
        const L = Yr();
        s.value[s.value.length - 1] = W(L);
      } else
        s.value = s.value.map((L, ae) => L && W(L, ae));
    else
      s.value = W(s.value);
    t("time-update");
  }, Yr = () => Array.isArray(s.value) && s.value.length ? s.value[s.value.length - 1] : null;
  return {
    calendars: c,
    modelValue: s,
    month: de,
    year: I,
    time: u,
    disabledTimesConfig: se,
    today: d,
    validateTime: ne,
    getCalendarDays: J,
    getMarker: ee,
    handleScroll: Z,
    handleSwipe: X,
    handleArrow: y,
    selectDate: Ir,
    updateMonthYear: Hr,
    presetDate: zr,
    selectCurrentDate: Vr,
    updateTime: (L, ae = !0, B = !1) => {
      _(L, ae, B, Br);
    },
    assignMonthAndYear: A
  };
}, Cp = { key: 0 }, Dp = /* @__PURE__ */ xe({
  __name: "DatePicker",
  props: {
    ...Vt
  },
  emits: [
    "tooltip-open",
    "tooltip-close",
    "mount",
    "update:internal-model-value",
    "update-flow-step",
    "reset-flow",
    "auto-apply",
    "focus-menu",
    "select-date",
    "range-start",
    "range-end",
    "invalid-fixed-range",
    "time-update",
    "am-pm-change",
    "time-picker-open",
    "time-picker-close",
    "recalculate-position",
    "update-month-year",
    "auto-apply-invalid",
    "date-update",
    "invalid-date",
    "overlay-toggle"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, {
      calendars: l,
      month: o,
      year: i,
      modelValue: s,
      time: c,
      disabledTimesConfig: u,
      today: d,
      validateTime: f,
      getCalendarDays: h,
      getMarker: x,
      handleArrow: g,
      handleScroll: D,
      handleSwipe: k,
      selectDate: b,
      updateMonthYear: m,
      presetDate: M,
      selectCurrentDate: $,
      updateTime: C,
      assignMonthAndYear: _
    } = Sp(r, n, ge, A), W = Ba(), { setHoverDate: F, getDayClassData: H, clearHoverDate: ne } = Kp(s, r), { defaultedMultiCalendars: se } = je(r), de = U([]), I = U([]), O = U(null), T = _t(W, "calendar"), V = _t(W, "monthYear"), re = _t(W, "timePicker"), q = (Z) => {
      r.shadow || n("mount", Z);
    };
    it(
      l,
      () => {
        r.shadow || setTimeout(() => {
          n("recalculate-position");
        }, 0);
      },
      { deep: !0 }
    ), it(
      se,
      (Z, y) => {
        Z.count - y.count > 0 && _();
      },
      { deep: !0 }
    );
    const le = E(() => (Z) => h(o.value(Z), i.value(Z)).map((y) => ({
      ...y,
      days: y.days.map((X) => (X.marker = x(X), X.classData = H(X), X))
    })));
    function ge(Z) {
      var y;
      Z || Z === 0 ? (y = I.value[Z]) == null || y.triggerTransition(o.value(Z), i.value(Z)) : I.value.forEach((X, ee) => X.triggerTransition(o.value(ee), i.value(ee)));
    }
    function A() {
      n("update-flow-step");
    }
    const S = (Z, y = !1) => {
      b(Z, y), r.spaceConfirm && n("select-date");
    }, v = (Z, y, X = 0) => {
      var ee;
      (ee = de.value[X]) == null || ee.toggleMonthPicker(Z, y);
    }, R = (Z, y, X = 0) => {
      var ee;
      (ee = de.value[X]) == null || ee.toggleYearPicker(Z, y);
    }, K = (Z, y, X) => {
      var ee;
      (ee = O.value) == null || ee.toggleTimePicker(Z, y, X);
    }, w = (Z, y) => {
      var X;
      if (!r.range) {
        const ee = s.value ? s.value : d, Y = y ? new Date(y) : ee, Te = Z ? Ht(Y, { weekStartsOn: 1 }) : qs(Y, { weekStartsOn: 1 });
        b({
          value: Te,
          current: Pe(Y) === o.value(0),
          text: "",
          classData: {}
        }), (X = document.getElementById(gu(Te))) == null || X.focus();
      }
    }, oe = (Z) => {
      var y;
      (y = de.value[0]) == null || y.handleMonthYearChange(Z, !0);
    }, ke = (Z) => {
      m(0, { month: o.value(0), year: i.value(0) + (Z ? 1 : -1), fromNav: !0 });
    }, et = (Z, y) => {
      Z === ct.time && n(`time-picker-${y ? "open" : "close"}`), n("overlay-toggle", { open: y, overlay: Z });
    }, z = (Z) => {
      n("overlay-toggle", { open: !1, overlay: Z }), n("focus-menu");
    };
    return t({
      clearHoverDate: ne,
      presetDate: M,
      selectCurrentDate: $,
      toggleMonthPicker: v,
      toggleYearPicker: R,
      toggleTimePicker: K,
      handleArrow: g,
      updateMonthYear: m,
      getSidebarProps: () => ({
        modelValue: s,
        month: o,
        year: i,
        time: c,
        updateTime: C,
        updateMonthYear: m,
        selectDate: b,
        presetDate: M
      }),
      changeMonth: oe,
      changeYear: ke,
      selectWeekDate: w
    }), (Z, y) => (P(), j(Me, null, [
      Fe(Lr, {
        "multi-calendars": p(se).count,
        collapse: Z.collapse
      }, {
        default: ye(({ instance: X, index: ee }) => [
          Z.disableMonthYearSelect ? Q("", !0) : (P(), Ae(wp, Ne({
            key: 0,
            ref: (Y) => {
              Y && (de.value[ee] = Y);
            },
            months: p(iu)(Z.formatLocale, Z.locale, Z.monthNameFormat),
            years: p(vo)(Z.yearRange, Z.locale, Z.reverseYears),
            month: p(o)(X),
            year: p(i)(X),
            instance: X
          }, Z.$props, {
            onMount: y[0] || (y[0] = (Y) => q(p(Ea).header)),
            onResetFlow: y[1] || (y[1] = (Y) => Z.$emit("reset-flow")),
            onUpdateMonthYear: (Y) => p(m)(X, Y),
            onOverlayClosed: z,
            onOverlayOpened: y[2] || (y[2] = (Y) => Z.$emit("overlay-toggle", { open: !0, overlay: Y }))
          }), dt({ _: 2 }, [
            Re(p(V), (Y, Te) => ({
              name: Y,
              fn: ye((N) => [
                ie(Z.$slots, Y, ot(gt(N)))
              ])
            }))
          ]), 1040, ["months", "years", "month", "year", "instance", "onUpdateMonthYear"])),
          Fe(Pp, Ne({
            ref: (Y) => {
              Y && (I.value[ee] = Y);
            },
            "mapped-dates": le.value(X),
            month: p(o)(X),
            year: p(i)(X),
            instance: X
          }, Z.$props, {
            onSelectDate: (Y) => p(b)(Y, X !== 1),
            onHandleSpace: (Y) => S(Y, X !== 1),
            onSetHoverDate: y[3] || (y[3] = (Y) => p(F)(Y)),
            onHandleScroll: (Y) => p(D)(Y, X),
            onHandleSwipe: (Y) => p(k)(Y, X),
            onMount: y[4] || (y[4] = (Y) => q(p(Ea).calendar)),
            onResetFlow: y[5] || (y[5] = (Y) => Z.$emit("reset-flow")),
            onTooltipOpen: y[6] || (y[6] = (Y) => Z.$emit("tooltip-open", Y)),
            onTooltipClose: y[7] || (y[7] = (Y) => Z.$emit("tooltip-close", Y))
          }), dt({ _: 2 }, [
            Re(p(T), (Y, Te) => ({
              name: Y,
              fn: ye((N) => [
                ie(Z.$slots, Y, ot(gt({ ...N })))
              ])
            }))
          ]), 1040, ["mapped-dates", "month", "year", "instance", "onSelectDate", "onHandleSpace", "onHandleScroll", "onHandleSwipe"])
        ]),
        _: 3
      }, 8, ["multi-calendars", "collapse"]),
      Z.enableTimePicker ? (P(), j("div", Cp, [
        Z.$slots["time-picker"] ? ie(Z.$slots, "time-picker", ot(Ne({ key: 0 }, { time: p(c), updateTime: p(C) }))) : (P(), Ae(Mu, Ne({
          key: 1,
          ref_key: "timePickerRef",
          ref: O
        }, Z.$props, {
          hours: p(c).hours,
          minutes: p(c).minutes,
          seconds: p(c).seconds,
          "internal-model-value": Z.internalModelValue,
          "disabled-times-config": p(u),
          "validate-time": p(f),
          onMount: y[8] || (y[8] = (X) => q(p(Ea).timePicker)),
          "onUpdate:hours": y[9] || (y[9] = (X) => p(C)(X)),
          "onUpdate:minutes": y[10] || (y[10] = (X) => p(C)(X, !1)),
          "onUpdate:seconds": y[11] || (y[11] = (X) => p(C)(X, !1, !0)),
          onResetFlow: y[12] || (y[12] = (X) => Z.$emit("reset-flow")),
          onOverlayClosed: y[13] || (y[13] = (X) => et(X, !1)),
          onOverlayOpened: y[14] || (y[14] = (X) => et(X, !0)),
          onAmPmChange: y[15] || (y[15] = (X) => Z.$emit("am-pm-change", X))
        }), dt({ _: 2 }, [
          Re(p(re), (X, ee) => ({
            name: X,
            fn: ye((Y) => [
              ie(Z.$slots, X, ot(gt(Y)))
            ])
          }))
        ]), 1040, ["hours", "minutes", "seconds", "internal-model-value", "disabled-times-config", "validate-time"]))
      ])) : Q("", !0)
    ], 64));
  }
}), $p = (e, t) => {
  const a = U(), {
    defaultedMultiCalendars: n,
    defaultedConfig: r,
    defaultedHighlight: l,
    defaultedRange: o,
    propDates: i,
    defaultedFilters: s,
    defaultedMultiDates: c
  } = je(e), { modelValue: u, year: d, month: f, calendars: h } = Bn(e, t), { isDisabled: x } = Ta(e), { selectYear: g, groupedYears: D, showYearPicker: k, isDisabled: b, toggleYearPicker: m, handleYearSelect: M, handleYear: $ } = _u({
    modelValue: u,
    multiCalendars: n,
    range: o,
    highlight: l,
    calendars: h,
    propDates: i,
    month: f,
    year: d,
    filters: s,
    props: e,
    emit: t
  }), C = (O, T) => [O, T].map((V) => Wt(V, "MMMM", { locale: e.formatLocale })).join("-"), _ = E(() => (O) => u.value ? Array.isArray(u.value) ? u.value.some((T) => vi(O, T)) : vi(u.value, O) : !1), W = (O) => {
    if (o.value.enabled) {
      if (Array.isArray(u.value)) {
        const T = Se(O, u.value[0]) || Se(O, u.value[1]);
        return Dr(u.value, a.value, O) && !T;
      }
      return !1;
    }
    return !1;
  }, F = (O, T) => O.quarter === ii(T) && O.year === _e(T), H = (O) => typeof l.value == "function" ? l.value({ quarter: ii(O), year: _e(O) }) : !!l.value.quarters.find((T) => F(T, O)), ne = E(() => (O) => {
    const T = Ee(/* @__PURE__ */ new Date(), { year: d.value(O) });
    return ov({
      start: Dn(T),
      end: Ws(T)
    }).map((V) => {
      const re = La(V), q = si(V), le = x(V), ge = W(re), A = H(re);
      return {
        text: C(re, q),
        value: re,
        active: _.value(re),
        highlighted: A,
        disabled: le,
        isBetween: ge
      };
    });
  }), se = (O) => {
    yo(O, u, c.value.limit), t("auto-apply", !0);
  }, de = (O) => {
    u.value = go(u, O, t), Er(u.value, t, e.autoApply, e.modelAuto);
  }, I = (O) => {
    u.value = O, t("auto-apply");
  };
  return {
    defaultedConfig: r,
    defaultedMultiCalendars: n,
    groupedYears: D,
    year: d,
    isDisabled: b,
    quarters: ne,
    showYearPicker: k,
    modelValue: u,
    setHoverDate: (O) => {
      a.value = O;
    },
    selectYear: g,
    selectQuarter: (O, T, V) => {
      if (!V)
        return h.value[T].month = Pe(si(O)), c.value.enabled ? se(O) : o.value.enabled ? de(O) : I(O);
    },
    toggleYearPicker: m,
    handleYearSelect: M,
    handleYear: $
  };
}, Lp = { class: "dp--quarter-items" }, Ep = ["data-test", "disabled", "onClick", "onMouseover"], Np = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "QuarterPicker",
  props: {
    ...Vt
  },
  emits: [
    "update:internal-model-value",
    "reset-flow",
    "overlay-closed",
    "auto-apply",
    "range-start",
    "range-end",
    "overlay-toggle",
    "update-month-year"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, l = Ba(), o = _t(l, "yearMode"), {
      defaultedMultiCalendars: i,
      defaultedConfig: s,
      groupedYears: c,
      year: u,
      isDisabled: d,
      quarters: f,
      modelValue: h,
      showYearPicker: x,
      setHoverDate: g,
      selectQuarter: D,
      toggleYearPicker: k,
      handleYearSelect: b,
      handleYear: m
    } = $p(r, n);
    return t({ getSidebarProps: () => ({
      modelValue: h,
      year: u,
      selectQuarter: D,
      handleYearSelect: b,
      handleYear: m
    }) }), (M, $) => (P(), Ae(Lr, {
      "multi-calendars": p(i).count,
      collapse: M.collapse,
      stretch: ""
    }, {
      default: ye(({ instance: C }) => [
        ce("div", {
          class: "dp-quarter-picker-wrap",
          style: Pt({ minHeight: `${p(s).modeHeight}px` })
        }, [
          M.$slots["top-extra"] ? ie(M.$slots, "top-extra", {
            key: 0,
            value: M.internalModelValue
          }) : Q("", !0),
          ce("div", null, [
            Fe(wu, Ne(M.$props, {
              items: p(c)(C),
              instance: C,
              "show-year-picker": p(x)[C],
              year: p(u)(C),
              "is-disabled": (_) => p(d)(C, _),
              onHandleYear: (_) => p(m)(C, _),
              onYearSelect: (_) => p(b)(_, C),
              onToggleYearPicker: (_) => p(k)(C, _ == null ? void 0 : _.flow, _ == null ? void 0 : _.show)
            }), dt({ _: 2 }, [
              Re(p(o), (_, W) => ({
                name: _,
                fn: ye((F) => [
                  ie(M.$slots, _, ot(gt(F)))
                ])
              }))
            ]), 1040, ["items", "instance", "show-year-picker", "year", "is-disabled", "onHandleYear", "onYearSelect", "onToggleYearPicker"])
          ]),
          ce("div", Lp, [
            (P(!0), j(Me, null, Re(p(f)(C), (_, W) => (P(), j("div", { key: W }, [
              ce("button", {
                type: "button",
                class: we(["dp--qr-btn", {
                  "dp--qr-btn-active": _.active,
                  "dp--qr-btn-between": _.isBetween,
                  "dp--qr-btn-disabled": _.disabled,
                  "dp--highlighted": _.highlighted
                }]),
                "data-test": _.value,
                disabled: _.disabled,
                onClick: (F) => p(D)(_.value, C, _.disabled),
                onMouseover: (F) => p(g)(_.value)
              }, [
                M.$slots.quarter ? ie(M.$slots, "quarter", {
                  key: 0,
                  value: _.value,
                  text: _.text
                }) : (P(), j(Me, { key: 1 }, [
                  st(Ye(_.text), 1)
                ], 64))
              ], 42, Ep)
            ]))), 128))
          ])
        ], 4)
      ]),
      _: 3
    }, 8, ["multi-calendars", "collapse"]));
  }
}), Rp = ["id", "tabindex", "role", "aria-label"], Ip = {
  key: 0,
  class: "dp--menu-load-container"
}, Hp = /* @__PURE__ */ ce("span", { class: "dp--menu-loader" }, null, -1), zp = [
  Hp
], Vp = {
  key: 1,
  class: "dp--menu-header"
}, Bp = {
  key: 0,
  class: "dp__sidebar_left"
}, Yp = ["data-test", "onClick", "onKeydown"], Fp = {
  key: 2,
  class: "dp__sidebar_right"
}, jp = {
  key: 3,
  class: "dp__action_extra"
}, xi = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "DatepickerMenu",
  props: {
    ...$r,
    shadow: { type: Boolean, default: !1 },
    openOnTop: { type: Boolean, default: !1 },
    internalModelValue: { type: [Date, Array], default: null },
    noOverlayFocus: { type: Boolean, default: !1 },
    collapse: { type: Boolean, default: !1 },
    getInputRect: { type: Function, default: () => ({}) },
    isTextInputDate: { type: Boolean, default: !1 }
  },
  emits: [
    "close-picker",
    "select-date",
    "auto-apply",
    "time-update",
    "flow-step",
    "update-month-year",
    "invalid-select",
    "update:internal-model-value",
    "recalculate-position",
    "invalid-fixed-range",
    "tooltip-open",
    "tooltip-close",
    "time-picker-open",
    "time-picker-close",
    "am-pm-change",
    "range-start",
    "range-end",
    "auto-apply-invalid",
    "date-update",
    "invalid-date",
    "overlay-toggle"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, l = U(null), o = E(() => {
      const { openOnTop: N, ...J } = r;
      return {
        ...J,
        flowStep: F.value,
        collapse: r.collapse,
        noOverlayFocus: r.noOverlayFocus,
        menuWrapRef: l.value
      };
    }), { setMenuFocused: i, setShiftKey: s, control: c } = bu(), u = Ba(), { defaultedTextInput: d, defaultedInline: f, defaultedConfig: h, defaultedUI: x } = je(r), g = U(null), D = U(0), k = U(null), b = U(!1), m = U(null);
    Le(() => {
      if (!r.shadow) {
        b.value = !0, M(), window.addEventListener("resize", M);
        const N = nt(l);
        if (N && !d.value.enabled && !f.value.enabled && (i(!0), T()), N) {
          const J = (me) => {
            h.value.allowPreventDefault && me.preventDefault(), pa(me, h.value, !0);
          };
          N.addEventListener("pointerdown", J), N.addEventListener("mousedown", J);
        }
      }
    }), ra(() => {
      window.removeEventListener("resize", M);
    });
    const M = () => {
      const N = nt(k);
      N && (D.value = N.getBoundingClientRect().width);
    }, { arrowRight: $, arrowLeft: C, arrowDown: _, arrowUp: W } = Aa(), { flowStep: F, updateFlowStep: H, childMount: ne, resetFlow: se, handleFlow: de } = Xp(r, n, m), I = E(() => r.monthPicker ? X2 : r.yearPicker ? G2 : r.timePicker ? mp : r.quarterPicker ? Np : Dp), O = E(() => {
      var N;
      if (h.value.arrowLeft)
        return h.value.arrowLeft;
      const J = (N = l.value) == null ? void 0 : N.getBoundingClientRect(), me = r.getInputRect();
      return (me == null ? void 0 : me.width) < (D == null ? void 0 : D.value) && (me == null ? void 0 : me.left) <= ((J == null ? void 0 : J.left) ?? 0) ? `${(me == null ? void 0 : me.width) / 2}px` : (me == null ? void 0 : me.right) >= ((J == null ? void 0 : J.right) ?? 0) && (me == null ? void 0 : me.width) < (D == null ? void 0 : D.value) ? `${(D == null ? void 0 : D.value) - (me == null ? void 0 : me.width) / 2}px` : "50%";
    }), T = () => {
      const N = nt(l);
      N && N.focus({ preventScroll: !0 });
    }, V = E(() => {
      var N;
      return ((N = m.value) == null ? void 0 : N.getSidebarProps()) || {};
    }), re = () => {
      r.openOnTop && n("recalculate-position");
    }, q = _t(u, "action"), le = E(() => r.monthPicker || r.yearPicker ? _t(u, "monthYear") : r.timePicker ? _t(u, "timePicker") : _t(u, "shared")), ge = E(() => r.openOnTop ? "dp__arrow_bottom" : "dp__arrow_top"), A = E(() => ({
      dp__menu_disabled: r.disabled,
      dp__menu_readonly: r.readonly,
      "dp-menu-loading": r.loading
    })), S = E(
      () => ({
        dp__menu: !0,
        dp__menu_index: !f.value.enabled,
        dp__relative: f.value.enabled,
        ...x.value.menu ?? {}
      })
    ), v = (N) => {
      pa(N, h.value, !0);
    }, R = () => {
      r.escClose && n("close-picker");
    }, K = (N) => {
      if (r.arrowNavigation) {
        if (N === vt.up)
          return W();
        if (N === vt.down)
          return _();
        if (N === vt.left)
          return C();
        if (N === vt.right)
          return $();
      } else
        N === vt.left || N === vt.up ? z("handleArrow", vt.left, 0, N === vt.up) : z("handleArrow", vt.right, 0, N === vt.down);
    }, w = (N) => {
      s(N.shiftKey), !r.disableMonthYearSelect && N.code === He.tab && N.target.classList.contains("dp__menu") && c.value.shiftKeyInMenu && (N.preventDefault(), pa(N, h.value, !0), n("close-picker"));
    }, oe = () => {
      T(), n("time-picker-close");
    }, ke = (N) => {
      var J, me, Oe;
      (J = m.value) == null || J.toggleTimePicker(!1, !1), (me = m.value) == null || me.toggleMonthPicker(!1, !1, N), (Oe = m.value) == null || Oe.toggleYearPicker(!1, !1, N);
    }, et = (N, J = 0) => {
      var me, Oe, Tt;
      return N === "month" ? (me = m.value) == null ? void 0 : me.toggleMonthPicker(!1, !0, J) : N === "year" ? (Oe = m.value) == null ? void 0 : Oe.toggleYearPicker(!1, !0, J) : N === "time" ? (Tt = m.value) == null ? void 0 : Tt.toggleTimePicker(!0, !1) : ke(J);
    }, z = (N, ...J) => {
      var me, Oe;
      (me = m.value) != null && me[N] && ((Oe = m.value) == null || Oe[N](...J));
    }, Z = () => {
      z("selectCurrentDate");
    }, y = (N, J) => {
      z("presetDate", N, J);
    }, X = () => {
      z("clearHoverDate");
    }, ee = (N, J) => {
      z("updateMonthYear", N, J);
    }, Y = (N, J) => {
      N.preventDefault(), K(J);
    }, Te = (N) => {
      var J, me, Oe;
      if (w(N), N.key === He.home || N.key === He.end)
        return z(
          "selectWeekDate",
          N.key === He.home,
          N.target.getAttribute("id")
        );
      switch ((N.key === He.pageUp || N.key === He.pageDown) && (N.shiftKey ? (z("changeYear", N.key === He.pageUp), (J = Pl(l.value, "overlay-year")) == null || J.focus()) : (z("changeMonth", N.key === He.pageUp), (me = Pl(l.value, N.key === He.pageUp ? "action-prev" : "action-next")) == null || me.focus()), N.target.getAttribute("id") && ((Oe = l.value) == null || Oe.focus({ preventScroll: !0 }))), N.key) {
        case He.esc:
          return R();
        case He.arrowLeft:
          return Y(N, vt.left);
        case He.arrowRight:
          return Y(N, vt.right);
        case He.arrowUp:
          return Y(N, vt.up);
        case He.arrowDown:
          return Y(N, vt.down);
        default:
          return;
      }
    };
    return t({
      updateMonthYear: ee,
      switchView: et,
      handleFlow: de
    }), (N, J) => {
      var me, Oe, Tt;
      return P(), j("div", {
        id: N.uid ? `dp-menu-${N.uid}` : void 0,
        ref_key: "dpMenuRef",
        ref: l,
        tabindex: p(f).enabled ? void 0 : "0",
        role: p(f).enabled ? void 0 : "dialog",
        "aria-label": (me = N.ariaLabels) == null ? void 0 : me.menu,
        class: we(S.value),
        style: Pt({ "--dp-arrow-left": O.value }),
        onMouseleave: X,
        onClick: v,
        onKeydown: Te
      }, [
        (N.disabled || N.readonly) && p(f).enabled || N.loading ? (P(), j("div", {
          key: 0,
          class: we(A.value)
        }, [
          N.loading ? (P(), j("div", Ip, zp)) : Q("", !0)
        ], 2)) : Q("", !0),
        N.$slots["menu-header"] ? (P(), j("div", Vp, [
          ie(N.$slots, "menu-header")
        ])) : Q("", !0),
        !p(f).enabled && !N.teleportCenter ? (P(), j("div", {
          key: 2,
          class: we(ge.value)
        }, null, 2)) : Q("", !0),
        ce("div", {
          ref_key: "innerMenuRef",
          ref: k,
          class: we({
            dp__menu_content_wrapper: ((Oe = N.presetDates) == null ? void 0 : Oe.length) || !!N.$slots["left-sidebar"] || !!N.$slots["right-sidebar"],
            "dp--menu-content-wrapper-collapsed": e.collapse && (((Tt = N.presetDates) == null ? void 0 : Tt.length) || !!N.$slots["left-sidebar"] || !!N.$slots["right-sidebar"])
          }),
          style: Pt({ "--dp-menu-width": `${D.value}px` })
        }, [
          N.$slots["left-sidebar"] ? (P(), j("div", Bp, [
            ie(N.$slots, "left-sidebar", ot(gt(V.value)))
          ])) : Q("", !0),
          N.presetDates.length ? (P(), j("div", {
            key: 1,
            class: we({ "dp--preset-dates-collapsed": e.collapse, "dp--preset-dates": !0 })
          }, [
            (P(!0), j(Me, null, Re(N.presetDates, (be, ia) => (P(), j(Me, { key: ia }, [
              be.slot ? ie(N.$slots, be.slot, {
                key: 0,
                presetDate: y,
                label: be.label,
                value: be.value
              }) : (P(), j("button", {
                key: 1,
                type: "button",
                style: Pt(be.style || {}),
                class: we(["dp__btn dp--preset-range", { "dp--preset-range-collapsed": e.collapse }]),
                "data-test": be.testId ?? void 0,
                onClick: Ra((Bt) => y(be.value, be.noTz), ["prevent"]),
                onKeydown: (Bt) => p(pt)(Bt, () => y(be.value, be.noTz), !0)
              }, Ye(be.label), 47, Yp))
            ], 64))), 128))
          ], 2)) : Q("", !0),
          ce("div", {
            ref_key: "calendarWrapperRef",
            ref: g,
            class: "dp__instance_calendar",
            role: "document"
          }, [
            (P(), Ae(kr(I.value), Ne({
              ref_key: "dynCmpRef",
              ref: m
            }, o.value, {
              "flow-step": p(F),
              onMount: p(ne),
              onUpdateFlowStep: p(H),
              onResetFlow: p(se),
              onFocusMenu: T,
              onSelectDate: J[0] || (J[0] = (be) => N.$emit("select-date")),
              onDateUpdate: J[1] || (J[1] = (be) => N.$emit("date-update", be)),
              onTooltipOpen: J[2] || (J[2] = (be) => N.$emit("tooltip-open", be)),
              onTooltipClose: J[3] || (J[3] = (be) => N.$emit("tooltip-close", be)),
              onAutoApply: J[4] || (J[4] = (be) => N.$emit("auto-apply", be)),
              onRangeStart: J[5] || (J[5] = (be) => N.$emit("range-start", be)),
              onRangeEnd: J[6] || (J[6] = (be) => N.$emit("range-end", be)),
              onInvalidFixedRange: J[7] || (J[7] = (be) => N.$emit("invalid-fixed-range", be)),
              onTimeUpdate: J[8] || (J[8] = (be) => N.$emit("time-update")),
              onAmPmChange: J[9] || (J[9] = (be) => N.$emit("am-pm-change", be)),
              onTimePickerOpen: J[10] || (J[10] = (be) => N.$emit("time-picker-open", be)),
              onTimePickerClose: oe,
              onRecalculatePosition: re,
              onUpdateMonthYear: J[11] || (J[11] = (be) => N.$emit("update-month-year", be)),
              onAutoApplyInvalid: J[12] || (J[12] = (be) => N.$emit("auto-apply-invalid", be)),
              onInvalidDate: J[13] || (J[13] = (be) => N.$emit("invalid-date", be)),
              onOverlayToggle: J[14] || (J[14] = (be) => N.$emit("overlay-toggle", be)),
              "onUpdate:internalModelValue": J[15] || (J[15] = (be) => N.$emit("update:internal-model-value", be))
            }), dt({ _: 2 }, [
              Re(le.value, (be, ia) => ({
                name: be,
                fn: ye((Bt) => [
                  ie(N.$slots, be, ot(gt({ ...Bt })))
                ])
              }))
            ]), 1040, ["flow-step", "onMount", "onUpdateFlowStep", "onResetFlow"]))
          ], 512),
          N.$slots["right-sidebar"] ? (P(), j("div", Fp, [
            ie(N.$slots, "right-sidebar", ot(gt(V.value)))
          ])) : Q("", !0),
          N.$slots["action-extra"] ? (P(), j("div", jp, [
            N.$slots["action-extra"] ? ie(N.$slots, "action-extra", {
              key: 0,
              selectCurrentDate: Z
            }) : Q("", !0)
          ])) : Q("", !0)
        ], 6),
        !N.autoApply || p(h).keepActionRow ? (P(), Ae(B2, Ne({
          key: 3,
          "menu-mount": b.value
        }, o.value, {
          "calendar-width": D.value,
          onClosePicker: J[16] || (J[16] = (be) => N.$emit("close-picker")),
          onSelectDate: J[17] || (J[17] = (be) => N.$emit("select-date")),
          onInvalidSelect: J[18] || (J[18] = (be) => N.$emit("invalid-select")),
          onSelectNow: Z
        }), dt({ _: 2 }, [
          Re(p(q), (be, ia) => ({
            name: be,
            fn: ye((Bt) => [
              ie(N.$slots, be, ot(gt({ ...Bt })))
            ])
          }))
        ]), 1040, ["menu-mount", "calendar-width"])) : Q("", !0)
      ], 46, Rp);
    };
  }
});
var qa = /* @__PURE__ */ ((e) => (e.center = "center", e.left = "left", e.right = "right", e))(qa || {});
const Up = ({
  menuRef: e,
  menuRefInner: t,
  inputRef: a,
  pickerWrapperRef: n,
  inline: r,
  emit: l,
  props: o,
  slots: i
}) => {
  const { defaultedConfig: s } = je(o), c = U({}), u = U(!1), d = U({
    top: "0",
    left: "0"
  }), f = U(!1), h = Ke(o, "teleportCenter");
  it(h, () => {
    d.value = JSON.parse(JSON.stringify({})), $();
  });
  const x = (T) => {
    if (o.teleport) {
      const V = T.getBoundingClientRect();
      return {
        left: V.left + window.scrollX,
        top: V.top + window.scrollY
      };
    }
    return { top: 0, left: 0 };
  }, g = (T, V) => {
    d.value.left = `${T + V - c.value.width}px`;
  }, D = (T) => {
    d.value.left = `${T}px`;
  }, k = (T, V) => {
    o.position === qa.left && D(T), o.position === qa.right && g(T, V), o.position === qa.center && (d.value.left = `${T + V / 2 - c.value.width / 2}px`);
  }, b = (T) => {
    const { width: V, height: re } = T.getBoundingClientRect(), { top: q, left: le } = o.altPosition ? o.altPosition(T) : x(T);
    return { top: +q, left: +le, width: V, height: re };
  }, m = () => {
    d.value.left = "50%", d.value.top = "50%", d.value.transform = "translate(-50%, -50%)", d.value.position = "fixed", delete d.value.opacity;
  }, M = () => {
    const T = nt(a), { top: V, left: re, transform: q } = o.altPosition(T);
    d.value = { top: `${V}px`, left: `${re}px`, transform: q ?? "" };
  }, $ = (T = !0) => {
    var V;
    if (!r.value.enabled) {
      if (h.value)
        return m();
      if (o.altPosition !== null)
        return M();
      if (T) {
        const re = o.teleport ? (V = t.value) == null ? void 0 : V.$el : e.value;
        re && (c.value = re.getBoundingClientRect()), l("recalculate-position");
      }
      return se();
    }
  }, C = ({ inputEl: T, left: V, width: re }) => {
    window.screen.width > 768 && !u.value && k(V, re), F(T);
  }, _ = (T) => {
    const { top: V, left: re, height: q, width: le } = b(T);
    d.value.top = `${q + V + +o.offset}px`, f.value = !1, u.value || (d.value.left = `${re + le / 2 - c.value.width / 2}px`), C({ inputEl: T, left: re, width: le });
  }, W = (T) => {
    const { top: V, left: re, width: q } = b(T);
    d.value.top = `${V - +o.offset - c.value.height}px`, f.value = !0, C({ inputEl: T, left: re, width: q });
  }, F = (T) => {
    if (o.autoPosition) {
      const { left: V, width: re } = b(T), { left: q, right: le } = c.value;
      if (!u.value) {
        if (Math.abs(q) !== Math.abs(le)) {
          if (q <= 0)
            return u.value = !0, D(V);
          if (le >= document.documentElement.clientWidth)
            return u.value = !0, g(V, re);
        }
        return k(V, re);
      }
    }
  }, H = () => {
    const T = nt(a);
    if (T) {
      const { height: V } = c.value, { top: re, height: q } = T.getBoundingClientRect(), le = window.innerHeight - re - q, ge = re;
      return V <= le ? Ca.bottom : V > le && V <= ge ? Ca.top : le >= ge ? Ca.bottom : Ca.top;
    }
    return Ca.bottom;
  }, ne = (T) => H() === Ca.bottom ? _(T) : W(T), se = () => {
    const T = nt(a);
    if (T)
      return o.autoPosition ? ne(T) : _(T);
  }, de = function(T) {
    if (T) {
      const V = T.scrollHeight > T.clientHeight, re = window.getComputedStyle(T).overflowY.indexOf("hidden") !== -1;
      return V && !re;
    }
    return !0;
  }, I = function(T) {
    return !T || T === document.body || T.nodeType === Node.DOCUMENT_FRAGMENT_NODE ? window : de(T) ? T : I(
      T.assignedSlot && s.value.shadowDom ? T.assignedSlot.parentNode : T.parentNode
    );
  }, O = (T) => {
    if (T)
      switch (o.position) {
        case qa.left:
          return { left: 0, transform: "translateX(0)" };
        case qa.right:
          return { left: `${T.width}px`, transform: "translateX(-100%)" };
        default:
          return { left: `${T.width / 2}px`, transform: "translateX(-50%)" };
      }
    return {};
  };
  return {
    openOnTop: f,
    menuStyle: d,
    xCorrect: u,
    setMenuPosition: $,
    getScrollableParent: I,
    shadowRender: (T, V) => {
      var re, q, le;
      const ge = document.createElement("div"), A = (re = nt(a)) == null ? void 0 : re.getBoundingClientRect();
      ge.setAttribute("id", "dp--temp-container");
      const S = (q = n.value) != null && q.clientWidth ? n.value : document.body;
      S.append(ge);
      const v = O(A), R = s.value.shadowDom ? Object.keys(i).filter(
        (w) => ["right-sidebar", "left-sidebar", "top-extra", "action-extra"].includes(w)
      ) : Object.keys(i), K = qt(
        T,
        {
          ...V,
          shadow: !0,
          style: { opacity: 0, position: "absolute", ...v }
        },
        Object.fromEntries(R.map((w) => [w, i[w]]))
      );
      wo(K, ge), c.value = (le = K.el) == null ? void 0 : le.getBoundingClientRect(), wo(null, ge), S.removeChild(ge);
    }
  };
}, da = [
  { name: "clock-icon", use: ["time", "calendar", "shared"] },
  { name: "arrow-left", use: ["month-year", "calendar", "shared", "year-mode"] },
  { name: "arrow-right", use: ["month-year", "calendar", "shared", "year-mode"] },
  { name: "arrow-up", use: ["time", "calendar", "month-year", "shared"] },
  { name: "arrow-down", use: ["time", "calendar", "month-year", "shared"] },
  { name: "calendar-icon", use: ["month-year", "time", "calendar", "shared", "year-mode"] },
  { name: "day", use: ["calendar", "shared"] },
  { name: "month-overlay-value", use: ["calendar", "month-year", "shared"] },
  { name: "year-overlay-value", use: ["calendar", "month-year", "shared", "year-mode"] },
  { name: "year-overlay", use: ["month-year", "shared"] },
  { name: "month-overlay", use: ["month-year", "shared"] },
  { name: "month-overlay-header", use: ["month-year", "shared"] },
  { name: "year-overlay-header", use: ["month-year", "shared"] },
  { name: "hours-overlay-value", use: ["calendar", "time", "shared"] },
  { name: "hours-overlay-header", use: ["calendar", "time", "shared"] },
  { name: "minutes-overlay-value", use: ["calendar", "time", "shared"] },
  { name: "minutes-overlay-header", use: ["calendar", "time", "shared"] },
  { name: "seconds-overlay-value", use: ["calendar", "time", "shared"] },
  { name: "seconds-overlay-header", use: ["calendar", "time", "shared"] },
  { name: "hours", use: ["calendar", "time", "shared"] },
  { name: "minutes", use: ["calendar", "time", "shared"] },
  { name: "month", use: ["calendar", "month-year", "shared"] },
  { name: "year", use: ["calendar", "month-year", "shared", "year-mode"] },
  { name: "action-buttons", use: ["action"] },
  { name: "action-preview", use: ["action"] },
  { name: "calendar-header", use: ["calendar", "shared"] },
  { name: "marker-tooltip", use: ["calendar", "shared"] },
  { name: "action-extra", use: ["menu"] },
  { name: "time-picker-overlay", use: ["calendar", "time", "shared"] },
  { name: "am-pm-button", use: ["calendar", "time", "shared"] },
  { name: "left-sidebar", use: ["menu"] },
  { name: "right-sidebar", use: ["menu"] },
  { name: "month-year", use: ["month-year", "shared"] },
  { name: "time-picker", use: ["menu", "shared"] },
  { name: "action-row", use: ["action"] },
  { name: "marker", use: ["calendar", "shared"] },
  { name: "quarter", use: ["shared"] },
  { name: "top-extra", use: ["shared", "month-year"] },
  { name: "tp-inline-arrow-up", use: ["shared", "time"] },
  { name: "tp-inline-arrow-down", use: ["shared", "time"] },
  { name: "menu-header", use: ["menu"] }
], Wp = [{ name: "trigger" }, { name: "input-icon" }, { name: "clear-icon" }, { name: "dp-input" }], qp = {
  all: () => da,
  monthYear: () => da.filter((e) => e.use.includes("month-year")),
  input: () => Wp,
  timePicker: () => da.filter((e) => e.use.includes("time")),
  action: () => da.filter((e) => e.use.includes("action")),
  calendar: () => da.filter((e) => e.use.includes("calendar")),
  menu: () => da.filter((e) => e.use.includes("menu")),
  shared: () => da.filter((e) => e.use.includes("shared")),
  yearMode: () => da.filter((e) => e.use.includes("year-mode"))
}, _t = (e, t, a) => {
  const n = [];
  return qp[t]().forEach((r) => {
    e[r.name] && n.push(r.name);
  }), a != null && a.length && a.forEach((r) => {
    r.slot && n.push(r.slot);
  }), n;
}, Vn = (e) => {
  const t = E(() => (n) => e.value ? n ? e.value.open : e.value.close : ""), a = E(() => (n) => e.value ? n ? e.value.menuAppearTop : e.value.menuAppearBottom : "");
  return { transitionName: t, showTransition: !!e.value, menuTransition: a };
}, Bn = (e, t, a) => {
  const { defaultedRange: n, defaultedTz: r } = je(e), l = G(wt(G(), r.value.timezone)), o = U([{ month: Pe(l), year: _e(l) }]), i = (f) => {
    const h = {
      hours: na(l),
      minutes: _a(l),
      seconds: 0
    };
    return n.value.enabled ? [h[f], h[f]] : h[f];
  }, s = un({
    hours: i("hours"),
    minutes: i("minutes"),
    seconds: i("seconds")
  });
  it(
    n,
    (f, h) => {
      f.enabled !== h.enabled && (s.hours = i("hours"), s.minutes = i("minutes"), s.seconds = i("seconds"));
    },
    { deep: !0 }
  );
  const c = E({
    get: () => e.internalModelValue,
    set: (f) => {
      !e.readonly && !e.disabled && t("update:internal-model-value", f);
    }
  }), u = E(
    () => (f) => o.value[f] ? o.value[f].month : 0
  ), d = E(
    () => (f) => o.value[f] ? o.value[f].year : 0
  );
  return it(
    c,
    (f, h) => {
      a && JSON.stringify(f ?? {}) !== JSON.stringify(h ?? {}) && a();
    },
    { deep: !0 }
  ), {
    calendars: o,
    time: s,
    modelValue: c,
    month: u,
    year: d,
    today: l
  };
}, Kp = (e, t) => {
  const {
    defaultedMultiCalendars: a,
    defaultedMultiDates: n,
    defaultedUI: r,
    defaultedHighlight: l,
    defaultedTz: o,
    propDates: i,
    defaultedRange: s
  } = je(t), { isDisabled: c } = Ta(t), u = U(null), d = U(wt(/* @__PURE__ */ new Date(), o.value.timezone)), f = (v) => {
    !v.current && t.hideOffsetDates || (u.value = v.value);
  }, h = () => {
    u.value = null;
  }, x = (v) => Array.isArray(e.value) && s.value.enabled && e.value[0] && u.value ? v ? Ge(u.value, e.value[0]) : We(u.value, e.value[0]) : !0, g = (v, R) => {
    const K = () => e.value ? R ? e.value[0] || null : e.value[1] : null, w = e.value && Array.isArray(e.value) ? K() : null;
    return Se(G(v.value), w);
  }, D = (v) => {
    const R = Array.isArray(e.value) ? e.value[0] : null;
    return v ? !We(u.value ?? null, R) : !0;
  }, k = (v, R = !0) => (s.value.enabled || t.weekPicker) && Array.isArray(e.value) && e.value.length === 2 ? t.hideOffsetDates && !v.current ? !1 : Se(G(v.value), e.value[R ? 0 : 1]) : s.value.enabled ? g(v, R) && D(R) || Se(v.value, Array.isArray(e.value) ? e.value[0] : null) && x(R) : !1, b = (v, R) => {
    if (Array.isArray(e.value) && e.value[0] && e.value.length === 1) {
      const K = Se(v.value, u.value);
      return R ? Ge(e.value[0], v.value) && K : We(e.value[0], v.value) && K;
    }
    return !1;
  }, m = (v) => !e.value || t.hideOffsetDates && !v.current ? !1 : s.value.enabled ? t.modelAuto && Array.isArray(e.value) ? Se(v.value, e.value[0] ? e.value[0] : d.value) : !1 : n.value.enabled && Array.isArray(e.value) ? e.value.some((R) => Se(R, v.value)) : Se(v.value, e.value ? e.value : d.value), M = (v) => {
    if (s.value.autoRange || t.weekPicker) {
      if (u.value) {
        if (t.hideOffsetDates && !v.current)
          return !1;
        const R = Et(u.value, +s.value.autoRange), K = Xt(G(u.value), t.weekStart);
        return t.weekPicker ? Se(K[1], G(v.value)) : Se(R, G(v.value));
      }
      return !1;
    }
    return !1;
  }, $ = (v) => {
    if (s.value.autoRange || t.weekPicker) {
      if (u.value) {
        const R = Et(u.value, +s.value.autoRange);
        if (t.hideOffsetDates && !v.current)
          return !1;
        const K = Xt(G(u.value), t.weekStart);
        return t.weekPicker ? Ge(v.value, K[0]) && We(v.value, K[1]) : Ge(v.value, u.value) && We(v.value, R);
      }
      return !1;
    }
    return !1;
  }, C = (v) => {
    if (s.value.autoRange || t.weekPicker) {
      if (u.value) {
        if (t.hideOffsetDates && !v.current)
          return !1;
        const R = Xt(G(u.value), t.weekStart);
        return t.weekPicker ? Se(R[0], v.value) : Se(u.value, v.value);
      }
      return !1;
    }
    return !1;
  }, _ = (v) => Dr(e.value, u.value, v.value), W = () => t.modelAuto && Array.isArray(t.internalModelValue) ? !!t.internalModelValue[0] : !1, F = () => t.modelAuto ? su(t.internalModelValue) : !0, H = (v) => {
    if (t.weekPicker)
      return !1;
    const R = s.value.enabled ? !k(v) && !k(v, !1) : !0;
    return !c(v.value) && !m(v) && !(!v.current && t.hideOffsetDates) && R;
  }, ne = (v) => s.value.enabled ? t.modelAuto ? W() && m(v) : !1 : m(v), se = (v) => l.value ? d2(v.value, i.value.highlight) : !1, de = (v) => {
    const R = c(v.value);
    return R && (typeof l.value == "function" ? !l.value(v.value, R) : !l.value.options.highlightDisabled);
  }, I = (v) => {
    var R;
    return typeof l.value == "function" ? l.value(v.value) : (R = l.value.weekdays) == null ? void 0 : R.includes(v.value.getDay());
  }, O = (v) => (s.value.enabled || t.weekPicker) && (!(a.value.count > 0) || v.current) && F() && !(!v.current && t.hideOffsetDates) && !m(v) ? _(v) : !1, T = (v) => {
    const { isRangeStart: R, isRangeEnd: K } = le(v), w = s.value.enabled ? R || K : !1;
    return {
      dp__cell_offset: !v.current,
      dp__pointer: !t.disabled && !(!v.current && t.hideOffsetDates) && !c(v.value),
      dp__cell_disabled: c(v.value),
      dp__cell_highlight: !de(v) && (se(v) || I(v)) && !ne(v) && !w && !C(v) && !(O(v) && t.weekPicker) && !K,
      dp__cell_highlight_active: !de(v) && (se(v) || I(v)) && ne(v),
      dp__today: !t.noToday && Se(v.value, d.value) && v.current,
      "dp--past": We(v.value, d.value),
      "dp--future": Ge(v.value, d.value)
    };
  }, V = (v) => ({
    dp__active_date: ne(v),
    dp__date_hover: H(v)
  }), re = (v) => {
    if (e.value && !Array.isArray(e.value)) {
      const R = Xt(e.value, t.weekStart);
      return {
        ...A(v),
        dp__range_start: Se(R[0], v.value),
        dp__range_end: Se(R[1], v.value),
        dp__range_between_week: Ge(v.value, R[0]) && We(v.value, R[1])
      };
    }
    return {
      ...A(v)
    };
  }, q = (v) => {
    if (e.value && Array.isArray(e.value)) {
      const R = Xt(e.value[0], t.weekStart), K = e.value[1] ? Xt(e.value[1], t.weekStart) : [];
      return {
        ...A(v),
        dp__range_start: Se(R[0], v.value) || Se(K[0], v.value),
        dp__range_end: Se(R[1], v.value) || Se(K[1], v.value),
        dp__range_between_week: Ge(v.value, R[0]) && We(v.value, R[1]) || Ge(v.value, K[0]) && We(v.value, K[1]),
        dp__range_between: Ge(v.value, R[1]) && We(v.value, K[0])
      };
    }
    return {
      ...A(v)
    };
  }, le = (v) => {
    const R = a.value.count > 0 ? v.current && k(v) && F() : k(v) && F(), K = a.value.count > 0 ? v.current && k(v, !1) && F() : k(v, !1) && F();
    return { isRangeStart: R, isRangeEnd: K };
  }, ge = (v) => {
    const { isRangeStart: R, isRangeEnd: K } = le(v);
    return {
      dp__range_start: R,
      dp__range_end: K,
      dp__range_between: O(v),
      dp__date_hover: Se(v.value, u.value) && !R && !K && !t.weekPicker,
      dp__date_hover_start: b(v, !0),
      dp__date_hover_end: b(v, !1)
    };
  }, A = (v) => ({
    ...ge(v),
    dp__cell_auto_range: $(v),
    dp__cell_auto_range_start: C(v),
    dp__cell_auto_range_end: M(v)
  }), S = (v) => s.value.enabled ? s.value.autoRange ? A(v) : t.modelAuto ? { ...V(v), ...ge(v) } : t.weekPicker ? q(v) : ge(v) : t.weekPicker ? re(v) : V(v);
  return {
    setHoverDate: f,
    clearHoverDate: h,
    getDayClassData: (v) => t.hideOffsetDates && !v.current ? {} : {
      ...T(v),
      ...S(v),
      [t.dayClass ? t.dayClass(v.value, t.internalModelValue) : ""]: !0,
      ...r.value.calendarCell ?? {}
    }
  };
}, Ta = (e) => {
  const { defaultedFilters: t, defaultedRange: a, propDates: n, defaultedMultiDates: r } = je(e), l = (I) => n.value.disabledDates ? typeof n.value.disabledDates == "function" ? n.value.disabledDates(G(I)) : !!br(I, n.value.disabledDates) : !1, o = (I) => n.value.maxDate ? e.yearPicker ? _e(I) > _e(n.value.maxDate) : Ge(I, n.value.maxDate) : !1, i = (I) => n.value.minDate ? e.yearPicker ? _e(I) < _e(n.value.minDate) : We(I, n.value.minDate) : !1, s = (I) => {
    const O = o(I), T = i(I), V = l(I), re = t.value.months.map((S) => +S).includes(Pe(I)), q = e.disabledWeekDays.length ? e.disabledWeekDays.some((S) => +S === Zv(I)) : !1, le = h(I), ge = _e(I), A = ge < +e.yearRange[0] || ge > +e.yearRange[1];
    return !(O || T || V || re || A || q || le);
  }, c = (I, O) => We(...va(n.value.minDate, I, O)) || Se(...va(n.value.minDate, I, O)), u = (I, O) => Ge(...va(n.value.maxDate, I, O)) || Se(...va(n.value.maxDate, I, O)), d = (I, O, T) => {
    let V = !1;
    return n.value.maxDate && T && u(I, O) && (V = !0), n.value.minDate && !T && c(I, O) && (V = !0), V;
  }, f = (I, O, T, V) => {
    let re = !1;
    return V ? n.value.minDate && n.value.maxDate ? re = d(I, O, T) : (n.value.minDate && c(I, O) || n.value.maxDate && u(I, O)) && (re = !0) : re = !0, re;
  }, h = (I) => Array.isArray(n.value.allowedDates) && !n.value.allowedDates.length ? !0 : n.value.allowedDates ? !br(I, n.value.allowedDates) : !1, x = (I) => !s(I), g = (I) => a.value.noDisabledRange ? !Us({ start: I[0], end: I[1] }).some((O) => x(O)) : !0, D = (I) => {
    if (I) {
      const O = _e(I);
      return O >= +e.yearRange[0] && O <= e.yearRange[1];
    }
    return !0;
  }, k = (I, O) => !!(Array.isArray(I) && I[O] && (a.value.maxRange || a.value.minRange) && D(I[O])), b = (I, O, T = 0) => {
    if (k(O, T) && D(I)) {
      const V = Fs(I, O[T]), re = vu(O[T], I), q = re.length === 1 ? 0 : re.filter((ge) => x(ge)).length, le = Math.abs(V) - (a.value.minMaxRawRange ? 0 : q);
      if (a.value.minRange && a.value.maxRange)
        return le >= +a.value.minRange && le <= +a.value.maxRange;
      if (a.value.minRange)
        return le >= +a.value.minRange;
      if (a.value.maxRange)
        return le <= +a.value.maxRange;
    }
    return !0;
  }, m = () => !e.enableTimePicker || e.monthPicker || e.yearPicker || e.ignoreTimeValidation, M = (I) => Array.isArray(I) ? [I[0] ? tl(I[0]) : null, I[1] ? tl(I[1]) : null] : tl(I), $ = (I, O, T) => I.find(
    (V) => +V.hours === na(O) && V.minutes === "*" ? !0 : +V.minutes === _a(O) && +V.hours === na(O)
  ) && T, C = (I, O, T) => {
    const [V, re] = I, [q, le] = O;
    return !$(V, q, T) && !$(re, le, T) && T;
  }, _ = (I, O) => {
    const T = Array.isArray(O) ? O : [O];
    return Array.isArray(e.disabledTimes) ? Array.isArray(e.disabledTimes[0]) ? C(e.disabledTimes, T, I) : !T.some((V) => $(e.disabledTimes, V, I)) : I;
  }, W = (I, O) => {
    const T = Array.isArray(O) ? [Na(O[0]), O[1] ? Na(O[1]) : void 0] : Na(O), V = !e.disabledTimes(T);
    return I && V;
  }, F = (I, O) => e.disabledTimes ? Array.isArray(e.disabledTimes) ? _(O, I) : W(O, I) : O, H = (I) => {
    let O = !0;
    if (!I || m())
      return !0;
    const T = !n.value.minDate && !n.value.maxDate ? M(I) : I;
    return (e.maxTime || n.value.maxDate) && (O = gi(
      e.maxTime,
      n.value.maxDate,
      "max",
      lt(T),
      O
    )), (e.minTime || n.value.minDate) && (O = gi(
      e.minTime,
      n.value.minDate,
      "min",
      lt(T),
      O
    )), F(I, O);
  }, ne = (I) => {
    if (!e.monthPicker)
      return !0;
    let O = !0;
    const T = G(Nt(I));
    if (n.value.minDate && n.value.maxDate) {
      const V = G(Nt(n.value.minDate)), re = G(Nt(n.value.maxDate));
      return Ge(T, V) && We(T, re) || Se(T, V) || Se(T, re);
    }
    if (n.value.minDate) {
      const V = G(Nt(n.value.minDate));
      O = Ge(T, V) || Se(T, V);
    }
    if (n.value.maxDate) {
      const V = G(Nt(n.value.maxDate));
      O = We(T, V) || Se(T, V);
    }
    return O;
  }, se = E(() => (I) => !e.enableTimePicker || e.ignoreTimeValidation ? !0 : H(I)), de = E(() => (I) => e.monthPicker ? Array.isArray(I) && (a.value.enabled || r.value.enabled) ? !I.filter((O) => !ne(O)).length : ne(I) : !0);
  return {
    isDisabled: x,
    validateDate: s,
    validateMonthYearInRange: f,
    isDateRangeAllowed: g,
    checkMinMaxRange: b,
    isValidTime: H,
    isTimeValid: se,
    isMonthValid: de
  };
}, Nr = () => {
  const e = E(() => (n, r) => n == null ? void 0 : n.includes(r)), t = E(() => (n, r) => n.count ? n.solo ? !0 : r === 0 : !0), a = E(() => (n, r) => n.count ? n.solo ? !0 : r === n.count - 1 : !0);
  return { hideNavigationButtons: e, showLeftIcon: t, showRightIcon: a };
}, Xp = (e, t, a) => {
  const n = U(0), r = un({
    [Ea.timePicker]: !e.enableTimePicker || e.timePicker || e.monthPicker,
    [Ea.calendar]: !1,
    [Ea.header]: !1
  }), l = E(() => e.monthPicker || e.timePicker), o = (d) => {
    var f;
    if ((f = e.flow) != null && f.length) {
      if (!d && l.value)
        return u();
      r[d] = !0, Object.keys(r).filter((h) => !r[h]).length || u();
    }
  }, i = () => {
    var d, f;
    (d = e.flow) != null && d.length && n.value !== -1 && (n.value += 1, t("flow-step", n.value), u()), ((f = e.flow) == null ? void 0 : f.length) === n.value && at().then(() => s());
  }, s = () => {
    n.value = -1;
  }, c = (d, f, ...h) => {
    var x, g;
    e.flow[n.value] === d && a.value && ((g = (x = a.value)[f]) == null || g.call(x, ...h));
  }, u = (d = 0) => {
    d && (n.value += d), c(ct.month, "toggleMonthPicker", !0), c(ct.year, "toggleYearPicker", !0), c(ct.calendar, "toggleTimePicker", !1, !0), c(ct.time, "toggleTimePicker", !0, !0);
    const f = e.flow[n.value];
    (f === ct.hours || f === ct.minutes || f === ct.seconds) && c(f, "toggleTimePicker", !0, !0, f);
  };
  return { childMount: o, updateFlowStep: i, resetFlow: s, handleFlow: u, flowStep: n };
}, Qp = {
  key: 1,
  class: "dp__input_wrap"
}, Gp = ["id", "name", "inputmode", "placeholder", "disabled", "readonly", "required", "value", "autocomplete", "aria-disabled", "aria-invalid"], Jp = ["aria-label"], Zp = {
  key: 2,
  class: "dp__clear_icon"
}, e3 = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "DatepickerInput",
  props: {
    isMenuOpen: { type: Boolean, default: !1 },
    inputValue: { type: String, default: "" },
    ...$r
  },
  emits: [
    "clear",
    "open",
    "update:input-value",
    "set-input-date",
    "close",
    "select-date",
    "set-empty-date",
    "toggle",
    "focus-prev",
    "focus",
    "blur",
    "real-blur",
    "text-input"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, {
      defaultedTextInput: l,
      defaultedAriaLabels: o,
      defaultedInline: i,
      defaultedConfig: s,
      defaultedRange: c,
      defaultedMultiDates: u,
      defaultedUI: d,
      getDefaultPattern: f,
      getDefaultStartTime: h
    } = je(r), { checkMinMaxRange: x } = Ta(r), g = U(), D = U(null), k = U(!1), b = U(!1), m = U(!1), M = U(null), $ = E(
      () => ({
        dp__pointer: !r.disabled && !r.readonly && !l.value.enabled,
        dp__disabled: r.disabled,
        dp__input_readonly: !l.value.enabled,
        dp__input: !0,
        dp__input_icon_pad: !r.hideInputIcon,
        dp__input_valid: typeof r.state == "boolean" ? r.state : !1,
        dp__input_invalid: typeof r.state == "boolean" ? !r.state : !1,
        dp__input_focus: k.value || r.isMenuOpen,
        dp__input_reg: !l.value.enabled,
        ...d.value.input ?? {}
      })
    ), C = () => {
      n("set-input-date", null), r.clearable && r.autoApply && (n("set-empty-date"), g.value = null);
    }, _ = (A) => {
      const S = h();
      return v2(
        A,
        l.value.format ?? f(),
        S ?? mu({}, r.enableSeconds),
        r.inputValue,
        m.value,
        r.formatLocale
      );
    }, W = (A) => {
      const { rangeSeparator: S } = l.value, [v, R] = A.split(`${S}`);
      if (v) {
        const K = _(v.trim()), w = R ? _(R.trim()) : null;
        if (rn(K, w))
          return;
        const oe = K && w ? [K, w] : [K];
        x(w, oe, 0) && (g.value = K ? oe : null);
      }
    }, F = () => {
      m.value = !0;
    }, H = (A) => {
      if (c.value.enabled)
        W(A);
      else if (u.value.enabled) {
        const S = A.split(";");
        g.value = S.map((v) => _(v.trim())).filter((v) => v);
      } else
        g.value = _(A);
    }, ne = (A) => {
      var S;
      const v = typeof A == "string" ? A : (S = A.target) == null ? void 0 : S.value;
      v !== "" ? (l.value.openMenu && !r.isMenuOpen && n("open"), H(v), n("set-input-date", g.value)) : C(), m.value = !1, n("update:input-value", v), n("text-input", A, g.value);
    }, se = (A) => {
      l.value.enabled ? (H(A.target.value), l.value.enterSubmit && Sl(g.value) && r.inputValue !== "" ? (n("set-input-date", g.value, !0), g.value = null) : l.value.enterSubmit && r.inputValue === "" && (g.value = null, n("clear"))) : O(A);
    }, de = (A, S) => {
      var v;
      if (M.value && S && !b.value)
        return A.preventDefault(), b.value = !0, (v = M.value) == null ? void 0 : v.focus();
      l.value.enabled && l.value.tabSubmit && H(A.target.value), l.value.tabSubmit && Sl(g.value) && r.inputValue !== "" ? (n("set-input-date", g.value, !0, !0), g.value = null) : l.value.tabSubmit && r.inputValue === "" && (g.value = null, n("clear", !0));
    }, I = () => {
      k.value = !0, n("focus"), at().then(() => {
        var A;
        l.value.enabled && l.value.selectOnFocus && ((A = D.value) == null || A.select());
      });
    }, O = (A) => {
      if (A.preventDefault(), pa(A, s.value, !0), l.value.enabled && l.value.openMenu && !i.value.input) {
        if (l.value.openMenu === "open" && !r.isMenuOpen)
          return n("open");
        if (l.value.openMenu === "toggle")
          return n("toggle");
      } else
        l.value.enabled || n("toggle");
    }, T = () => {
      n("real-blur"), k.value = !1, (!r.isMenuOpen || i.value.enabled && i.value.input) && n("blur"), r.autoApply && l.value.enabled && g.value && !r.isMenuOpen && (n("set-input-date", g.value), n("select-date"), g.value = null);
    }, V = (A) => {
      pa(A, s.value, !0), n("clear");
    }, re = (A, S) => {
      if (A.key === "Tab" && de(A, S), A.key === "Enter" && se(A), !l.value.enabled) {
        if (A.code === "Tab")
          return;
        A.preventDefault();
      }
    }, q = () => {
      var A;
      (A = D.value) == null || A.focus({ preventScroll: !0 });
    }, le = (A) => {
      g.value = A;
    }, ge = (A) => {
      A.key === He.tab && (b.value = !1, de(A));
    };
    return t({
      focusInput: q,
      setParsedDate: le
    }), (A, S) => {
      var v, R;
      return P(), j("div", { onClick: O }, [
        A.$slots.trigger && !A.$slots["dp-input"] && !p(i).enabled ? ie(A.$slots, "trigger", { key: 0 }) : Q("", !0),
        !A.$slots.trigger && (!p(i).enabled || p(i).input) ? (P(), j("div", Qp, [
          A.$slots["dp-input"] && !A.$slots.trigger && (!p(i).enabled || p(i).enabled && p(i).input) ? ie(A.$slots, "dp-input", {
            key: 0,
            value: e.inputValue,
            isMenuOpen: e.isMenuOpen,
            onInput: ne,
            onEnter: se,
            onTab: de,
            onClear: V,
            onBlur: T,
            onKeypress: re,
            onPaste: F,
            onFocus: I,
            openMenu: () => A.$emit("open"),
            closeMenu: () => A.$emit("close"),
            toggleMenu: () => A.$emit("toggle")
          }) : Q("", !0),
          A.$slots["dp-input"] ? Q("", !0) : (P(), j("input", {
            key: 1,
            id: A.uid ? `dp-input-${A.uid}` : void 0,
            ref_key: "inputRef",
            ref: D,
            "data-test": "dp-input",
            name: A.name,
            class: we($.value),
            inputmode: p(l).enabled ? "text" : "none",
            placeholder: A.placeholder,
            disabled: A.disabled,
            readonly: A.readonly,
            required: A.required,
            value: e.inputValue,
            autocomplete: A.autocomplete,
            "aria-disabled": A.disabled || void 0,
            "aria-invalid": A.state === !1 ? !0 : void 0,
            onInput: ne,
            onBlur: T,
            onFocus: I,
            onKeypress: re,
            onKeydown: S[0] || (S[0] = (K) => re(K, !0)),
            onPaste: F
          }, null, 42, Gp)),
          ce("div", {
            "aria-label": (v = p(o)) == null ? void 0 : v.clearInput,
            onClick: S[3] || (S[3] = (K) => n("toggle"))
          }, [
            A.$slots["input-icon"] && !A.hideInputIcon ? (P(), j("span", {
              key: 0,
              class: "dp__input_icon",
              onClick: S[1] || (S[1] = (K) => n("toggle"))
            }, [
              ie(A.$slots, "input-icon")
            ])) : Q("", !0),
            !A.$slots["input-icon"] && !A.hideInputIcon && !A.$slots["dp-input"] ? (P(), Ae(p(dn), {
              key: 1,
              "aria-label": (R = p(o)) == null ? void 0 : R.calendarIcon,
              class: "dp__input_icon dp__input_icons",
              onClick: S[2] || (S[2] = (K) => n("toggle"))
            }, null, 8, ["aria-label"])) : Q("", !0)
          ], 8, Jp),
          A.$slots["clear-icon"] && e.inputValue && A.clearable && !A.disabled && !A.readonly ? (P(), j("span", Zp, [
            ie(A.$slots, "clear-icon", { clear: V })
          ])) : Q("", !0),
          A.clearable && !A.$slots["clear-icon"] && e.inputValue && !A.disabled && !A.readonly ? (P(), j("button", {
            key: 3,
            ref_key: "clearBtnRef",
            ref: M,
            class: "dp--clear-btn",
            type: "button",
            onBlur: S[4] || (S[4] = (K) => b.value = !1),
            onKeydown: S[5] || (S[5] = (K) => p(pt)(K, () => V(K), !0, ge)),
            onClick: S[6] || (S[6] = Ra((K) => V(K), ["prevent"]))
          }, [
            Fe(p(ou), {
              class: "dp__input_icons",
              "data-test": "clear-icon"
            })
          ], 544)) : Q("", !0)
        ])) : Q("", !0)
      ]);
    };
  }
}), t3 = typeof window < "u" ? window : void 0, il = () => {
}, a3 = (e) => Eu() ? (Nu(e), !0) : !1, n3 = (e, t, a, n) => {
  if (!e)
    return il;
  let r = il;
  const l = it(
    () => p(e),
    (i) => {
      r(), i && (i.addEventListener(t, a, n), r = () => {
        i.removeEventListener(t, a, n), r = il;
      });
    },
    { immediate: !0, flush: "post" }
  ), o = () => {
    l(), r();
  };
  return a3(o), o;
}, r3 = (e, t, a, n = {}) => {
  const { window: r = t3, event: l = "pointerdown" } = n;
  return r ? n3(r, l, (o) => {
    const i = nt(e), s = nt(t);
    !i || !s || i === o.target || o.composedPath().includes(i) || o.composedPath().includes(s) || a(o);
  }, { passive: !0 }) : void 0;
}, l3 = /* @__PURE__ */ xe({
  compatConfig: {
    MODE: 3
  },
  __name: "VueDatePicker",
  props: {
    ...$r
  },
  emits: [
    "update:model-value",
    "update:model-timezone-value",
    "text-submit",
    "closed",
    "cleared",
    "open",
    "focus",
    "blur",
    "internal-model-change",
    "recalculate-position",
    "flow-step",
    "update-month-year",
    "invalid-select",
    "invalid-fixed-range",
    "tooltip-open",
    "tooltip-close",
    "time-picker-open",
    "time-picker-close",
    "am-pm-change",
    "range-start",
    "range-end",
    "date-update",
    "invalid-date",
    "overlay-toggle",
    "text-input"
  ],
  setup(e, { expose: t, emit: a }) {
    const n = a, r = e, l = Ba(), o = U(!1), i = Ke(r, "modelValue"), s = Ke(r, "timezone"), c = U(null), u = U(null), d = U(null), f = U(!1), h = U(null), x = U(!1), g = U(!1), D = U(!1), k = U(!1), { setMenuFocused: b, setShiftKey: m } = bu(), { clearArrowNav: M } = Aa(), { validateDate: $, isValidTime: C } = Ta(r), {
      defaultedTransitions: _,
      defaultedTextInput: W,
      defaultedInline: F,
      defaultedConfig: H,
      defaultedRange: ne,
      defaultedMultiDates: se
    } = je(r), { menuTransition: de, showTransition: I } = Vn(_);
    Le(() => {
      R(r.modelValue), at().then(() => {
        if (!F.value.enabled) {
          const B = ge(h.value);
          B == null || B.addEventListener("scroll", ee), window == null || window.addEventListener("resize", Y);
        }
      }), F.value.enabled && (o.value = !0), window == null || window.addEventListener("keyup", Te), window == null || window.addEventListener("keydown", N);
    }), ra(() => {
      if (!F.value.enabled) {
        const B = ge(h.value);
        B == null || B.removeEventListener("scroll", ee), window == null || window.removeEventListener("resize", Y);
      }
      window == null || window.removeEventListener("keyup", Te), window == null || window.removeEventListener("keydown", N);
    });
    const O = _t(l, "all", r.presetDates), T = _t(l, "input");
    it(
      [i, s],
      () => {
        R(i.value);
      },
      { deep: !0 }
    );
    const { openOnTop: V, menuStyle: re, xCorrect: q, setMenuPosition: le, getScrollableParent: ge, shadowRender: A } = Up({
      menuRef: c,
      menuRefInner: u,
      inputRef: d,
      pickerWrapperRef: h,
      inline: F,
      emit: n,
      props: r,
      slots: l
    }), {
      inputValue: S,
      internalModelValue: v,
      parseExternalModelValue: R,
      emitModelValue: K,
      formatInputValue: w,
      checkBeforeEmit: oe
    } = I2(n, r, f), ke = E(
      () => ({
        dp__main: !0,
        dp__theme_dark: r.dark,
        dp__theme_light: !r.dark,
        dp__flex_display: F.value.enabled,
        "dp--flex-display-collapsed": D.value,
        dp__flex_display_with_input: F.value.input
      })
    ), et = E(() => r.dark ? "dp__theme_dark" : "dp__theme_light"), z = E(() => r.teleport ? {
      to: typeof r.teleport == "boolean" ? "body" : r.teleport,
      disabled: !r.teleport || F.value.enabled
    } : {}), Z = E(() => ({ class: "dp__outer_menu_wrap" })), y = E(() => F.value.enabled && (r.timePicker || r.monthPicker || r.yearPicker || r.quarterPicker)), X = () => {
      var B, te;
      return (te = (B = d.value) == null ? void 0 : B.$el) == null ? void 0 : te.getBoundingClientRect();
    }, ee = () => {
      o.value && (H.value.closeOnScroll ? kt() : le());
    }, Y = () => {
      var B;
      o.value && le();
      const te = (B = u.value) == null ? void 0 : B.$el.getBoundingClientRect().width;
      D.value = document.body.offsetWidth <= te;
    }, Te = (B) => {
      B.key === "Tab" && !F.value.enabled && !r.teleport && H.value.tabOutClosesMenu && (h.value.contains(document.activeElement) || kt()), g.value = B.shiftKey;
    }, N = (B) => {
      g.value = B.shiftKey;
    }, J = () => {
      !r.disabled && !r.readonly && (A(xi, r), le(!1), o.value = !0, o.value && n("open"), o.value || fn(), R(r.modelValue));
    }, me = () => {
      var B;
      S.value = "", fn(), (B = d.value) == null || B.setParsedDate(null), n("update:model-value", null), n("update:model-timezone-value", null), n("cleared"), H.value.closeOnClearValue && kt();
    }, Oe = () => {
      const B = v.value;
      return !B || !Array.isArray(B) && $(B) ? !0 : Array.isArray(B) ? se.value.enabled || B.length === 2 && $(B[0]) && $(B[1]) ? !0 : ne.value.partialRange && !r.timePicker ? $(B[0]) : !1 : !1;
    }, Tt = () => {
      oe() && Oe() ? (K(), kt()) : n("invalid-select", v.value);
    }, be = (B) => {
      ia(), K(), H.value.closeOnAutoApply && !B && kt();
    }, ia = () => {
      d.value && W.value.enabled && d.value.setParsedDate(v.value);
    }, Bt = (B = !1) => {
      r.autoApply && C(v.value) && Oe() && (ne.value.enabled && Array.isArray(v.value) ? (ne.value.partialRange || v.value.length === 2) && be(B) : be(B));
    }, fn = () => {
      W.value.enabled || (v.value = null);
    }, kt = () => {
      F.value.enabled || (o.value && (o.value = !1, q.value = !1, b(!1), m(!1), M(), n("closed"), S.value && R(i.value)), fn(), n("blur"));
    }, vn = (B, te, ue = !1) => {
      if (!B) {
        v.value = null;
        return;
      }
      const ft = Array.isArray(B) ? !B.some((Oa) => !$(Oa)) : $(B), Ot = C(B);
      ft && Ot && (k.value = !0, v.value = B, te && (x.value = ue, Tt(), n("text-submit")), at().then(() => {
        k.value = !1;
      }));
    }, Rr = () => {
      r.autoApply && C(v.value) && K(), ia();
    }, Yn = () => o.value ? kt() : J(), Ir = (B) => {
      v.value = B;
    }, Hr = () => {
      W.value.enabled && (f.value = !0, w()), n("focus");
    }, zr = () => {
      if (W.value.enabled && (f.value = !1, R(r.modelValue), x.value)) {
        const B = c2(h.value, g.value);
        B == null || B.focus();
      }
      n("blur");
    }, Vr = (B) => {
      u.value && u.value.updateMonthYear(0, {
        month: pi(B.month),
        year: pi(B.year)
      });
    }, Br = (B) => {
      R(B ?? r.modelValue);
    }, Yr = (B, te) => {
      var ue;
      (ue = u.value) == null || ue.switchView(B, te);
    }, L = (B) => H.value.onClickOutside ? H.value.onClickOutside(B) : kt(), ae = (B = 0) => {
      var te;
      (te = u.value) == null || te.handleFlow(B);
    };
    return r3(c, d, () => L(Oe)), t({
      closeMenu: kt,
      selectDate: Tt,
      clearValue: me,
      openMenu: J,
      onScroll: ee,
      formatInputValue: w,
      // exposed for testing purposes
      updateInternalModelValue: Ir,
      // modify internal modelValue
      setMonthYear: Vr,
      parseModel: Br,
      switchView: Yr,
      toggleMenu: Yn,
      handleFlow: ae,
      dpWrapMenuRef: c
    }), (B, te) => (P(), j("div", {
      ref_key: "pickerWrapperRef",
      ref: h,
      class: we(ke.value),
      "data-datepicker-instance": ""
    }, [
      Fe(e3, Ne({
        ref_key: "inputRef",
        ref: d,
        "input-value": p(S),
        "onUpdate:inputValue": te[0] || (te[0] = (ue) => Gt(S) ? S.value = ue : null),
        "is-menu-open": o.value
      }, B.$props, {
        onClear: me,
        onOpen: J,
        onSetInputDate: vn,
        onSetEmptyDate: p(K),
        onSelectDate: Tt,
        onToggle: Yn,
        onClose: kt,
        onFocus: Hr,
        onBlur: zr,
        onRealBlur: te[1] || (te[1] = (ue) => f.value = !1),
        onTextInput: te[2] || (te[2] = (ue) => B.$emit("text-input", ue))
      }), dt({ _: 2 }, [
        Re(p(T), (ue, ft) => ({
          name: ue,
          fn: ye((Ot) => [
            ie(B.$slots, ue, ot(gt(Ot)))
          ])
        }))
      ]), 1040, ["input-value", "is-menu-open", "onSetEmptyDate"]),
      (P(), Ae(kr(B.teleport ? Ti : "div"), ot(gt(z.value)), {
        default: ye(() => [
          Fe(Va, {
            name: p(de)(p(V)),
            css: p(I) && !p(F).enabled
          }, {
            default: ye(() => [
              o.value ? (P(), j("div", Ne({
                key: 0,
                ref_key: "dpWrapMenuRef",
                ref: c
              }, Z.value, {
                class: { "dp--menu-wrapper": !p(F).enabled },
                style: p(F).enabled ? void 0 : p(re)
              }), [
                Fe(xi, Ne({
                  ref_key: "dpMenuRef",
                  ref: u
                }, B.$props, {
                  "internal-model-value": p(v),
                  "onUpdate:internalModelValue": te[3] || (te[3] = (ue) => Gt(v) ? v.value = ue : null),
                  class: { [et.value]: !0, "dp--menu-wrapper": B.teleport },
                  "open-on-top": p(V),
                  "no-overlay-focus": y.value,
                  collapse: D.value,
                  "get-input-rect": X,
                  "is-text-input-date": k.value,
                  onClosePicker: kt,
                  onSelectDate: Tt,
                  onAutoApply: Bt,
                  onTimeUpdate: Rr,
                  onFlowStep: te[4] || (te[4] = (ue) => B.$emit("flow-step", ue)),
                  onUpdateMonthYear: te[5] || (te[5] = (ue) => B.$emit("update-month-year", ue)),
                  onInvalidSelect: te[6] || (te[6] = (ue) => B.$emit("invalid-select", p(v))),
                  onAutoApplyInvalid: te[7] || (te[7] = (ue) => B.$emit("invalid-select", ue)),
                  onInvalidFixedRange: te[8] || (te[8] = (ue) => B.$emit("invalid-fixed-range", ue)),
                  onRecalculatePosition: p(le),
                  onTooltipOpen: te[9] || (te[9] = (ue) => B.$emit("tooltip-open", ue)),
                  onTooltipClose: te[10] || (te[10] = (ue) => B.$emit("tooltip-close", ue)),
                  onTimePickerOpen: te[11] || (te[11] = (ue) => B.$emit("time-picker-open", ue)),
                  onTimePickerClose: te[12] || (te[12] = (ue) => B.$emit("time-picker-close", ue)),
                  onAmPmChange: te[13] || (te[13] = (ue) => B.$emit("am-pm-change", ue)),
                  onRangeStart: te[14] || (te[14] = (ue) => B.$emit("range-start", ue)),
                  onRangeEnd: te[15] || (te[15] = (ue) => B.$emit("range-end", ue)),
                  onDateUpdate: te[16] || (te[16] = (ue) => B.$emit("date-update", ue)),
                  onInvalidDate: te[17] || (te[17] = (ue) => B.$emit("invalid-date", ue)),
                  onOverlayToggle: te[18] || (te[18] = (ue) => B.$emit("overlay-toggle", ue))
                }), dt({ _: 2 }, [
                  Re(p(O), (ue, ft) => ({
                    name: ue,
                    fn: ye((Ot) => [
                      ie(B.$slots, ue, ot(gt({ ...Ot })))
                    ])
                  }))
                ]), 1040, ["internal-model-value", "class", "open-on-top", "no-overlay-focus", "collapse", "is-text-input-date", "onRecalculatePosition"])
              ], 16)) : Q("", !0)
            ]),
            _: 3
          }, 8, ["name", "css"])
        ]),
        _: 3
      }, 16))
    ], 2));
  }
}), bo = /* @__PURE__ */ (() => {
  const e = l3;
  return e.install = (t) => {
    t.component("Vue3DatePicker", e);
  }, e;
})(), o3 = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  default: bo
}, Symbol.toStringTag, { value: "Module" }));
Object.entries(o3).forEach(([e, t]) => {
  e !== "default" && (bo[e] = t);
});
const i3 = ["type", "disabled"], _i = /* @__PURE__ */ xe({
  __name: "UIButton",
  props: {
    size: { default: "md" },
    type: { default: "default" },
    variant: { default: "default" },
    submit: { type: Boolean },
    disabled: { type: Boolean },
    to: {},
    openInNewTab: { type: Boolean }
  },
  setup(e) {
    const t = e, { isSubmitting: a } = Hu(), n = E(() => t.submit && a.value);
    return (r, l) => {
      const o = Oi("router-link");
      return r.to ? (P(), Ae(o, {
        key: 1,
        to: r.to,
        disabled: r.disabled,
        class: we(["tw-reset focus:outline-none", {
          "text-center text-base font-bold rounded-md border focus:shadow-glow disabled:bg-ui-gray-300 disabled:border-ui-gray-300 disabled:text-white": r.type !== "link",
          "hover:underline disabled:text-ui-gray-300 cursor-pointer font-bold focus:underline": r.type === "link",
          "border-pivot-teal-700 bg-pivot-teal-700 text-white hover:bg-pivot-teal-800 hover:border-pivot-teal-800": r.variant === "default" && r.type === "default",
          "border-pivot-purple-600 bg-pivot-purple-600 text-white hover:bg-pivot-purple-700 hover:border-pivot-purple-700": r.variant === "alt" && r.type === "default",
          "border-error-red-600 bg-error-red-600 text-white hover:bg-error-red-700 hover:border-error-red-700": r.variant === "destructive" && r.type === "default",
          "border-transparent bg-transparent text-pivot-teal-600 hover:bg-pivot-teal-50 disabled:text-white": r.variant === "reverse" && r.type === "default",
          "border-pivot-teal-700 bg-white text-pivot-teal-700 hover:border-pivot-teal-800 hover:text-pivot-teal-800 disabled:bg-transparent": r.variant === "default" && r.type === "outline",
          "border-pivot-purple-600 bg-white text-pivot-purple-600 hover:border-pivot-purple-700 hover:text-pivot-purple-700 disabled:bg-transparent": r.variant === "alt" && r.type === "outline",
          "border-error-red-600 bg-white text-error-red-600 hover:border-error-red-700 hover:text-error-red-700 disabled:bg-transparent": r.variant === "destructive" && r.type === "outline",
          "border-white bg-white text-white hover:bg-pivot-teal-50 disabled:bg-transparent": r.variant === "reverse" && r.type === "outline",
          "border-transparent bg-white text-pivot-teal-700 hover:border-pivot-teal-700 disabled:bg-transparent": r.variant === "default" && r.type === "invisible",
          "border-transparent bg-white text-pivot-purple-600 hover:border-pivot-purple-600 disabled:bg-transparent": r.variant === "alt" && r.type === "invisible",
          "border-transparent bg-white text-error-red-600 hover:border-error-red-600 disabled:bg-transparent": r.variant === "destructive" && r.type === "invisible",
          "text-error-red-600": r.variant === "destructive" && r.type === "link",
          "text-pivot-teal-700": r.variant !== "destructive" && r.type === "link",
          "py-1.5 px-5": r.size === "md" && r.type !== "link",
          "py-0.5 px-2": r.size === "sm" && r.type !== "link"
        }]),
        target: r.openInNewTab ? "_blank" : "_self"
      }, {
        default: ye(() => [
          ie(r.$slots, "default")
        ]),
        _: 3
      }, 8, ["to", "disabled", "target", "class"])) : (P(), j("button", {
        key: 0,
        type: r.submit ? "submit" : "button",
        disabled: r.disabled,
        class: we(["tw-reset focus:outline-none", {
          "text-center text-base font-bold rounded-md border focus:shadow-glow disabled:bg-ui-gray-300 disabled:border-ui-gray-300 disabled:text-white": r.type !== "link",
          "hover:underline disabled:text-ui-gray-300 cursor-pointer font-bold focus:underline": r.type === "link",
          "border-pivot-teal-700 bg-pivot-teal-700 text-white hover:bg-pivot-teal-800 hover:border-pivot-teal-800": r.variant === "default" && r.type === "default",
          "border-pivot-purple-600 bg-pivot-purple-600 text-white hover:bg-pivot-purple-700 hover:border-pivot-purple-700": r.variant === "alt" && r.type === "default",
          "border-error-red-600 bg-error-red-600 text-white hover:bg-error-red-700 hover:border-error-red-700": r.variant === "destructive" && r.type === "default",
          "border-transparent bg-transparent text-pivot-teal-600 hover:bg-pivot-teal-50 disabled:text-white": r.variant === "reverse" && r.type === "default",
          "border-pivot-teal-700 text-pivot-teal-700 hover:border-pivot-teal-800 hover:text-pivot-teal-800 disabled:bg-transparent": r.variant === "default" && r.type === "outline",
          "border-pivot-purple-600 text-pivot-purple-600 hover:border-pivot-purple-700 hover:text-pivot-purple-700 disabled:bg-transparent": r.variant === "alt" && r.type === "outline",
          "border-error-red-600 text-error-red-600 hover:border-error-red-700 hover:text-error-red-700 disabled:bg-transparent": r.variant === "destructive" && r.type === "outline",
          "border-white bg-white text-white hover:bg-pivot-teal-50 disabled:bg-transparent": r.variant === "reverse" && r.type === "outline",
          "border-transparent text-pivot-teal-700 hover:border-pivot-teal-700 disabled:bg-transparent": r.variant === "default" && r.type === "invisible",
          "border-transparent text-pivot-purple-600 hover:border-pivot-purple-600 disabled:bg-transparent": r.variant === "alt" && r.type === "invisible",
          "border-transparent text-error-red-600 hover:border-error-red-600 disabled:bg-transparent": r.variant === "destructive" && r.type === "invisible",
          "text-error-red-600": r.variant === "destructive" && r.type === "link",
          "text-pivot-teal-700": r.variant !== "destructive" && r.type === "link",
          "py-1.5 px-5": r.size === "md" && r.type !== "link",
          "py-0.5 px-2": r.size === "sm" && r.type !== "link"
        }])
      }, [
        n.value ? (P(), j(Me, { key: 1 }, [
          ce("div", {
            class: we(`inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] ${!r.variant || r.variant == "default" ? "text-white" : "text-pivot-teal-800"}`),
            role: "status",
            "aria-hidden": "true"
          }, null, 2),
          st(" Submitting ")
        ], 64)) : ie(r.$slots, "default", { key: 0 })
      ], 10, i3));
    };
  }
}), s3 = ["disabled", "onClick"], u3 = /* @__PURE__ */ ce("span", { class: "sr-only" }, "Open Calendar", -1), c3 = ["value", "disabled", "onInput", "onBlur", "onKeydown", "onPaste"], d3 = { class: "flex gap-3 items-center w-full mt-2" }, f3 = /* @__PURE__ */ ce("div", { class: "flex-grow" }, null, -1), D3 = /* @__PURE__ */ xe({
  __name: "UIDateInput",
  props: {
    modelValue: {},
    name: {},
    rules: {},
    label: { default: "date" },
    disabled: { type: Boolean },
    clearable: { type: Boolean },
    time: { type: Boolean },
    max: {},
    min: {}
  },
  setup(e) {
    const t = e, { value: a, errorMessage: n, handleBlur: r } = Ya(
      Ke(t, "name"),
      Ke(t, "rules"),
      {
        label: Ke(t, "label"),
        syncVModel: !0
      }
    ), l = /^\s*(\d+)\s*([/\-.])\s*(\d+)(?:\s*\2\s*(\d+))?(?:(?:\s*,\s*|\s+)(\d+)\s*:\s*(\d+)(?:\s*([APap][mM]))?)?\s*$/;
    function o(i) {
      var u;
      const s = /* @__PURE__ */ new Date();
      s.setSeconds(0), s.setMilliseconds(0);
      const c = l.exec(i);
      if (!c)
        return null;
      if (c[4]) {
        const d = parseInt(c[4]);
        s.setFullYear(d < 100 ? d + 2e3 : d);
      }
      if (s.setMonth(parseInt(c[1]) - 1), s.setDate(parseInt(c[3])), c[5] && c[6]) {
        const d = ((u = c[7]) == null ? void 0 : u.toLowerCase()) == "pm";
        let f = parseInt(c[5]);
        s.setHours(f < 12 && d ? f + 12 : f), s.setMinutes(parseInt(c[6]));
      }
      return s;
    }
    return (i, s) => (P(), j("div", null, [
      Fe(p(bo), {
        modelValue: p(a),
        "onUpdate:modelValue": s[0] || (s[0] = (c) => Gt(a) ? a.value = c : null),
        class: "date-picker",
        disabled: i.disabled,
        clearable: i.clearable,
        "enable-time-picker": i.time,
        "text-input": { openMenu: !1, format: o },
        "min-date": i.min,
        "max-date": i.max,
        "auto-apply": !i.time,
        "six-weeks": "center",
        teleport: "",
        "time-picker-inline": "",
        "is-24": !1,
        onKeydown: s[1] || (s[1] = lr(Ra(() => {
        }, ["prevent"]), ["enter"])),
        onBlur: p(r)
      }, {
        "dp-input": ye(({
          value: c,
          onInput: u,
          onEnter: d,
          onTab: f,
          onBlur: h,
          onKeypress: x,
          onPaste: g,
          toggleMenu: D
        }) => [
          ce("div", {
            class: we(["tw-reset flex w-full h-8 border rounded-sm border-ui-gray-400 focus-within:shadow-glow", {
              "border-warning-orange-600": p(n)
            }])
          }, [
            ce("button", {
              type: "button",
              class: we(["bg-pivot-teal-700 text-white hover:bg-pivot-teal-800 focus:outline-none w-8 h-full disabled:bg-ui-gray-300", {
                "text-warning-orange-600 italic": p(n)
              }]),
              disabled: i.disabled,
              tabindex: "-1",
              onClick: D
            }, [
              Fe(p(Fa), { icon: "calendar" }),
              u3
            ], 10, s3),
            ce("input", {
              class: we(["inline-block px-2 h-full w-full rounded-sm focus:outline-none placeholder:text-ui-gray-400 disabled:text-ui-gray-300 disabled:bg-ui-gray-100", {
                "pr-8": i.clearable
              }]),
              value: c,
              disabled: i.disabled,
              onInput: u,
              onBlur: h,
              onKeydown: [
                x,
                lr(d, ["enter"]),
                lr(f, ["tab"])
              ],
              onPaste: g
            }, null, 42, c3)
          ], 2)
        ]),
        "action-row": ye(({ selectDate: c }) => [
          ce("div", d3, [
            i.clearable ? (P(), Ae(_i, {
              key: 0,
              size: "sm",
              type: "link",
              variant: "destructive",
              onClick: i.clearDate
            }, {
              default: ye(() => [
                st(" Clear ")
              ]),
              _: 1
            }, 8, ["onClick"])) : Q("", !0),
            f3,
            Fe(_i, {
              size: "sm",
              onClick: c
            }, {
              default: ye(() => [
                st(" Select ")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "disabled", "clearable", "enable-time-picker", "text-input", "min-date", "max-date", "auto-apply", "onBlur"])
    ]));
  }
}), v3 = { class: "tw-reset overflow-hidden rounded-lg bg-white shadow-lg border border-ui-gray-300 p-4" }, $3 = /* @__PURE__ */ xe({
  __name: "UICard",
  props: {
    collapsible: { type: Boolean },
    modelValue: { type: Boolean },
    "onUpdate:modelValue": { type: Function }
  },
  setup(e) {
    const t = e, a = U(!1), n = E(() => !!t["onUpdate:modelValue"]), r = E({
      get() {
        return t.collapsible ? n.value ? !!t.modelValue : a.value : !1;
      },
      set(l) {
        var o;
        n.value ? (o = t["onUpdate:modelValue"]) == null || o.call(t, l) : a.value = l;
      }
    });
    return Jt(
      "card",
      un({ collapsed: r, collapsible: Ke(t, "collapsible") })
    ), (l, o) => (P(), j("div", v3, [
      ie(l.$slots, "default")
    ]));
  }
}), L3 = /* @__PURE__ */ xe({
  __name: "UICardHeading",
  setup(e) {
    const t = zt("card");
    if (!t)
      throw new Error("UICardHeading must be used inside UICard");
    const a = Ke(t, "collapsible"), n = Ke(t, "collapsed");
    function r() {
      a.value && (n.value = !n.value);
    }
    return (l, o) => (P(), j("div", {
      class: we(["tw-reset text-pivot-purple-700 font-bold text-lg", {
        "cursor-pointer": a.value
      }]),
      onClick: r
    }, [
      a.value ? (P(), Ae(p(Fa), {
        key: 0,
        icon: n.value ? "chevron-up" : "chevron-down",
        class: "text-pivot-purple-700 pr-2 font-base",
        size: "lg"
      }, null, 8, ["icon"])) : Q("", !0),
      ie(l.$slots, "default")
    ], 2));
  }
}), E3 = /* @__PURE__ */ xe({
  __name: "UICardContent",
  setup(e) {
    const t = zt("card");
    if (!t)
      throw new Error("UICardContent must be used inside UICard");
    const a = Ke(t, "collapsed");
    return (n, r) => (P(), Ae(p(Ts), {
      show: !a.value,
      class: "tw-reset",
      enter: "transition duration-100 ease-out",
      "enter-from": "transform opacity-0",
      "enter-to": "transform opacity-100",
      leave: "transition duration-75 ease-out",
      "leave-from": "transform opacity-100",
      "leave-to": "transform opacity-0"
    }, {
      default: ye(() => [
        ie(n.$slots, "default")
      ]),
      _: 3
    }, 8, ["show"]));
  }
}), m3 = { key: 0 }, Mi = /* @__PURE__ */ xe({
  __name: "UIStep",
  props: {
    state: { default: "future" },
    index: {}
  },
  setup(e) {
    return (t, a) => (P(), j("div", {
      class: we(["group flex text-base font-bold items-center", {
        "text-ui-gray-800": t.state !== "future",
        "text-ui-gray-400 hover:text-ui-gray-600": t.state === "future"
      }])
    }, [
      ce("span", {
        class: we(["border rounded-full w-6 h-6 mr-2 flex items-center justify-center", {
          "border-ui-gray-400 group-hover:border-ui-gray-600 group-hover:text-ui-gray-800": t.state === "future",
          "bg-pivot-teal-700 border-pivot-teal-700": t.state === "complete",
          "bg-warning-orange-600 border-warning-orange-600": t.state === "warn",
          "bg-pivot-purple-700 border-pivot-purple-700": t.state === "current",
          "text-white": t.state !== "future"
        }])
      }, [
        t.state !== "complete" && t.state !== "warn" ? (P(), j("span", m3, Ye(t.index), 1)) : Q("", !0),
        t.state === "complete" || t.state === "warn" ? (P(), Ae(p(Fa), {
          key: 1,
          icon: t.state === "complete" ? "check" : "exclamation",
          class: "w-4 h-4 inline-flex"
        }, null, 8, ["icon"])) : Q("", !0)
      ], 2),
      ie(t.$slots, "default")
    ], 2));
  }
}), p3 = { "aria-label": "Progress" }, h3 = {
  role: "list",
  class: "flex items-center"
}, N3 = /* @__PURE__ */ xe({
  __name: "UIStepList",
  props: {
    steps: {},
    currentStep: {},
    erroredSteps: { default: () => [] }
  },
  setup(e) {
    const t = e, a = E(() => {
      if (!t.currentStep)
        return [];
      const n = t.steps.findIndex(
        (r) => r.key === t.currentStep
      );
      return t.steps.slice(0, n).map((r) => r.key);
    });
    return (n, r) => {
      const l = Oi("router-link");
      return P(), j("nav", p3, [
        ce("ol", h3, [
          (P(!0), j(Me, null, Re(n.steps, (o, i) => (P(), j("li", {
            key: o.key,
            class: "pr-8 last:pr-0 relative"
          }, [
            o.href ? (P(), Ae(l, {
              key: 0,
              to: o.href,
              class: "cursor-pointer"
            }, {
              default: ye(() => [
                Fe(Mi, {
                  index: i + 1,
                  state: n.erroredSteps.includes(o.key) ? "warn" : o.key === n.currentStep ? "current" : a.value.includes(o.key) ? "complete" : "future"
                }, {
                  default: ye(() => [
                    st(Ye(o.name), 1)
                  ]),
                  _: 2
                }, 1032, ["index", "state"])
              ]),
              _: 2
            }, 1032, ["to"])) : (P(), Ae(Mi, {
              key: 1,
              index: i + 1,
              state: n.erroredSteps.includes(o.key) ? "warn" : o.key === n.currentStep ? "current" : a.value.includes(o.key) ? "complete" : "future"
            }, {
              default: ye(() => [
                st(Ye(o.name), 1)
              ]),
              _: 2
            }, 1032, ["index", "state"]))
          ]))), 128))
        ])
      ]);
    };
  }
});
export {
  _i as UIButton,
  $3 as UICard,
  E3 as UICardContent,
  L3 as UICardHeading,
  A3 as UICheckbox,
  D3 as UIDateInput,
  M3 as UIFieldError,
  k3 as UIForm,
  Fa as UIIcon,
  x3 as UIIconLayers,
  w3 as UILabel,
  S3 as UINumberInput,
  T3 as UIRadioButton,
  C3 as UISelectInput,
  N3 as UIStepList,
  P3 as UISwitch,
  _3 as UITextInput
};
