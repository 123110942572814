import { GetLtiSessionResponse, LtiConfigRequest } from '@pi/api-types'
import { LtiMessageTypeValue } from '@pi/types'
import { postRedirect } from 'src/setup/mixins/form-post'
import client from 'src/shared/api-client'
export async function cancelLTIFlow(
  session: GetLtiSessionResponse | undefined
) {
  if (
    session &&
    session.context.messageType === LtiMessageTypeValue.DeepLinkingRequest
  ) {
    const response = await client.lti.cancelConnection()
    const { redirectUrl, openedInNewTab, token } = response

    if (openedInNewTab) {
      window.opener.postMessage(
        JSON.stringify({
          type: 'pivot:deep-link-response',
          redirectUrl,
          token
        }),
        `${window.location.protocol}//${window.PI_API_HOST}`
      )
      window.close()
    } else {
      postRedirect(redirectUrl, { JWT: token })
    }
  } else {
    window.close()
  }
}
